import React, { useState } from 'react';
import { Autocomplete } from 'ui/components';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import ModalAlterarFormaPagamento from '../modalAlterarFormaPagamento';

export default function InfoPagamentoDemanda({
  data,
  setData,
  setLoading,
  infoPagamentoDefault,
  onSetMessage,
}) {
  const [showModal, setShowModal] = useState({ show: false });

  const onAutoCompleteFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });

    return formasPagamento;
  };

  const onSelectFormaPagamento = async (
    formaPagamento,
    atualizaData = true
  ) => {
    setLoading(true);

    let buscarInformacaoBancaria = true;

    let objData = {
      formaPagamento,
      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
    };

    if (formaPagamento.nrSeqFormaPagamento) {
      if (infoPagamentoDefault.nrSeqFormaPagamento) {
        if (
          formaPagamento.nrSeqFormaPagamento !==
          infoPagamentoDefault.nrSeqFormaPagamento
        ) {
          buscarInformacaoBancaria = false;

          setShowModal({
            show: true,
            formaPagamento,
          });
        }
      }

      if (buscarInformacaoBancaria) {
        if (formaPagamento.flgGrupo === 'D' && data.nrSeqPessoaCli) {
          const {
            informacoesBancarias,
          } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
            nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
            nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
          });

          if (informacoesBancarias.length === 1) {
            objData = {
              informacaoBancaria: informacoesBancarias[0],
              nrSeqPessoaInformacaoBancaria:
                informacoesBancarias[0].nrSeqPessoaInformacaoBancaria,
            };
          } else {
            objData = {
              informacaoBancaria: undefined,
              nrSeqPessoaInformacaoBancaria: undefined,
            };
          }
        } else {
          objData = {
            informacaoBancaria: undefined,
            nrSeqPessoaInformacaoBancaria: undefined,
          };
        }
      }
    } else {
      objData = {
        formaPagamento: undefined,
        nrSeqFormaPagamento: undefined,
        informacaoBancaria: undefined,
        nrSeqPessoaInformacaoBancaria: undefined,
      };
    }

    if (atualizaData) {
      setData({ ...data, ...objData });
    }

    setLoading(false);

    return objData;
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });

    onSetMessage(status, msg);

    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });

    onSetMessage(status, msg);

    return informacoesBancarias;
  };

  return (
    <>
      <div className='col'>
        <Autocomplete
          label='Forma de Pagamento'
          enabled={data.flgPermiteAlterar}
          required
          searchDataSource={onAutoCompleteFormaPagamento}
          selectedItem={data.formaPagamento}
          onSelectItem={(formaPagamento) => {
            data.formaPagamento = formaPagamento;
            data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;

            onSelectFormaPagamento(formaPagamento);
          }}
          dataSourceTextProperty='noFormaPagamento'
        />
      </div>
      <div className='col-2'>
        <Autocomplete
          label='Vencimento'
          enabled={data.flgPermiteAlterar}
          required
          searchDataSource={onSearchVencimento}
          selectedItem={data.vencimento}
          onSelectItem={(vencimento) =>
            setData({
              ...data,
              vencimento,
              nrSeqVencimento: vencimento.nrSeqVencimento,
            })
          }
          dataSourceTextProperty='noVencimento'
        />
      </div>
      <div className='col'>
        <Autocomplete
          label='Informação Bancária'
          enabled={data.flgPermiteAlterar}
          visible={
            data.nrSeqFormaPagamento !== null &&
            data.nrSeqFormaPagamento !== undefined &&
            data.formaPagamento?.flgGrupo === 'D'
          }
          required={data.formaPagamento?.flgGrupo === 'D'}
          searchDataSource={onSearchInformacaoBancaria}
          selectedItem={data.informacaoBancaria}
          onSelectItem={(informacaoBancaria) =>
            setData({
              ...data,
              informacaoBancaria,
              nrSeqPessoaInformacaoBancaria:
                informacaoBancaria.nrSeqPessoaInformacaoBancaria,
            })
          }
          dataSourceTextProperty='noDescricao'
        />
      </div>

      <ModalAlterarFormaPagamento
        show={showModal.show}
        onClose={() =>
          setShowModal({
            show: false,
          })
        }
        formaPagamento={showModal.formaPagamento}
        formaPagamentoDefault={infoPagamentoDefault.formaPagamento}
        data={data}
        setData={setData}
      />
    </>
  );
}
