import Cidade from './cidade';
import Estado from './estado';
import Pais from './pais';
import TipoEndereco from './tipoEndereco';

export default class Endereco {
  constructor(jsonObject = {}) {
    if (jsonObject != null) {
      this.nrSeqEndereco = jsonObject.nrSeqEndereco;
      this.cep = jsonObject.cep;
      this.cepdto = jsonObject.cepdto;
      this.bairro = jsonObject.bairro;
      this.noRua = jsonObject.noRua;
      this.complemento = jsonObject.complemento;
      this.nrSeqCidade = jsonObject.nrSeqCidade;
      this.numero = jsonObject.numero;
      this.noCidade = jsonObject.noCidade;
      this.status = jsonObject.status;
      this.cidade = new Cidade(jsonObject.cidade ?? {});
      this.estado = new Estado(jsonObject.estado ?? {});
      this.pais = new Pais(jsonObject.pais ?? {});
      this.iEstadual = jsonObject.iEstadual;
      // this.tipoEndereco = jsonObject.tipoEndereco;
      // #region
      this.nrSeqTipoEndereco = jsonObject.nrSeqTipoEndereco;
      this.tipoEndereco = new TipoEndereco(jsonObject.tipoEndereco ?? {});
      // #endregion

      this.uF = jsonObject.uf;
    }
  }

  get enderecoCompleto() {
    if (this.noRua)
      return `${this.noRua ?? ''} ${this.numero ?? ''} ${
        this.complemento ?? ''
      } ${this.bairro ?? ''} ${this.noCidade ?? ''} ${this.uF ?? ''}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
