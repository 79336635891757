import qs from 'qs';
import {
  ContaRecurso,
  ContaRecursoTipoChamada,
  ResumoSinteticoFinanceiro,
  ContaDetalhada,
} from 'core/models/TEL';
import ContaRecursoDowloadConta from 'core/models/TEL/ContaRecursoDowloadConta';
import api from '../api';

const base = 'TEL/ContaRecurso';

export const getContaRecurso = async (id) => {
  const response = await api.get(`${base}`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ContaRecurso(value) : {};
};

export const postGerarPDFContaBase64 = async (parameters) => {
  const response = await api.post(`${base}/GerarPDFContaBase64`, parameters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const getDownloadContas = async (filters) => {
  const response = await api.get(`${base}/DownloadAccounts`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contaDetalhada:
      value?.map((json) => new ContaRecursoDowloadConta(json)) ?? [],
  };
};

export const getAuditoriaContaRecurso = async (id) => {
  const response = await api.get(`${base}/GetAuditoriaContaRecurso`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    auditoria: value?.map((json) => new ContaRecursoTipoChamada(json)) ?? [],
  };
};

export const getContaRecursoAutoComplete = async (filters) => {
  const response = await api.get(`${base}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contaRecursos: value?.map((json) => new ContaRecurso(json)) ?? [],
  };
};

export const getContaRecursoAutoCompleteWithPermissions = async (filters) => {
  const response = await api.get(`${base}/AutoCompleteWithPermissions`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contaRecursos: value?.map((json) => new ContaRecurso(json)) ?? [],
  };
};

export const getResumoSinteticoFinanceiro = async (filters) => {
  const response = await api.get(`${base}/ResumoSinteticoFinanceiro`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    resumoSintetico: value ? new ResumoSinteticoFinanceiro(value) : {},
  };
};

export const getResumoSinteticoParaClientes = async (filters) => {
  const response = await api.get(`${base}/ResumoSinteticoParaClientes`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    resumoSintetico: value ? new ResumoSinteticoFinanceiro(value) : {},
  };
};

export const getContaDetalhada = async (filters) => {
  const response = await api.get(`${base}/ContaDetalhada`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contaDetalhada: value?.map((json) => new ContaDetalhada(json)) ?? [],
  };
};

export const postGerarPDFConta = async (parameters) => {
  const response = await api.post(`${base}/GerarPDFConta`, parameters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    pathConta: value,
  };
};

export const postImportaConsumoMVNO = async (parameters) => {
  const response = await api.post(`${base}/ImportarConsumoMVNO`, parameters);
  return response.data ?? {};
};

export const postDataAtivacaoMVNO = async (data) => {
  const response = await api.post(`${base}/DataAtivacaoLinhasMVNO`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};
export const postImprimirResumoSinteticoFinanceiro = async (type, filters) => {
  const response = await api.post(
    `${base}/ImprimirResumoSinteticoFinanceiro/${type}`,
    filters
  );
  return response.data ?? {};
};

export const postImprimirContaDetalhada = async (filters) => {
  const response = await api.post(`${base}/ImprimirContaDetalhada`, filters);
  return response.data ?? {};
};

export const postRecalcularContas = async (parameters) => {
  const response = await api.post(`${base}/RecalcularContas`, parameters);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    value,
  };
};
export const postRecalcularRateioContas = async (parameters) => {
  const response = await api.post(`${base}/RecalcularRateio`, parameters);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    value,
  };
};

export const postExcelResumoSinteticoFinanceiro = async (type, filters) => {
  const response = await api.post(
    `${base}/ExcelResumoSinteticoFinanceiro/${type}`,
    filters
  );
  return response.data ?? {};
};

export const removeClientLink = async (data) => {
  const obj = data.nrSeqContaRecursos.map((item) => item);
  const response = await api.put(`${base}/RemoveClientLink`, obj);
  return response.data ?? {};
};

export const getEnviaEmailIndividual = async (filters) => {
  const response = await api.get(`${base}/GetEnviaEmailIndividual`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, pagination, value } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contaRecursos: value?.map((json) => new ContaRecurso(json)) ?? [],
  };
};

export const sendFaturas = async (filters) => {
  const response = await api.post(`${base}/EnviarFaturas`, filters);

  return {
    status: response.data.status,
    message: response.data.message,
    erros: response.data.value,
  };
};

export const sendMensagemWhatsApp = async (filters) => {
  const response = await api.post(`${base}/EnviarMensagemWhatsApp`, filters);

  return {
    status: response.data.status,
    message: response.data.message,
    erros: response.data.value,
  };
};

export const postIncluirGrupoLinha = async (data) =>
  (await api.post(`${base}/IncluirLinhasGrupo`, data)).data ?? {};

export const putLiberaVisualizacao = async (data) =>
  (await api.put(`${base}/LiberaVisualizacao`, data)).data ?? {};

export const putBloquearVisualizacao = async (data) =>
  (await api.put(`${base}/BloquearVisualizacao`, data)).data ?? {};

export const postImprimirResumoSinteticoParaClientes = async (filters) => {
  const response = await api.post(
    `${base}/ImprimirResumoSinteticoParaClientes`,
    filters
  );
  return response.data ?? {};
};

export const postExcelResumoSinteticoParaClientes = async (filters) => {
  const response = await api.post(
    `${base}/ExcelResumoSinteticoParaClientes`,
    filters
  );
  return response.data ?? {};
};

export const getContaRecursoList = async (filters) => {
  const response = await api.get(`${base}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaRecurso(json)) ?? [],
  };
};

export const getUltimoCicloLinha = async (nrSeqChip) => {
  const response = await api.get(`${base}/GetUltimoCicloLinha`, {
    params: { nrSeqChip },
  });
  const { value } = response.data ?? {};

  return value;
};

export const getLinkDownloadFatura = async (nrSeqContaRecurso) => {
  const response = await api.get(`${base}/GetLinkDownloadFatura`, {
    params: { nrSeqContaRecurso },
  });

  const { status, message, value } = response.data ?? {};

  return { status, message, value };
};
