import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { Autocomplete, Button, GridView } from 'ui/components';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';

const MotoristaStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [dataMotorista, setDataMotorista] = useState();
  const [mensagemMotorista, setMensagemMotorista] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (gridView && gridView.current && data.motoristas) {
      gridView.current.setDataSource(data.motoristas);
    }
  }, []);

  const onSearchMotorista = async (e) => {
    const response = await getMotoristaAutoComplete({
      noPessoa: e,
      retornaVeiculosVinculado: true,
    });

    return response.motoristas;
  };

  const onClickAddMotorista = async () => {
    if (dataMotorista) {
      if (dataMotorista.veiculoMotoristas) {
        if (dataMotorista.veiculoMotoristas.length > 0) {
          onSetMessage(
            ResponseStatus.Error,
            'O Motorista não pode ser vinculado em mais de um veículo.'
          );

          return;
        }
      }

      const obj = {
        ...dataMotorista,
        status: GridView.EnumStatus.Inserir,
      };

      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setData({ ...data, motoristas: list });
      setDataMotorista();
      setMensagemMotorista({ show: false, message: '' });
    } else {
      onSetMessage(false, 'Informe o Motorista');
    }
  };

  const onClickRemoveMotorista = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setData({ ...data, motoristas: list });
  };

  const columns = [
    { key: 'nrSeqPessoaMot', visible: false },
    { key: 'noPessoa', title: 'Motorista', sortable: false },
    {
      key: 'nrSeqMotorista',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveMotorista(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      {mensagemMotorista.show && (
        <div className='row mb-3'>
          <div className='col'>
            <div className='card bg-light border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{mensagemMotorista.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3 algin-items-end'>
        <div className='col-6'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchMotorista}
            selectedItem={dataMotorista}
            onSelectItem={(motorista) => {
              setDataMotorista(motorista);

              if (Object.keys(motorista).length > 0) {
                if (motorista.veiculoMotoristas !== null) {
                  if (motorista.veiculoMotoristas.length > 0) {
                    const placas = motorista.veiculoMotoristas.map(
                      (e) => e.veiculo?.placa
                    );

                    setMensagemMotorista({
                      show: true,
                      message: `Esse motorista já esta vinculado aos seguintes veículos: ${placas.join(
                        ', '
                      )}`,
                    });
                  } else if (mensagemMotorista.show) {
                    setMensagemMotorista({ show: false, message: '' });
                  }
                } else if (mensagemMotorista.show) {
                  setMensagemMotorista({ show: false, message: '' });
                }
              } else if (mensagemMotorista.show) {
                setMensagemMotorista({ show: false, message: '' });
              }
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2 d-flex align-items-end'>
          <Button
            onClick={onClickAddMotorista}
            outline
            icon='plus'
            theme={
              dataMotorista?.veiculoMotoristas?.length > 0
                ? Theme.Inactive
                : Theme.Success
            }
            style={{ flexGrow: 1 }}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <GridView
          ref={gridView}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columns}
          offlineData
          showPagination={false}
          showSelectSizes={false}
        />
      </div>
    </div>
  );
});

export default MotoristaStep;
