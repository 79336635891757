import Empresa from '../SEG/empresa';

export default class AlmoxarifadoEmpresa {
  constructor(jsonObject = {}) {
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = new Empresa(jsonObject.empresa);
  }

  toJson = () => JSON.stringify(this);
}
