import Empresa from 'core/models/SEG/empresa';
import ContaCorrente from 'core/models/FIN/contaCorrente';
import PlanoDeConta from 'core/models/FIN/planoDeConta';

export default class FormaPagamentoEmpresa {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqExtratoCategoriaRec = jsonObject.nrSeqExtratoCategoriaRec;
    this.nrSeqExtratoCategoriaPag = jsonObject.nrSeqExtratoCategoriaPag;
    this.nrSeqContaCorrenteRec = jsonObject.nrSeqContaCorrenteRec;
    this.nrSeqContaCorrentePag = jsonObject.nrSeqContaCorrentePag;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPlanoDeContaPag = jsonObject.nrSeqPlanoDeContaPag;
    this.nrSeqPlanoDeContaRec = jsonObject.nrSeqPlanoDeContaRec;
    this.flgGeraExtratoPag = jsonObject.flgGeraExtratoPag;
    this.flgGeraExtratoRec = jsonObject.flgGeraExtratoRec;
    this.nrSeqFormaPagamento_Empresa = jsonObject.nrSeqFormaPagamento_Empresa;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.contaCorrenteRec = new ContaCorrente(
      jsonObject.contaCorrenteRec ?? {}
    );
    this.contaCorrentePag = new ContaCorrente(
      jsonObject.contaCorrentePag ?? {}
    );
    this.planoDeContaPag = new PlanoDeConta(jsonObject.planoDeContaPag ?? {});
    this.planoDeContaRec = new PlanoDeConta(jsonObject.planoDeContaRec ?? {});
  }

  toJson = () => JSON.stringify(this);
}
