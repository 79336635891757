import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import Tree from 'ui/components/Treeview/index';
import {
  getAlmoxarifadoLocal,
  saveAlmoxarifadoLocal,
  deleteAlmoxarifadoLocal,
  getAlmoxarifadoLocalPaiTreeview,
} from 'core/services/EST/almoxarifadoLocal';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';

export default function AlmoxarifadoLocalItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [treeData, setTree] = useState([]);

  const criaHierarquiaTreeView = (input) => {
    input.forEach((item) => {
      item.label = item.noPlanoDeConta;
    });

    const linhas = input.map((column) => ({
      label: column.noAlmoxarifadoLocal ?? '',
      key: column.nrSeqAlmoxarifadoLocal,
      root: !column.nrSeqAlmoxarifadoLocalPai > 0,
      children: input
        .filter(
          (item) =>
            item.nrSeqAlmoxarifadoLocalPai === column.nrSeqAlmoxarifadoLocal
        )
        .map((column2) => ({
          label: column2.noAlmoxarifadoLocal,
          key: column2.nrSeqAlmoxarifadoLocal,
          root: !column2.nrSeqAlmoxarifadoLocalPai > 0,
          children: input
            .filter(
              (item) =>
                item.nrSeqAlmoxarifadoLocalPai ===
                column2.nrSeqAlmoxarifadoLocal
            )
            .map((column3) => ({
              label: column3.noAlmoxarifadoLocal,
              key: column3.nrSeqAlmoxarifadoLocal,
              root: !column3.nrSeqAlmoxarifadoLocalPai > 0,
              children: input
                .filter(
                  (item) =>
                    item.nrSeqAlmoxarifadoLocalPai ===
                    column3.nrSeqAlmoxarifadoLocal
                )
                .map((column4) => ({
                  label: column4.noAlmoxarifadoLocal,
                  key: column4.nrSeqAlmoxarifadoLocal,
                  root: !column4.nrSeqAlmoxarifadoLocalPai > 0,
                  children: input
                    .filter(
                      (item) =>
                        item.nrSeqAlmoxarifadoLocalPai ===
                        column4.nrSeqAlmoxarifadoLocal
                    )
                    .map((column5) => ({
                      label: column5.noAlmoxarifadoLocal,
                      key: column5.nrSeqAlmoxarifadoLocal,
                      root: !column5.nrSeqAlmoxarifadoLocalPai > 0,
                      children: input.filter(
                        (item) =>
                          item.nrSeqAlmoxarifadoLocalPai ===
                          column5.nrSeqAlmoxarifadoLocal
                      ),
                    })),
                })),
            })),
        })),
    }));

    return linhas;
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const obj = await getAlmoxarifadoLocal(registryKey);

      setData({ ...obj });

      const { almoxarifados } = await getAlmoxarifadoLocalPaiTreeview();

      const treeViewData = criaHierarquiaTreeView(almoxarifados);
      setTree(treeViewData);

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const response = await saveAlmoxarifadoLocal(data);

    const { status, message: msg } = response;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData({});
    }

    setLoading(false);

    return setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAlmoxarifadoLocal(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchAlmoxarifado = async (e: any) => {
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
    const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqUsuario: sessionUser.nrSeqUsuario,
    });
    return almoxarifados;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Almoxarifado Local'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqAlmoxarifadoLocal > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            label='Descrição'
            maxLength={100}
            text={data.noAlmoxarifadoLocal}
            onChangedValue={(noAlmoxarifadoLocal) =>
              setData({
                ...data,
                noAlmoxarifadoLocal,
              })
            }
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Almoxarifado'
            searchDataSource={onSearchAlmoxarifado}
            selectedItem={data.almoxarifado}
            onSelectItem={(almoxarifado) => {
              setData({
                ...data,
                almoxarifado,
                nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
              });
            }}
            dataSourceTextProperty='noAlmoxarifado'
          />
        </div>
        <div className='col-1'>
          <Switch
            label='Ativo'
            formControl
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
      <div className='row' label='Almoxarifado Local Pai'>
        <div className='col-12 mb-3'>
          <Tree
            label='Almoxarifado Local Pai'
            data={treeData}
            selectedKey={data.nrSeqAlmoxarifadoLocalPai}
            onChangedValue={(nrSeqAlmoxarifadoLocalPai) =>
              setData({ ...data, nrSeqAlmoxarifadoLocalPai })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
