import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getCidadePagined,
  deleteAllCidade,
  printCidade,
} from 'core/services/SEG/cidade';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { CSDSelPage, GridView, Autocomplete, Textbox } from 'ui/components';
import { Pais, Estado } from 'core/models/SEG';

const columns = [
  { key: 'nrSeqCidade', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrSeqEstado', title: 'NrSeqEstado', visible: false },
  { key: 'cdCidade', title: 'Código' },
  { key: 'noCidade', title: 'Cidade' },
  { key: 'estado.noEstado', title: 'Estado' },
  { key: 'cdIbge', title: 'CdIbge', visible: false },
];

export default function Cidade30({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [estado, setEstado] = useState(new Estado());
  const [pais, setPais] = useState(new Pais());

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getCidadePagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: pais.nrSeqPais ?? new Pais(),
    });
    onSetMessage(status, msg);
    return estados;
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const paises = await getPaisAutoComplete({
        noPais: 'Brasil',
      });
      setPais(paises.paises[0]);
      setFiltros({ ...filtros, nrSeqPais: paises.paises[0].nrSeqPais });
      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Cidade/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqCidade = sourceRow.find((e) => e.key === 'nrSeqCidade');
    onOpenMaintenance(nrSeqCidade.value);
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });
    onSetMessage(status, msg);
    return paises;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllCidade(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printCidade(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Cidade'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Nome'
            text={filtros.noCidade}
            maxLength={60}
            onChangedValue={(noCidade) => setFiltros({ ...filtros, noCidade })}
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='País'
            searchDataSource={onSearchPais}
            selectedItem={pais}
            onSelectItem={(obj) => {
              setPais(obj);
            }}
            dataSourceTextProperty='noPais'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Estado'
            searchDataSource={onSearchEstado}
            selectedItem={estado}
            onSelectItem={(obj) => {
              setEstado(obj);
              setFiltros({ ...filtros, nrSeqEstado: obj.nrSeqEstado });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
