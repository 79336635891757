import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  BootstrapSizes,
  JustifyContent,
  Theme,
} from 'ui/Helpers/utils';

import { MaskTypes } from 'ui/Helpers/masks';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  CSDManutPage,
  Textbox,
  Autocomplete,
  RadioButton,
  Panel,
  Button,
  GridView,
  Switch,
} from 'ui/components';

import { Chip, ClienteLinha, Operadora } from 'core/models/TEL';

import { getAparelhoAutoComplete } from 'core/services/TEL/aparelho';
import { getBonusAutoComplete } from 'core/services/TEL/bonus';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';
import { getChipAutoComplete } from 'core/services/TEL/chip';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getGrupoTipoChamadaAutoComplete } from 'core/services/TEL/grupoTipoChamada';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';
import { getEncargoManualAutoComplete } from 'core/services/TEL/encargoManual';
import { getPacoteSmsAutoComplete } from 'core/services/TEL/pacotesms';
import { getParcelamentoAparelhoList } from 'core/services/TEL/parcelamentoAparelho';
import { getTipoChamadaAutoComplete } from 'core/services/TEL/tipoChamada';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';
import {
  getClienteLinha,
  saveClienteLinha,
  deleteClienteLinha,
  getClienteLinhaAutoComplete,
} from 'core/services/TEL/clienteLinha';

import CadastroRapidoChip from 'ui/pages/TEL/Chip/cadastroRapido';
import { getClienteLinhaLogList } from 'core/services/TEL/clienteLinhaLog';

export default function ClienteLinhaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenTransaction,
  findTransaction,
  transaction,
}) {
  const formSubmit = useRef();
  const [clienteLinha, setClienteLinha] = useState({});
  const [message, setMessage] = useState(null);

  const [ocorrenciaClienteLinha, setOcorrenciaClienteLinha] = useState({
    flgAquisicao: 'S',
  });
  const [encargoManualClienteLinha, setEncargoManualClienteLinha] = useState(
    {}
  );
  const [ocorrenciaPorGrupo, setOcorrenciaPorGrupo] = useState(false);

  const [centroDeCustoRateio, setCentroDeCustoRateio] = useState({
    vlrPercentual: 0,
  });

  const [totalCentroDeCustoRateio, setTotalCentroDeCustoRateio] = useState(0);

  const [chip, setChip] = useState({});
  const [operadora, setOperadora] = useState({});

  const [loading, setLoading] = useState(false);

  const gridViewEncargosManuais = useRef();
  const gridViewDataOcorrenciaAcoes = useRef();
  const gridViewParcelamentoAparelhos = useRef();
  const gridViewCentrodeCustosRateio = useRef();
  const gridViewHistoricoAlteracoes = useRef();

  const onClickEditEncargoManual = async (e, datasource) => {
    const encargoManual = datasource.filter((em) => em === e);
    const newdatasource = datasource.filter((em) => em !== e);
    const obj = {
      ...encargoManual[0],
      status: GridView.EnumStatus.Alterar,
    };
    setEncargoManualClienteLinha(obj);

    if (gridViewEncargosManuais && gridViewEncargosManuais.current)
      gridViewEncargosManuais.current.setDataSource(newdatasource);
  };

  const onClickEditOcorrencia = async (e, datasource) => {
    const ocorrencia = datasource.filter((em) => em === e);
    const newdatasource = datasource.filter((em) => em !== e);

    const grupoTipoChamadaItem = {
      nrSeqGrupoTipoChamada: ocorrencia[0].grupoTipoChamada.nrSeqTipoChamada,
      noGrupoTipoChamada: ocorrencia[0].grupoTipoChamada.noGrupoTipoChamada,
      noTipoChamada: ocorrencia[0].tipoChamada.noTipoChamada,
      nrSeqTipoChamada: ocorrencia[0].tipoChamada.nrseqTipoChamada,
    };

    const obj = {
      ...ocorrencia[0],
      grupoTipoChamada: grupoTipoChamadaItem,
      nrSeqGrupoTipoChamada: grupoTipoChamadaItem.noGrupoTipoChamada,
      noGrupoTipoChamada: grupoTipoChamadaItem.nrSeqGrupoTipoChamada,
      status: GridView.EnumStatus.Alterar,
    };
    setOcorrenciaClienteLinha(obj);

    if (gridViewDataOcorrenciaAcoes && gridViewDataOcorrenciaAcoes.current)
      gridViewDataOcorrenciaAcoes.current.setDataSource(newdatasource);
  };

  const onClickEditCentroDeCusto = async (e, datasource) => {
    const centroCusto = datasource.filter((em) => em === e);
    const newdatasource = datasource.filter((em) => em !== e);
    const obj = {
      ...centroCusto[0],
      status: GridView.EnumStatus.Alterar,
    };
    setCentroDeCustoRateio(obj);

    if (gridViewCentrodeCustosRateio && gridViewCentrodeCustosRateio.current)
      gridViewCentrodeCustosRateio.current.setDataSource(newdatasource);
  };

  const onClickRemoveCentroDeCusto = (selectedValue, datasource) => {
    const newdatasource = datasource.filter((e) => e !== selectedValue);
    const obj = { ...selectedValue, status: GridView.EnumStatus.Remover };
    newdatasource.push(obj);

    if (gridViewCentrodeCustosRateio && gridViewCentrodeCustosRateio.current)
      gridViewCentrodeCustosRateio.current.setDataSource(newdatasource);
  };

  const columnsHistoricoAlteracoes = [
    {
      key: 'data',
      title: 'Data',
      visible: true,
    },
    { key: 'tipoOperacao', title: 'Operação', visible: true },

    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Centro de Custo',
      visible: true,
    },
    { key: 'usuario', title: 'Usuario', visible: true },
    { key: 'flgStatus', title: 'Status', visible: true },
  ];

  const columnsCentrodeCustosRateio = [
    {
      key: 'nrSeqClienteLinhaCentroCusto',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'nrSeqClienteLinha',
      title: 'nrSeqClienteLinha',
      visible: false,
    },
    {
      key: 'nrSeqCentroCusto',
      title: 'nrSeqCentroCusto',
      visible: false,
    },
    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Nome',
      visible: true,
    },
    {
      key: 'vlrPercentual',
      title: 'Valor Percentual',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'nrSeqClienteLinhaCentroCusto',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, datasource) => onClickEditCentroDeCusto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqClienteLinhaCentroCusto',
      type: GridView.ColumnTypes.Button,
      title: 'Remover',
      onClick: onClickRemoveCentroDeCusto,
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover Centro De Custo',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickRemoveEncargoManual = async (selectedValue) => {
    const datasource = gridViewEncargosManuais.current?.getDataSource();
    const newdatasource = datasource.filter((e) => e !== selectedValue);
    const obj = { ...selectedValue, status: GridView.EnumStatus.Remover };
    newdatasource.push(obj);
    if (gridViewEncargosManuais && gridViewEncargosManuais.current) {
      gridViewEncargosManuais.current.setDataSource(newdatasource);
    }
  };

  const columnsEncargosManuais = [
    { key: 'nrSeqEncargomanual', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'encargoManual.noEncargoManual',
      title: 'Encargo Manual',
      visible: true,
    },
    {
      key: 'dtIniEncargoManual',
      title: 'Data Inicio',
      visible: true,
    },
    {
      key: 'dtFimEncargoManual',
      title: 'Data Final',
      visible: true,
    },
    {
      key: 'vlrEncargo',
      title: 'Valor Encargo',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'nrSeqEncargomanual',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, datasource) => onClickEditEncargoManual(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqEncargomanual',
      type: GridView.ColumnTypes.Button,
      title: 'Remover',
      onClick: (e, datasource) => onClickRemoveEncargoManual(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickRemoveOcorrencia = async (selectedValue, datasource) => {
    const newdatasource = datasource.filter((e) => e !== selectedValue);
    const obj = { ...selectedValue, status: GridView.EnumStatus.Remover };
    newdatasource.push(obj);

    if (gridViewDataOcorrenciaAcoes && gridViewDataOcorrenciaAcoes.current)
      gridViewDataOcorrenciaAcoes.current.setDataSource(newdatasource);
  };

  const columnsDataOcorrenciaAcoes = [
    { key: 'nrSeqClOcorrencia', type: GridView.ColumnTypes.Checkbox },
    { key: 'noAquisicaoStr', title: 'Ação', visible: true },
    {
      key: 'dtOcorrencia',
      title: 'Data Ocorrência',
      visible: true,
    },
    { key: 'tipoChamada.noTipoChamada', title: 'Tipo Chamada', visible: true },
    {
      key: 'grupoTipoChamada.noGrupoTipoChamada',
      title: 'Grupo Tipo Chamada',
      visible: true,
    },

    {
      key: 'nrSeqClOcorrencia',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, datasource) => onClickEditOcorrencia(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqClOcorrencia',
      type: GridView.ColumnTypes.Button,
      title: 'Remover',
      onClick: (e, datasource) => onClickRemoveOcorrencia(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsParcelamentoAparelhos = [
    { key: 'nrSeqParcelamentoAparelho', type: GridView.ColumnTypes.Checkbox },
    { key: 'noParcelamentoAparelho', title: 'Descrição', visible: true },
    {
      key: 'dtIniVigencia',
      title: 'Inicio',
      visible: true,
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrOriginal',
      title: 'Total',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
    { key: 'nrParcelas', title: 'Parcelado Em', visible: true },
    { key: 'nrParcelasClienteLinha', title: 'Parcelas', visible: true },
    {
      key: 'vlrParcela',
      title: 'Vlr Parcela',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPago',
      title: 'Vlr Pago',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrRestante',
      title: 'Vlr Restante',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
  ];

  const statusLinha = [
    {
      text: 'Normal',
      value: 'N',
    },
    {
      text: 'Cancelado',
      value: 'C',
    },

    {
      text: 'Transferido',
      value: 'T',
    },

    { text: 'Bloqueado', value: 'B' },
  ];

  const tipoOcorrencia = [
    {
      text: 'Aquisição',
      value: 'S',
    },
    {
      text: 'Cancelamento',
      value: 'N',
    },
  ];

  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const register = await getClienteLinha(registryKey);

      const { parcelamentos } = await getParcelamentoAparelhoList({
        nrSeqClienteLinha: registryKey,
      });

      if (
        gridViewParcelamentoAparelhos &&
        gridViewParcelamentoAparelhos.current
      ) {
        gridViewParcelamentoAparelhos.current.setDataSource(parcelamentos);
      }

      if (gridViewEncargosManuais && gridViewEncargosManuais.current) {
        gridViewEncargosManuais.current.setDataSource(register.encargosManuais);
      }

      if (gridViewCentrodeCustosRateio && gridViewCentrodeCustosRateio.current)
        gridViewCentrodeCustosRateio.current.setDataSource(
          register.centroDeCustos
        );

      if (gridViewDataOcorrenciaAcoes && gridViewDataOcorrenciaAcoes.current)
        gridViewDataOcorrenciaAcoes.current.setDataSource(register.ocorrencias);

      setLoading(false);

      setClienteLinha(register);

      setChip(register.chip);

      setOperadora(register.chip.operadora);

      const { logs } = await getClienteLinhaLogList({
        nrSeqClienteLinha: registryKey,
      });

      if (gridViewHistoricoAlteracoes && gridViewHistoricoAlteracoes.current)
        gridViewHistoricoAlteracoes.current.setDataSource(logs);
    } else {
      setClienteLinha(new ClienteLinha({ flgStatus: 'N' }));
      setChip(new Chip());
      setOperadora(new Operadora());
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onSearchAparelho = async (e) => {
    const { status, message: msg, aparelhos } = await getAparelhoAutoComplete({
      nrImei: e,
    });
    onSetMessage(status, msg);
    return aparelhos;
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchEncargoManual = async (e) => {
    const {
      status,
      message: msg,
      encargos,
    } = await getEncargoManualAutoComplete({
      noEncargoManual: e,
    });
    onSetMessage(status, msg);
    return encargos;
  };

  const onSearchGrupoTipoChamada = async (e) => {
    if (ocorrenciaPorGrupo) {
      const {
        status,
        message: msg,
        grupos,
      } = await getGrupoTipoChamadaAutoComplete({
        noGrupoTipoChamada: e,
      });
      onSetMessage(status, msg);
      return grupos;
    }
    const {
      status,
      message: msg,
      tiposchamadas,
    } = await getTipoChamadaAutoComplete({
      noTipoChamada: e,
    });
    onSetMessage(status, msg);
    return tiposchamadas;
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });
    onSetMessage(status, msg);
    return chips;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
      flgAtivo: true,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onSearchClienteLinhaPai = async (e) => {
    const { status, message: msg, linhas } = await getClienteLinhaAutoComplete({
      nrLinhaCompleto: e,
      nrSeqPessoaCli: clienteLinha.nrSeqPessoaCli,
    });

    onSetMessage(status, msg);
    return linhas;
  };

  const onSearchBonus = async (e) => {
    const { status, message: msg, bonus } = await getBonusAutoComplete({
      noBonus: e,
    });
    onSetMessage(status, msg);
    return bonus;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: clienteLinha.nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchGrupoCustoEmpresa = async (e) => {
    const {
      status,
      message: msg,
      grupos,
    } = await getGrupoTipoChamadaAutoComplete({
      nrImei: e,
    });
    onSetMessage(status, msg);
    return grupos;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({
      codNoCentroDeCusto: e,
    });

    onSetMessage(status, msg);

    return centros;
  };

  const onSearchPlanoRateioEmpresa = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
      flgPlanoRateioEmpresa: true,
      flgAtivo: true,
    });

    onSetMessage(status, msg);

    return planos;
  };
  const onSearchCentroDeCustoRateio = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({
      codNoCentroDeCusto: e,
    });
    onSetMessage(status, msg);
    return centros;
  };

  const onSearchFaturarProporcionalmente = async (e) => {
    const {
      status,
      message: msg,
      grupos,
    } = await getGrupoTipoChamadaAutoComplete({
      noGrupoTipoChamada: e,
    });
    onSetMessage(status, msg);
    return grupos;
  };

  const onSearchPacoteSMS = async (e) => {
    const { status, message: msg, pacotes } = await getPacoteSmsAutoComplete({
      noPacoteSms: e,
    });
    onSetMessage(status, msg);
    return pacotes;
  };

  const onClickAddCentroDeCustoRateio = async () => {
    let total = 0;
    let cont = 0;
    const data = gridViewCentrodeCustosRateio.current.getDataSource();

    const validacao = data.filter(
      (encargo) =>
        encargo.nrSeqCentroDeCusto === centroDeCustoRateio.nrSeqCentroDeCusto
    );

    if (validacao.length > 0) {
      onSetMessage(
        Theme.Danger,
        'O mesmo centro de custo não pode ser adicionado duas vezes'
      );
    } else {
      const obj = {
        ...centroDeCustoRateio,
        status: centroDeCustoRateio.status ?? GridView.EnumStatus.Inserir,
      };
      data.push(obj);

      if (gridViewCentrodeCustosRateio && gridViewCentrodeCustosRateio.current)
        gridViewCentrodeCustosRateio.current.setDataSource(data);

      for (cont = 0; cont < data.length; cont += 1)
        total += parseInt(data[cont].vlrPercentual, 10);

      setTotalCentroDeCustoRateio(total);

      setCentroDeCustoRateio({});
    }
  };

  const onClickAddOcorrencia = async () => {
    const data = gridViewDataOcorrenciaAcoes.current.getDataSource();
    if (ocorrenciaPorGrupo) {
      ocorrenciaClienteLinha.nrSeqTipoChamada = undefined;
      ocorrenciaClienteLinha.tipoChamada = {};
    } else {
      ocorrenciaClienteLinha.nrSeqGrupoTipoChamada = undefined;
      ocorrenciaClienteLinha.grupoTipoChamada = {};
    }

    if (
      ocorrenciaClienteLinha.dtOcorrencia === '' ||
      ocorrenciaClienteLinha.dtOcorrencia === undefined
    ) {
      onSetMessage(Theme.Danger, 'Data de ocorrência inválida.');
    } else if (
      ocorrenciaPorGrupo &&
      (ocorrenciaClienteLinha.nrSeqGrupoTipoChamada === undefined ||
        ocorrenciaClienteLinha.nrSeqGrupoTipoChamada === '')
    ) {
      onSetMessage(Theme.Danger, 'Grupo Tipo Chamada inválido');
    } else if (
      (!ocorrenciaPorGrupo &&
        ocorrenciaClienteLinha.nrSeqTipoChamada === undefined) ||
      ocorrenciaClienteLinha.nrSeqTipoChamada === ''
    ) {
      onSetMessage(Theme.Danger, 'Tipo Chamada inválido');
    } else {
      ocorrenciaClienteLinha.noAquisicaoStr =
        ocorrenciaClienteLinha.flgAquisicao === 'S'
          ? 'Aquisição'
          : 'Cancelamento';

      const obj = {
        ...ocorrenciaClienteLinha,
        status: ocorrenciaClienteLinha.status ?? GridView.EnumStatus.Inserir,
      };

      data.push(obj);

      if (gridViewDataOcorrenciaAcoes && gridViewDataOcorrenciaAcoes.current)
        gridViewDataOcorrenciaAcoes.current.setDataSource(data);
    }

    setOcorrenciaClienteLinha({ flgAquisicao: 'S' });
    setOcorrenciaPorGrupo({});
  };

  const onClickAddEncargoManual = async () => {
    const data = gridViewEncargosManuais.current.getDataSource();
    if (encargoManualClienteLinha.nrSeqEncargoManual === undefined) {
      onSetMessage(
        Theme.Danger,
        'Selecione o Encargo Manual que deseja incluir.'
      );
    } else if (
      encargoManualClienteLinha.dtIniEncargoManual !== undefined &&
      encargoManualClienteLinha.dtIniEncargoManual !== '' &&
      (encargoManualClienteLinha.dtFimEncargoManual === undefined ||
        encargoManualClienteLinha.dtFimEncargoManual === '')
    ) {
      onSetMessage(
        Theme.Danger,
        'Para incluir Período de Vigência é necessário informar a Data Inicio e Data Final.'
      );
    } else if (
      encargoManualClienteLinha.vlrEncargo === undefined ||
      encargoManualClienteLinha.vlrEncargo === ''
    ) {
      onSetMessage(Theme.Danger, 'Informe o valor do Encargo selecionado.');
    } else {
      const obj = {
        ...encargoManualClienteLinha,
        status: encargoManualClienteLinha.status ?? GridView.EnumStatus.Inserir,
      };
      data.push(obj);

      if (gridViewEncargosManuais && gridViewEncargosManuais.current) {
        gridViewEncargosManuais.current.setDataSource(data);
      }
      setEncargoManualClienteLinha({});
    }
  };

  const save = async () => {
    setLoading(true);

    clienteLinha.encargosManuais = gridViewEncargosManuais.current.getDataSource();
    clienteLinha.centroDeCustos = gridViewCentrodeCustosRateio.current.getDataSource();
    clienteLinha.ocorrencias = gridViewDataOcorrenciaAcoes.current.getDataSource();

    const { status, message: msg } = await saveClienteLinha(clienteLinha);

    if (status === ResponseStatus.Success)
      gridViewCentrodeCustosRateio.current.setDataSource([]);
    if (status === ResponseStatus.Success)
      gridViewEncargosManuais.current.setDataSource([]);
    if (status === ResponseStatus.Success)
      gridViewDataOcorrenciaAcoes.current.setDataSource([]);
    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setClienteLinha(status === ResponseStatus.Success ? {} : clienteLinha);
    setChip(status === ResponseStatus.Success ? {} : {});
    setOperadora(status === ResponseStatus.Success ? {} : {});
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteClienteLinha(registryKey);

      setClienteLinha(status === ResponseStatus.Success ? {} : clienteLinha);
      setMessage({
        msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  const cadastroRapidoChip = useRef();
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Cliente Linha'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      transaction={transaction}
      onNew={() => {
        setClienteLinha(new ClienteLinha());
        setChip(new Chip());
        setOperadora(new Operadora());
      }}
      onSave={save}
      onDelete={clienteLinha?.nrSeqClienteLinha > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      nestedForms={[
        <CadastroRapidoChip
          handleNewItemSaved={(chipalt) =>
            setClienteLinha({
              ...clienteLinha,
              chip: chipalt,
              nrSeqChip: chipalt.nrSeqChip,
            })
          }
          ref={cadastroRapidoChip}
        />,
      ]}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={clienteLinha.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) =>
              setClienteLinha({
                ...clienteLinha,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='Nº Linha (Chip)'
            searchDataSource={onSearchChip}
            selectedItem={clienteLinha.chip}
            onSelectItem={(chipalt) =>
              setClienteLinha({
                ...clienteLinha,
                chip: chipalt,
                nrSeqChip: chipalt.nrSeqChip,
              })
            }
            dataSourceTextProperty='linhaFormatada'
            onNewItemPageRef={cadastroRapidoChip}
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='Plano'
            searchDataSource={onSearchPlano}
            selectedItem={clienteLinha.plano}
            onSelectItem={(plano) =>
              setClienteLinha({
                ...clienteLinha,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Nome Cliente na Conta'
            text={clienteLinha.noClienteConta}
            onChangedValue={(noClienteConta) =>
              setClienteLinha({ ...clienteLinha, noClienteConta })
            }
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Nr IMEI (Aparelho)'
            searchDataSource={onSearchAparelho}
            selectedItem={clienteLinha.aparelho}
            onSelectItem={(aparelho) =>
              setClienteLinha({
                ...clienteLinha,
                aparelho,
                nrSeqAparelho: aparelho.nrSeqAparelho,
              })
            }
            dataSourceTextProperty='nrImei'
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Número Termo'
            text={clienteLinha.nrTermo}
            onChangedValue={(nrTermo) =>
              setClienteLinha({ ...clienteLinha, nrTermo })
            }
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Cliente Linha Pai'
            searchDataSource={onSearchClienteLinhaPai}
            selectedItem={clienteLinha.clienteLinhaPai}
            onSelectItem={(clienteLinhaPai) =>
              setClienteLinha({
                ...clienteLinha,
                clienteLinhaPai,
                nrSeqClienteLinhaPai: clienteLinhaPai.nrSeqClienteLinha,
              })
            }
            dataSourceTextProperty='nrLinhaCompleto'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Bônus'
            searchDataSource={onSearchBonus}
            selectedItem={clienteLinha.bonus}
            onSelectItem={(bonus) =>
              setClienteLinha({
                ...clienteLinha,
                bonus,
                nrSeqBonus: bonus.nrSeqBonus,
              })
            }
            dataSourceTextProperty='noBonus'
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='Forma de Pagamento'
            searchDataSource={onSearchFormaPagamento}
            selectedItem={clienteLinha.formaPagamento}
            onSelectItem={(formaPagamento) =>
              setClienteLinha({
                ...clienteLinha,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              })
            }
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Informação Bancária'
            searchDataSource={onSearchInformacaoBancaria}
            selectedItem={clienteLinha.informacaoBancaria}
            onSelectItem={(informacaoBancaria) =>
              setClienteLinha({
                ...clienteLinha,
                informacaoBancaria,
                nrSeqPessoaInformacaoBancaria:
                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>

        <div className='col-2'>
          <Autocomplete
            label='Vencimento'
            searchDataSource={onSearchVencimento}
            selectedItem={clienteLinha.vencimento}
            onSelectItem={(vencimento) =>
              setClienteLinha({
                ...clienteLinha,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Grupo Custo Empresa'
            searchDataSource={onSearchGrupoCustoEmpresa}
            selectedItem={clienteLinha.grupoCustoEmpresa}
            onSelectItem={(grupoCustoEmpresa) =>
              setClienteLinha({
                ...clienteLinha,
                grupoCustoEmpresa,
                nrSeqGrupoCustoEmpresa: grupoCustoEmpresa.nrSeqGrupoTipoChamada,
              })
            }
            dataSourceTextProperty='noGrupoTipoChamada'
          />
        </div>

        <div className='col-6'>
          <Autocomplete
            label='Centro de Custo'
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={clienteLinha.centroDeCusto}
            onSelectItem={(centroDeCusto) =>
              setClienteLinha({
                ...clienteLinha,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              })
            }
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Plano de Rateio Empresa'
            searchDataSource={onSearchPlanoRateioEmpresa}
            selectedItem={clienteLinha.planoRateioEmpresa}
            onSelectItem={(planoRateioEmpresa) =>
              setClienteLinha({
                ...clienteLinha,
                planoRateioEmpresa,
                nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>

        <div className='row mt-3'>
          <div className='col-7'>
            <RadioButton
              label='Status da Linha'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={clienteLinha.flgStatus}
              buttons={statusLinha}
              onChange={(flgStatus) => {
                setClienteLinha({
                  ...clienteLinha,
                  flgStatus,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Cobrança de Encargos Manuais'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Encargo Manual'
                    searchDataSource={onSearchEncargoManual}
                    selectedItem={encargoManualClienteLinha.encargoManual}
                    onSelectItem={(encargoManual) =>
                      setEncargoManualClienteLinha({
                        ...encargoManualClienteLinha,
                        nrSeqEncargoManual: encargoManual.nrSeqEncargoManual,
                        encargoManual,
                      })
                    }
                    dataSourceTextProperty='noEncargoManual'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Data Inicio Vigência'
                    text={encargoManualClienteLinha.dtIniEncargoManual}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtIniEncargoManual) =>
                      setEncargoManualClienteLinha({
                        ...encargoManualClienteLinha,
                        dtIniEncargoManual,
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Data Final Vigência'
                    text={encargoManualClienteLinha.dtFimEncargoManual}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtFimEncargoManual) =>
                      setEncargoManualClienteLinha({
                        ...encargoManualClienteLinha,
                        dtFimEncargoManual,
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor'
                    text={encargoManualClienteLinha.vlrEncargo}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrEncargo) =>
                      setEncargoManualClienteLinha({
                        ...encargoManualClienteLinha,
                        vlrEncargo,
                      })
                    }
                  />
                </div>

                <div className='col-2 d-flex align-items-end'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar Encargo'
                    onClick={onClickAddEncargoManual}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewEncargosManuais}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsEncargosManuais}
                    offlineData
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Centro de Custos (Rateio)'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-5'>
                  <Autocomplete
                    label='Centro de Custo'
                    searchDataSource={onSearchCentroDeCustoRateio}
                    selectedItem={centroDeCustoRateio.centroDeCusto}
                    onSelectItem={(centroDeCusto) =>
                      setCentroDeCustoRateio({
                        ...centroDeCustoRateio,
                        nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                        centroDeCusto,
                      })
                    }
                    dataSourceTextProperty='noCentroDeCusto'
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Valor Percentual'
                    text={centroDeCustoRateio.vlrPercentual}
                    mask={MaskTypes.Integer}
                    onChangedValue={(vlrPercentual) =>
                      setCentroDeCustoRateio({
                        ...centroDeCustoRateio,
                        vlrPercentual,
                      })
                    }
                  />
                </div>

                <div className='col-1 d-flex align-items-end'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='+'
                    onClick={onClickAddCentroDeCustoRateio}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Total %'
                    text={totalCentroDeCustoRateio}
                    readOnly
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewCentrodeCustosRateio}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsCentrodeCustosRateio}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Datas de Ocorrencia de Ações'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Textbox
                    label='Data de Aquisição'
                    text={clienteLinha.dtAquisicao}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtAquisicao) =>
                      setClienteLinha({
                        ...clienteLinha,
                        dtAquisicao,
                      })
                    }
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Data de Cancelamento'
                    text={clienteLinha.dtCancelamento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtCancelamento) =>
                      setClienteLinha({ ...clienteLinha, dtCancelamento })
                    }
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='Faturar Proporcionalmente'
                    searchDataSource={onSearchFaturarProporcionalmente}
                    selectedItem={clienteLinha.grupoTCFatProporc}
                    onSelectItem={(grupoTCFatProporc) =>
                      setClienteLinha({
                        ...clienteLinha,
                        grupoTCFatProporc,
                        nrSeqGrupoTCFatProporc:
                          grupoTCFatProporc.nrSeqGrupoTipoChamada,
                      })
                    }
                    dataSourceTextProperty='noGrupoTipoChamada'
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Data Bloqueio'
                    text={clienteLinha.dtBloqueio}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtBloqueio) =>
                      setClienteLinha({
                        ...clienteLinha,
                        dtBloqueio,
                      })
                    }
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Data Desbloqueio'
                    text={clienteLinha.dtDesbloqueio}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtDesbloqueio) =>
                      setClienteLinha({
                        ...clienteLinha,
                        dtDesbloqueio,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-4'>
                  <Textbox
                    label='Nr. Protocolo Bloqueio'
                    text={clienteLinha.nrProtbloqueio}
                    onChangedValue={(nrProtbloqueio) =>
                      setClienteLinha({
                        ...clienteLinha,
                        nrProtbloqueio,
                      })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12 mt-3'>
          <Panel>
            <Panel.Header
              title='Adicionar Pacote SMS'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Autocomplete
                    label='Pacote SMS'
                    searchDataSource={onSearchPacoteSMS}
                    selectedItem={clienteLinha.pacoteSms}
                    onSelectItem={(pacoteSms) => {
                      setClienteLinha({
                        ...clienteLinha,
                        pacoteSms,
                        nrSeqPacoteSms: pacoteSms.nrSeqPacoteSms,
                      });
                    }}
                    dataSourceTextProperty='noPacoteSms'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Início Vigencia SMS'
                    text={clienteLinha.dtIniVigenciaPacSms}
                    maxLength={10}
                    readOnly={clienteLinha.nrSeqPacoteSms < 0 === true}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtIniVigenciaPacSms) =>
                      setClienteLinha({
                        ...clienteLinha,
                        dtIniVigenciaPacSms,
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Fim Vigencia SMS'
                    text={clienteLinha.dtFimVigenciaPacSms}
                    maxLength={10}
                    readOnly={clienteLinha.nrSeqPacoteSms < 0 === true}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtFimVigenciaPacSms) =>
                      setClienteLinha({
                        ...clienteLinha,
                        dtFimVigenciaPacSms,
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor Pacote SMS'
                    text={clienteLinha.vlrPacoteSms}
                    mask={MaskTypes.Decimal}
                    readOnly={clienteLinha.nrSeqPacoteSms < 0 === true}
                    onChangedValue={(vlrPacoteSms) =>
                      setClienteLinha({
                        ...clienteLinha,
                        vlrPacoteSms,
                      })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Datas de Ocorrencia de Ações'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <RadioButton
                    label='Tipo de Ocorrencia'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={ocorrenciaClienteLinha.flgAquisicao}
                    buttons={tipoOcorrencia}
                    onChange={(flgAquisicao) =>
                      setOcorrenciaClienteLinha({
                        ...ocorrenciaClienteLinha,
                        flgAquisicao,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox
                    label={
                      ocorrenciaClienteLinha.flgAquisicao === 'S'
                        ? 'Data de Aquisição'
                        : 'Data de Cancelamento'
                    }
                    text={ocorrenciaClienteLinha.dtOcorrencia}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtOcorrencia) =>
                      setOcorrenciaClienteLinha({
                        ...ocorrenciaClienteLinha,
                        dtOcorrencia,
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Por Grupo'
                    onChange={(flgPorGrupo) =>
                      setOcorrenciaPorGrupo(flgPorGrupo)
                    }
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label={
                      ocorrenciaPorGrupo ? 'Grupo Tipo Chamada' : 'Tipo Chamada'
                    }
                    searchDataSource={onSearchGrupoTipoChamada}
                    selectedItem={ocorrenciaClienteLinha.grupoTipoChamada}
                    visible={setOcorrenciaPorGrupo}
                    onSelectItem={(grupoTipoChamada) => {
                      if (ocorrenciaPorGrupo)
                        setOcorrenciaClienteLinha({
                          ...ocorrenciaClienteLinha,
                          grupoTipoChamada,
                          nrSeqGrupoTipoChamada:
                            grupoTipoChamada.nrSeqGrupoTipoChamada,
                        });
                      else
                        setOcorrenciaClienteLinha({
                          ...ocorrenciaClienteLinha,
                          tipoChamada: grupoTipoChamada,
                          nrSeqTipoChamada: grupoTipoChamada.nrSeqTipoChamada,
                        });
                    }}
                    dataSourceTextProperty={
                      ocorrenciaPorGrupo
                        ? 'noGrupoTipoChamada'
                        : 'noTipoChamada'
                    }
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Observacoes'
                    text={ocorrenciaClienteLinha.obs}
                    maxLength={1000}
                    onChangedValue={(obs) =>
                      setOcorrenciaClienteLinha({
                        ...ocorrenciaClienteLinha,
                        obs,
                      })
                    }
                  />
                </div>

                <div className='col-2 d-flex align-items-end'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar Ocorrência'
                    onClick={onClickAddOcorrencia}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewDataOcorrenciaAcoes}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsDataOcorrenciaAcoes}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Data de Renovação do Contrato do Cliente'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />

            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Textbox
                    label='Data de Renovação'
                    text={clienteLinha.dtRenovacao}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChangedValue={(dtRenovacao) =>
                      setClienteLinha({ ...clienteLinha, dtRenovacao })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Automatização de Envio de Faturas / Extras'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Envia SMS'
                    checked={clienteLinha.flgEnviaSms}
                    onChange={(flgEnviaSms) => {
                      setClienteLinha({
                        ...clienteLinha,
                        flgEnviaSms,
                      });
                    }}
                  />
                </div>

                <div className='col-2'>
                  <Switch
                    formControl
                    label='Modem'
                    checked={clienteLinha.flgModem}
                    onChange={(flgModem) => {
                      setClienteLinha({
                        ...clienteLinha,
                        flgModem,
                      });
                    }}
                  />
                </div>

                <div className='col-2'>
                  <Switch
                    formControl
                    label='Recebe Fatura E-mail'
                    checked={clienteLinha.flgRecFatCorreio}
                    onChange={(flgRecFatCorreio) => {
                      setClienteLinha({
                        ...clienteLinha,
                        flgRecFatCorreio,
                      });
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Email Recebimento Fatura'
                    text={clienteLinha.txtEmailFatura}
                    onChangedValue={(txtEmailFatura) =>
                      setClienteLinha({ ...clienteLinha, txtEmailFatura })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor de Venda'
                    text={clienteLinha.vlrVendaAparelho}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrVendaAparelho) =>
                      setClienteLinha({ ...clienteLinha, vlrVendaAparelho })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <Textbox
                    label='Observação'
                    text={clienteLinha.observacao}
                    onChangedValue={(observacao) =>
                      setClienteLinha({ ...clienteLinha, observacao })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3  '>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Informações do Chip'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox label='Nr ICCID' text={chip.nrIccid} readOnly />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Operadora'
                    text={operadora.noOperadora}
                    readOnly
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Nota Fiscal'
                    text={chip.nrNotaFiscal}
                    readOnly
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Data Nota Fiscal'
                    text={chip.dtNotaFiscal}
                    readOnly
                    mask={MaskTypes.Date}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Preço de Custo'
                    text={chip.vlrPrecoCusto}
                    readOnly
                    mask={MaskTypes.Decimal}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Parcelamentos de Aparelhos'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewParcelamentoAparelhos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsParcelamentoAparelhos}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Histórico de Alterações'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewHistoricoAlteracoes}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsHistoricoAlteracoes}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
