import React, { useEffect, useRef, useState } from 'react';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';

import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getNfsPagined,
  printNfs,
  deleteAllNfs,
  getStatusNfsAutoComplete,
  transmitNfs,
  cancelNfs,
  postDownload,
  printList,
  getPatternsNFSe,
  getExibeFiltroPendenteFaturamento,
  verificarStatusNfs,
} from 'core/services/VEN/nfs';

import { getLastNfse } from 'core/services/VEN/nfse';

import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Textbox,
  DropdownMulti,
  Switch,
} from 'ui/components';
import ModalDetalhesNotaFiscal from './modalDetalhesNotaFiscal';
import ModalMotivoCancelNotaFiscal from './modalMotivoCancelNotaFiscal';

function newDateFormat(intervalo) {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() - intervalo + 1)
    .toString()
    .padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.0`;
}

export default function NotaFiscal({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const [exibePendenteFaturamento, setExibePendenteFaturamento] = useState(
    false
  );
  const [filtros, setFiltros] = useState({
    dtCadInicial: newDateFormat(1),
    dtCadFinal: newDateFormat(0),
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalMotivoCancelamento, setModalMotivoCancelamento] = useState({
    show: false,
  });
  const [modalDetails, setModalDetails] = useState({ show: false });
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownCliente = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchPessoaCli = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchStatus = async (e) => {
    const { status, message: msg, data: nfs } = await getStatusNfsAutoComplete({
      noStatus: e,
    });
    if (msg) onSetMessage(status, msg);
    return nfs;
  };

  const onSearchTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocTipoOrcamentoAutoComplete({
      noTipoOrcamento: e,
    });
    onSetMessage(status, msg);
    return tipos;
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getNfsPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printList(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onClickTransmitir = async (e) => {
    setLoading(true);

    const { status, message: msg } = await transmitNfs(e);

    setLoading(false);

    onSetMessage(status, msg);

    search();
  };

  const onClickCancelar = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await getPatternsNFSe(
      e.nrSeqEmpresa
    );

    if (status === ResponseStatus.Error) {
      return onSetMessage(status, msg);
    }

    const objNFSe = { ...e, tipoIntegracao: value };
    setLoading(false);

    return setModalMotivoCancelamento({ show: true, nfse: objNFSe });
  };

  const onCancelar = async (item) => {
    setModalMotivoCancelamento({ show: false });
    setLoading(true);

    const { status, message: msg } = await cancelNfs(item);

    setLoading(false);

    onSetMessage(status, msg);

    search();
  };

  const onPrintNF = async (e) => {
    setLoading(true);

    if (e.nrSeqNfs === 0) {
      setLoading(false);
      return;
    }
    const { value } = await printNfs(e.nrSeqNfs);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const download = (filename, text) => {
    const element = document.createElement('a');

    const link = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;

    element.setAttribute('href', link);

    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const onClickDownload = async (e) => {
    if (e.nrSeqNfs === 0) {
      return;
    }
    setLoading(true);

    const { message: msg } = await postDownload(e);

    download(msg.split(';')[0], msg.split(';')[1]);

    setLoading(false);
  };

  const onClickShowDetails = async (e) => {
    setLoading(true);

    const { nrSeqNfs } = e;
    if (nrSeqNfs === 0) {
      setLoading(false);
      return;
    }
    const details = await getLastNfse({ nrSeqNfs });

    const { noXml, noRetornoLote, noRetornoNfs } = details;

    const modal = {
      show: true,
      noXml,
      noRetornoLote,
      noRetornoNfs,
    };

    setModalDetails(modal);

    setLoading(false);
  };

  const closeModalDetails = () => {
    setModalDetails({ show: false });
  };

  const closeModalMotivoCancelamento = () => {
    setModalMotivoCancelamento({ show: false });
  };

  const onClickVerificarNfse = async (e) => {
    setLoading(true);

    const { status, message: msg, data } = await verificarStatusNfs(e);

    setLoading(false);

    if (data) {
      await search();
      onSetMessage(status, `${msg}. Clique em detalhes para mais informações.`);
    } else {
      onSetMessage(ResponseStatus.Warning, msg);
    }
  };

  const columns = [
    { key: 'nrSeqNfs', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrNfs', title: 'Número' },
    { key: 'serieNFs', title: 'Série' },
    { key: 'dtCad', title: 'Cadastro', format: GridView.DataTypes.Date },
    { key: 'dtEmissao', title: 'Autorização', format: GridView.DataTypes.Date },
    { key: 'empresa.noPessoa', title: 'Empresa', visible: false },
    { key: 'cliente.noPessoa', title: 'Cliente', sortKey: 'noPessoa' },
    {
      key: 'orcamento.cdProposta',
      title: 'Proposta',
      sortKey: 'cdProposta',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'dtRenovacao',
      title: 'Data Renovação',
      sortKey: 'dtRenovacao',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'orcamento.poco.cdPocPoco',
      title: 'Poço',
      sortKey: 'cdPocPoco',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'noStatusAutorizacao',
      title: 'Status',
      colorText: 'noColorStatusAutorizacao',
    },
    {
      key: 'vlrBruto',
      title: 'Vlr Bru',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrLiquido',
      title: 'Vlr Líq',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqNfs',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickTransmitir(e, datasource),
      theme: Theme.Success,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Autorizar na Prefeitura',
      tooltipDirection: 'bottom',
      title: 'Autorizar',
      visibleDynamic: 'flgPermiteAutorizar',
    },
    {
      key: 'nrSeqNfs',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickCancelar(e, datasource),

      theme: Theme.Danger,
      icon: ['fa', 'ban'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar na Prefeitura',
      tooltipDirection: 'bottom',
      title: 'Cancelar',
      visibleDynamic: 'flgPermiteCancelar',
    },
    {
      key: 'nrSeqNfs',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickShowDetails(e, datasource),
      theme: Theme.Info,
      icon: 'tasks',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhes',
      tooltipDirection: 'bottom',
      title: 'Detalhes',
    },
    {
      key: 'nrSeqNfs',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onPrintNF(e, datasource),
      theme: Theme.Info,
      icon: ['fa', 'print'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
      title: 'Imprimir',
    },
    {
      key: 'nrSeqNfs',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Info,
      icon: ['fa', 'download'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
      title: 'Download',
    },
    {
      key: 'nrSeqNfs',
      title: 'Situação',
      visibleDynamic: 'flgPossivelNaoAutorizar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickVerificarNfse(e, datasource),
      theme: Theme.Success,
      icon: 'info-circle',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Verificar NFSe',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    async function load() {
      setLoading(true);
      setExibePendenteFaturamento(await getExibeFiltroPendenteFaturamento());

      const obj = {
        dtCadInicial: newDateFormat(1),
        dtCadFinal: newDateFormat(0),
      };
      const { data, pagination } = await getNfsPagined(obj);

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'NotaFiscal/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqNfs = sourceRow.find((e) => e.key === 'nrSeqNfs');
    onOpenMaintenance(nrSeqNfs.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllNfs(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  useEffect(() => {
    dropDownCliente.current.loadDataSource(onSearchPessoaCli);
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Notas Fiscais'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
      onPrint={onPrint}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Emissão Inicial'
            text={filtros.dtEmissaoInicial}
            mask={MaskTypes.Date}
            onChange={(dtEmissaoInicial) =>
              setFiltros({
                ...filtros,
                dtEmissaoInicial,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Emissão Final'
            text={filtros.dtEmissaoFinal}
            mask={MaskTypes.Date}
            onChange={(dtEmissaoFinal) =>
              setFiltros({
                ...filtros,
                dtEmissaoFinal,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Número da Nota'
            text={filtros.nrNfs}
            MaskTypes={MaskTypes.Integer}
            onChangedValue={(nrNfs) => {
              setFiltros({
                ...filtros,
                nrNfs,
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Textbox
            label='Proposta'
            text={filtros.cdProposta}
            MaskTypes={MaskTypes.Integer}
            onChangedValue={(cdProposta) => {
              setFiltros({
                ...filtros,
                cdProposta,
              });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Poço'
            text={filtros.cdPocPoco}
            MaskTypes={MaskTypes.Integer}
            onChangedValue={(cdPocPoco) => {
              setFiltros({
                ...filtros,
                cdPocPoco,
              });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-8'>
          <DropdownMulti
            label='Cliente'
            ref={dropDownCliente}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqPessoaCli'
            selectedItems={selectedItems.cliente ?? []}
            onSelectItem={(cliente) => {
              setSelectedItems({ ...selectedItems, cliente });
              setFiltros({
                ...filtros,
                nrSeqPessoaCliS: cliente?.map((p) => p.nrSeqPessoaCli),
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Status'
            searchDataSource={onSearchStatus}
            selectedItem={filtros.status}
            onSelectItem={(status) => {
              setFiltros({
                ...filtros,
                status,
                nrSeqStatus: status.nrSeqStatus,
              });
            }}
            dataSourceTextProperty='noStatus'
          />
        </div>
        {exibePendenteFaturamento && (
          <div className='col'>
            <Switch
              formControl
              checked={filtros.flgPendenteFaturamento}
              label='Pendente Faturamento'
              onChange={(flgPendenteFaturamento) =>
                setFiltros({
                  ...filtros,
                  flgPendenteFaturamento,
                })
              }
            />
          </div>
        )}

        <div className='col'>
          <DatePicker
            label='Cadastro Inicial'
            text={filtros.dtCadInicial}
            mask={MaskTypes.Date}
            onChange={(dtCadInicial) =>
              setFiltros({
                ...filtros,
                dtCadInicial,
              })
            }
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Cadastro Final'
            text={filtros.dtCadFinal}
            mask={MaskTypes.Date}
            onChange={(dtCadFinal) =>
              setFiltros({
                ...filtros,
                dtCadFinal,
              })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Tipo Orçamento'
            searchDataSource={onSearchTipoOrcamento}
            selectedItem={filtros.tipoOrcamento}
            onSelectItem={(tipoOrcamento) => {
              filtros.nrSeqPocTipoOrcamento = setFiltros({
                ...filtros,
                tipoOrcamento,
                nrSeqPocTipoOrcamento: tipoOrcamento.nrSeqPocTipoOrcamento,
              });
            }}
            dataSourceTextProperty='noPocTipoOrcamento'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
            enableExcelExport
            sumFields
          />
        </div>
      </div>

      <ModalDetalhesNotaFiscal
        noXml={modalDetails.noXml}
        noRetornoLote={modalDetails.noRetornoLote}
        noRetornoNfs={modalDetails.noRetornoNfs}
        show={modalDetails.show}
        onClose={closeModalDetails}
      />

      <ModalMotivoCancelNotaFiscal
        show={modalMotivoCancelamento.show}
        nfse={modalMotivoCancelamento.nfse}
        onCancelar={(item) => onCancelar(item)}
        onClose={closeModalMotivoCancelamento}
      />
    </CSDSelPage>
  );
}
