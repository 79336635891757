import React, { useRef, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  GridView,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import Loader from 'ui/components/Loader';
import { getPessoaAutoCompleteRemDesCli } from 'core/services/SEG';
import { generateCteUnified } from 'core/services/FRO/viagem';

export default function ModalAgruparCte(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, cte } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (cte !== undefined) {
      let sum = 0;
      let soma = 0;
      for (let i = 0; i < cte.length; ) {
        sum += cte[i].vlrFrete;
        soma += cte[i].pesoBruto;
        i += 1;
      }

      setData({
        ...data,
        vlrFrete: sum,
        vlrSomadoCte: sum,
        totalPeso: soma,
      });
    }
    gridView?.current?.setDataSource(cte);
  }, [show]);

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onGenerateCteUnified = async () => {
    if (gridView && gridView.current) {
      setLoading(true);
      const lista = gridView.current.getDataSource();

      if (lista.length === 0) {
        onSetMessage(0, 'Necessário selecionar as ctes para agrupamento');
        setLoading(false);
        return;
      }

      if (
        data.vlrFrete === null ||
        data.vlrFrete === undefined ||
        data.vlrFrete === 0
      ) {
        onSetMessage(0, 'Necessário inserir um valor valido para agrupamento');
        setLoading(false);
        return;
      }

      let alteraCliente = false;

      if (data.nrSeqPessoaCli > 0) {
        alteraCliente = true;
      }

      lista.forEach((item) => {
        item.vlrFrete = data.vlrFrete;
        if (alteraCliente) {
          item.nrSeqPessoaCli = data.nrSeqPessoaCli;
          item.cliente = data.cliente;
        }
      });

      const obj = {
        lista,
        nrSeqPessoaCli: data.nrSeqPessoaCli,
        vlrFrete: data.vlrFrete,
      };

      const { status, message: msg } = await generateCteUnified(obj);

      if (msg) onSetMessage(status, msg);
      setLoading(false);

      if (status === ResponseStatus.Success) {
        props.onClose({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }
    }
  };

  /* const salvar = async (flgConfirmaAlteracao = false) => {
    setLoading(true);

    const lista = gridView.current.getDataSource();

    const obj = {
      titulosAgrupados: lista,
      nrSeqTipoDespesa: data.nrSeqTipoDespesa,
      vlrTotal: data.vlrSaldo,
      flgConfirmaAlteracao,
    };

    if (obj.tituloPagarPlanoDeConta !== null) {
      for (let i = 0; i < obj.tituloPagarPlanoDeConta.length; ) {
        obj.tituloPagarPlanoDeConta[i].vlrTitulo =
          obj.tituloPagarPlanoDeConta[i].vlrPlanoConta;
        i += 1;
      }
    }

    const { status, message: msg } = await AgrupamentoTituloPagar(obj);

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  }; */

  const onSearchPessoaCli = async (e) => {
    const {
      status,
      message: msg,
      data: pessoasCli,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'CLI',
    });
    if (msg) onSetMessage(status, msg);
    return pessoasCli;
  };

  const columns = [
    { key: 'nrSeqColetaEmbarque', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdColetaEmbarque', title: 'Cód.' },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'vlrDoc', title: 'Total Doc.', format: GridView.DataTypes.Decimal },
    { key: 'averbado', title: 'Aver.' },
    { key: 'vlrFrete', title: 'Frete', format: GridView.DataTypes.Decimal },

    {
      key: 'noProtocoloAverbacaoSeguro',
      title: 'Protocolo Seguro 1',
    },
    {
      key: 'noProtocoloAverbacaoSeguroEmpresa',
      title: 'Protocolo Seguro 2',
    },
  ];

  return (
    <Modal
      show={show}
      title='Agrupamento de Titulos'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='col-5 mb-3'>
            <Textbox
              label='Valor Total Frete'
              readOnly
              text={data?.vlrSomadoCte}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrSomadoCte) =>
                setData({ ...data, vlrSomadoCte })
              }
            />
          </div>
          <div className='col-7 mb-3'>
            <Textbox
              label='Novo Valor do CTE'
              text={data?.vlrFrete}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrFrete) => setData({ ...data, vlrFrete })}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-5 mb-3'>
            <Textbox
              label='Peso Total'
              readOnly
              text={data?.totalPeso}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(totalPeso) => setData({ ...data, totalPeso })}
            />
          </div>
          <div className='col-7 mb-3'>
            <Autocomplete
              label='Tomador (Cliente)'
              searchDataSource={onSearchPessoaCli}
              selectedItem={data?.cliente}
              onSelectItem={(cliente) => {
                setData({
                  ...data,
                  cliente,
                  nrSeqPessoaCli: cliente.nrSeqPessoa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={() => onGenerateCteUnified()}
            />
          </div>

          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
