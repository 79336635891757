import React from 'react';
import { Autocomplete, Textarea, Textbox } from 'ui/components';
// @ts-expect-error
import { ResponseStatus } from 'ui/Helpers/enums.ts';
import { MaskTypes } from 'ui/Helpers/masks';
import { IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN/cliente';
import { getPacotesAdicionaisAutoComplete } from 'core/services/TEL/tipoChamada';
import { getClienteLinhasAutoCompleteResumido } from 'core/services/TEL/clienteLinha';

export default function ManutencaoAdicionarPacoteDeDadosAdicional({
  data,
  setData,
  onSetMessage,
}: IManutencaoDemanda) {
  const onSearchClienteLinha = async (e: string) => {
    const obj: any = {
      nrPrefixoLinha: e,
      flgStatus: 'N',
      nrSeqOperadora: 20,
    };

    if (data.nrSeqPessoaCli) {
      obj.nrSeqPessoaCli = data.nrSeqPessoaCli;
    }
    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const {
      status,
      message: msg,
      data: linhas,
    } = await getClienteLinhasAutoCompleteResumido(obj);

    if (linhas.length === 0)
      onSetMessage(
        ResponseStatus.Error,
        'Cliente não possui linhas cadastradas nessa empresa'
      );

    onSetMessage(status, msg);

    return linhas;
  };

  const onSearchCliente = async (e: string) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: false,
      flgFiltrarEmpresa: true,
    });

    return clientes;
  };

  const onSearchPacotesAdicionais = async (e: string) => {
    const { tiposChamadas } = await getPacotesAdicionaisAutoComplete({
      noTipoChamada: e,
    });

    return tiposChamadas;
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col-2'>
          <Autocomplete
            label='Linha'
            required
            maxLength={16}
            searchDataSource={onSearchClienteLinha}
            minLengthTextSearch={5}
            placeholder='Informe ao menos 5 dígitos da linha'
            selectedItem={data.chip}
            onSelectItem={(linha: any) => {
              setData({
                ...data,
                chip: linha.chip,
                nrSeqChip: linha.nrSeqChip,
                cliente: linha.cliente,
                nrSeqPessoaCli: linha.nrSeqPessoaCli,
                empresa: linha.cliente?.empresa,
                nrSeqEmpresa: linha.cliente?.nrSeqEmpresa,
                nrSeqClienteLinha: linha.nrSeqClienteLinha,
              });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            required
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Informe ao menos 3 caracteres do nome do cliente'
            selectedItem={data.cliente}
            onSelectItem={(cliente: any) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                chip: undefined,
                nrSeqChip: undefined,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Autocomplete
            label='Operadora'
            enabled={false}
            readOnly
            selectedItem={data.chip?.operadora}
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pacote'
            searchDataSource={onSearchPacotesAdicionais}
            selectedItem={data.tipoChamada}
            onSelectItem={(tipoChamada: any) => {
              setData({
                ...data,
                tipoChamada,
                nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                vlrPacoteEncargoManual: tipoChamada.vlrEncargo,
              });
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Valor Pacote'
            maxLength={12}
            text={data.vlrPacoteEncargoManual}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrPacoteEncargoManual: number) => {
              setData({
                ...data,
                vlrPacoteEncargoManual,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao: string) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
      </div>
    </>
  );
}
