import React, { useRef } from 'react';
import { Theme } from 'ui/Helpers/utils';
import { Autocomplete, GridView, Button } from 'ui/components';
// @ts-expect-error
import { ResponseStatus, ColumnDataTypes } from 'ui/Helpers/enums.ts';
import { GridviewColumns } from 'ui/Helpers/interfaces';
import { IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import { getClienteLinhasAutoCompleteResumido } from 'core/services/TEL/clienteLinha';
import { getConsumoMVNO } from 'core/services/HELP/demanda';

export default function ManutencaoVerificarConsumoMVNO({
  data,
  setData,
  onSetMessage,
}: IManutencaoDemanda) {
  const gridView = useRef<any>();

  const onSearchClienteLinha = async (e: string) => {
    const obj: any = {
      nrPrefixoLinha: e,
      flgStatus: 'N',
      nrSeqOperadora: 20,
    };

    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const {
      status,
      message: msg,
      data: linhas,
    } = await getClienteLinhasAutoCompleteResumido(obj);

    if (linhas.length === 0)
      onSetMessage(
        ResponseStatus.Error,
        'Cliente não possui linhas cadastradas nessa empresa'
      );

    onSetMessage(status, msg);

    return linhas;
  };

  const searchConsumoMVNO = async () => {
    if (!data.nrSeqChip) {
      onSetMessage(ResponseStatus.Error, 'Selecione a linha');

      return;
    }

    const { status, msg, consumo }: any = await getConsumoMVNO(data.nrSeqChip);

    if (status === ResponseStatus.Success) {
      gridView?.current?.setDataSource([consumo]);
    } else {
      onSetMessage(status, msg);
    }
  };

  const columns: Array<GridviewColumns> = [
    { key: 'consumoDados', title: 'Consumo De Dados', sortable: false },
    {
      key: 'dtInicioPeriodo',
      title: 'Inicio do Ciclo',
      format: ColumnDataTypes.Date,
      sortable: false,
    },
    {
      key: 'dtFimPeriodo',
      title: 'Fim do Ciclo',
      format: ColumnDataTypes.Date,
      sortable: false,
    },
  ];

  return (
    <>
      <div className='row mb-3'>
        <div className='col-2'>
          <Autocomplete
            label='Linha'
            required
            maxLength={16}
            searchDataSource={onSearchClienteLinha}
            selectedItem={data.chip}
            minLengthTextSearch={5}
            placeholder='Informe ao menos 5 dígitos da linha'
            onSelectItem={(linha: any) => {
              setData({
                ...data,
                chip: linha.chip,
                nrSeqChip: linha.nrSeqChip,
                cliente: linha.cliente,
                nrSeqPessoaCli: linha.nrSeqPessoaCli,
                empresa: linha.cliente?.empresa,
                nrSeqEmpresa: linha.cliente?.nrSeqEmpresa,
                nrSeqClienteLinha: linha.nrSeqClienteLinha,
              });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            selectedItem={data.cliente}
            enabled={false}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col d-flex align-items-end'>
          <Button
            text='Buscar Consumo'
            onClick={searchConsumoMVNO}
            theme={Theme.Primary}
            icon='search'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            showSelectSizes={false}
            showPagination={false}
          />
        </div>
      </div>
    </>
  );
}
