import Veiculo from './veiculo';

export default class ManutencaoVeiculoPlaca {
  constructor(jsonObject = {}) {
    this.nrOdometro = jsonObject.nrOdometro;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;

    // Propriedades auxiliares
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.placa = jsonObject.placa;
    this.ultOdometro = jsonObject.ultOdometro;
    this.ultAbastecimento = jsonObject.ultAbastecimento;
    this.status = jsonObject.status;
    this.flgPrincipal = jsonObject.flgPrincipal;
    this.noTipoOdometro = jsonObject.noTipoOdometro;
  }

  toJson = () => JSON.stringify(this);
}
