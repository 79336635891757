import React, { useEffect, useRef, useState } from 'react';
import { BootstrapSizes, MimeTypes, Theme } from 'ui/Helpers/utils';
import { Button, FileUpload, GridView, Panel } from 'ui/components';

export default function ImportadorDocumentosDemanda({
  data,
  documentos,
  setDocumentos,
  setDataListDocRemove,
  onSetMessage,
}) {
  const gridView = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [fileToRemove, setFileToRemove] = useState(null);

  const onClickAddDocumento = () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    } else {
      setFileToRemove(gridItem);
    }

    if (gridView && gridView.current) {
      gridView.current.setDataSource(list);
    }
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridView && gridView.current) {
          gridView.current.setDataSource(data.documentos);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (fileToRemove !== null) {
      const index = documentos.findIndex(
        (doc) =>
          doc.name === fileToRemove.noArquivo &&
          doc.size === fileToRemove.noTamanho &&
          doc.type === fileToRemove.noTipoArquivo
      );

      if (index >= 0) {
        documentos.splice(index, 1);
      }

      setFileToRemove(null);
    }
  }, [fileToRemove]);

  return (
    <Panel>
      <Panel.Header theme={Theme.Primary} title='Documentos' />
      <Panel.Body>
        <div className='row mb-3'>
          <div className='col-6'>
            <FileUpload
              files={importedFile}
              onChange={(files) => {
                if (files.length > 0) {
                  if (files[0].size <= 5000000) {
                    setImportedFile(files);
                  } else {
                    onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                  }
                } else {
                  setImportedFile(files);
                }
              }}
              allowedMimeTypes={[
                MimeTypes.Types.PDF,
                MimeTypes.Types.Image,
                MimeTypes.Types.Word,
              ]}
            />
          </div>
          <div className='col d-flex justify-content-center'>
            <Button
              text='Adicionar Documento'
              className='px-5'
              icon='plus'
              theme={Theme.Success}
              size={BootstrapSizes.Medium}
              template={Button.Templates.Button}
              onClick={() => onClickAddDocumento()}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
}
