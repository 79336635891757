import {
  SearchClienteFromChip,
  getClienteAutoCompleteDemanda,
} from 'core/services/FIN';
import {
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoComplete,
  getClienteLinhasByCliente,
  getPlanoAutoCompleteDemanda,
  getTipoChamadaAutoCompleteResumido,
  getUltimoCicloLinha,
} from 'core/services/TEL';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  FileUpload,
  GridView,
  Panel,
  Textarea,
  Textbox,
} from 'ui/components';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import ModalRegistrarChipNovo from './modalRegistrarChipNovo';

export default function ManutencaoTrocaTitularidade({
  data,
  setData,
  onSetMessage,
  addFiles = false,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  setLoading,
  clienteMessage,
  onSelectCliente,
  onSelectFormaPagamento,
  onAutoCompleteFormaPagamento,
}) {
  const gridView = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [contratoMessage, setContratoMessage] = useState({});
  const [showModalCadastroChipNovo, setShowModalCadastroChipNovo] = useState(
    false
  );
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);

  useEffect(() => {
    if ('nrSeqDemanda' in data) {
      if (documentos.length > 0) {
        if (gridView && gridView.current) {
          gridView.current.setDataSource(data.documentos);
        }
      }
    }
  }, [data]);

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      NrPrefixoLinha: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const onSelectChip = async (chip) => {
    setLoading(true);

    const newDataObject = {
      ...data,
      chip,
      nrSeqChip: chip.nrSeqChip,
    };

    if ('nrSeqChip' in chip) {
      const today = new Date();

      const lastCycle = await getUltimoCicloLinha(chip.nrSeqChip);

      const cliente = await SearchClienteFromChip(chip.nrSeqChip);

      if (cliente.nrSeqPessoaCli) {
        newDataObject.clienteTitularOld = cliente;
        newDataObject.nrSeqPessoaOld = cliente?.nrSeqPessoaCli;

        if (!data.nrSeqEmpresa) {
          newDataObject.empresa = cliente.empresa;
          newDataObject.nrSeqEmpresa = cliente.empresa?.nrSeqEmpresa;
        }
      }

      const obj = {
        nrSeqChip: chip.nrSeqChip,
        flgStatus: 'N',
      };

      if (data.nrSeqEmpresa) {
        obj.nrSeqEmpresa = data.nrSeqEmpresa;
      }

      const { data: clienteLinhas } = await getClienteLinhasByCliente(obj);

      if (clienteLinhas.length > 0) {
        const clienteLinha = clienteLinhas[0];

        newDataObject.vlrVenda = clienteLinha.vlrEncargo;
        newDataObject.nrSeqPlano = clienteLinha.nrSeqPlano;
        newDataObject.plano = clienteLinha.plano;
        newDataObject.nrSeqCentroDeCusto = clienteLinha.nrSeqCentroDeCusto;
        newDataObject.centroDeCusto = clienteLinha.centroDeCusto;
        newDataObject.nrSeqTipoChamada = clienteLinha.nrSeqTipoChamada;
        newDataObject.tipoChamada = clienteLinha.tipoChamada;
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Não foi encontrado nenhum cliente linha com status normal em uma empresa que voce tenha acesso '
        );
      }

      if (lastCycle) {
        const dtPerInicial = new Date(lastCycle.dtPerInicial);
        const dtPerFinal = new Date(lastCycle.dtPerFinal);

        const twoMonthsAgo = new Date();
        twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

        if (dtPerFinal <= twoMonthsAgo) {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: `Essa linha não possui um ciclo importado recente, verifique se a linha não esta cancelada antes de prosseguir `,
            color: 'danger',
          });
        } else {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: ``,
            color: 'primary',
          });
        }

        const dtSolicitacao = new Date(
          dtPerFinal.getFullYear(),
          dtPerFinal.getMonth(),
          dtPerFinal.getDay() + 1
        );

        newDataObject.dtSolicitacao = dtSolicitacao.toJSON().slice(0, 10);
      } else {
        setContratoMessage({
          cycle: 'Linha não possui um ciclo anterior',
          message: '',
          color: 'primary',
        });

        newDataObject.dtSolicitacao = today.toJSON().slice(0, 10);
      }

      setData(newDataObject);
    } else {
      setContratoMessage({});

      setData({
        ...data,
        chip: undefined,
        nrSeqChip: undefined,
        dtSolicitacao: undefined,
        clienteTitularOld: undefined,
        nrSeqPessoaOld: undefined,
        vlrVenda: undefined,
        nrSeqPlano: undefined,
        plano: undefined,
        nrSeqCentroDeCusto: undefined,
        centroDeCusto: undefined,
        nrSeqTipoChamada: undefined,
        tipoChamada: undefined,
        nrSeqFormaPagamento: undefined,
        formaPagamento: undefined,
        nrSeqVencimento: undefined,
        vencimento: undefined,
        nrSeqPessoaInformacaoBancaria: undefined,
        informacaoBancaria: undefined,
        nrSeqPlanoRateioEmpresa: undefined,
        planoRateioEmpresa: undefined,
      });
    }

    setLoading(false);
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });

    return clientes;
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    onSetMessage(status, msg);
    return centros;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchPlanoOperadoraDestino = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.chip.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPacoteDados = async (e) => {
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o Plano.');
      return [];
    }

    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');

      return [];
    }
    if (data.nrSeqCentroDeCusto) {
      const {
        planovinculo,
      } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
        nrSeqPlano: data.plano?.nrSeqPlano,
        nrSeqOperadora: data.nrSeqOperadoraDestino,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
        noTipoChamada: e,
      });
      if (planovinculo.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Centro de Custo sem pacote vinculado nessa Empresa.'
        );
      }

      return planovinculo;
    }
    const { tiposChamadas } = await getTipoChamadaAutoCompleteResumido({
      nrSeqPlano: data.plano?.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadoraDestino,
      flgBuscaEncargo: true,
      noTipoChamada: e,
    });

    if (tiposChamadas.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Nenhum pacote de dados encontrado para esse plano'
      );
    }

    return tiposChamadas;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(list);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <>
      {'cycle' in contratoMessage && data.nrSeqChip !== undefined && (
        <div className='row mb-3'>
          <div className='col'>
            <div className={`card border-${contratoMessage.color}`}>
              <div className={`card-body text-${contratoMessage.color}`}>
                <p className='card-text mb-0'>{contratoMessage.cycle}</p>
                {contratoMessage.message.length > 0 && (
                  <p className='card-text'>{contratoMessage.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Número da linha'
            required
            enabled={!data.nrSeqDemanda}
            searchDataSource={onSearchChip}
            minLengthTextSearch={5}
            placeholder='Informe ao menos 5 dígitos da linha'
            selectedItem={data.chip}
            onSelectItem={async (chip) => {
              await onSelectChip(chip);
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cliente Original'
            enabled={false}
            readOnly
            selectedItem={data.clienteTitularOld}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='Dados da Titular' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                {data.clienteTitularOld?.flgFisica ||
                !data.clienteTitularOld ? (
                  <>
                    <div className='col-2'>
                      <Textbox
                        label='CPF'
                        text={data.clienteTitularOld?.nrDocumento}
                        mask={MaskTypes.CPF}
                        readOnly
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='RG'
                        text={data.clienteTitularOld?.nrRG}
                        maxLength={15}
                        readOnly
                      />
                    </div>
                  </>
                ) : (
                  <div className='col-2'>
                    <Textbox
                      label='CNPJ'
                      text={data.clienteTitularOld?.nrDocumento}
                      mask={MaskTypes.CNPJ}
                      readOnly
                    />
                  </div>
                )}
                <div className='col'>
                  <Textbox
                    label='Endereço'
                    text={data.clienteTitularOld?.endereco?.enderecoCompleto}
                    readOnly
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <div className='col'>
            <Autocomplete
              label='Novo Titular'
              required
              searchDataSource={onSearchCliente}
              minLengthTextSearch={3}
              placeholder='Digite ao menos 3 caracteres para buscar Cliente'
              selectedItem={data.cliente}
              onSelectItem={(cliente) => {
                data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
                data.cliente = cliente;

                onSelectCliente(cliente, false, true, false);
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
      </div>
      {data.cliente && (
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Dados do Novo Titular'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  {data.cliente?.flgFisica ? (
                    <>
                      <div className='col-2'>
                        <Textbox
                          label='CPF'
                          text={data.cliente?.nrDocumento}
                          mask={MaskTypes.CPF}
                          readOnly
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='RG'
                          text={data.cliente?.nrRG}
                          maxLength={15}
                          readOnly
                        />
                      </div>
                    </>
                  ) : (
                    <div className='col-2'>
                      <Textbox
                        label='CNPJ'
                        text={data.cliente?.nrDocumento}
                        mask={MaskTypes.CNPJ}
                        readOnly
                      />
                    </div>
                  )}
                  <div className='col'>
                    <Textbox
                      label='Endereço'
                      text={data.cliente?.endereco?.enderecoCompleto}
                      readOnly
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='Dados da Linha' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Centro de Custo'
                    enabled={
                      data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa
                    }
                    placeholder={
                      !data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'
                    }
                    searchDataSource={onSearchCentroDeCusto}
                    selectedItem={data.centroDeCusto}
                    onSelectItem={(centroDeCusto) =>
                      setData({
                        ...data,
                        centroDeCusto,
                        nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                      })
                    }
                    dataSourceTextProperty='noCentroDeCusto'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Plano'
                    enabled={
                      data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa
                    }
                    required
                    searchDataSource={onSearchPlanoOperadoraDestino}
                    selectedItem={data.plano}
                    onSelectItem={(plano) =>
                      setData({
                        ...data,
                        plano,
                        nrSeqPlano: plano.nrSeqPlano,
                        tipoChamada: undefined,
                        nrSeqTipoChamada: undefined,
                      })
                    }
                    dataSourceTextProperty='noPlano'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Pacote'
                    enabled={data.flgPermiteAlterar && !!data.plano?.nrSeqPlano}
                    placeholder={!data.plano?.nrSeqPlano && 'Selecione o Plano'}
                    required
                    searchDataSource={onSearchPacoteDados}
                    selectedItem={data.tipoChamada}
                    onSelectItem={async (tipoChamada) => {
                      if ('nrSeqTipoChamada' in tipoChamada) {
                        const planoRateio = await getPlanoRateio(
                          tipoChamada.nrSeqTipoChamada
                        );

                        if (planoRateio) {
                          setHasPlanoRateioDefault(true);

                          setData({
                            ...data,
                            tipoChamada,
                            nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                            nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                            vlrVenda:
                              tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                            vlrPacoteEncargoManual:
                              tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                            planoRateioEmpresa: planoRateio,
                            nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                          });
                        } else {
                          setHasPlanoRateioDefault(false);

                          setData({
                            ...data,
                            tipoChamada,
                            nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                            nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                            vlrVenda:
                              tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                            vlrPacoteEncargoManual:
                              tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                          });
                        }
                      } else {
                        setHasPlanoRateioDefault(false);

                        setData({
                          ...data,
                          tipoChamada,
                          nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                          nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                          noPacoteDados: tipoChamada.noTipoChamada,
                          vlrVenda:
                            tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                          vlrPacoteEncargoManual:
                            tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                        });
                      }
                    }}
                    dataSourceTextProperty='noTipoChamada'
                  />
                </div>
                <div className='col-1'>
                  <Textbox
                    label='Valor Pacote'
                    text={data.vlrVenda}
                    mask={MaskTypes.Decimal}
                    readOnly={!data.tipoChamada?.flgPermiteEditar}
                    onChangedValue={(vlrVenda) => {
                      setData({
                        ...data,
                        vlrPacoteEncargoManual: vlrVenda,
                        vlrVenda,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Plano de Rateio Empresa'
                    searchDataSource={onSearchPlanoRateio}
                    enabled={
                      data.flgPermiteAlterar &&
                      !!data.empresa?.nrSeqEmpresa &&
                      !hasPlanoRateioDefault
                    }
                    placeholder={
                      !data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'
                    }
                    selectedItem={data.planoRateioEmpresa}
                    onSelectItem={(planoRateioEmpresa) => {
                      setData({
                        ...data,
                        planoRateioEmpresa,
                        nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
                      });
                    }}
                    dataSourceTextProperty='noPlano'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Forma de Pagamento'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onAutoCompleteFormaPagamento}
                    selectedItem={data.formaPagamento}
                    onSelectItem={(formaPagamento) => {
                      data.formaPagamento = formaPagamento;
                      data.nrSeqFormaPagamento =
                        formaPagamento.nrSeqFormaPagamento ?? undefined;

                      onSelectFormaPagamento(formaPagamento);
                    }}
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
                <div className='col-2'>
                  <Autocomplete
                    label='Vencimento'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchVencimento}
                    selectedItem={data.vencimento}
                    onSelectItem={(vencimento) =>
                      setData({
                        ...data,
                        vencimento,
                        nrSeqVencimento: vencimento.nrSeqVencimento,
                      })
                    }
                    dataSourceTextProperty='noVencimento'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Informação Bancária'
                    enabled={data.flgPermiteAlterar}
                    visible={
                      data.formaPagamento !== undefined &&
                      data.formaPagamento?.flgGrupo === 'D'
                    }
                    required={
                      data.formaPagamento !== undefined &&
                      data.formaPagamento?.flgGrupo === 'D'
                    }
                    searchDataSource={onSearchInformacaoBancaria}
                    selectedItem={data.informacaoBancaria}
                    onSelectItem={(informacaoBancaria) =>
                      setData({
                        ...data,
                        informacaoBancaria,
                        nrSeqPessoaInformacaoBancaria:
                          informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                      })
                    }
                    dataSourceTextProperty='noDescricao'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
        {addFiles && (
          <div className='col'>
            <div className='row mt-4 mb-3'>
              <div className='col'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => {
                    if (files[0].size <= 5000000) {
                      setImportedFile(files);
                    } else {
                      onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                    }
                  }}
                  allowedMimeTypes={[
                    MimeTypes.Types.PDF,
                    MimeTypes.Types.Image,
                    MimeTypes.Types.Word,
                  ]}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex justify-content-center'>
                <Button
                  text='Adicionar Documento'
                  className='px-5'
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  onClick={() => onClickAddDocumento()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {addFiles && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      )}

      <ModalRegistrarChipNovo
        data={data}
        setData={setData}
        show={showModalCadastroChipNovo}
        onClose={() => setShowModalCadastroChipNovo(false)}
      />
    </>
  );
}
