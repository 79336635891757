import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  PageTypes,
  ResponseStatus,
  Theme,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Panel,
  Switch,
  FileUpload,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getOperadora,
  saveOperadora,
  deleteOperadora,
} from 'core/services/TEL/operadora';

export default function OperadoraItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setData(await getOperadora(registryKey));

      setLoading(false);
    } else setData({});

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveOperadora(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteOperadora(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onClickAddDocumento = async (adicionarTTpadrao = true) => {
    if (importedFile.length > 0) {
      const arquivoTTBase64 = await toBase64(importedFile[0]);

      const arquivoTT = String(arquivoTTBase64).substring(
        String(arquivoTTBase64).indexOf(',') + 1
      );

      if (adicionarTTpadrao) {
        data.arquivoTT = arquivoTT;
      } else {
        data.arquivoTTMigracaoCartao = arquivoTT;
      }

      setImportedFile([]);

      save();
    }
  };

  const onClickDownload = async () => {
    const linkSource = `data:application/pdf;base64, ${data.arquivoTT}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'TT para upload';
    downloadLink.click();
  };

  const onClickDownloadArquivoTTMigracaoCartao = async () => {
    const linkSource = `data:application/pdf;base64, ${data.arquivoTTMigracaoCartao}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'TT para upload - Migracao para Cartao';
    downloadLink.click();
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Operadora'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onSave={save}
      onDelete={data?.nrSeqOperadora > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        {data.arquivoTT && (
          <ToolbarButtons.Button
            text='Download Arquivo TT'
            onClick={() => onClickDownload()}
          />
        )}
        {data.arquivoTTMigracaoCartao && (
          <ToolbarButtons.Button
            text='Download Arquivo TT Migração para Cartao'
            onClick={() => onClickDownloadArquivoTTMigracaoCartao()}
          />
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Manutenção Operadora' theme={Theme.Light} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-1'>
                <Switch
                  formControl
                  label='Ativo'
                  checked={data.flgAtivo}
                  onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Nome'
                  maxLength={100}
                  required
                  text={data.noOperadora}
                  onChangedValue={(noOperadora) =>
                    setData({ ...data, noOperadora })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Código Operadora'
                  maxLength={20}
                  required
                  text={data.cdOperadora}
                  onChangedValue={(cdOperadora) =>
                    setData({ ...data, cdOperadora })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Valor Mensalidade'
                  maxLength={8}
                  required
                  text={data.vlrMensalidade}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrMensalidade) =>
                    setData({ ...data, vlrMensalidade })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Conta Destino TT'
                  text={data.nrContaDestinoPnTT}
                  maxLength={40}
                  onChangedValue={(nrContaDestinoPnTT) =>
                    setData({ ...data, nrContaDestinoPnTT })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  label='Reverte Portabilidade'
                  checked={data.flgRevertePortabilidade}
                  onChange={(flgRevertePortabilidade) =>
                    setData({ ...data, flgRevertePortabilidade })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='VC1' theme={Theme.Light} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Mesma Operadora'
                    required
                    text={data.vlrVc1MesmaOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc1MesmaOper) =>
                      setData({ ...data, vlrVc1MesmaOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Outra Operadora'
                    required
                    text={data.vlrVc1OutraOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc1OutraOper) =>
                      setData({ ...data, vlrVc1OutraOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Fixo'
                    required
                    text={data.vlrVc1Fixo}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc1Fixo) =>
                      setData({ ...data, vlrVc1Fixo })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='col'>
          <Panel>
            <Panel.Header title='VC2' theme={Theme.Light} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Mesma Operadora'
                    required
                    text={data.vlrVc2MesmaOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc2MesmaOper) =>
                      setData({ ...data, vlrVc2MesmaOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Outra Operadora'
                    required
                    text={data.vlrVc2OutraOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc2OutraOper) =>
                      setData({ ...data, vlrVc2OutraOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Fixo'
                    required
                    text={data.vlrVc2Fixo}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc2Fixo) =>
                      setData({ ...data, vlrVc2Fixo })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='VC3' theme={Theme.Light} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Mesma Operadora'
                    required
                    text={data.vlrVc3MesmaOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc3MesmaOper) =>
                      setData({ ...data, vlrVc3MesmaOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Outra Operadora'
                    required
                    text={data.vlrVc3OutraOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc3OutraOper) =>
                      setData({ ...data, vlrVc3OutraOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Fixo'
                    required
                    text={data.vlrVc3Fixo}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVc3Fixo) =>
                      setData({ ...data, vlrVc3Fixo })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='col'>
          <Panel>
            <Panel.Header title='Modem' theme={Theme.Light} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Modem 256 Kb'
                    required
                    text={data.vlrModem256}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrModem256) =>
                      setData({ ...data, vlrModem256 })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Modem 512 Kb'
                    required
                    text={data.vlrModem512}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrModem512) =>
                      setData({ ...data, vlrModem512 })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Modem 1 Mb'
                    required
                    text={data.vlrModem1Mb}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrModem1Mb) =>
                      setData({ ...data, vlrModem1Mb })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='SMS' theme={Theme.Light} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Mesma Operadora'
                    required
                    text={data.vlrSmsMesmaOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrSmsMesmaOper) =>
                      setData({ ...data, vlrSmsMesmaOper })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Outra Operadora'
                    required
                    text={data.vlrSmsOutraOper}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrSmsOutraOper) =>
                      setData({ ...data, vlrSmsOutraOper })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='col'>
          <div className='row mb-3'>
            <div className='col'>
              <FileUpload
                files={importedFile}
                onChange={(files) => {
                  if (files[0].size <= 5000000) {
                    setImportedFile(files);
                  } else {
                    onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                  }
                }}
                allowedMimeTypes={[MimeTypes.Types.PDF]}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Textbox
                label='Versão do Documento'
                text={data.cdVersaoArquivo}
                onChangedValue={(cdVersaoArquivo) =>
                  setData({ ...data, cdVersaoArquivo })
                }
              />
            </div>
            <div className='col d-flex justify-content-center'>
              <Button
                text='Adicionar TT Padrão'
                className='px-5'
                theme={Theme.Success}
                size={BootstrapSizes.Medium}
                template={Button.Templates.Button}
                onClick={() => onClickAddDocumento(true)}
              />
            </div>
            <div className='col d-flex justify-content-center'>
              <Button
                text='Adicionar TT Migração para Cartão'
                className='px-5'
                theme={Theme.Success}
                size={BootstrapSizes.Medium}
                template={Button.Templates.Button}
                onClick={() => onClickAddDocumento(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </CSDManutPage>
  );
}
