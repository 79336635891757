import qs from 'qs';
import Pagination from 'core/models/pagination';
import AcertoMotoristaReceita from 'core/models/FIN/acertoMotoristaReceita';
import api from '../api';

const baseURL = `/FIN/AcertoMotoristaReceita`;

export const changeAcerto = async (ids, nrseqacertomotorista) => {
  const obj = {
    ids,
    nrseqacertomotorista,
  };

  const response = await api.post(`${baseURL}/ChangeAcerto`, obj);
  const value = response.data ?? {};
  return value;
};

export const GetReceitasAcerto = async (filters) => {
  const response = await api.post(`${baseURL}/GetReceitasAcerto`, filters);
  const { value } = response.data ?? {};
  return value;
};

export const getAcertoMotoristaReceitaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaReceita(json)) ?? [],
  };
};

export const getAcertoMotoristaReceitaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaReceita(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getSearchReceitasModal = async (filters) => {
  const response = await api.get(`${baseURL}/SearchReceitasModal`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaReceita(json)) ?? [],
  };
};

export const getVinculaReceitaAcerto = async (data) => {
  const response = await api.post(`${baseURL}/VinculaReceitaAcerto`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};

export const getAcertoMotoristaReceita = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AcertoMotoristaReceita(value) : {};
};

export const saveAcertoMotoristaReceita = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAcertoMotoristaReceita = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAcertoMotoristaReceita = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelAcertoMotoristaReceita = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const deleteAllAcertoMotoristaReceita = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
