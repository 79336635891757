import React, { useState } from 'react';
import { Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  gerarArquivoSpedFiscal,
  gerarArquivoValidacaoSpedFiscal,
} from 'core/services/FIS/spedFiscal';
import { CSDSelPage, Autocomplete, DatePicker, Button } from 'ui/components';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

export default function SpedFiscal({ transaction, isActive }) {
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onAutoCompleteEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };
  const onClickGerarArquivo = async () => {
    if (!filtros.dtInicial && !filtros.dtFinal) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Necessário Informar as Datas da Pesquisa'
      );
    }
    if (!filtros.nrSeqEmpresa) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Necessário Informar a Empresa'
      );
    }

    setLoading(true);
    try {
      const { noEmpresa } = filtros;
      const dataFinal = new Date(filtros.dtFinal);

      const { message: msg, data: base64 } = await gerarArquivoSpedFiscal(
        filtros
      );

      if (!base64) {
        setLoading(false);
        return onSetMessage(ResponseStatus.Error, msg);
      }

      // Pegar mês e ano da data final
      const mes = (dataFinal.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataFinal.getFullYear().toString();

      // Remover caracteres especiais do nome da empresa
      const removeCaracteresEspeciais = (texto) =>
        texto.replace(/[^\w\s]/gi, '');

      const nomeArquivo = `Sped${mes}${ano}${removeCaracteresEspeciais(
        noEmpresa
      )}`.replaceAll(' ', '');

      const linkSource = `data:text/plain;base64,${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = nomeArquivo;
      downloadLink.click();
    } catch (error) {
      onSetMessage(ResponseStatus.Error, 'Erro ao gerar o arquivo.');
    } finally {
      setLoading(false);
    }

    return true;
  };

  const onClickGerarValidacao = async () => {
    if (!filtros.dtInicial && !filtros.dtFinal) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Necessario Informar as Datas da Pesquisa'
      );
    }
    if (!filtros.nrSeqEmpresa) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Necessario Informar a Empresa'
      );
    }
    setLoading(true);
    try {
      const { noEmpresa } = filtros;
      const dataFinal = new Date(filtros.dtFinal);

      const {
        message: msg,
        data: base64,
      } = await gerarArquivoValidacaoSpedFiscal(filtros);

      if (!base64) {
        setLoading(false);
        return onSetMessage(ResponseStatus.Error, msg);
      }

      // Pegar mês e ano da data final
      const mes = (dataFinal.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataFinal.getFullYear().toString();

      // Remover caracteres especiais do nome da empresa
      const removeCaracteresEspeciais = (texto) =>
        texto.replace(/[^\w\s]/gi, '');

      const nomeArquivo = `Sped${mes}${ano}${removeCaracteresEspeciais(
        noEmpresa
      )}validacao`.replaceAll(' ', '');

      const linkSource = `data:text/plain;base64,${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = nomeArquivo;
      downloadLink.click();
    } catch (error) {
      onSetMessage(
        ResponseStatus.Error,
        'Erro ao gerar o arquivo de Validação.'
      );
    } finally {
      setLoading(false);
    }
    return true;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de SpedFiscal'
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Inicial'
            text={filtros.dtInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicial) => {
              setFiltros({ ...filtros, dtInicial });
            }}
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Final'
            text={filtros.dtFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFinal) => {
              setFiltros({ ...filtros, dtFinal });
            }}
          />
        </div>
        <div className='col-5 mb-3'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onAutoCompleteEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
                noEmpresa: empresa.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mt-2'>
          <Button
            onClick={onClickGerarArquivo}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Gerar Arquivo'
          />
        </div>
        <div className='col-2 mt-2'>
          <Button
            onClick={onClickGerarValidacao}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Apenas Validação'
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
