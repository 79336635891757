import { Transacao, TransacaoPaginaConfiguracao } from 'core/models/SEG';
import Usuario from 'core/models/SEG/usuario';
import qs from 'qs';
import Pagination from 'core/models/pagination';
import api from '../api';

// const baseURL = `/SEG/Usuario`;

export const getUsuarioPagined = async (filters) => {
  const response = await api.get(`/Usuario/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Usuario(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getUsuario = async (id) => {
  const response = await api.get(`/Usuario/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Usuario(value) : {};
};

export const saveUsuario = async (data) => {
  const response = await api.post(`/Usuario/`, data);
  return response.data ?? {};
};

export const deleteUsuario = async (id) => {
  const response = await api.delete(`/Usuario/`, { params: { id } });
  return response.data ?? {};
};

export const changePassword = async (filters) => {
  const response = await api.get(`/Usuario/ChangePassword`, {
    params: filters,
  });

  return response.data;
};

export const getTransacoesIniciais = async () => {
  const response = await api.get(`/Usuario/GetStartTransactions`);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    transacoes: value?.map((json) => new Transacao(json)) ?? [],
  };
};

export const getUsuarioAutoComplete = async (filters) => {
  const response = await api.get(`/Usuario/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Usuario(json)) ?? [],
  };
};

export const getTransacaoPaginaConfiguracao = async (nrSeqTransacaopagina) => {
  const response = await api.get(
    `/Usuario/GetTransacaoConfiguracaoPagina/${nrSeqTransacaopagina}`
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    configuracoes:
      value?.map((json) => new TransacaoPaginaConfiguracao(json)) ?? [],
  };
};

export const getTransacaoPaginaConfiguracaoNoPaginaWeb = async (filters) => {
  const response = await api.get(
    `/Usuario/GetTransacaoPaginaConfiguracaoNoPaginaWeb`,
    { params: filters }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    configuracoes:
      value?.map((json) => new TransacaoPaginaConfiguracao(json)) ?? [],
  };
};

export const getUsuarioList = async (filters) => {
  const response = await api.get(`/Usuario/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Usuario(json)) ?? [],
  };
};

export const printUsuario = async (filters) => {
  const response = await api.get(`/Usuario/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllUsuario = async (ids) => {
  const response = await api.delete(`/Usuario/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const GerarSenhaEmail = async (data) => {
  const response = await api.post(`Usuario/GerarSenhaEmail`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new Usuario(value ?? {}),
  };
};

export const ReativarUsuario = async (data) => {
  const response = await api.post(`Usuario/ReativarUsuario`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new Usuario(value ?? {}),
  };
};

export const RegisterAudit = async (data) => {
  const response = await api.post(`Usuario/RegisterAudit`, data);
  const { status, message } = response.data;
  return {
    status,
    message,
  };
};
