import { React, useEffect } from 'react';

import {
  // limitText,
  formatStringNumberToFloat,
  limitTextSplitLine,
} from 'ui/Helpers/utils';

const base64PreFiltro =
  'iVBORw0KGgoAAAANSUhEUgAAAAYAAAAHCAYAAAArkDztAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAACrSURBVBhXY/zz+9d/JhZWhv9//wLxfwZGZiYGJiYGBsb////8v371GsOp03sZfv95xyAursDg7hbEwHLt6lmGFcuXMhw7vZzBxFqS4cc/LYZr18UZWE6dOMHw5s0rBg8ve4aS/FCGt99+MRzedYOBxcLSguHLl/cMW3bNZrh97yKDiIAqQ3JcOQOLmqY+g5CgNIOKmjrD3XuXGLi4RRgUlUxBlv//z4ABGBgAKuhAPrI1kSAAAAAASUVORK5CYII=';
const imagemPreFiltro = new Image();

const colorTitles = '#1622a2';
const sizeTitles = 12;
const colorCement = '#C0C0C0';
let ctx = {};
let linhasRevestimentos = [];
// let posXInicialPerfuracao = 0;
let posXFinalPerfuracao = 0;
let bordaEsquerdaExternaGlobal = 0;
let diametroMaiorPerfuracao = 0;

const texto = (posX, posY, conteudo, size, color) => {
  ctx.beginPath();

  if (!size) size = 12;

  ctx.font = `${size}px Arial`;

  if (color) ctx.fillStyle = color;
  ctx.fillText(conteudo, posX - 2, posY - 3);
  ctx.fillStyle = 'black';

  return ctx.measureText(conteudo).width;
};

const arrow = (fromx, fromy, tox, toy, arrowWidth, color) => {
  const headlen = 10;
  const angle = Math.atan2(toy - fromy, tox - fromx);

  ctx.save();
  ctx.strokeStyle = color;

  ctx.beginPath();
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);
  ctx.lineWidth = arrowWidth;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(tox, toy);
  ctx.lineTo(
    tox - headlen * Math.cos(angle - Math.PI / 7),
    toy - headlen * Math.sin(angle - Math.PI / 7)
  );

  ctx.lineTo(
    tox - headlen * Math.cos(angle + Math.PI / 7),
    toy - headlen * Math.sin(angle + Math.PI / 7)
  );

  ctx.lineTo(tox, toy);
  ctx.lineTo(
    tox - headlen * Math.cos(angle - Math.PI / 7),
    toy - headlen * Math.sin(angle - Math.PI / 7)
  );

  ctx.stroke();
  ctx.restore();
};

const converePolegadasParaMilimetros = (valor, escala) => {
  if (!escala) escala = 1;
  const resp = valor * 25.4 * escala;
  return resp;
};

const dimensionaMilimetros = (valor, escala) => {
  if (!escala) escala = 0.7;
  const resp = (valor / 300) * 100 * escala;
  return resp;
};
const dimensionaMetros = (valor, escala) => {
  if (!escala) escala = 1;
  return valor * 10 * escala;
};

const dimensionaMilimetrosConvertendoDePolegadas = (valor, escala) => {
  if (!escala) escala = 1;
  const resp = dimensionaMilimetros(converePolegadasParaMilimetros(valor));
  return resp * escala;
};
const desenhaLinhaVertical = (inicioX, inicioY, comprimento, espessura) => {
  ctx.beginPath();
  if (espessura) ctx.lineWidth = espessura;
  ctx.moveTo(inicioX, inicioY);
  ctx.lineTo(inicioX, inicioY + comprimento);
  ctx.stroke();
  ctx.lineWidth = 1;
};

const desenhaRetangulo = (
  inicioX,
  inicioY,
  width,
  height,
  imagemBase64,
  color,
  sourceover,
  imagem
) => {
  const image = new Image();

  image.src = `data:image/png;base64,${imagemBase64}`;

  if (width > 0) {
    if (imagemBase64) {
      image.onload = () => {
        const pattern = ctx.createPattern(image, 'repeat');
        ctx.fillStyle = pattern;
        ctx.globalCompositeOperation = 'destination-over';
        if (sourceover) ctx.globalCompositeOperation = 'source-over';

        ctx.fillRect(inicioX, inicioY, width, height);
      };
    } else if (imagem) {
      const pattern = ctx.createPattern(imagem, 'repeat');
      ctx.fillStyle = pattern;
      ctx.globalCompositeOperation = 'destination-over';
      if (sourceover) ctx.globalCompositeOperation = 'source-over';

      ctx.fillRect(inicioX, inicioY, width, height);
    } else {
      ctx.fillStyle = color;
      ctx.fillRect(inicioX, inicioY, width, height);
    }
  } else if (width < 0) {
    if (imagemBase64) {
      image.onload = () => {
        const pattern = ctx.createPattern(image, 'repeat');
        ctx.fillStyle = pattern;
        ctx.globalCompositeOperation = 'destination-over';
        if (sourceover) ctx.globalCompositeOperation = 'source-over';

        ctx.fillRect(
          inicioX - Math.abs(width),
          inicioY,
          Math.abs(width),
          height
        );
      };
    } else {
      ctx.fillStyle = color;
      ctx.fillRect(inicioX - Math.abs(width), inicioY, Math.abs(width), height);
    }
  }
};

const desenhaLinhaHorizontal = (inicioX, inicioY, comprimento, espessura) => {
  ctx.beginPath();
  ctx.moveTo(inicioX, inicioY);
  if (espessura) ctx.lineWidth = espessura;
  ctx.lineTo(inicioX + comprimento, inicioY);
  ctx.stroke();
  ctx.lineWidth = 1;
};

const desenhaSetaParaEsquerda = (inicioX, inicioY, color) => {
  const canvas = document.getElementById('canvas');
  if (canvas.getContext) {
    ctx.beginPath();
    ctx.moveTo(inicioX, inicioY);
    ctx.lineTo(inicioX + 10, inicioY - 4);
    ctx.lineTo(inicioX + 10, inicioY + 4);
    ctx.fillStyle = color;
    ctx.fill();

    ctx.fillStyle = 'black';
  }
};

const desenhaSetaParaBaixo = (inicioX, inicioY, color) => {
  const canvas = document.getElementById('canvas');
  if (canvas.getContext) {
    ctx.beginPath();
    ctx.moveTo(inicioX, inicioY);
    ctx.lineTo(inicioX - 5, inicioY - 8);
    ctx.lineTo(inicioX + 5, inicioY - 8);
    ctx.fillStyle = color;
    ctx.fill();

    ctx.fillStyle = 'black';
  }
};

const desenhaItemLegenda = (nome, cor, image, inicioX, inicioY, sourceover) => {
  desenhaRetangulo(inicioX, inicioY, 30, 15, image, cor, sourceover);
  texto(inicioX + 35, inicioY + 15, nome, undefined, 'black');
};

const desenhaItemLegendaNivelEstatico = (inicioX, inicioY) => {
  desenhaSetaParaBaixo(inicioX + 15 / 2, inicioY + 12, 'blue');
  desenhaLinhaHorizontal(inicioX, inicioY + 12, 15);
  texto(inicioX + 25, inicioY + 15, 'nível estático', undefined, 'black');
};

const desenhaItemLegendaEntradaAgua = (inicioX, inicioY) => {
  desenhaSetaParaEsquerda(inicioX, inicioY + 8, 'blue');
  texto(inicioX + 25, inicioY + 15, 'entrada de água', undefined, 'black');
};

const desenhaLegendaParteFormacoes = (data, inicioY) => {
  let inicioX = 10;
  let posY = inicioY;
  texto(inicioX, inicioY + 15, 'Formações:', undefined, 'black');

  inicioX += 80;

  let cont = 0;
  let distinctFormacao = '';
  data
    .sort((a, b) =>
      a.noPocPerflitFormacao.localeCompare(b.noPocPerflitFormacao)
    )
    .forEach((item) => {
      if (distinctFormacao !== item.noPocPerflitFormacao) {
        desenhaRetangulo(
          inicioX,
          posY,
          30,
          15,
          item.noImagemBase64,
          undefined,
          true
        );
        texto(
          inicioX + 40,
          posY + 15,
          item.noPocPerflitFormacao.trim().toLocaleLowerCase()
        );
        cont += 1;
        inicioX += 180;
        if (cont === 4) {
          cont = 0;
          posY += 30;
          inicioX = 10;
        }
        distinctFormacao = item.noPocPerflitFormacao;
      }
    });
};

const desenhaLegendaParteFixa = (
  inicioY,
  possuiPreFiltro,
  possuiNivelEstatico,
  possuiEntradaAgua
) => {
  let inicioX = 10;

  texto(inicioX, inicioY + 30, 'Legenda:', undefined, 'black');

  inicioX += 80;

  desenhaItemLegenda(
    'cimentação',
    colorCement,
    undefined,
    inicioX,
    inicioY + 15
  );

  if (possuiPreFiltro) {
    inicioX += 120;
    desenhaItemLegenda(
      'pré-filtro',
      undefined,
      base64PreFiltro,
      inicioX,
      inicioY + 15,
      true
    );
  }

  if (possuiNivelEstatico) {
    inicioX += 120;
    desenhaItemLegendaNivelEstatico(inicioX, inicioY + 15);
  }

  if (possuiEntradaAgua) {
    inicioX += 120;
    desenhaItemLegendaEntradaAgua(inicioX, inicioY + 15);
  }
};

/* const desenhaFundoBranco = () => {
  const ctx = document.getElementById('canvas');
  const canvas = document.getElementById('canvas').getContext('2d');
  // canvas.globalCompositeOperation = 'source-over';
  canvas.fillStyle = 'rgb(255,0,255)';
  canvas.fillRect(0, 0, 800, ctx.height);
};
*/
const desenhaLegenda = (
  data,
  inicioY,
  possuiPreFiltro,
  possuiNivelEstatico,
  possuiEntradaAgua
) => {
  desenhaLegendaParteFixa(
    inicioY,
    possuiPreFiltro,
    possuiNivelEstatico,
    possuiEntradaAgua
  );
  desenhaLegendaParteFormacoes(data.formacoes, inicioY + 40);
};

const desenhaTubo = (
  inicioX,
  inicioY,
  diametro,
  comprimento,
  diametroPol,
  mostrarEscala = true
) => {
  if (mostrarEscala) {
    arrow(inicioX, inicioY + 5, inicioX + diametro, inicioY + 5, 1, 'black');
    arrow(inicioX + diametro, inicioY + 5, inicioX, inicioY + 5, 1, 'black');

    texto(inicioX + diametro / 2, inicioY, diametroPol);
  }

  desenhaLinhaVertical(inicioX, inicioY, comprimento);
  desenhaLinhaVertical(inicioX + diametro, inicioY, comprimento);

  if (posXFinalPerfuracao === 0) {
    posXFinalPerfuracao = inicioX + diametro;
  }
};

const calculaProfundidade = (data) => {
  let profundidade = 0;
  data.perfuracoes
    .sort((a, b) => a.nrInicio - b.nrInicio)
    .forEach((item) => {
      profundidade = item.nrFim;
    });

  return profundidade;
};

const calculaDiametro = (data) => {
  let diametro = 0;
  data.perfuracoes
    .sort((a, b) => a.nrInicio - b.nrInicio)
    .forEach((item) => {
      if (item.nrDiametromm > diametro) diametro = item.nrDiametromm;
    });

  return dimensionaMilimetros(diametro);
};

const desenharPerfuracao = (
  data,
  inicioX,
  inicioY,
  escala,
  mostrarEscala = true
) => {
  let diametroAnterior = 0;
  let inicioXAnterior = inicioX;

  if (mostrarEscala)
    texto(inicioX + 5, 15, 'Ø Perf. (pol.)', sizeTitles, colorTitles);
  ctx.globalCompositeOperation = 'destination-over';
  data.perfuracoes
    .sort((a, b) => a.nrInicio - b.nrInicio)
    .forEach((item) => {
      const comprimento = dimensionaMetros(item.nrFim - item.nrInicio, escala);

      const diametro = dimensionaMilimetros(item.nrDiametromm);
      const diametroPol = item.nrDiametropol;

      if (diametroMaiorPerfuracao < diametro)
        diametroMaiorPerfuracao = diametro;

      let inicioXReal = (diametroAnterior - diametro) / 2;
      if (inicioXReal < 0) inicioXReal = 0;

      if (diametroAnterior > 0) {
        desenhaLinhaHorizontal(
          inicioXAnterior,
          inicioY,
          (diametroAnterior - diametro) / 2
        );

        desenhaLinhaHorizontal(
          inicioXReal + inicioXAnterior + diametro,
          inicioY,
          (diametroAnterior - diametro) / 2
        );
      }

      desenhaTubo(
        inicioXReal + inicioXAnterior,
        inicioY,
        diametro,
        comprimento,
        diametroPol,
        mostrarEscala
      );
      inicioY += comprimento;
      diametroAnterior = diametro;
      inicioXAnterior = inicioXReal + inicioXAnterior;
    });

  desenhaLinhaHorizontal(inicioXAnterior, inicioY, diametroAnterior);
  ctx.globalCompositeOperation = 'source-over';
  return inicioX + diametroMaiorPerfuracao;
};

const desenharDescricaoLitologica = (inicioX, inicioY, data, escala) => {
  const inicio = inicioX + 10;

  data.camadas
    .sort((a, b) => a.nrInicio - b.nrInicio)
    .forEach((item) => {
      desenhaLinhaHorizontal(
        inicio - 10,
        dimensionaMetros(item.nrInicio, escala) + inicioY,
        210
      );
      let descricaoperfil = `${item.noPocPerflitCamada}`;
      if (item.noObservacao) {
        descricaoperfil += ` ${item.noObservacao
          ?.replace(item.noPocPerflitCamada, '')
          ?.replace('Solo', '')
          ?.replace('Arenito', '')
          ?.replace('Folheto', '')
          ?.replace('Granito', '')}`;
      }

      // descricaoperfil = limitText(descricaoperfil, 45);
      const lines =
        dimensionaMetros(item.nrFim - item.nrInicio, escala) / 10 - 1;
      const textoSplit = limitTextSplitLine(descricaoperfil, 40, lines);

      let verticalSpacing = 3;
      textoSplit.forEach((desc) => {
        texto(
          inicio,
          dimensionaMetros(item.nrInicio, escala) +
            inicioY +
            13 +
            verticalSpacing,
          desc,
          11,
          undefined,
          escala
        );
        verticalSpacing += 15;
      });

      desenhaLinhaHorizontal(inicio - 10, inicioY, 200);
    });

  return inicio + 200;
};

const desenharNivelEstatico = (data, inicioX, inicioY, comprimento, escala) => {
  if (data.bombeamentos) {
    if (data.bombeamentos.length > 0) {
      const inicio =
        dimensionaMetros(data.bombeamentos[0].nrNivel, escala) + inicioY;
      desenhaSetaParaBaixo(inicioX + comprimento / 2, inicio, 'blue');

      desenhaLinhaHorizontal(inicioX, inicio, comprimento);
      texto(
        inicioX + (comprimento - 20) / 2,
        inicio + 15,
        data.bombeamentos[0].nrNivel,
        12,
        'blue'
      );
    }
  }
};

const desenharTubos = (data, inicioX, inicioY, escala) => {
  ctx.globalCompositeOperation = 'source-over';

  data
    .sort((a, b) => b.nrEaInt - a.nrEaInt)
    .forEach((item) => {
      if (item.flgTipoObjeto === 'R') {
        const inicio = dimensionaMetros(item.nrInicio, escala) + inicioY;
        const comprimento = dimensionaMetros(
          item.nrFim - item.nrInicio,
          escala
        );
        const diametro = dimensionaMilimetros(item.nrDiametromm) / 2;
        desenhaLinhaVertical(inicioX - diametro, inicio, comprimento, 2);
        desenhaLinhaVertical(inicioX + diametro, inicio, comprimento, 2);
        // desenhaLinhaHorizontal(inicioX - diametro, inicio, -100, 1);
      }
    });
};

const desenharFiltros = (data, inicioX, inicioY, comprimento, escala) => {
  // let ultimodiametro = 0;

  ctx.globalCompositeOperation = 'source-over';
  data
    .sort((a, b) => b.nrEaInt - a.nrEaInt)
    .forEach((item) => {
      if (item.flgTipoObjeto === 'F') {
        let contY = item.nrInicio;

        const centro = inicioX + comprimento / 2;
        const diametro = dimensionaMilimetros(item.nrDiametromm);

        desenhaRetangulo(
          centro - diametro / 2,
          dimensionaMetros(item.nrInicio, escala) + inicioY + 3,
          diametro,
          dimensionaMetros(item.nrFim - item.nrInicio, escala) - 3,
          undefined,
          '#fafafa'
        );

        while (contY <= item.nrFim) {
          desenhaRetangulo(
            centro - diametro / 2,
            dimensionaMetros(contY, escala) + inicioY,
            diametro,
            2,
            undefined,
            '#D4D2D2'
          );

          contY += 1;
        }

        desenhaRetangulo(
          centro - diametro / 2,
          dimensionaMetros(item.nrFim, escala) + inicioY,
          diametro,
          3,
          undefined,
          '#D4D2D2'
        );
      }
    });

  ctx.globalCompositeOperation = 'destination-over';
};

const desenharPerfilConstrutivoCimentacaoPreFiltro = (
  lista,
  maiorEspessura,
  inicioCimento,
  inicioY,
  color,
  image,
  border,
  sourceover,
  escala,
  bordaInferior
) => {
  let bordaEsquerdaExterna = 1000;
  let bordaDireitaExterna = 0;

  let primeiraBordaEsquerdaInterna = 0;
  let primeiraBordaDireitaInterna = 0;

  let paredeInternaEsquerda = 0;
  let paredeInternaDireita = 1000;

  lista
    .sort((a, b) => b.nrEaInt - a.nrEaInt)
    .forEach((item) => {
      // calcula espessura do cimento (diamentro externo - diametro interno) /2
      let espessuraCimento =
        Number(formatStringNumberToFloat(item.nrEaExt.trim())) -
        Number(formatStringNumberToFloat(item.nrEaInt.trim()));

      espessuraCimento =
        dimensionaMilimetrosConvertendoDePolegadas(espessuraCimento) / 2;

      // fim do calculo da espessura

      const profunidadeCimento = dimensionaMetros(
        item.nrFim - item.nrInicio,
        escala
      );

      if (maiorEspessura < espessuraCimento)
        maiorEspessura = espessuraCimento / 2;

      let inicioDoCimento =
        inicioCimento -
        dimensionaMilimetrosConvertendoDePolegadas(
          formatStringNumberToFloat(item.nrEaExt)
        ) /
          2;

      desenhaRetangulo(
        inicioDoCimento,
        inicioY + dimensionaMetros(item.nrInicio, escala),
        espessuraCimento,
        profunidadeCimento,
        undefined,
        color,
        sourceover,
        image
      );

      if (border) {
        desenhaLinhaVertical(
          inicioDoCimento,
          inicioY + dimensionaMetros(item.nrInicio, escala),
          profunidadeCimento,
          1
        );

        desenhaLinhaVertical(
          inicioDoCimento + espessuraCimento,
          inicioY + dimensionaMetros(item.nrInicio, escala),
          profunidadeCimento,
          2
        );

        desenhaLinhaVertical(
          inicioDoCimento,
          inicioY + dimensionaMetros(item.nrInicio, escala),
          profunidadeCimento,
          2
        );

        if (bordaInferior) {
          desenhaLinhaHorizontal(
            inicioDoCimento,
            inicioY + dimensionaMetros(item.nrFim, escala),
            espessuraCimento,
            3
          );
        }
      }
      if (inicioDoCimento < bordaEsquerdaExterna)
        bordaEsquerdaExterna = inicioDoCimento;

      if (inicioDoCimento > primeiraBordaEsquerdaInterna)
        primeiraBordaEsquerdaInterna = inicioDoCimento;

      if (
        inicioDoCimento + espessuraCimento > paredeInternaEsquerda &&
        Number(item.nrEaInt.trim().replace(',', '.').replace('/', '')) > 0
      ) {
        paredeInternaEsquerda = inicioDoCimento + espessuraCimento;
      }

      inicioDoCimento =
        inicioCimento +
        dimensionaMilimetrosConvertendoDePolegadas(
          formatStringNumberToFloat(item.nrEaInt)
        ) /
          2;

      desenhaRetangulo(
        inicioDoCimento,
        inicioY + dimensionaMetros(item.nrInicio, escala),
        espessuraCimento,
        profunidadeCimento,
        undefined,
        color,
        image
      );

      if (border) {
        desenhaLinhaVertical(
          inicioDoCimento,
          inicioY + dimensionaMetros(item.nrInicio, escala),
          profunidadeCimento,
          2
        );
        desenhaLinhaVertical(
          inicioDoCimento + espessuraCimento,
          inicioY + dimensionaMetros(item.nrInicio, escala),
          profunidadeCimento,
          2
        );

        if (bordaInferior) {
          desenhaLinhaHorizontal(
            inicioDoCimento,
            inicioY + dimensionaMetros(item.nrFim, escala),
            espessuraCimento,
            3
          );
        }
      }
      if (inicioDoCimento > bordaDireitaExterna)
        bordaDireitaExterna = inicioDoCimento + espessuraCimento;

      if (
        inicioDoCimento > primeiraBordaDireitaInterna &&
        primeiraBordaDireitaInterna === 0
      )
        primeiraBordaDireitaInterna = inicioDoCimento;

      if (
        inicioDoCimento < paredeInternaDireita &&
        Number(item.nrEaInt.trim().replace(',', '.').replace('/', '')) > 0
      )
        paredeInternaDireita = inicioDoCimento;
    });

  return {
    primeiraBordaEsquerdaInterna,
    bordaEsquerdaExterna,
    primeiraBordaDireitaInterna,
    bordaDireitaExterna,
    paredeInternaEsquerda,
    paredeInternaDireita,
  };
};

const desenhaTampaPoco = (
  esquerda,
  direita,
  inicioY,
  bordaEsquerdaExterna,
  bordaDireitaExterna
) => {
  desenhaLinhaHorizontal(
    bordaEsquerdaExterna - 20,
    inicioY - 10,
    esquerda - bordaEsquerdaExterna + 20,
    2
  );
  desenhaRetangulo(
    esquerda,
    inicioY - 10,
    bordaEsquerdaExterna - esquerda + 20 * -1,
    10,
    undefined,
    colorCement
  );
  desenhaLinhaVertical(esquerda, inicioY - 10, 20, 2);
  desenhaLinhaVertical(bordaEsquerdaExterna - 20, inicioY - 10, 10, 2);

  desenhaLinhaHorizontal(
    posXFinalPerfuracao,
    inicioY,
    bordaEsquerdaExterna - 20 - posXFinalPerfuracao
  );

  desenhaLinhaHorizontal(
    direita,
    inicioY - 10,
    bordaDireitaExterna - direita + 20,
    2
  );
  desenhaRetangulo(
    direita,
    inicioY - 10,
    bordaDireitaExterna - direita + 20,
    10,
    undefined,
    colorCement
  );
  desenhaLinhaVertical(direita, inicioY - 10, 20, 2);
  desenhaLinhaVertical(bordaDireitaExterna + 20, inicioY - 10, 10, 2);
};

const desenharPerfilConstrutivo = (
  inicioX,
  inicioY,
  comprimento,
  data,
  escala
) => {
  // let inicioCimento = inicio;

  let espessuraAcumulada = 0;

  texto(inicioX, 15, 'Perfil Construtivo/Litológico', sizeTitles, colorTitles);
  data.cimentacoes.forEach((item) => {
    const espessuraCimento =
      Number(item.nrEaExt.trim()) - Number(item.nrEaInt.trim());
    espessuraAcumulada +=
      dimensionaMilimetrosConvertendoDePolegadas(espessuraCimento) / 2;
  });

  const inicioCimentacao = inicioX + 40;

  const {
    // primeiraBordaEsquerdaInterna,
    bordaDireitaExterna,
    paredeInternaEsquerda,
    paredeInternaDireita,
    bordaEsquerdaExterna,
  } = desenharPerfilConstrutivoCimentacaoPreFiltro(
    data.cimentacoes,
    espessuraAcumulada,
    inicioCimentacao,
    inicioY,
    colorCement,
    undefined,
    true,
    false,
    escala,
    false
  );

  let paredeInternaEsquerdaAux = paredeInternaEsquerda;
  let paredeInternaDireitaAux = paredeInternaDireita;

  if (paredeInternaEsquerdaAux <= 0)
    paredeInternaEsquerdaAux = inicioCimentacao;

  if (paredeInternaDireitaAux === 1000) {
    paredeInternaDireitaAux =
      paredeInternaEsquerdaAux + diametroMaiorPerfuracao / 2;
  }

  if (paredeInternaEsquerda > 0) {
    desenhaTampaPoco(
      paredeInternaEsquerdaAux,
      paredeInternaDireitaAux,
      inicioY,
      bordaEsquerdaExterna,
      bordaDireitaExterna
    );
    bordaEsquerdaExternaGlobal = bordaEsquerdaExterna;
  } else {
    bordaEsquerdaExternaGlobal = inicioCimentacao - diametroMaiorPerfuracao / 2;
  }

  desenharPerfilConstrutivoCimentacaoPreFiltro(
    data.prefiltros,
    espessuraAcumulada,
    inicioCimentacao,
    inicioY,
    undefined,
    imagemPreFiltro,
    true,
    false,
    escala
  );

  desenharNivelEstatico(
    data,
    paredeInternaEsquerdaAux,
    inicioY,
    paredeInternaDireitaAux - paredeInternaEsquerdaAux,
    escala
  );

  desenharFiltros(
    data.revestimentos,
    paredeInternaEsquerdaAux,
    inicioY,
    paredeInternaDireitaAux - paredeInternaEsquerdaAux,
    escala
  );

  desenharTubos(data.revestimentos, inicioCimentacao, inicioY, escala);

  /* desenhaLinhaVertical(
    paredeInternaEsquerda,
    inicioY,
    dimensionaMetros(comprimento, escala)
  );
  */
  return paredeInternaDireitaAux;
};

const desenharFormacao = (inicioX, inicioY, data, escala) => {
  const inicio = inicioX;

  texto(inicioX, 15, 'FM', sizeTitles, colorTitles);

  desenhaLinhaHorizontal(inicioX, inicioY, 20, 2);
  data.formacoes
    .sort((a, b) => a.nrInicio - b.nrInicio)
    .forEach((item) => {
      desenhaRetangulo(
        inicio,
        inicioY + dimensionaMetros(item.nrInicio, escala),
        20,
        dimensionaMetros(item.nrFim - item.nrInicio, escala),
        item.noImagemBase64
      );

      desenhaLinhaVertical(
        inicio,
        inicioY + dimensionaMetros(item.nrInicio, escala),
        dimensionaMetros(item.nrFim - item.nrInicio, escala),
        3
      );
    });

  return inicio;
};

const desenharPerfilLitologico = (inicioX, inicioY, data, escala) => {
  const inicio = inicioX;

  texto(inicioX + 110, 15, 'Descrição Litológica', sizeTitles, colorTitles);

  data.camadas
    .sort((a, b) => a.nrInicio - b.nrInicio)
    .forEach((item) => {
      desenhaRetangulo(
        inicio + 4,
        inicioY + dimensionaMetros(item.nrInicio, escala),
        80,
        dimensionaMetros(item.nrFim - item.nrInicio, escala),
        item.noImagemBase64,
        'white'
      );
    });

  return inicio + 80;
};

const desenharEntradasAgua = (data, inicioX, inicioY, escala) => {
  data.entradasAgua
    .sort((a, b) => a.nrProfundidade - b.nrProfundidade)
    .forEach((item) => {
      desenhaSetaParaEsquerda(
        inicioX,
        dimensionaMetros(item.nrProfundidade, escala) + inicioY,
        'blue'
      );
    });

  return inicioX + 50;
};

const desenharRegua = (inicioX, inicioY, comprimento, escala) => {
  let cont = 0;
  const inicio = inicioX;

  desenhaLinhaVertical(inicio, inicioY, dimensionaMetros(comprimento, escala));

  const dimensionaMetrosRegua = dimensionaMetros(comprimento, escala);

  let ultimamedida = 0;

  for (cont = 0; cont <= dimensionaMetrosRegua; cont += 1) {
    if ((cont / escala) % 100 === 0) {
      desenhaLinhaHorizontal(inicio - 30, cont + inicioY, 30);
      texto(inicio - 25, cont + inicioY + 15, cont / escala / 10, 8);
      ultimamedida = cont / escala / 10;
    } else if ((cont / escala) % 50 === 0) {
      desenhaLinhaHorizontal(inicio - 20, cont + inicioY, 20);
    } else if ((cont / escala) % 10 === 0) {
      if (cont / escala) {
        desenhaLinhaHorizontal(inicio - 5, cont + inicioY, 5);
      }
    }
  }

  if (ultimamedida !== comprimento)
    texto(inicio - 25, dimensionaMetrosRegua + inicioY + 15, comprimento, 8);
  return inicio;
};

const desenharRevestimento = (data, inicioX, inicioY, escala) => {
  linhasRevestimentos = [];
  const inicio = inicioX;

  texto(inicioX + 25, 15, 'Revestimentos', sizeTitles, colorTitles);

  let controlaMesmoInicio = 0;
  let espacamentoMesmoInicio = 0;

  let ultimaDescricaoRevestimento = '';
  let ultimaDescricaoFiltro = '';

  let imprimir = false;

  let primeiro = true;
  data.revestimentos
    .sort((a, b) => a.nrFim - b.nrFim)
    .forEach((item) => {
      let tipo = 'Tubo';
      if (item.flgTipoObjeto === 'F') tipo = 'filtro';
      const descricao1 = `  ${tipo
        .trim()
        .toLocaleLowerCase()} ${item.nrDiametropol
        .trim()
        .toLocaleLowerCase()} pol 
        `;
      // este código e para não repetir a mesma descrição do revestimento ou filtro caso o mesmo ja tenha
      // sido informado so exibir quando variar
      imprimir = false;
      if (
        item.flgTipoObjeto === 'F' &&
        descricao1.trim() !== ultimaDescricaoFiltro.trim()
      )
        imprimir = true;
      else if (
        item.flgTipoObjeto === 'R' &&
        descricao1 !== ultimaDescricaoRevestimento
      )
        imprimir = true;
      // fim do código para evitar repetição

      if (imprimir) {
        const descricao2 = `  ${
          item.noPocMaterialRevestimento ??
          item.noPocTipoFiltro.trim().toLocaleLowerCase()
        }`;

        if (item.flgTipoObjeto === 'F') ultimaDescricaoFiltro = descricao1;
        else ultimaDescricaoRevestimento = descricao1;

        if (controlaMesmoInicio >= item.nrInicio - 5 && !primeiro) {
          // temos descrições na mesma posição em y fazendo com que o texto fique sobrescrito
          // este código e para dar uma espaçamento para estes casos
          espacamentoMesmoInicio += 35;
        } else {
          espacamentoMesmoInicio = 0;
          controlaMesmoInicio = item.nrInicio;
        }
        // fim do código para espaçamento

        // estamos imprimindo a descrição
        // a descrição e composta pelo materia e observação do material
        // e uma linha horizontal apontando para a posição do desenho correspondente
        texto(
          inicio + 1,
          inicioY +
            dimensionaMetros(item.nrInicio + 1, escala) +
            espacamentoMesmoInicio,
          descricao1.toLocaleLowerCase(),
          10
        );
        texto(
          inicio + 1,
          inicioY +
            dimensionaMetros(item.nrInicio + 2, escala) +
            espacamentoMesmoInicio,
          descricao2.toLocaleLowerCase(),
          10
        );

        const a = inicioX;
        const b =
          inicioY +
          dimensionaMetros(item.nrInicio + 2, escala) +
          espacamentoMesmoInicio;
        const c =
          inicio + 190 - inicioX - dimensionaMilimetros(item.nrDiametromm) / 2;

        linhasRevestimentos.push({
          inicioX: a,
          inicioY: b,
          comprimento: c,
        });

        /* desenhaLinhaHorizontal(
          inicioX,
          inicioY +
            dimensionaMetros(item.nrInicio + 2, escala) +
            espacamentoMesmoInicio,
          inicio + 190 - inicioX - dimensionaMilimetros(item.nrDiametromm) / 2
        );
*/

        // fim da impressão

        primeiro = false;
      }
    });
  return inicio + 150;
};

const desenharLinhasRevestimento = () => {
  for (let cont = 0; cont < linhasRevestimentos.length; cont += 1) {
    desenhaLinhaHorizontal(
      linhasRevestimentos[cont].inicioX,
      linhasRevestimentos[cont].inicioY,
      linhasRevestimentos[cont].comprimento
    );
  }
};

const desenharPoco = (data, escala) => {
  posXFinalPerfuracao = 0;
  bordaEsquerdaExternaGlobal = 0;
  diametroMaiorPerfuracao = 0;

  let inicioX = 160 - calculaDiametro(data);
  const inicioY = 30;

  imagemPreFiltro.src = `data:image/png;base64,${base64PreFiltro}`;
  imagemPreFiltro.onload = () => {
    const profundidade = calculaProfundidade(data);

    ctx = document.getElementById('canvas').getContext('2d');

    document.getElementById('canvas').height =
      dimensionaMetros(profundidade, escala) + inicioY + 200;
    document.getElementById('canvas').width = 760;

    inicioX = desenharPerfuracao(data, inicioX, inicioY, escala);

    inicioX = desenharRevestimento(data, inicioX, inicioY, escala);
    // posXInicialPerfuracao = inicioX;

    ctx.globalCompositeOperation = 'source-over';
    // const posXPerfilConstrutivo = inicioX;
    inicioX = desenharPerfilConstrutivo(
      inicioX,
      inicioY,
      profundidade,
      data,
      escala
    );

    desenharLinhasRevestimento();

    // desenhaLinhaHorizontal(inicioX, inicioY, 80);

    const inicioPerfilLitocologico = inicioX;

    inicioX = desenharPerfilLitologico(
      inicioPerfilLitocologico,
      inicioY,
      data,
      escala
    );

    desenharPerfuracao(
      data,
      bordaEsquerdaExternaGlobal,
      inicioY,
      escala,
      false
    );

    ctx.globalCompositeOperation = 'destination-over';
    desenhaRetangulo(
      0,
      0,
      inicioPerfilLitocologico,
      document.getElementById('canvas').height,
      undefined,
      'white',
      true
    );

    const posInicioXPerfilLitologico = inicioX;

    desenhaLinhaVertical(
      inicioX,
      inicioY,
      dimensionaMetros(profundidade, escala)
    );

    inicioX = desenharEntradasAgua(data, inicioX, inicioY, escala);

    desenhaLinhaHorizontal(inicioX - 50, inicioY, 50);
    inicioX = desenharRegua(inicioX, inicioY, profundidade, escala);
    desenhaLinhaHorizontal(
      inicioX - 50,
      dimensionaMetros(profundidade, escala) + inicioY,
      50
    );

    inicioX = desenharDescricaoLitologica(inicioX, inicioY, data, escala);

    const posXInicioFormacao = inicioX;

    inicioX = desenharFormacao(inicioX, inicioY, data, escala);

    // para gerar o fundo branco
    desenhaRetangulo(
      0,
      0,
      document.getElementById('canvas').width,
      30,
      undefined,
      'white'
    );

    desenhaRetangulo(
      inicioX + 20,
      0,
      document.getElementById('canvas').width - inicioX,
      document.getElementById('canvas').height,
      undefined,
      'white'
    );

    //

    const possuiPreFiltro = data.prefiltros.length > 0;
    const possuiNivelEstatico = data.bombeamentos.length > 0;
    const possuiEntradaAgua = data.entradasAgua.length > 0;

    // para gerar o fundo branco
    desenhaRetangulo(
      posInicioXPerfilLitologico,
      0,
      posXInicioFormacao - posInicioXPerfilLitologico,
      document.getElementById('canvas').height,
      undefined,
      'white'
    );

    desenhaRetangulo(
      0,
      inicioY + dimensionaMetros(profundidade, escala),
      document.getElementById('canvas').width,
      300,
      undefined,
      'white'
    );
    //
    ctx.globalCompositeOperation = 'source-over';
    desenhaLegenda(
      data,
      inicioY + dimensionaMetros(profundidade, escala) + 15,
      possuiPreFiltro,
      possuiNivelEstatico,
      possuiEntradaAgua
    );

    desenhaLinhaHorizontal(
      0,
      dimensionaMetros(profundidade, escala) + inicioY + 25,
      inicioX + 20,
      1
    );
  };
};

const DesenhoPoco = ({ data, escala }) => {
  useEffect(() => {
    desenharPoco(data, escala / 100);
  });

  return (
    <>
      <div className='row mb-3'>
        <div className='col-11 offset-1'>
          <canvas id='canvas' />
        </div>
      </div>
    </>
  );
};

export default DesenhoPoco;
