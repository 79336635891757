import Pessoa from 'core/models/SEG/pessoa';
import Empresa from 'core/models/SEG/empresa';
import NfFatura from 'core/models/COP/nfFatura';
import NfItem from 'core/models/COP/nfItem';
import NfTipo from 'core/models/COP/nfTipo';
import Fornecedor from 'core/models/FIN/fornecedor';
import Cfo from 'core/models/FIS/cfo';
import Transportadora from 'core/models/FRO/transportadora';
import NfPlanoDeConta from 'core/models/COP/nfPlanoDeConta';
import TituloPagar from 'core/models/FIN/tituloPagar';
import PedidoCompra from 'core/models/VEN/pedidoCompra';
import ManutencaoVeiculoOS from 'core/models/FRO/manutencaoVeiculoOS';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import Cidade from 'core/models/SEG/cidade';

export default class Nf {
  constructor(jsonObject = {}) {
    this.serieForNf = jsonObject.serieForNf;
    this.nrForNf = jsonObject.nrForNf;
    this.dtCad = jsonObject.dtCad;
    this.dtEmis = jsonObject.dtEmis;
    this.dtPrevisaoEntrega = jsonObject.dtPrevisaoEntrega;
    this.dtReceb = jsonObject.dtReceb;
    this.dtValid = jsonObject.dtValid;
    this.dtCancel = jsonObject.dtCancel;
    this.flgCondPgto = jsonObject.flgCondPgto;
    this.percIcmsFrete = jsonObject.percIcmsFrete;
    this.vlrNfBruto = jsonObject.vlrNfBruto;
    this.vlrNfLiq = jsonObject.vlrNfLiq;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrIpi = jsonObject.vlrIpi;
    this.vlrDespesa = jsonObject.vlrDespesa;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrDesc = jsonObject.vlrDesc;
    this.vlrBaseCalcIcms = jsonObject.vlrBaseCalcIcms;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.vlrBaseCalcIpi = jsonObject.vlrBaseCalcIpi;
    this.nrSeqNftipo = jsonObject.nrSeqNftipo;
    this.obsNf = jsonObject.obsNf;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;
    this.nrSeqTituloPagarTrans = jsonObject.nrSeqTituloPagarTrans;
    this.vlrBaseSubstTrib = jsonObject.vlrBaseSubstTrib;
    this.vlrSubstTrib = jsonObject.vlrSubstTrib;
    this.vlrIrrf = jsonObject.vlrIrrf;
    this.vlrPis = jsonObject.vlrPis;
    this.vlrCofins = jsonObject.vlrCofins;
    this.vlrCsll = jsonObject.vlrCsll;
    this.vlrIssqn = jsonObject.vlrIssqn;
    this.vlrRetcsll = jsonObject.vlrRetcsll;
    this.vlrRetInss = jsonObject.vlrRetInss;
    this.vlrRetIrrf = jsonObject.vlrRetIrrf;
    this.vlrRetIss = jsonObject.vlrRetIss;
    this.vlrRetPis = jsonObject.vlrRetPis;
    this.vlrRetCofins = jsonObject.vlrRetCofins;
    this.chNfe = jsonObject.chNfe;
    this.infoCpl = jsonObject.infoCpl;
    this.nrSeqPessoaTra = jsonObject.nrSeqPessoaTra;
    this.vlrSeguro = jsonObject.vlrSeguro;
    this.outrasRetencoes = jsonObject.outrasRetencoes;
    this.baseCalculoServico = jsonObject.baseCalculoServico;
    this.aliquotaServico = jsonObject.aliquotaServico;
    this.descontoCondicionado = jsonObject.descontoCondicionado;
    this.flgOptantesSimplesNacional = jsonObject.flgOptantesSimplesNacional;
    this.flgIncentivadorCultural = jsonObject.flgIncentivadorCultural;
    this.competenciaServico = jsonObject.competenciaServico;
    this.numeroRPS = jsonObject.numeroRPS;
    this.serieRPS = jsonObject.serieRPS;
    this.nrSeqPedidoCompra = jsonObject.nrSeqPedidoCompra;
    this.tipoRPS = jsonObject.tipoRPS;
    this.dtEmissaoRps = jsonObject.dtEmissaoRps;
    this.vlrIcmsDiferido = jsonObject.vlrIcmsDiferido;
    this.chaveNfe = jsonObject.chaveNfe;
    this.noModelo = jsonObject.noModelo;
    this.vlrContrSocial = jsonObject.vlrContrSocial;
    this.vlrRetInssRPA = jsonObject.vlrRetInssRPA;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.vlrBCST = jsonObject.vlrBCST;
    this.vlrPIcmsST = jsonObject.vlrPIcmsST;
    this.vlrIcmsST = jsonObject.vlrIcmsST;
    this.pedidoCompra = jsonObject.pedidoCompra;
    this.vlrTotalFatura = jsonObject.vlrTotalFatura;
    this.noPlanoDeContaNf = jsonObject.noPlanoDeContaNf;
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.pedidoCompra = new PedidoCompra(jsonObject.pedidoCompra ?? {});
    this.cfo = new Cfo(jsonObject.cfo ?? {});
    this.transportadora = new Transportadora(jsonObject.transportadora ?? {});
    this.tipoNotaFiscal = new NfTipo(jsonObject.tipoNotaFiscal ?? {});
    this.pessoaFor = new Pessoa(jsonObject.pessoaFor ?? {});
    this.pessoaEmp = new Empresa(jsonObject.pessoaEmp ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.destinatario = new ColetaDestinatario(jsonObject.destinatario ?? {});
    this.tipoImportacaoNota = jsonObject.tipoImportacaoNota;
    this.cidadeEntrega = new Cidade(jsonObject.cidadeEntrega ?? {});
    this.nrSeqControleMercadoriaDesembarque =
      jsonObject.nrSeqControleMercadoriaDesembarque;
    this.nrSeqControleMercadoriaEmbarque =
      jsonObject.nrSeqControleMercadoriaEmbarque;

    this.flgReentrega = jsonObject.flgReentrega;
    this.flgStatus = jsonObject.flgStatus;
    this.manutencaoVeiculoOS = new ManutencaoVeiculoOS(
      jsonObject.manutencaoVeiculoOS ?? {}
    );
    this.planosDeConta = jsonObject.planosDeConta
      ? jsonObject.planosDeConta.map((item) => new NfPlanoDeConta(item ?? {}))
      : [];
    this.produtos = jsonObject.produtos
      ? jsonObject.produtos.map((item) => new NfItem(item ?? {}))
      : [];
    this.faturas = jsonObject.faturas
      ? jsonObject.faturas.map((item) => new NfFatura(item ?? {}))
      : [];
    this.cartaFretes = jsonObject.cartaFretes
      ? jsonObject.cartaFretes.map((item) => new TituloPagar(item ?? {}))
      : [];

    this.cdColetaEmbarque = jsonObject.cdColetaEmbarque;
  }

  toJson = () => JSON.stringify(this);
}
