import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  getContaRecurso,
  getContaDetalhada,
  removeClientLink,
  postRecalcularContas,
  postRecalcularRateioContas,
  postImprimirContaDetalhada,
  getContaRecursoAutoCompleteWithPermissions,
} from 'core/services/TEL/contaRecurso';

import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

import ModalResumoCustos from './modalResumoCustos';

import ModalEnviarFaturasPorSMS from '../ResumoSintetico/modalEnviarFaturasPorSMS';
import ModalEnviarFaturasPorWhats from '../ResumoSintetico/modalEnviarFaturasPorWhats';
import ModalEnviarFaturasPorEmail from '../ResumoSintetico/modalEnviarFaturasPorEmail';

export default function RelContaChamada({
  isActive,
  transaction,

  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  const [modalShow, setModalShow] = useState({});
  const [selectedRecursos, setSelectedRecursos] = useState([]);
  const [parametros, setParametros] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    noLayoutConta: 'RecalculoAgrupado',
  });
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickOpenContaChamada = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.ContaChamada
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue.nrSeqContaRecurso;
      onOpenTransaction(transactionContaChamada);
    }
  };

  const onOpenMaintenanceClienteLinha = async (registryKey) => {
    const recurso = await getContaRecurso(registryKey);

    const transactionClienteLinha = findTransaction(
      TransacationCodes.ClienteLinha
    );

    if (transactionClienteLinha) {
      transactionClienteLinha.registryKey = recurso.nrSeqClienteLinha;
      onOpenTransaction(transactionClienteLinha, true);
    }
  };

  const onClickOpenClienteLinha = (selectedValue) => {
    onOpenMaintenanceClienteLinha(selectedValue.nrSeqContaRecurso);
  };

  const onClickExibirResumo = (selectedValue) => {
    setParametros({
      ...parametros,
      nrSeqContaRecurso: selectedValue.nrSeqContaRecurso,
    });
    setModalShow({ ...modalShow, exibirResumo: true });
  };

  const onClickRecalcularConta = (selectedValue) => {
    const contas = [];
    contas.push(selectedValue.nrSeqContaRecurso);
    postRecalcularContas(contas).then((e) => {
      const { status, message: msg } = e;
      onSetMessage(status, msg);
    });
  };

  const onClickRecalcularContaRateio = (selectedValue) => {
    const contas = [];
    contas.push(selectedValue.nrSeqContaRecurso);
    postRecalcularRateioContas(contas).then((e) => {
      const { status, message: msg } = e;
      onSetMessage(status, msg);
    });
  };

  const onPrintContaDetalhada = (selectedValue) => {
    setLoading(true);
    postImprimirContaDetalhada(selectedValue.nrSeqContaRecurso).then((e) => {
      const { status, message: msg, value } = e;
      onSetMessage(status, msg);
      if (value) onOpenReport(transaction.noTransacao, value);
    });
    setLoading(false);
  };

  const onOpenEnviarSMS = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar SMS.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'nrLinhaCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorSMS: true });
    }
  };

  const onOpenEnviarWhats = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar Whatsapp.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'nrLinhaCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorWhats: true });
    }
  };

  const onOpenEnviarEmail = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar email.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'nrLinhaCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorEmail: true });
    }
  };

  const columns = [
    { key: 'nrSeqContaRecurso', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrContrato', title: 'Contrato' },
    { key: 'nrLinhaCompleto', title: 'Linha' },
    { key: 'noPessoa', title: 'Nome' },
    {
      key: 'cdCliente02',
      title: 'Matricula',
      visible: false,
    },
    {
      key: 'noCentroDeCusto',
      title: 'Centro de Custo',
    },
    { key: 'periodo', title: 'Período' },

    { key: 'noPlano', title: 'Plano' },
    { key: 'ocorrencias', title: 'Ocorrências', visible: false },
    { key: 'noFormaPagamento', title: 'Forma Pag', visible: false },
    { key: 'noPlanoRateioEmpresa', title: 'Plano Rateio', visible: false },
    { key: 'vlrTotal', title: 'Oper' },
    {
      key: 'vlrRateioEmpresa',
      title: 'Rateio',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrRecalculado',
      title: 'Recalc',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'qtdeTotalVoz',
      title: 'Voz',
    },
    { key: 'qtdeTotalDados', title: 'Dados' },
    { key: 'qtdeTotalSms', title: 'SMS' },

    { key: 'noStatusFinanceiro', title: 'Info Pgto', color: 'noColor' },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenContaChamada(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Detalhamento do Conta Chamada',
      tooltipDirection: 'bottom',
      title: 'Cham',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenClienteLinha(e),
      theme: Theme.Danger,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir cliente linha',
      tooltipDirection: 'bottom',
      title: 'Linha',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickExibirResumo(e),
      theme: Theme.Secondary,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Resumos de Custo Original e Recalculado',
      tooltipDirection: 'bottom',
      title: 'Resum',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRecalcularConta(e),
      theme: Theme.Secondary,
      icon: 'sync-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Recalcular Conta',
      tooltipDirection: 'bottom',
      title: 'Recalc',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRecalcularContaRateio(e),
      theme: Theme.Primary,
      icon: 'sync-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Recalcular Rateio',
      tooltipDirection: 'bottom',
      title: 'Rec.Rateio',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onPrintContaDetalhada(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
      title: 'Impr',
    },
  ];

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    onSetMessage(status, msg);
    return clientes;
  };

  useEffect(() => {}, []);

  const onSearchContaRecurso = async (e) => {
    const {
      status,
      message: msg,
      contaRecursos,
    } = await getContaRecursoAutoCompleteWithPermissions({
      nrPrefixoLinha: e,
    });
    onSetMessage(status, msg);
    return contaRecursos;
  };

  const onRemoveClient = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Devem ser selecionadas as linhas para remoção do vínculo.'
      );
    else {
      const nrSeqContaRecursos = selecteds
        .map((row) => ({
          nrSeqContaRecurso: row.find(
            (column) => column.key === 'nrSeqContaRecurso'
          ).value,
        }))
        .map((item) => item.nrSeqContaRecurso);

      const { status, message: msg } = await removeClientLink({
        nrSeqContaRecursos,
      });

      onSetMessage(status, msg);
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Relatório Conta Detalhada'
      loading={loading}
      transaction={transaction}
      onSearch={async () => {
        onSetMessage();
        setLoading(true);

        const {
          status,
          message: msg,
          contaDetalhada,
          pagination,
        } = await getContaDetalhada(filters);

        onSetMessage(status, msg);

        if (gridView && gridView.current)
          gridView.current.setDataSource(contaDetalhada, pagination);

        setLoading(false);
      }}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Enviar Email'
          icon='envelope'
          onClick={() => onOpenEnviarEmail()}
        />
        <ToolbarButtons.Button
          text='Enviar Whats'
          icon={['fab', 'whatsapp']}
          onClick={() => onOpenEnviarWhats()}
        />
        <ToolbarButtons.Button
          text='Enviar SMS'
          icon={['far', 'comment-alt']}
          onClick={() => onOpenEnviarSMS()}
        />
        <ToolbarButtons.Button
          text='Remover Vinculo de Cliente'
          icon='trash-alt'
          onClick={() => onRemoveClient()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Nº Linha (Chip)'
            searchDataSource={onSearchContaRecurso}
            selectedItem={filters.contaRecurso}
            minLengthTextSearch={5}
            placeholder='Informe ao menos 5 dígitos da linha'
            onSelectItem={(contaRecursoAlt) =>
              setFilters({
                ...filters,
                contaRecurso: contaRecursoAlt,
                nrPrefixoLinha: contaRecursoAlt.linhaFormatada,
              })
            }
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col-4'>
          <Textbox
            label='Matrícula '
            text={filters.cdCliente02}
            onChangedValue={(cdCliente02) => {
              setFilters({ ...filters, cdCliente02 });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-8'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={filters.cliente}
            minLengthTextSearch={3}
            placeholder='Informe ao menos 3 caracteres do nome do cliente'
            onSelectItem={(cliente) =>
              setFilters({
                ...filters,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>

      <ModalResumoCustos
        recurso={parametros.nrSeqContaRecurso}
        show={modalShow.exibirResumo}
        hideClose
        onClose={() => {
          setModalShow({ ...modalShow, exibirResumo: false });
          setParametros({ ...parametros, nrSeqContaRecurso: 0 });
        }}
      />

      <ModalEnviarFaturasPorSMS
        show={modalShow.enviarFaturasPorSMS}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorSMS: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
      <ModalEnviarFaturasPorWhats
        show={modalShow.enviarFaturasPorWhats}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorWhats: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
      <ModalEnviarFaturasPorEmail
        show={modalShow.enviarFaturasPorEmail}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorEmail: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
    </CSDSelPage>
  );
}
