import Pagination from 'core/models/pagination';
import ConciliacaoBancaria from 'core/models/FIN/conciliacaoBancaria';
import qs from 'qs';
import api from '../api';

const baseURL = `/FIN/ConciliacaoBancaria`;

export const postUploadAbastecimentosXml = async (
  file,
  nrSeqsContaCorrente
) => {
  const formData = new FormData();
  formData.append('nrSeqsContaCorrente', nrSeqsContaCorrente);
  formData.append('file', file);

  const response = await api.post(`${baseURL}/UploadXml`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value,
    pagination: new Pagination(pagination),
  };
};

export const getConciliacaoBancariaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ConciliacaoBancaria(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getConciliacaoBancaria = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ConciliacaoBancaria(value) : {};
};

export const saveConciliacaoBancaria = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteConciliacaoBancaria = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printConciliacaoBancaria = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelConciliacaoBancaria = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllConciliacaoBancaria = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
