import Usuario from 'core/models/SEG/usuario';

export default class AlmoxarifadoUsuario {
  constructor(jsonObject = {}) {
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
