import PessoaSetor from 'core/models/SEG/pessoaSetor';

export default class ContatoAdicional {
  constructor(jsonObject = {}) {
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqPessoaSetor = jsonObject.nrSeqPessoaSetor;
    this.nome = jsonObject.nome;
    this.fone = jsonObject.fone;
    this.email = jsonObject.email;
    this.msn = jsonObject.msn;
    this.celular = jsonObject.celular;
    this.dtNascimento = jsonObject.dtNascimento;
    this.nrSeqContatoAdicional = jsonObject.nrSeqContatoAdicional;
    this.setor = new PessoaSetor(jsonObject.setor ?? {});
    this.nrCpf = jsonObject.nrCpf;
    this.nrRg = jsonObject.nrRg;
    this.flgRepresentanteLegal = jsonObject.flgRepresentanteLegal;
  }

  toJson = () => JSON.stringify(this);
}
