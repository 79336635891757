import qs from 'qs';
import Pagination from 'core/models/pagination';
import ControleMercadoria from 'core/models/FRO/controleMercadoria';

import api from '../api';

const baseURL = `/FRO/ControleMercadoria`;

export const getControleMercadoriaList = async (filters: any) => {
  const response = await api.get(`${baseURL}/ControleMercadoria/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new ControleMercadoria(json)) ?? [],
  };
};

export const getControleMercadoriaPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new ControleMercadoria(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getControleMercadoria = async (id: any) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return new ControleMercadoria(value);
};

export const saveControleMercadoria = async (data: any) => {
  const response = await api.post(`${baseURL}/Save`, data);
  return response.data ?? {};
};

export const generateTravel = async (nrSeqControleMercadoria: number) => {
  const response = await api.post(`${baseURL}/GenerateTravel`, {
    nrSeqControleMercadoria,
  });
  return response.data ?? {};
};

export const releaseForCounting = async (nrSeqControleMercadoria: number) => {
  const response = await api.post(`${baseURL}/ReleaseForCounting`, {
    nrSeqControleMercadoria,
  });
  return response.data ?? {};
};

export const generateTravelFinancial = async (
  nrSeqControleMercadoria: number
) => {
  const response = await api.post(`${baseURL}/GenerateTravelFinancial`, {
    nrSeqControleMercadoria,
  });
  return response.data ?? {};
};

export const generateTravelUnique = async (
  nrSeqControleMercadoria: number,
  notasVincular: Array<number>
) => {
  const response = await api.post(`${baseURL}/GenerateTravelUniqueCte`, {
    nrSeqControleMercadoria,
    notasVincular,
  });
  return response.data ?? {};
};

export const generateTravelFinancialUnique = async (
  nrSeqControleMercadoria: number
) => {
  const response = await api.post(
    `${baseURL}/GenerateTravelFinancialUniqueCte`,
    { nrSeqControleMercadoria }
  );
  return response.data ?? {};
};

export const removeDelivery = async (
  notasVincular: Array<number>,
  nrSeqControleMercadoria: number
) => {
  const response = await api.post(`${baseURL}/RemoveDelivery`, {
    notasVincular,
    nrSeqControleMercadoria,
  });
  return response.data ?? {};
};

export const removeArrivalOfMaterials = async (
  notasVincular: Array<number>,
  nrSeqControleMercadoria: number
) => {
  const response = await api.post(`${baseURL}/RemoveArrivalOfMaterials`, {
    nrSeqControleMercadoria,
    notasVincular,
  });
  return response.data ?? {};
};

export const deleteControleMercadoria = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printControleMercadoria = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printCheckMaterialsReport = async (filters: any) => {
  const response = await api.get(`${baseURL}/PrintCheckMaterialsReport`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelControleMercadoria = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllControleMercadoria = async (ids: Array<number>) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelCheckCountingReport = async (
  nrSeqControleMercadoria: number
) => {
  const response = await api.get(`${baseURL}/ExcelCheckCountingReport`, {
    params: { nrSeqControleMercadoria },
  });
  return response.data ?? {};
};

export const printDeliveryReport = async (filters: any) => {
  const response = await api.get(`${baseURL}/PrintDelivery`, {
    params: filters,
  });
  return response.data ?? {};
};
