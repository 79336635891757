import qs from 'qs';
import Pagination from 'core/models/pagination';
import NfControleMercadoriaHist from 'core/models/FRO/nfControleMercadoriaHist';
import api from '../api';

const baseURL = `/FRO/NfControleMercadoriaHist`;

export const getNfControleMercadoriaHistList = async (filters) => {
  const response = await api.get(
    `/${baseURL}/NfControleMercadoriaHist/Search`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfControleMercadoriaHist(json)) ?? [],
  };
};

export const getNfControleMercadoriaHistPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfControleMercadoriaHist(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNfControleMercadoriaHist = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NfControleMercadoriaHist(value) : {};
};

export const saveNfControleMercadoriaHist = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteNfControleMercadoriaHist = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfControleMercadoriaHist = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNfControleMercadoriaHist = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNfControleMercadoriaHist = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
