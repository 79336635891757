import { getNotaPelaChave } from 'core/services/FRO/abastecimento';
import React, { useEffect, useState } from 'react';

import { Button, Modal, Notification, Textbox } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalImportarAbastecimento(props) {
  // const gridView = useRef();
  // const [importedFile, setImportedFile] = useState([]);
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);

  const { show } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    setData({ chaveNfe: '' });
  }, [show]);

  const onBuscaPelaChave = async () => {
    if (typeof data.chaveNfe === 'string') {
      const { status, message: msg, data: nota } = await getNotaPelaChave({
        chNfe: data.chaveNfe,
      });

      if (nota !== null && nota !== undefined) {
        nota.tipoImportacaoNota = 'NF';
      }

      if (status !== ResponseStatus.Error) {
        props.onSucessImport({
          msg: 'Nota importada com sucesso',
          theme: Theme.Success,
          value: nota,
        });
      } else
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    } else {
      onSetMessage({
        message: 'Necessário informar a chave no campo Importa NFE.',
        theme: Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  return (
    <Modal
      show={show}
      title='Importar Via NF-e'
      size={BootstrapSizes.Large}
      onMessagerClose={() => setMessage(null)}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-6'>
            <Textbox
              label='Importa NF'
              text={data.chaveNfe}
              onChangedValue={(chaveNfe) => setData({ ...data, chaveNfe })}
            />
          </div>
          <div className='col-3 mt-3'>
            <Button
              className='m-1'
              text='Importar'
              icon={['fas', 'search']}
              onClick={onBuscaPelaChave}
              theme={Theme.Primary}
              template={Button.Templates.Default}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
