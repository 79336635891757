import qs from 'qs';
import Pagination from 'core/models/pagination';
import AlmoxarifadoLocal from 'core/models/EST/almoxarifadoLocal';
import api from '../api';

const baseURL = `/EST/AlmoxarifadoLocal`;

export const getAlmoxarifadoLocalList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
  };
};

export const getAlmoxarifadoLocalPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const getAlmoxarifadoLocalPaginedRelSaldoProduto = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedRelSaldoProduto`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAlmoxarifadoLocal = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AlmoxarifadoLocal(value) : {};
};

export const getBalance = async (id) => {
  const response = await api.get(`${baseURL}/GetBalance`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getBalanceByProduct = async (nrseqproduto, nrseqempresa) => {
  const response = await api.get(`${baseURL}/getBalanceByProduct`, {
    params: { nrseqproduto, nrseqempresa },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getBalanceByProductGeneral = async (nrseqproduto) => {
  const response = await api.get(`${baseURL}/GetBalanceByProductGeneral`, {
    params: { nrseqproduto },
  });
  const { value } = response.data ?? {};
  return value;
};

export const saveAlmoxarifadoLocal = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAlmoxarifadoLocal = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAlmoxarifadoLocal = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};
export const printAlmoxarifadoLocalRelSaldoProduto = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelSaldoProduto`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAlmoxarifadoLocalRelSaldoProduto = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelRelSaldoProduto`, {
    params: filters,
  });
  return response.data ?? {};
};
export const excelAlmoxarifadoLocal = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAlmoxarifadoLocal = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getAlmoxarifadoLocalAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    almoxarifados: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
  };
};
export const getAlmoxarifadoLocalAutoCompleteComCaminho = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteComCaminho`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    almoxarifados: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
  };
};
export const getAlmoxarifadoLocalAutoCompleteFullName = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteFullName`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    almoxarifados: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
  };
};

export const getCusto = async (nrSeqAlmoxarifadoLocal, dtDocumento) => {
  const response = await api.get(`${baseURL}/GetCusto`, {
    params: { nrSeqAlmoxarifadoLocal, dtDocumento },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getAlmoxarifadoLocalPaiTreeview = async (filters) => {
  const response = await api.get(`${baseURL}/Treeview`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    almoxarifados: value?.map((json) => new AlmoxarifadoLocal(json)) ?? [],
  };
};
