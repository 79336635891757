import React, { useState, useEffect, useRef } from 'react';
import {
  CSDManutPage,
  Panel,
  Button,
  Autocomplete,
  DatePicker,
  CSDPieGraphic,
  CSDBarGraphic,
  ToolbarButtons,
} from 'ui/components';
import {
  Theme,
  JustifyContent,
  BootstrapSizes,
  ResponseStatus,
  PageTypes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  getDefaultFiltersValues,
  getGraficos,
  getDashboardTransporte,
} from 'core/services/FRO/dashboardTransporte';
import { getEmpresaAutoComplete } from 'core/services/SEG';

import DashboardTransporte from 'core/models/FRO/dashboardTransporte';
import {
  getDashboardAbastecimento,
  printDashboardAbastecimento,
} from 'core/services/FRO/dashboardAbastecimento';

const EnumGraficos = Object.freeze({
  EstadoColeta: 0,
  ValorEstadoColeta: 1,
  EstadoEntrega: 2,
  ValorEstadoEntrega: 3,
  PesoEstadoEntrega: 4,
  PesoCarregado: 5,
  FaturamentoPorCliente: 6,
  CarregamentoCteMes: 7,
  PesoMes: 8,
  CarregamentoCteAno: 9,
  TotalPeso: 10,
  NumeroEmbarques: 11,
  TotalLitros: 12,
  ValorMedioPosto: 13,
  valorPorVeiculo: 14,
  valorMedioPorModelo: 15,
  valorMediaPorVeiculo: 16,
  valorMediaPorEstado: 17,
});

export default function DashboardTransporteItem({
  transaction,
  isActive,
  reload,
  onOpenReport,
  onOpenPage,
}) {
  const [message, setMessage] = useState(null);
  const [filters, setFilters] = useState({});
  const [data, setData] = useState(new DashboardTransporte({}));
  const [loading, setLoading] = useState(false);
  const estadoColeta = useRef();
  const estadoEntrega = useRef();
  const valorEstadoColeta = useRef();
  const valorEstadoEntrega = useRef();
  const pesoEstadoEntrega = useRef();
  const pesoCarregado = useRef();
  const faturamentoPorCliente = useRef();
  const carregamentoCteMes = useRef();
  const carregamentoCteAno = useRef();
  const totalPeso = useRef();
  const numeroEmbarques = useRef();
  const totalLitros = useRef();
  const valorMedioPosto = useRef();
  const pesoMes = useRef();
  const valorPorVeiculo = useRef();
  const valorMedioPorModelo = useRef();
  const valorMediaPorVeiculo = useRef();
  const valorMediaPorEstado = useRef();

  const optionsPieGraphic = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { dataIndex } = tooltipItem;
            const currentValue = tooltipItem.dataset.data[dataIndex];
            const total = tooltipItem.dataset.data.reduce(
              (sum, item) => sum + item
            );

            const percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return ` ${percentage}% (${currentValue})`;
          },
        },
      },
    },
  };

  const optionsBarGraphic = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  const graficos = [
    <CSDBarGraphic ref={pesoCarregado} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={totalPeso} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={numeroEmbarques} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={faturamentoPorCliente} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={carregamentoCteMes} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={pesoMes} options={optionsBarGraphic} />,
    <CSDPieGraphic ref={estadoColeta} options={optionsPieGraphic} />,
    <CSDPieGraphic ref={estadoEntrega} options={optionsPieGraphic} />,
    <CSDPieGraphic ref={valorEstadoColeta} options={optionsPieGraphic} />,
    <CSDPieGraphic ref={valorEstadoEntrega} options={optionsPieGraphic} />,
    <CSDBarGraphic ref={pesoEstadoEntrega} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={carregamentoCteAno} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={totalLitros} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={valorMedioPosto} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={valorPorVeiculo} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={valorMedioPorModelo} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={valorMediaPorVeiculo} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={valorMediaPorEstado} options={optionsBarGraphic} />,
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const configuraGrafico = (grafico, type) => {
    let dataGrafico = {};

    if (type === 1) {
      dataGrafico = {
        labels: grafico.labels,
        datasets: [
          {
            data:
              grafico.integerData.length > 0
                ? grafico.integerData
                : grafico.decimalData,
            themes: grafico.themes,
            theme: grafico.theme ?? Theme.Primary,
          },
        ],
      };

      if (grafico.labelDataSet)
        dataGrafico.datasets.forEach((item) => {
          item.label = grafico.labelDataSet;
        });
    } else {
      dataGrafico = {
        labels: grafico.labels,
        datasets: grafico.dataSets.map((dataSet) => {
          if (dataSet.dataInteger.length > 0)
            dataSet.data = dataSet.dataInteger;
          else dataSet.data = dataSet.dataDecimal;

          return dataSet;
        }),
      };
    }

    return dataGrafico;
  };

  const carregaGrafico = (dataGrafico, enumGrafico) => {
    const datasource = {
      [EnumGraficos.PesoCarregado]: pesoCarregado.current,
      [EnumGraficos.TotalPeso]: totalPeso.current,
      [EnumGraficos.NumeroEmbarques]: numeroEmbarques.current,
      [EnumGraficos.FaturamentoPorCliente]: faturamentoPorCliente.current,
      [EnumGraficos.CarregamentoCteMes]: carregamentoCteMes.current,
      [EnumGraficos.PesoMes]: pesoMes.current,
      [EnumGraficos.EstadoColeta]: estadoColeta.current,
      [EnumGraficos.EstadoEntrega]: estadoEntrega.current,
      [EnumGraficos.ValorEstadoColeta]: valorEstadoColeta.current,
      [EnumGraficos.ValorEstadoEntrega]: valorEstadoEntrega.current,
      [EnumGraficos.PesoEstadoEntrega]: pesoEstadoEntrega.current,
      [EnumGraficos.CarregamentoCteAno]: carregamentoCteAno.current,
      [EnumGraficos.TotalLitros]: totalLitros.current,
      [EnumGraficos.ValorMedioPosto]: valorMedioPosto.current,
      [EnumGraficos.valorPorVeiculo]: valorPorVeiculo.current,
      [EnumGraficos.valorMedioPorModelo]: valorMedioPorModelo.current,
      [EnumGraficos.valorMediaPorVeiculo]: valorMediaPorVeiculo.current,
      [EnumGraficos.valorMediaPorEstado]: valorMediaPorEstado.current,
    };

    datasource[enumGrafico]?.setDataSource(dataGrafico);
  };

  const carregaGraficos = async (datasource) => {
    for (let i = 0; i < datasource.length; i += 1) {
      const type = i > 8 ? 2 : 1;

      const dataGrafico = configuraGrafico(datasource[i], type);

      carregaGrafico(dataGrafico, datasource[i].enumGrafico);
    }
  };

  const load = async (e) => {
    const { data: result, status, message: msg } = await getGraficos(e);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      const {
        status: statusData,
        message: msgData,
        data: dashboardTransporte,
      } = await getDashboardTransporte();

      const {
        data: dashboardAbastecimento,
      } = await getDashboardAbastecimento();

      if (statusData === ResponseStatus.Success) {
        setData({ ...dashboardTransporte, ...dashboardAbastecimento });

        await carregaGraficos(result);
      }

      if (msgData) onSetMessage(statusData, msgData);
    }

    setLoading(false);
  };

  const search = async (getNewFilters) => {
    setLoading(true);

    let filtersValues = getNewFilters ? {} : filters;

    if (getNewFilters) {
      const {
        data: defaultValues,
        status,
        message: msg,
      } = await getDefaultFiltersValues();

      if (msg) onSetMessage(status, msg);

      setFilters(defaultValues);

      filtersValues = defaultValues;
    }

    load(filtersValues);
  };

  useEffect(async () => {
    search(true);
  }, []);

  useEffect(async () => {
    search(true);
  }, [reload]);

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onPrint = async () => {
    setLoading(true);
    const obj = { ...filters };
    const value = await printDashboardAbastecimento(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDManutPage
      transaction={transaction}
      loading={loading}
      isActive={isActive}
      title='Dashboard Frotas'
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Slides'
          icon='play'
          onClick={() => {
            onOpenPage(
              transaction.noTransacao,
              'DashboardTransporte/slide',
              PageTypes.Other,
              filters,
              'play',
              'Slides Dashboard Frotas'
            );
          }}
        />
        <ToolbarButtons.Button
          text='Impressão dos postos com valores por estado'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Acesso Rápido'
              icon={['fas', 'fast-forward']}
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectItem={filters.empresa}
                      onSelectItem={(empresa) => {
                        setFilters({
                          ...filters,
                          empresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Inicial'
                      text={filters.dtInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtInicial) =>
                        setFilters({ ...filters, dtInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Final'
                      text={filters.dtFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtFinal) =>
                        setFilters({ ...filters, dtFinal })
                      }
                    />
                  </div>
                  <div className='col-3 mt-3'>
                    <Button
                      text='Buscar'
                      outline
                      icon='random'
                      theme={Theme.Primary}
                      size={BootstrapSizes.Large}
                      template={Button.Templates.Default}
                      onClick={() => search(false)}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        {data.pesoCarregado && (
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Top 10 Clientes por Peso Carregado (TON)'
                icon={['fas', 'chart-bar']}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graficos[0]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {data.totalPeso || data.numeroEmbarques ? (
          <div className='row mb-3'>
            {data.totalPeso && (
              <div className='col'>
                <Panel>
                  <Panel.Header
                    title='Total de Peso'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[1]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
            {data.numeroEmbarques && (
              <div className='col'>
                <Panel>
                  <Panel.Header
                    title='Número de Embarques'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[2]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
        {data.faturamentoPorCliente && (
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Top 10 Faturamento por Cliente'
                icon={['fas', 'chart-bar']}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graficos[3]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {data.carregamentoCteMes && (
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Quantidade de Carregamentos no Mes'
                icon={['fas', 'chart-bar']}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graficos[4]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {data.pesoMes && (
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Peso Carregado P/ Unidade (TON)'
                icon={['fas', 'chart-bar']}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graficos[5]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {data.estadoColeta || data.estadoEntrega ? (
          <div className='row mb-3'>
            {data.estadoColeta && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Quantidade de Conhecimentos por Estado de Coleta'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col-6'>{graficos[6]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
            {data.estadoEntrega && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Quantidade de Conhecimentos por Estado de Entrega'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col-6'>{graficos[7]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
        {data.valorEstadoColeta || data.valorEstadoEntrega ? (
          <div className='row mb-3'>
            {data.valorEstadoColeta && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Valor Total de Conhecimentos por Estado de Coleta'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col-6'>{graficos[8]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
            {data.valorEstadoEntrega && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Valor Total de Conhecimentos por Estado de Entrega'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col-6'>{graficos[9]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
        {data.pesoEstadoEntrega && (
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Peso Total de Conhecimentos por Estado de Entrega'
                icon={['fas', 'chart-bar']}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graficos[10]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {data.carregamentoCteAno && (
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Quantidade de Conhecimentos por Mês'
                icon={['fas', 'chart-bar']}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graficos[11]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {data.totalLitros || data.valorMedioPosto ? (
          <div className='row mb-3'>
            {data.totalLitros && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Total de Litros Abastecido Por Posto'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[12]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
            {data.valorMedioPosto && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Média do Valor Por Posto'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[13]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
        {data.totalPorVeiculo ? (
          <div className='row mb-3'>
            {data.totalPorVeiculo && (
              <div className='col-12'>
                <Panel>
                  <Panel.Header
                    title='Total de Litros Abastecido Por Veiculo'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[14]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
        {data.valorMedioPorModelo || data.valorMediaPorVeiculo ? (
          <div className='row mb-3'>
            {data.valorMedioPorModelo && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Media por modelo de veiculo'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[15]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
            {data.valorMediaPorVeiculo && (
              <div className='col-6'>
                <Panel>
                  <Panel.Header
                    title='Media por veiculo'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[16]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
        {data.valorMediaPorEstado ? (
          <div className='row mb-3'>
            {data.valorMediaPorEstado && (
              <div className='col-8'>
                <Panel>
                  <Panel.Header
                    title='Media dos valores por Estado'
                    icon={['fas', 'chart-bar']}
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row justify-content-center'>
                      <div className='col'>{graficos[17]}</div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </CSDManutPage>
  );
}
