import React, { useState, useEffect } from 'react';
import {
  Modal,
  Textbox,
  Notification,
  DatePicker,
  Button,
  Loader,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  saveNfControleMercadoriaHist,
  getNfControleMercadoriaHist,
} from 'core/services/FRO/nfControleMercadoriaHist';

export default function ModalDetalhesControleMercadoria({
  show,

  nrSeqNfControleMercadoriaHist,
  onClose,
}) {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, msg) => {
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onSend = async () => {
    setLoading(true);
    const { status, message: msg } = await saveNfControleMercadoriaHist({
      nrSeqNfControleMercadoriaHist,
      ...data,
    });
    setLoading(false);
    if (status === ResponseStatus.Success) {
      onClose();
    } else if (msg) {
      onSetMessage(status, msg);
    }
  };

  useEffect(async () => {
    const value = await getNfControleMercadoriaHist(
      nrSeqNfControleMercadoriaHist
    );
    setData(value);
  }, [nrSeqNfControleMercadoriaHist]);

  return (
    <Modal
      show={show}
      title='Detalhes da Mercadoria'
      onClose={() => onClose()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-3'>
              <DatePicker
                label='Previsão de Entrega'
                text={data?.dtPrevisaoEntrega}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPrevisaoEntrega) =>
                  setData({ ...data, dtPrevisaoEntrega })
                }
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Frete Alterado'
                text={data?.vlrFreteAlterado}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrFreteAlterado) => {
                  setData({
                    ...data,
                    vlrFreteAlterado,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Confirmar'
          icon='check'
          theme={Theme.Success}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onClose()}
        />
      </Modal.Footer>
    </Modal>
  );
}
