import React, { useState, useRef, useEffect } from 'react';
import {
  getAparelhoAutoCompleteForDemanda,
  getChipAutoComplete,
  getClienteLinhasAutoCompleteResumido,
} from 'core/services/TEL';
import { GridviewColumns } from 'ui/Helpers/interfaces';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Textarea, GridView, Button } from 'ui/components';
import ModalRegistrarChipNovo from './modalRegistrarChipNovo';

export default function ManutencaoDefaultCriacaoMassiva({
  data,
  setData,
  onSetMessage,
  setDataMassivo,
  dataMassivo,
  onSearchEmpresa,
}: any) {
  const gridView = useRef<any>();
  const [showModalCadastroChipNovo, setShowModalCadastroChipNovo] = useState(
    false
  );
  const [linhasInfo, setLinhasInfo] = useState<string>('');

  const onSearchChip = async (e: string) => {
    const obj: any = {
      NrPrefixoLinha: e,
    };

    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const { status, message: msg, chips } = await getChipAutoComplete(obj);

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const onSearchAparelho = async (e: string) => {
    const { aparelhos } = await getAparelhoAutoCompleteForDemanda({
      noAparelho: e,
    });

    return aparelhos;
  };

  const addLinhasMassivo = async () => {
    const listaLinhas = linhasInfo
      .replace(/ {2}/g, '\t')
      .replace(/[()\-,. ]/g, '')
      .split(/\r?\n/)
      .map((e) => e.split(/\r?\t| +/))
      .filter((e) => e.length >= 1 && e[0] !== '');

    const linhasValidas = listaLinhas.every((linha) => linha[0].length === 11);

    if (linhasValidas) {
      const promises: Array<Promise<any>> = [];

      listaLinhas.forEach((linha) => {
        promises.push(
          getClienteLinhasAutoCompleteResumido({
            nrPrefixoLinha: linha[0],
            flgStatus: 'N',
            nrSeqPessoaCli: data.nrSeqPessoaCli,
          })
        );
      });

      const linhasNaoEncontradas: Array<string> = [];
      const newDataSourceItems: Array<any> = [];
      const dataSource = gridView?.current?.getDataSource() ?? [];

      const result = await Promise.all(promises);

      if (
        !result.every(
          (res) => res.data.nrSeqPessoaCli === res[0].data.nrSeqPessoaCli
        )
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'As linhas informadas devem todas ser do mesmo cliente'
        );

        return;
      }

      result.forEach(async (res, index) => {
        if (res.data.length > 0) {
          const clienteLinha = res.data[0];

          if (
            !dataSource.find(
              (item: any) =>
                item.nrSeqClienteLinha === clienteLinha.nrSeqClienteLinha
            )
          ) {
            const obj: any = {
              nrSeqTipoDemanda: data.nrSeqTipoDemanda,
              nrSeqStatusDemanda: data.nrSeqStatusDemanda,
              dtSolicitacao: data.dtSolicitacao,
              dtCad: data.dtCad,
              flgCobrarEncargoTipoDemanda: data.flgCobrarEncargoTipoDemanda,
              vlrEncargoTipoDemanda: data.vlrEncargoTipoDemanda,
              nrSeqEmpresa: clienteLinha.nrSeqEmpresa,
              empresa: clienteLinha.empresa,
              nrSeqPessoaCli: clienteLinha.nrSeqPessoaCli,
              cliente: clienteLinha.cliente,
              nrSeqChip: clienteLinha.nrSeqChip,
              chip: clienteLinha.chip,
              nrSeqClienteLinha: clienteLinha.nrSeqClienteLinha,
              nrSeqOperadoraDestino: clienteLinha.chip?.nrSeqOperadora,
              operadoraDestino: clienteLinha.chip?.operadora,
              status: 'Inserir',
            };

            if (data.tipoDemanda?.cdTipoDemanda?.includes('Troca de Chip')) {
              if (listaLinhas[index].length > 1) {
                // eslint-disable-next-line no-unused-vars
                const [_, iccid] = listaLinhas[index];
                obj.nrIccid = iccid;
              } else {
                obj.nrIccid = '';
              }
            } else if (
              data.tipoDemanda?.cdTipoDemanda.includes('Troca de Numero')
            ) {
              if (listaLinhas[index].length > 1) {
                // eslint-disable-next-line no-unused-vars
                const [_, novaLinha] = listaLinhas[index];

                const [chipsEncontrados] = await onSearchChip(novaLinha);

                if (chipsEncontrados.length === 1) {
                  const [chipNovo] = chipsEncontrados;

                  obj.nrSeqChipNovo = chipNovo.nrSeqChip;
                  obj.chipNovo = chipNovo;
                }
              }
            } else if (data.tipoDemanda?.flgTrocaAparelho) {
              if (listaLinhas[index].length > 1) {
                // eslint-disable-next-line no-unused-vars
                const [_, noAparelho] = listaLinhas[index];

                const [aparelhos] = await onSearchAparelho(noAparelho);

                if (aparelhos.length === 1) {
                  const [aparelho] = aparelhos;

                  obj.nrSeqAparelho = aparelho.nrSeqAparelho;
                  obj.aparelho = aparelho;
                }
              }
            }

            newDataSourceItems.push(obj);
          }
        } else {
          linhasNaoEncontradas.push(listaLinhas[index][0]);
        }
      });

      const newDataSource = [...dataSource, ...newDataSourceItems];

      setDataMassivo(newDataSource);

      if (linhasNaoEncontradas.length > 0) {
        const msg = `Para as seguintes linhas não foram encontrados um cliente linha com status normal ou com vinculo a uma empresa que voce tenha acesso. Linhas: ${linhasNaoEncontradas.join(
          ', '
        )}`;

        onSetMessage(ResponseStatus.Error, msg);
      }

      setLinhasInfo('');
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'As linhas devem conter 11 digitos contando o prefixo'
      );
    }
  };

  const updateIccid = async (keyValue: any, value: string, row: number) => {
    const dataSource = gridView.current.getDataSource() ?? [];

    const gridItem = dataSource.find(
      (item: any) => item.nrSeqClienteLinha === keyValue
    );

    if (gridItem.nrIccid !== value) {
      if (value.length !== 20) {
        onSetMessage(ResponseStatus.Error, 'O ICCID deve ter 20 caracteres');
      }

      gridItem.nrIccid = value;

      const dataSourceBuilded = gridView.current.getBuildedDataSource();

      const iccidIndex = dataSourceBuilded[row - 1].findIndex(
        (e: any) => e.key === 'nrIccid'
      );
      dataSourceBuilded[row - 1][iccidIndex].value = value;

      gridView?.current?.setBuildedDataSource(dataSourceBuilded);
    }
  };

  const updateNoObservacao = async (
    keyValue: any,
    value: string,
    row: number
  ) => {
    const dataSource = gridView.current.getDataSource() ?? [];

    const gridItem = dataSource.find(
      (item: any) => item.nrSeqClienteLinha === keyValue
    );

    if (gridItem.noObservacao !== value) {
      gridItem.noObservacao = value;

      const dataSourceBuilded = gridView.current.getBuildedDataSource();

      const index = dataSourceBuilded[row - 1].findIndex(
        (e: any) => e.key === 'noObservacao'
      );

      dataSourceBuilded[row - 1][index].value = value;

      gridView?.current?.setBuildedDataSource(dataSourceBuilded);
    }
  };

  const fNrIccid = (keyValue: any, value: string, row: number) => {
    updateIccid(keyValue, value, row);
  };

  const fNoObservacao = (keyValue: any, value: string, row: number) => {
    updateNoObservacao(keyValue, value, row);
  };

  const onRemoverLinha = (e: any, dataSource: any) => {
    const gridFiltered = dataSource.filter((item: any) => item !== e);

    setDataMassivo(gridFiltered);
  };

  const fOnSelectEmpresa = (keyValue: number, value: any) => {
    const dataSource = gridView?.current?.getDataSource() ?? [];

    const gridItem = dataSource.find(
      (item: any) => item.nrSeqClienteLinha === keyValue
    );

    gridItem.empresa = value ?? undefined;
    gridItem.nrSeqEmpresa = value.nrSeqEmpresa ?? undefined;
  };

  const columnsTrocaChip: Array<GridviewColumns> = [
    { key: 'nrSeqClienteLinha', title: '', visible: false },
    {
      key: 'empresa',
      title: 'Empresa',
      sortable: false,
      // @ts-expect-error
      type: GridView.ColumnTypes.Autocomplete,
      dataSourceTextProperty: 'noPessoa',
      searchDataSource: onSearchEmpresa,
      onSelectItem: fOnSelectEmpresa,
    },
    { key: 'cliente.noPessoa', title: 'Cliente', sortable: false },
    { key: 'chip.nrPrefixoLinha', title: 'Linha', sortable: false },
    {
      key: 'operadoraDestino.noOperadora',
      title: 'Operadora',
      sortable: false,
    },
    {
      key: 'nrIccid',
      title: 'Novo Chip (ICCID)',
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNrIccid,
      sortable: false,
    },
    {
      key: 'noObservacao',
      title: 'Observação',
      sortable: false,
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNoObservacao,
    },
    {
      key: 'noIteracao',
      title: 'Erro',
      sortable: false,
    },
    {
      key: 'nrSeqClienteLinha',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onRemoverLinha(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover Linha',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsDefault: Array<GridviewColumns> = [
    { key: 'nrSeqClienteLinha', title: '', visible: false },
    {
      key: 'empresa',
      title: 'Empresa',
      sortable: false,
      // @ts-expect-error
      type: GridView.ColumnTypes.Autocomplete,
      dataSourceTextProperty: 'noPessoa',
      searchDataSource: onSearchEmpresa,
      onSelectItem: fOnSelectEmpresa,
    },
    { key: 'cliente.noPessoa', title: 'Cliente', sortable: false },
    { key: 'chip.nrPrefixoLinha', title: 'Linha', sortable: false },
    {
      key: 'operadoraDestino.noOperadora',
      title: 'Operadora',
      sortable: false,
    },
    {
      key: 'noObservacao',
      title: 'Observação',
      sortable: false,
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNoObservacao,
    },
    {
      key: 'noIteracao',
      title: 'Erro',
      sortable: false,
    },
    {
      key: 'nrSeqClienteLinha',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onRemoverLinha(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover Linha',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource(dataMassivo ?? []);
    }
  }, [dataMassivo]);

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textarea
            label='Linhas'
            rows={4}
            text={linhasInfo}
            onChangedValue={(linhas: string) => {
              setLinhasInfo(linhas);
            }}
          />
        </div>
        <div className='col-2'>
          <Button
            outline
            visible={data.flgPermiteAlterar}
            icon='plus'
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            onClick={addLinhasMassivo}
            text='Adicionar Linhas'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={
              data.tipoDemanda?.cdTipoDemanda.includes('Troca de Chip')
                ? columnsTrocaChip
                : columnsDefault
            }
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <ModalRegistrarChipNovo
        data={data}
        setData={setData}
        show={showModalCadastroChipNovo}
        onClose={() => setShowModalCadastroChipNovo(false)}
      />
    </>
  );
}
