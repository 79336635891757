export default class Operadora {
  constructor(jsonObject = {}) {
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.cdOperadora = jsonObject.cdOperadora;
    this.noOperadora = jsonObject.noOperadora;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgAtivo = jsonObject.flgAtivo;
    this.vlrMensalidade = jsonObject.vlrMensalidade;
    this.vlrSmsMesmaOper = jsonObject.vlrSmsMesmaOper;
    this.vlrSmsOutraOper = jsonObject.vlrSmsOutraOper;
    this.vlrVc1MesmaOper = jsonObject.vlrVc1MesmaOper;
    this.vlrVc1OutraOper = jsonObject.vlrVc1OutraOper;
    this.vlrVc2MesmaOper = jsonObject.vlrVc2MesmaOper;
    this.vlrVc2OutraOper = jsonObject.vlrVc2OutraOper;
    this.vlrVc3MesmaOper = jsonObject.vlrVc3MesmaOper;
    this.vlrVc3OutraOper = jsonObject.vlrVc3OutraOper;
    this.vlrVc1Fixo = jsonObject.vlrVc1Fixo;
    this.vlrVc2Fixo = jsonObject.vlrVc2Fixo;
    this.vlrVc3Fixo = jsonObject.vlrVc3Fixo;
    this.vlrModem256 = jsonObject.vlrModem256;
    this.vlrModem512 = jsonObject.vlrModem512;
    this.vlrModem1Mb = jsonObject.vlrModem1Mb;
    this.nrDiaInicialFatura = jsonObject.nrDiaInicialFatura;
    this.nrDiaFinalFatura = jsonObject.nrDiaFinalFatura;
    this.noLayout = jsonObject.noLayout;
    this.arquivoTT = jsonObject.arquivoTT;
    this.nrContaDestinoPnTT = jsonObject.nrContaDestinoPnTT;
    this.flgRevertePortabilidade = jsonObject.flgRevertePortabilidade;
    this.cdVersaoArquivo = jsonObject.cdVersaoArquivo;
    this.arquivoTTMigracaoCartao = jsonObject.arquivoTTMigracaoCartao;
  }

  toJson = () => JSON.stringify(this);
}
