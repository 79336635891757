import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  getTipoEmbarque,
  saveTipoEmbarque,
  deleteTipoEmbarque,
} from 'core/services/FRO/tipoEmbarque';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  GridView,
  Switch,
  RadioButton,
  Button,
  Panel,
} from 'ui/components';
import { getCfoAutoComplete } from 'core/services/FIS';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getGrupoIcmsAutoComplete } from 'core/services/FIS/grupoIcms';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import TipoEmbarqueEstado from 'core/models/FRO/tipoEmbarqueEstado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import TipoEmbarque from 'core/models/FRO/tipoEmbarque';
import GrupoIcms from 'core/models/FIS/grupoIcms';
import { Cfo } from 'core/models/FIS';
import TituloEspecie from 'core/models/FIN/tituloEspecie';
import { Empresa, Estado, Pais } from 'core/models/SEG';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import TipoEmbarqueEmpresa from 'core/models/FRO/tipoEmbarqueEmpresa';

const statusPreco = [
  { text: 'Por Estado', value: 'S' },
  { text: 'Rem. x Dest.', value: 'R' },
  { text: 'Tabela', value: 'F' },
  { text: 'Tabela KM', value: 'K' },
  { text: 'Tabela Percentual', value: 'P' },
];

const statusCalculo = [
  { text: 'Peso', value: true },
  { text: 'Quantidade', value: false },
];

export default function TipoEmbarqueItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();
  const gridViewEstado: any = useRef();
  const gridViewEmpresa: any = useRef();

  const [data, setData] = useState<TipoEmbarque | {}>({});
  const [
    dataTipoEmbarqueEstado,
    setDataTipoEmbarqueEstado,
  ] = useState<TipoEmbarqueEstado>(new TipoEmbarqueEstado({}));
  const [dataTipoEmbarqueEmpresa, setDataTipoEmbarqueEmpresa] = useState<
    TipoEmbarqueEmpresa | {}
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onSetMessage = (status: number, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = (): void => {
    formSubmit.current.reset();
    setData({});
    gridViewEstado?.current?.setDataSource([]);
    gridViewEmpresa?.current?.setDataSource([]);
    setDataTipoEmbarqueEstado(new TipoEmbarqueEstado({}));
    setDataTipoEmbarqueEmpresa({});
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj: TipoEmbarque | {} = await getTipoEmbarque(registryKey);

      setData(obj);

      if (gridViewEstado && gridViewEstado.current) {
        gridViewEstado.current.setDataSource(
          'tipoEmbarqueEstados' in obj ? obj.tipoEmbarqueEstados : null
        );
      }

      if (gridViewEmpresa && gridViewEmpresa.current) {
        gridViewEmpresa.current.setDataSource(
          'tipoEmbarqueEmpresas' in obj ? obj.tipoEmbarqueEmpresas : null
        );
      }

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect((): void => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    let tipoembarqueestados = [];

    if (gridViewEstado && gridViewEstado.current) {
      tipoembarqueestados = gridViewEstado.current.getDataSource() ?? [];
    }

    let tipoembarqueempresas = [];

    if (gridViewEmpresa && gridViewEmpresa.current) {
      tipoembarqueempresas = gridViewEmpresa.current.getDataSource() ?? [];
    }

    const obj = {
      ...data,
      tipoEmbarqueEstados: tipoembarqueestados,
      tipoEmbarqueEmpresas: tipoembarqueempresas,
    };

    const { status, message: msg } = await saveTipoEmbarque(obj);

    if (status === ResponseStatus.Success) {
      onNew();
    } else {
      setData(data);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onClickAddEstado = (): void => {
    const list = gridViewEstado.current.getDataSource();

    // @ts-expect-error
    dataTipoEmbarqueEstado.status = GridView.EnumStatus.Inserir;

    if (
      dataTipoEmbarqueEstado.pais &&
      dataTipoEmbarqueEstado.estado &&
      dataTipoEmbarqueEstado.nrSeqEstado &&
      dataTipoEmbarqueEstado.vlrTonelada != null
    ) {
      if (
        list
          .map((item: TipoEmbarqueEstado) => item.estado.noEstado)
          .join('')
          .includes(dataTipoEmbarqueEstado.estado.noEstado)
      ) {
        onSetMessage(1, 'Estado já incluído.');
        setDataTipoEmbarqueEstado(new TipoEmbarqueEstado({}));
        return;
      }

      list.push(dataTipoEmbarqueEstado);

      if (gridViewEstado && gridViewEstado.current) {
        gridViewEstado.current.setDataSource(list);
      }
    } else {
      onSetMessage(1, 'Necessário informar todos os campos.');
    }
    setDataTipoEmbarqueEstado(new TipoEmbarqueEstado({}));
  };

  const onClickAddEmpresa = (): void => {
    const list = gridViewEmpresa.current.getDataSource();

    // @ts-expect-error
    dataTipoEmbarqueEmpresa.status = GridView.EnumStatus.Inserir;

    if (
      'nrSeqEmpresa' in dataTipoEmbarqueEmpresa &&
      dataTipoEmbarqueEmpresa.nrSeqEmpresa
    ) {
      if (
        list
          .map((item: TipoEmbarqueEmpresa) => item.empresa.noPessoa)
          .join('')
          .includes(dataTipoEmbarqueEmpresa.empresa.noPessoa)
      ) {
        onSetMessage(1, 'Empresa já incluída.');
        setDataTipoEmbarqueEmpresa({});
        return;
      }

      list.push(dataTipoEmbarqueEmpresa);

      if (gridViewEmpresa && gridViewEmpresa.current) {
        gridViewEmpresa.current.setDataSource(list);
      }
    } else {
      onSetMessage(1, 'Necessário selecionar uma empresa.');
    }
    setDataTipoEmbarqueEmpresa({});
  };

  const onClickRemoveFromGridView = async (
    e: TipoEmbarqueEstado,
    gridView: any
  ) => {
    const list = gridView.current.getDataSource();
    list.filter((elemento: any) => elemento === e)[0].status =
      // @ts-expect-error
      GridView.EnumStatus.Remover;

    if (gridView && gridView.current) {
      gridView.current.setDataSource(list);
    }
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteTipoEmbarque(registryKey);

      if (status === ResponseStatus.Success) {
        onNew();
      } else {
        setData(data);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onSearchCfo = async (e: string): Promise<Array<Cfo>> => {
    const { cfos }: { cfos: Array<Cfo> } = await getCfoAutoComplete({
      noCfo: e,
    });

    return cfos;
  };

  const onSearchTituloEspecie = async (
    e: string
  ): Promise<Array<TituloEspecie>> => {
    const {
      data: tituloEspecies,
    }: { data: Array<TituloEspecie> } = await getTituloEspecieAutoComplete({
      noTituloEspecie: e,
    });

    return tituloEspecies;
  };

  const onSearchGrupoIcms = async (e: string): Promise<Array<GrupoIcms>> => {
    const {
      grupos,
    }: { grupos: Array<GrupoIcms> } = await getGrupoIcmsAutoComplete({
      noGrupoIcms: e,
    });

    return grupos;
  };

  const onSearchPais = async (e: string): Promise<Array<Pais>> => {
    const { paises }: { paises: Array<Pais> } = await getPaisAutoComplete({
      noPais: e,
    });

    return paises;
  };

  const onSearchEstado = async (e: string): Promise<Array<Estado>> => {
    const { estados }: { estados: Array<Estado> } = await getEstadoAutoComplete(
      {
        noEstado: e,
      }
    );

    return estados;
  };

  const onSearchEmpresa = async (e: string): Promise<Array<Empresa>> => {
    const {
      empresas,
    }: { empresas: Array<Empresa> } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const columns: Array<GridviewColumns> = [
    { key: 'pais.noPais', title: 'Pais' },
    { key: 'estado.noEstado', title: 'Estado' },
    {
      key: 'vlrTonelada',
      title: 'Tonelada',
      // @ts-expect-error
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqTipoEmbarque',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickRemoveFromGridView(e, gridViewEstado),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsEmpresa: Array<GridviewColumns> = [
    { key: 'empresa.noPessoa', title: 'Empresa' },
    {
      key: 'nrSeqTipoEmbarque',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickRemoveFromGridView(e, gridViewEmpresa),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo Embarque'
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={
        'nrSeqTipoEmbarque' in data && data?.nrSeqTipoEmbarque > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={20}
            required
            label='Código'
            text={'cdTipoEmbarque' in data ? data.cdTipoEmbarque : null}
            onChangedValue={(cdTipoEmbarque: string) =>
              setData({ ...data, cdTipoEmbarque })
            }
          />
        </div>
        <div className='col-7'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={60}
            required
            label='Descrição'
            text={'noTipoEmbarque' in data ? data.noTipoEmbarque : null}
            onChangedValue={(noTipoEmbarque: string) =>
              setData({ ...data, noTipoEmbarque })
            }
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={3}
            required
            label='Série'
            text={'serie' in data ? data.serie : null}
            onChangedValue={(serie: string) => setData({ ...data, serie })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Grupo ICMS CIF'
            searchDataSource={onSearchGrupoIcms}
            required
            selectedItem={'grupoIcmsCif' in data ? data.grupoIcmsCif : null}
            onSelectItem={(grupoIcmsCif: GrupoIcms) => {
              setData({
                ...data,
                grupoIcmsCif,
                nrSeqGrupoIcmsCif: grupoIcmsCif.nrSeqGrupoIcms,
              });
            }}
            dataSourceTextProperty='noGrupoIcms'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Cfo no Est'
            searchDataSource={onSearchCfo}
            required
            selectedItem={'cfoEst' in data ? data.cfoEst : null}
            onSelectItem={(cfoEst: Cfo) => {
              setData({
                ...data,
                cfoEst,
                nrSeqCfoEst: cfoEst.nrSeqCfo,
              });
            }}
            dataSourceTextProperty='noCfo'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Cfo no Pais'
            searchDataSource={onSearchCfo}
            required
            selectedItem={'cfoPais' in data ? data.cfoPais : null}
            onSelectItem={(cfoPais: Cfo) => {
              setData({
                ...data,
                cfoPais,
                nrSeqCfoPais: cfoPais.nrSeqCfo,
              });
            }}
            dataSourceTextProperty='noCfo'
          />
        </div>
        <div className='col-1'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Subt. Pedag'
            checked={
              'flgSubtrairPedagioFrete' in data
                ? data.flgSubtrairPedagioFrete
                : null
            }
            onChange={(flgSubtrairPedagioFrete: boolean) =>
              setData({ ...data, flgSubtrairPedagioFrete })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Grupo ICMS FOB'
            searchDataSource={onSearchGrupoIcms}
            selectedItem={'grupoIcmsFob' in data ? data.grupoIcmsFob : null}
            required
            onSelectItem={(grupoIcmsFob: GrupoIcms) => {
              setData({
                ...data,
                grupoIcmsFob,
                nrSeqGrupoIcmsFob: grupoIcmsFob.nrSeqGrupoIcms,
              });
            }}
            dataSourceTextProperty='noGrupoIcms'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Início fora do estado e termino no mesmo'
            searchDataSource={onSearchCfo}
            selectedItem={'cfoEstDif' in data ? data.cfoEstDif : null}
            onSelectItem={(cfoEstDif: Cfo) => {
              setData({
                ...data,
                cfoEstDif,
                nrSeqCfoEstDif: cfoEstDif.nrSeqCfo,
              });
            }}
            dataSourceTextProperty='noCfo'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Inicio fora do estado e termino em outro'
            searchDataSource={onSearchCfo}
            selectedItem={'cfoPaisDif' in data ? data.cfoPaisDif : null}
            onSelectItem={(cfoPaisDif: Cfo) => {
              setData({
                ...data,
                cfoPaisDif,
                nrSeqCfoPaisDif: cfoPaisDif.nrSeqCfo,
              });
            }}
            dataSourceTextProperty='noCfo'
          />
        </div>
        {'flgDefinePrecoEstado' in data && data.flgDefinePrecoEstado === 'S' && (
          <div className='col mb-3'>
            {/* @ts-expect-error */}
            <Switch
              formControl
              label='Nao Norm.'
              checked={data.flgPrecoPorEstadoNaoNormalizarPesoQuantidade}
              onChange={(
                flgPrecoPorEstadoNaoNormalizarPesoQuantidade: boolean
              ) =>
                setData({
                  ...data,
                  flgPrecoPorEstadoNaoNormalizarPesoQuantidade,
                })
              }
            />
          </div>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Espécie Título'
            searchDataSource={onSearchTituloEspecie}
            selectedItem={'tituloEspecie' in data ? data.tituloEspecie : null}
            onSelectItem={(tituloEspecie: TituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
            }}
            dataSourceTextProperty='noEspecie'
          />
        </div>
        <div className='col-4'>
          {/* @ts-expect-error */}
          <RadioButton
            outline
            label='Preço'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={statusPreco}
            selectedButton={
              'flgDefinePrecoEstado' in data ? data.flgDefinePrecoEstado : null
            }
            onChange={(flgDefinePrecoEstado: string) =>
              setData({ ...data, flgDefinePrecoEstado })
            }
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <RadioButton
            outline
            label='Calculo Por'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={statusCalculo}
            selectedButton={
              'flgTipoDeCalculoPeso' in data
                ? data.flgTipoDeCalculoPeso.toString()
                : null
            }
            onChange={(flgTipoDeCalculoPeso: boolean) =>
              setData({ ...data, flgTipoDeCalculoPeso })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Permite Agrupamento'
            checked={
              'flgPermiteAgrupar' in data ? data.flgPermiteAgrupar : null
            }
            onChange={(flgPermiteAgrupar: boolean) =>
              setData({ ...data, flgPermiteAgrupar })
            }
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <RadioButton
            outline
            label='Calculo Por'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={statusCalculo}
            selectedButton={
              'flgTipoDeCalculoPeso' in data
                ? data.flgTipoDeCalculoPeso.toString()
                : null
            }
            onChange={(flgTipoDeCalculoPeso: boolean) =>
              setData({ ...data, flgTipoDeCalculoPeso })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Add ICMS'
            checked={
              'flgAdiconaIcmsPreco' in data ? data.flgAdiconaIcmsPreco : null
            }
            onChange={(flgAdiconaIcmsPreco: boolean) =>
              setData({ ...data, flgAdiconaIcmsPreco })
            }
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Gera Titulo'
            checked={'flgGeraTitulo' in data ? data.flgGeraTitulo : null}
            onChange={(flgGeraTitulo: boolean) =>
              setData({ ...data, flgGeraTitulo })
            }
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Emite Doc.'
            checked={
              'flgEmiteDocumento' in data ? data.flgEmiteDocumento : null
            }
            onChange={(flgEmiteDocumento: boolean) =>
              setData({ ...data, flgEmiteDocumento })
            }
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Coleta'
            checked={'flgColeta' in data ? data.flgColeta : null}
            onChange={(flgColeta: boolean) => setData({ ...data, flgColeta })}
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Próprio'
            checked={'flgColeta' in data ? data.flgProprio : null}
            onChange={(flgProprio: boolean) => setData({ ...data, flgProprio })}
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Ativo'
            checked={'flgAtivo' in data ? data.flgAtivo : null}
            onChange={(flgAtivo: boolean) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Emite CRC'
            checked={'flgEmiteCrc' in data ? data.flgEmiteCrc : null}
            onChange={(flgEmiteCrc: boolean) =>
              setData({ ...data, flgEmiteCrc })
            }
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='ICMS Manu.'
            checked={'flgManual' in data ? data.flgManual : null}
            onChange={(flgManual: boolean) => setData({ ...data, flgManual })}
          />
        </div>

        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Descons. Pedag.'
            checked={
              'flgDesconsiderarPedagioDaBaseIcms' in data
                ? data.flgDesconsiderarPedagioDaBaseIcms
                : null
            }
            onChange={(flgDesconsiderarPedagioDaBaseIcms: boolean) =>
              setData({ ...data, flgDesconsiderarPedagioDaBaseIcms })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Converte para CT-e'
            checked={'flgConverteCte' in data ? data.flgConverteCte : null}
            onChange={(flgConverteCte: boolean) =>
              setData({ ...data, flgConverteCte })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col mb-12'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Empresas'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-8'>
                  <Autocomplete
                    label='Empresa'
                    selectedItem={
                      'empresa' in dataTipoEmbarqueEmpresa
                        ? dataTipoEmbarqueEmpresa.empresa
                        : null
                    }
                    searchDataSource={onSearchEmpresa}
                    onSelectItem={(empresa: Empresa) => {
                      setDataTipoEmbarqueEmpresa({
                        ...dataTipoEmbarqueEmpresa,
                        nrSeqEmpresa: empresa.nrSeqEmpresa,
                        empresa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    // @ts-expect-error
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onClickAddEmpresa}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewEmpresa}
                    // @ts-expect-error
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsEmpresa}
                    showPagination={false}
                    showSelectSizes={false}
                    sortOffline
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      {'flgDefinePrecoEstado' in data && data.flgDefinePrecoEstado === 'S' ? (
        <div className='row mb-3'>
          <div className='col mb-12'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Preços por Estado'
                icon={['fa', 'briefcase']}
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-3'>
                    <Autocomplete
                      label='País'
                      searchDataSource={onSearchPais}
                      selectedItem={dataTipoEmbarqueEstado.pais}
                      onSelectItem={(pais: Pais) => {
                        setDataTipoEmbarqueEstado({
                          ...dataTipoEmbarqueEstado,
                          pais,
                        });
                      }}
                      dataSourceTextProperty='noPais'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Estado'
                      searchDataSource={onSearchEstado}
                      selectedItem={dataTipoEmbarqueEstado.estado}
                      onSelectItem={(estado: Estado) => {
                        setDataTipoEmbarqueEstado({
                          ...dataTipoEmbarqueEstado,
                          estado,
                          nrSeqEstado: estado.nrSeqEstado,
                        });
                      }}
                      dataSourceTextProperty='noEstado'
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Valor de Tonelada'
                      maxLength={6}
                      type='number'
                      text={dataTipoEmbarqueEstado.vlrTonelada}
                      onChangedValue={(vlrTonelada: string) => {
                        setDataTipoEmbarqueEstado({
                          ...dataTipoEmbarqueEstado,
                          vlrTonelada,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Success}
                      // @ts-expect-error
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onClickAddEstado}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewEstado}
                      // @ts-expect-error
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                      sortOffline
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      ) : null}
    </CSDManutPage>
  );
}
