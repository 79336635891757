import api from '../api';

const baseURL = `/FIS/SpedFiscal`;

export const gerarArquivoSpedFiscal = async (filters) => {
  const response = await api.get(`${baseURL}/GerarArquivoSpedFiscal`, {
    params: filters,
  });
  const { message, value } = response.data ?? {};
  return {
    message,
    data: value,
  };
};
export const gerarArquivoValidacaoSpedFiscal = async (filters) => {
  const response = await api.get(`${baseURL}/GerarArquivoValidacaoSpedFiscal`, {
    params: filters,
  });
  const { message, value } = response.data ?? {};
  return {
    message,
    data: value,
  };
};
