import React, { useState, useRef } from 'react';
import {
  Theme,
  JustifyContent,
  PageTypes,
  ResponseStatus,
} from 'ui/Helpers/utils';

import {
  GridView,
  CSDSelPage,
  Panel,
  DatePicker,
  Textbox,
} from 'ui/components';
import {
  deleteAllConciliacaoBancaria,
  getConciliacaoBancariaPagined,
} from 'core/services/FIN/conciliacaoBancaria';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ConciliacaoBancaria({
  transaction,
  isActive,
  onOpenPage,
}) {
  const [message, onSetMessage] = useState();
  const [loading, setLoading] = useState();
  const [filtros, setFiltros] = useState({});
  const gridView = useRef();

  const columns = [
    { key: 'nrSeqConciliacaoBancaria', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'pessoa.noPessoa',
      title: 'Pessoa',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrDocumento',
      title: 'Nr Doc.',
    },
    {
      key: 'vlrDocumento',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
  ];

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getConciliacaoBancariaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ConciliacaoBancaria/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqConciliacaoBancaria = sourceRow.find(
      (e) => e.key === 'nrSeqConciliacaoBancaria'
    );

    if (nrSeqConciliacaoBancaria.value && nrSeqConciliacaoBancaria.value > 0) {
      onOpenMaintenance(nrSeqConciliacaoBancaria.value);
    }
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllConciliacaoBancaria(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      onSetMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Conciliação Bancaria'
      loading={loading}
      message={message}
      onNew={onOpenMaintenance}
      onSearch={() => search()}
      onDelete={onDelete}
      onMessagerClose={() => onSetMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='Filtros' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='row'>
                    <div className='col-3'>
                      <DatePicker
                        label='Data Inicial'
                        text={filtros.dtDocumentoInicial}
                        mask={MaskTypes.Date}
                        onChange={(dtDocumentoInicial) =>
                          setFiltros({ ...filtros, dtDocumentoInicial })
                        }
                      />
                    </div>
                    <div className='col-3'>
                      <DatePicker
                        label='Data Final'
                        text={filtros.dtDocumentoFinal}
                        mask={MaskTypes.Date}
                        onChange={(dtDocumentoFinal) =>
                          setFiltros({ ...filtros, dtDocumentoFinal })
                        }
                      />
                    </div>
                    <div className='col-4'>
                      <Textbox
                        label='Nr Documento'
                        maxLength={60}
                        text={filtros.nrDocumento}
                        onChangedValue={(nrDocumento) =>
                          setFiltros({ ...filtros, nrDocumento })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Conciliação Bancaria'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    transaction={transaction}
                    canControlVisibility
                    enableExcelExport
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    onColumnSort={onColumnSort}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                    onRowDoubleClick={onRowDoubleClick}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDSelPage>
  );
}
