import React, { useRef, useEffect, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getNfPagined, printNf, deleteAllNf } from 'core/services/COP/nf';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DropdownMulti,
  Textbox,
  RadioButton,
  DatePicker,
  ToolbarButtons,
} from 'ui/components';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getNfTipoAutoComplete } from 'core/services/COP/nfTipo';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import ModalImportarNFS from './modalImportarNFS';

export default function Nf({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const [filtros, setFiltros] = useState({ flgStatus: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownTipoNotaFiscal = useRef();
  const dropDownPlanoDeContas = useRef();

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getNfPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'NotaFiscalEntrada/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqNf = sourceRow.find((e) => e.key === 'nrSeqNf');
    onOpenMaintenance(nrSeqNf.value);
  };

  const columns = [
    { key: 'nrSeqNf', type: GridView.ColumnTypes.Checkbox },
    { key: 'pessoaEmp.noEmpresa', title: 'Empresa', sortKey: 'noEmpresa' },
    {
      key: 'pessoaFor.noPessoa',
      title: 'Fornecedor',
      sortKey: 'noPessoa',
    },
    { key: 'dtCad', title: 'Cadastro', format: GridView.DataTypes.Date },
    { key: 'dtEmis', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'dtReceb', title: 'Recebimento', format: GridView.DataTypes.Date },
    { key: 'nrForNf', title: 'Número' },
    { key: 'tipoNotaFiscal.noNfTipo', title: 'Tipo NF' },
    {
      key: 'noPlanoDeContaNf',
      title: 'Plano de Conta',
      visible: true,
    },
    { key: 'serieForNf', title: 'Série' },
    {
      key: 'vlrCsll',
      title: 'Valor CSLL',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetcsll',
      title: 'Valor CSLLRF',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'outrasRetencoes',
      title: 'Outras Rentenções',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrIrrf',
      title: 'Valor IR',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetIss',
      title: 'Valor ISS',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetInssRPA',
      title: 'Valor INSS RPA',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetInss',
      title: 'Valor INSSRF',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrIssqn',
      title: 'Valor ISSQN',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrPis',
      title: 'Valor PIS',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrCofins',
      title: 'Valor COFINS',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetPis',
      title: 'Valor PIS Ret',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetCofins',
      title: 'Valor COFINS Ret',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrRetIrrf',
      title: 'Valor IRRF',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrContrSocial',
      title: 'Valor Social',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'vlrNfBruto',
      title: 'Total Prod.',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrNfLiq', title: 'Total NF', format: GridView.DataTypes.Decimal },
  ];

  const statusNota = [
    { text: 'Efetivadas', value: 'E' },
    { text: 'Não Efetivadas', value: 'N' },
    { text: 'Todas', value: 'T' },
  ];

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printNf(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllNf(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchTipoNf = async (e) => {
    const { nftipos } = await getNfTipoAutoComplete({
      noNfTipo: e,
      flgAtivo: true,
    });

    return nftipos;
  };

  const onSearchPlanoDeConta = async (e) => {
    const { planodecontas } = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });

    return planodecontas;
  };

  useEffect(() => {
    dropDownTipoNotaFiscal.current.loadDataSource(onSearchTipoNf);
    dropDownPlanoDeContas.current.loadDataSource(onSearchPlanoDeConta);
  }, []);

  const onOpenImportaNfs = async () => {
    setModalShow({
      ...modalShow,
      importaNfS: true,
    });
  };

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Seleção de Nota Fiscal de Entrada'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar Nota Fiscal De Serviço'
          icon='fa-sharp fa-light fa-arrows-repeat'
          onClick={() => onOpenImportaNfs()}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-4'>
            <Autocomplete
              label='Fornecedor'
              searchDataSource={onSearchPessoaFor}
              selectedItem={filtros.pessoaFor}
              onSelectItem={(pessoaFor) => {
                setFiltros({
                  ...filtros,
                  pessoaFor,
                  nrSeqPessoaFor: pessoaFor.nrSeqPessoaFor,
                });
              }}
              dataSourceTextProperty='nomeDocumentoCidadeEstado'
            />
          </div>
          <div className='col-3'>
            <Textbox
              label='Número Nota'
              text={filtros.nrForNf}
              onChangedValue={(nrForNf) => {
                setFiltros({ ...filtros, nrForNf });
              }}
            />
          </div>
          <div className='col-2'>
            <Textbox
              label='Valor da Nota'
              text={filtros.vlrNfLiq}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrNfLiq) =>
                setFiltros({ ...filtros, vlrNfLiq })
              }
            />
          </div>
          <div className='col'>
            <RadioButton
              outline
              label='Status da Nota'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              buttons={statusNota}
              selectedButton={filtros.flgStatus}
              onChange={(flgStatus) => setFiltros({ ...filtros, flgStatus })}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-2'>
            <DatePicker
              label='Emissão de'
              text={filtros.dtEmissaoIni}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtEmissaoIni) =>
                setFiltros({ ...filtros, dtEmissaoIni })
              }
            />
          </div>
          <div className='col-2'>
            <DatePicker
              label='Emissão até'
              text={filtros.dtEmissaoFin}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtEmissaoFin) =>
                setFiltros({ ...filtros, dtEmissaoFin })
              }
            />
          </div>
          <div className='col-2'>
            <DatePicker
              label='Recebimento de'
              text={filtros.dtRecebimentoIni}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtRecebimentoIni) =>
                setFiltros({ ...filtros, dtRecebimentoIni })
              }
            />
          </div>
          <div className='col-2'>
            <DatePicker
              label='Recebimento até'
              text={filtros.dtRecebimentoFin}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtRecebimentoFin) =>
                setFiltros({ ...filtros, dtRecebimentoFin })
              }
            />
          </div>
          <div className='col-2'>
            <DatePicker
              label='Cadastro de'
              text={filtros.dtCadastroIni}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCadastroIni) =>
                setFiltros({ ...filtros, dtCadastroIni })
              }
            />
          </div>
          <div className='col-2'>
            <DatePicker
              label='Cadastro até'
              text={filtros.dtCadastroFin}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCadastroFin) =>
                setFiltros({ ...filtros, dtCadastroFin })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4'>
            <DropdownMulti
              label='Tipo NF'
              ref={dropDownTipoNotaFiscal}
              dataSourcePropertyText='noNfTipo'
              dataSourcePropertyValue='nrSeqNfTipo'
              selectedItems={selectedItems.tipoNotaFiscal ?? []}
              onSelectItem={(tipoNotaFiscal) => {
                setSelectedItems({ ...selectedItems, tipoNotaFiscal });
                setFiltros({
                  ...filtros,
                  nrSeqNfTipoS: tipoNotaFiscal?.map((p) => p.nrSeqNfTipo),
                });
              }}
            />
          </div>
          <div className='col-4'>
            <DropdownMulti
              label='Planos de conta'
              ref={dropDownPlanoDeContas}
              dataSourcePropertyText='noPlanoDeConta'
              dataSourcePropertyValue='nrSeqPlanoDeConta'
              selectedItems={selectedItems.planoDeConta ?? []}
              onSelectItem={(planoDeConta) => {
                setSelectedItems({ ...selectedItems, planoDeConta });
                setFiltros({
                  ...filtros,
                  nrSeqPlanoDeContaS: planoDeConta?.map(
                    (p) => p.nrSeqPlanoDeConta
                  ),
                });
              }}
            />
          </div>
        </div>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            sumFields
          />
        </div>
      </div>
      <ModalImportarNFS
        show={modalShow.importaNfS}
        onClose={() => {
          setModalShow({
            ...modalShow,
            importaNfS: false,
          });
        }}
      />
    </CSDSelPage>
  );
}
