import axios from 'axios';
import { refresh, getAuthData } from 'core/services/auth'; // eslint-disable-line import/no-cycle

const axiosDefaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  proxy: {
    host: process.env.REACT_APP_API_URL,
  },
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
};
const api = axios.create(axiosDefaultConfig);
const apiWithoutInterceptor = axios.create(axiosDefaultConfig);

const setTransaction = (NrSeqTransacao) => {
  api.defaults.headers.common.NrSeqTransacao = NrSeqTransacao ?? 0;
};

api.interceptors.request.use(async (config) => {
  const auditoria = localStorage.getItem('celaudit');
  if (auditoria) {
    let contentAudit = localStorage.getItem('celauditlog');

    contentAudit += `;${new Date().toLocaleString()},Request,${config.url}`;

    localStorage.setItem('celauditlog', contentAudit);

    /*  await api.post('/SEG/Usuario/RegisterAudit', {
      message: config.baseUrl,
    });

    */
  }

  const authData = getAuthData();
  if (authData) {
    const { lastrefresh } = JSON.parse(authData);

    const today = new Date();
    today.setSeconds(today.getSeconds() - 1800);

    if (today > new Date(lastrefresh)) {
      await refresh();
    }
  }

  return config;
});

api.interceptors.response.use(
  async (response) => {
    // onRegisterAudit('teste', 'teste');

    const auditoria = localStorage.getItem('celaudit');
    if (auditoria) {
      let contentAudit = localStorage.getItem('celauditlog');

      contentAudit += `;${new Date().toLocaleString()},Response,${
        response.config.url
      }`;

      localStorage.setItem('celauditlog', contentAudit);

      /*  await api.post('/SEG/Usuario/RegisterAudit', {
      message: config.baseUrl,
    });

    */
    }

    api.defaults.headers.common.NrSeqTransacao = 0;
    if (response.data) {
      if (response.data.message) {
        if (response.data.message.indexOf('desconhecido') > 0) {
          await api.post('/SEG/LogErroSistema/SaveObjectError', {
            objectError: response.config.data,
            baseUrl: response.config.url,
          });
        }
      }
    }
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) await refresh();

    return false;
  }
);

export default api;
export { apiWithoutInterceptor, setTransaction };
