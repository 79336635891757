import { FormaPagamento, TipoDespesa } from '../FIN';

export default class ManutencaoVeiculoFatura {
  constructor(jsonObject = {}) {
    this.nrSeq = jsonObject.nrSeq;
    this.vlrParcela = jsonObject.vlrParcela;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;

    // props auxiliares
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
