import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getAlmoxarifadoPagined,
  deleteAllAlmoxarifado,
} from 'core/services/EST/almoxarifado';
import { CSDSelPage, GridView, Textbox } from 'ui/components';

const columns = [
  { key: 'nrSeqAlmoxarifado', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdAlmoxarifado', title: 'Código' },
  { key: 'noAlmoxarifado', title: 'Descrição' },
];

export default function Almoxarifado({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAlmoxarifadoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getAlmoxarifadoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Almoxarifado/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAlmoxarifado = sourceRow.find(
      (e) => e.key === 'nrSeqAlmoxarifado'
    );
    onOpenMaintenance(nrSeqAlmoxarifado.value);
  };
  /*

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printAlmoxarifado(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  */

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllAlmoxarifado(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Almoxarifado'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      // onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-2'>
            <Textbox
              label='Código'
              text={filtros.cdAlmoxarifado}
              onChangedValue={(cdAlmoxarifado) => {
                setFiltros({ ...filtros, cdAlmoxarifado });
              }}
            />
          </div>
          <div className='col-4'>
            <Textbox
              label='Nome'
              text={filtros.noAlmoxarifado}
              onChangedValue={(noAlmoxarifado) => {
                setFiltros({ ...filtros, noAlmoxarifado });
              }}
            />
          </div>
        </div>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
