import { FormaPagamento, Fornecedor } from 'core/models/FIN';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { saveAcertoMotorista } from 'core/services/FIN/acertoMotorista';
import { GetOutrasDespesasAcerto } from 'core/services/FIN/acertoMotoristaDespesa';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getTipoDespesaAcertoList } from 'core/services/FIN/tipoDespesaAcerto';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import {
  DatePicker,
  Button,
  GridView,
  Switch,
  Autocomplete,
  Textbox,
  Notification,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';

const OutrasDespesas = forwardRef(
  ({
    data,
    registryKey,
    setData,
    acerto,
    transaction,
    setTransaction,
    bonus,
    setLoading,
  }) => {
    const [dataCadastroCliente, setDataCadastroCliente] = useState({
      flgAvista: false,
    });
    const [message, onSetMessage] = useState(null);
    const gridView = useRef();
    // const dataSourceRef = useRef({});

    /* useImperativeHandle(ref, () => ({
      setDataSource: (dataSource) => {
        if (dataSourceRef.current) {
          dataSourceRef.current = dataSource;
          setData({
            ...data,
            outrasDespesas: dataSourceRef.current.outrasDespesas,
          });
          setDataCadastroCliente({
            ...dataCadastroCliente,
            outrasDespesas: dataSourceRef.current.outrasDespesas,
          });

          if (gridView && gridView.current)
            gridView.current.setDataSource(
              dataSourceRef.current.outrasDespesas
            );
        }
      },
    })); */

    const setMessage = (status, msg) => {
      if (msg)
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    };

    const OnSearchFornecedor = async (e) => {
      const {
        message: msg,
        data: pessoaFors,
      } = await getFornecedorAutoComplete({
        noPessoa: e,
      });
      if (msg);
      return pessoaFors;
    };

    const onClickEditDespesa = async (selectedValue, datasource) => {
      const dataFaturas = datasource.filter(
        (fatura) => fatura !== selectedValue
      );
      const dataFatura = datasource.filter(
        (fatura) => fatura === selectedValue
      );

      dataFatura[0].status = GridView.EnumStatus.Remover;

      dataFaturas.push(dataFatura[0]);

      if (gridView && gridView.current)
        gridView.current.setDataSource(dataFaturas);

      setDataCadastroCliente({
        ...dataFatura[0],
        nrSeqAcertoMotoristaDespesa: null,
      });
    };

    const onClickRemoveOutrasDespesas = async (selectedValue, datasource) => {
      let placas = [];
      if (selectedValue.nrSeqAcertoMotoristaDespesa === undefined) {
        placas = datasource.filter((Placa) => Placa !== selectedValue);
      } else {
        placas = datasource.filter((Placa) => Placa !== selectedValue);
        const dataPlaca = datasource.filter((Placa) => Placa === selectedValue);
        dataPlaca[0].status = GridView.EnumStatus.Remover;
        placas.push(dataPlaca[0]);
      }
      if (gridView && gridView.current) gridView.current.setDataSource(placas);
    };

    const columns = [
      {
        key: 'nrSeqAcertoMotoristaDespesa',
        visible: false,
      },
      {
        key: 'nrDocumento',
        title: 'Nr Documento',
      },
      {
        key: 'dtDespesa',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'vlrDespesa',
        title: 'Valor',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'tipoDespesaAcerto.noTipoDespesaAcerto',
        title: 'Tipo Despesa',
      },
      {
        key: 'formaPagamento.noFormaPagamento',
        title: 'Forma Pagamento',
      },
      {
        key: 'fornecedor.noPessoa',
        title: 'Fornecedor',
      },
      {
        key: 'flgAvista',
        title: 'A vista',
      },
      {
        key: 'noObservacao',
        title: 'Obs',
      },
      {
        key: 'nrSeqAcertoMotoristaDespesa',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditDespesa(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
        visibleDynamic: 'flgExisteComissao',
      },
      {
        key: 'nrSeqAcertoMotoristaDespesa',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickRemoveOutrasDespesas(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const onSearchTipoDespesaAcerto = async (e) => {
      const {
        message: msg,
        data: tipoDespesas,
      } = await getTipoDespesaAcertoList({
        noTipoDespesaAcerto: e,
        flgComissao: false,
      });
      if (msg);
      return tipoDespesas;
    };

    const onSearchFormaPagamento = async (e) => {
      const {
        message: msg,
        formasPagamento,
      } = await getFormaPagamentoAutoComplete({
        noFormaPagamento: e,
        flgAtivo: true,
        flgTitPag: true,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });
      if (msg);
      return formasPagamento;
    };

    const load = async () => {
      if (data !== undefined) {
        if (data.outrasDespesas !== undefined && data.outrasDespesas !== null) {
          if (gridView && gridView.current)
            gridView.current.setDataSource(data.outrasDespesas);
        }
      }

      setMessage(
        ResponseStatus.Error,
        'Despesas Adicionadas, serão salvas automaticas no sistema.'
      );
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [data.nrSeqPessoaCli, registryKey]);

    useEffect(() => {
      if (dataCadastroCliente.flgAvista === true) {
        dataCadastroCliente.nrSeqFormaPagamento = null;
        dataCadastroCliente.formaPagamento = null;
      }
    }, [dataCadastroCliente.flgAvista]);

    const onAdicionarOutrasDespesasMantendoDados = async () => {
      if (
        data.nrSeqAcertoMotorista === null ||
        data.nrSeqAcertoMotorista === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Para Adicionar uma outra despesa, por favor salve o acerto primeiro'
        );
        return;
      }

      if (data.dtAcerto !== null && data.dtAcerto !== undefined) {
        setMessage(
          ResponseStatus.Error,
          'Você não pode adicionar uma despesa a um acerto ja efetivado'
        );
        return;
      }

      const itensGridView = gridView.current
        ? gridView.current.getDataSource()
        : [];

      const itensParaRemover = itensGridView.filter(
        (item) => item.status === 'Remover'
      );

      const despesa = { nrSeqAcertoMotorista: data.nrSeqAcertoMotorista };

      const outrasDespesas = await GetOutrasDespesasAcerto(despesa);

      const novaSegundaLista = outrasDespesas.filter(
        (item) =>
          !itensParaRemover.some(
            (removerItem) =>
              removerItem.nrSeqAcertoMotoristaDespesa ===
              item.nrSeqAcertoMotoristaDespesa
          )
      );

      const resultadoFinal = [...novaSegundaLista, ...itensParaRemover];

      const lista = resultadoFinal ?? [];
      const listaValidacao = [];
      if (lista !== null) {
        lista.forEach((elem) => listaValidacao.push(elem));
      }

      if (dataCadastroCliente.flgAvista === false) {
        if (
          dataCadastroCliente.nrSeqFormaPagamento === null ||
          dataCadastroCliente.nrSeqFormaPagamento === undefined
        ) {
          setMessage(
            ResponseStatus.Error,
            'Não é possivel adicionar despesas, sem selecionar a forma de pagamento'
          );
          return;
        }
      }

      if (
        dataCadastroCliente.nrSeqPessoaFor === null ||
        dataCadastroCliente.nrSeqPessoaFor === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel adicionar despesas, sem selecionar o Fornecedor'
        );
        return;
      }

      if (
        dataCadastroCliente.nrSeqTipoDespesaAcerto === null ||
        dataCadastroCliente.nrSeqTipoDespesaAcerto === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel adicionar despesas, sem selecionar o tipo de despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.vlrDespesa === null ||
        dataCadastroCliente.vlrDespesa === undefined ||
        dataCadastroCliente.vlrDespesa === 0
      ) {
        setMessage(
          ResponseStatus.Error,
          'Adicione um valor para adicionar a despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.dtDespesa === null ||
        dataCadastroCliente.dtDespesa === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Adicione uma data para adicionar a despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.nrDocumento === null ||
        dataCadastroCliente.nrDocumento === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Adicione um nr documento para adicionar a despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.formaPagamento === null ||
        dataCadastroCliente.formaPagamento === undefined
      ) {
        dataCadastroCliente.formaPagamento = new FormaPagamento();
      }
      if (
        dataCadastroCliente.fornecedor === null ||
        dataCadastroCliente.fornecedor === undefined
      ) {
        dataCadastroCliente.fornecedor = new Fornecedor();
      }
      listaValidacao.push(dataCadastroCliente);
      if (dataCadastroCliente.status !== GridView.EnumStatus.Alterar) {
        dataCadastroCliente.status = GridView.EnumStatus.Inserir;
      }
      lista.push(dataCadastroCliente);
      if (gridView && gridView.current) gridView.current.setDataSource(lista);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      setTransaction(transaction ? transaction.nrSeqTransacao : 0);
      const valorObj = {
        ...acerto,
        ...data,
        ...bonus,
        outrasDespesas: lista,
        nrSeqTransacao: transaction.nrSeqTransacao,
      };

      setLoading(true);

      const { status, message: msg, value: obj } = await saveAcertoMotorista(
        valorObj
      );

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          flgNewSave: false,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          flgNewSave: false,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
      setLoading(false);
    };

    const onAdicionarOutrasDespesas = async () => {
      if (
        data.nrSeqAcertoMotorista === null ||
        data.nrSeqAcertoMotorista === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Para Adicionar uma outra despesa, por favor salve o acerto primeiro'
        );
        return;
      }

      if (data.dtAcerto !== null && data.dtAcerto !== undefined) {
        setMessage(
          ResponseStatus.Error,
          'Você não pode adicionar uma despesa a um acerto ja efetivado'
        );
        return;
      }

      const itensGridView = gridView.current
        ? gridView.current.getDataSource()
        : [];

      const itensParaRemover = itensGridView.filter(
        (item) => item.status === 'Remover'
      );

      const despesa = { nrSeqAcertoMotorista: data.nrSeqAcertoMotorista };

      const outrasDespesas = await GetOutrasDespesasAcerto(despesa);

      const novaSegundaLista = outrasDespesas.filter(
        (item) =>
          !itensParaRemover.some(
            (removerItem) =>
              removerItem.nrSeqAcertoMotoristaDespesa ===
              item.nrSeqAcertoMotoristaDespesa
          )
      );

      const resultadoFinal = [...novaSegundaLista, ...itensParaRemover];

      const lista = resultadoFinal ?? [];
      const listaValidacao = [];
      if (lista !== null) {
        lista.forEach((elem) => listaValidacao.push(elem));
      }

      if (dataCadastroCliente.flgAvista === false) {
        if (
          dataCadastroCliente.nrSeqFormaPagamento === null ||
          dataCadastroCliente.nrSeqFormaPagamento === undefined
        ) {
          setMessage(
            ResponseStatus.Error,
            'Não é possivel adicionar despesas, sem selecionar a forma de pagamento'
          );
          return;
        }
      }

      if (
        dataCadastroCliente.nrSeqPessoaFor === null ||
        dataCadastroCliente.nrSeqPessoaFor === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel adicionar despesas, sem selecionar o Fornecedor'
        );
        return;
      }

      if (
        dataCadastroCliente.nrSeqTipoDespesaAcerto === null ||
        dataCadastroCliente.nrSeqTipoDespesaAcerto === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel adicionar despesas, sem selecionar o tipo de despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.vlrDespesa === null ||
        dataCadastroCliente.vlrDespesa === undefined ||
        dataCadastroCliente.vlrDespesa === 0
      ) {
        setMessage(
          ResponseStatus.Error,
          'Adicione um valor para adicionar a despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.dtDespesa === null ||
        dataCadastroCliente.dtDespesa === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Adicione uma data para adicionar a despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.nrDocumento === null ||
        dataCadastroCliente.nrDocumento === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Adicione um nr documento para adicionar a despesa'
        );
        return;
      }

      if (
        dataCadastroCliente.formaPagamento === null ||
        dataCadastroCliente.formaPagamento === undefined
      ) {
        dataCadastroCliente.formaPagamento = new FormaPagamento();
      }
      if (
        dataCadastroCliente.fornecedor === null ||
        dataCadastroCliente.fornecedor === undefined
      ) {
        dataCadastroCliente.fornecedor = new Fornecedor();
      }
      listaValidacao.push(dataCadastroCliente);
      if (dataCadastroCliente.status !== GridView.EnumStatus.Alterar) {
        dataCadastroCliente.status = GridView.EnumStatus.Inserir;
      }
      lista.push(dataCadastroCliente);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      setTransaction(transaction ? transaction.nrSeqTransacao : 0);
      const valorObj = {
        ...acerto,
        ...data,
        ...bonus,
        outrasDespesas: lista,
        nrSeqTransacao: transaction.nrSeqTransacao,
      };

      setLoading(true);

      const { status, message: msg, value: obj } = await saveAcertoMotorista(
        valorObj
      );

      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Error) {
        setMessage(ResponseStatus.Error, msg);
        setLoading(false);
        return;
      }

      if (gridView && gridView.current) gridView.current.setDataSource(lista);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          flgNewSave: false,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          flgNewSave: false,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }

      setDataCadastroCliente({ vlrDespesa: 0 });
      setLoading(false);
    };

    return (
      <div>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='display-8'>6 - Outras Despesas</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-1 mb-3'>
                    <Switch
                      formControl
                      label='A Vista '
                      checked={dataCadastroCliente.flgAvista}
                      onChange={(flgAvista) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          flgAvista,
                        })
                      }
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      label='Tipo Despesa'
                      searchDataSource={onSearchTipoDespesaAcerto}
                      selectedItem={dataCadastroCliente.tipoDespesaAcerto}
                      onSelectItem={(tipoDespesaAcerto) => {
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          tipoDespesaAcerto,
                          nrSeqTipoDespesaAcerto:
                            tipoDespesaAcerto.nrSeqTipoDespesaAcerto,
                        });
                      }}
                      dataSourceTextProperty='noTipoDespesaAcerto'
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='Data'
                      required
                      text={dataCadastroCliente.dtDespesa}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtDespesa) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtDespesa,
                        })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Textbox
                      label='Valor'
                      required
                      textRight
                      text={dataCadastroCliente.vlrDespesa}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrDespesa) => {
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          vlrDespesa,
                        });
                      }}
                    />
                  </div>
                  {!dataCadastroCliente.flgAvista && (
                    <div className='col mb-3'>
                      <Autocomplete
                        label='Forma Pagamento'
                        required
                        searchDataSource={onSearchFormaPagamento}
                        selectedItem={dataCadastroCliente.formaPagamento}
                        onSelectItem={(formaPagamento) => {
                          setDataCadastroCliente({
                            ...dataCadastroCliente,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                          });
                        }}
                        dataSourceTextProperty='noFormaPagamento'
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-4 mb-3'>
                  <Textbox
                    label='Numero Doc'
                    required
                    text={dataCadastroCliente.nrDocumento}
                    onChangedValue={(nrDocumento) => {
                      setDataCadastroCliente({
                        ...dataCadastroCliente,
                        nrDocumento,
                      });
                    }}
                  />
                </div>
                <div className='col-6 mb-3'>
                  <Autocomplete
                    label='Fornecedor'
                    searchDataSource={OnSearchFornecedor}
                    selectedItem={dataCadastroCliente.fornecedor}
                    onSelectItem={(fornecedor) => {
                      setDataCadastroCliente({
                        ...dataCadastroCliente,
                        fornecedor,
                        nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-6 mb-3'>
                  <Textbox
                    label='Observação'
                    text={dataCadastroCliente.noObservacao}
                    onChangedValue={(noObservacao) => {
                      setDataCadastroCliente({
                        ...dataCadastroCliente,
                        noObservacao,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAdicionarOutrasDespesas}
                  />
                </div>
                <div className='col-4 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Adicionar e manter os dados'
                    onClick={onAdicionarOutrasDespesasMantendoDados}
                  />
                </div>
              </div>

              <div className='col-12'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default OutrasDespesas;
