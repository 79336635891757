import qs from 'qs';
import { FormaPagamento } from 'core/models/FIN';
import Pagination from 'core/models/pagination';
import api from '../api';

// const baseURL = `/FIN/FormaPagamento`;

export const getFormaPagamentoAutoComplete = async (filters) => {
  const response = await api.get(`/FormaPagamento/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    formasPagamento: value?.map((json) => new FormaPagamento(json)) ?? [],
  };
};

export const getFormaPagamentoPdvAutoComplete = async (filters) => {
  const response = await api.get(`/FormaPagamento/AutoCompletePdv`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    formasPagamento: value?.map((json) => new FormaPagamento(json)) ?? [],
  };
};

export const getFormaPagamentoPagined = async (filters) => {
  const response = await api.get(`/FormaPagamento/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new FormaPagamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFormaPagamento = async (id) => {
  const response = await api.get(`/FormaPagamento/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new FormaPagamento(value) : {};
};

export const printFormaPagamento = async (filters) => {
  const response = await api.get(`/FormaPagamento/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllFormaPagamento = async (ids) => {
  const response = await api.delete(`/FormaPagamento/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const saveFormaPagamento = async (data) => {
  const response = await api.post(`/FormaPagamento/`, data);
  return response.data ?? {};
};

export const deleteFormaPagamento = async (id) => {
  const response = await api.delete(`/FormaPagamento/`, { params: { id } });
  return response.data ?? {};
};
