import qs from 'qs';
import Pagination from 'core/models/pagination';
import AcertoMotoristaDespesa from 'core/models/FIN/acertoMotoristaDespesa';
import api from '../api';

const baseURL = `/FIN/AcertoMotoristaDespesa`;

export const getAcertoMotoristaDespesaList = async (filters) => {
  const response = await api.get(`/$AcertoMotoristaDespesa/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaDespesa(json)) ?? [],
  };
};

export const GetOutrasDespesasAcerto = async (filters) => {
  const response = await api.post(
    `${baseURL}/GetOutrasDespesasAcerto`,
    filters
  );
  const { value } = response.data ?? {};
  return value;
};

export const getAcertoMotoristaDespesaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaDespesa(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAcertoMotoristaDespesa = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AcertoMotoristaDespesa(value) : {};
};

export const saveAcertoMotoristaDespesa = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAcertoMotoristaDespesa = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAcertoMotoristaDespesa = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAcertoMotoristaDespesa = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAcertoMotoristaDespesa = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
