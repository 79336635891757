import React, { useEffect, useState } from 'react';
import { getEsimActivationCode } from 'core/services/HELP';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Textbox } from 'ui/components';

export default function ModalAtivacaoEsim({
  show,
  onClose,
  data,
  onSetMessage,
}) {
  const [loading, setLoading] = useState(false);
  const [codeData, setCodeData] = useState({});

  const downloadQRCode = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = codeData.qrCode;
    downloadLink.download = 'qrcode.png';
    downloadLink.click();
  };

  const SearchActivationCode = async () => {
    setLoading(true);

    if (data.nrSeqChip) {
      const { status, message: msg, value } = await getEsimActivationCode(
        data.nrSeqChip
      );

      if (status === ResponseStatus.Success) {
        setCodeData(value);
      } else {
        onSetMessage(status, msg);
        setCodeData({});
        onClose();
      }
    }

    setLoading(false);
  };

  useEffect(async () => {
    if (show) {
      SearchActivationCode();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Codigo Ativação E-Sim'
      onClose={() => onClose()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        <div className='row mb-3'>
          <Textbox
            label='Código de Ativação'
            text={codeData.activationCode}
            readOnly
          />
        </div>
        <div className='row mb-3'>
          <div className='col d-flex justify-content-center'>
            <Button
              text='Baixar QR Code'
              icon='download'
              // icon='qrcode'
              onClick={() => downloadQRCode()}
              theme={Theme.Inactive}
              size={BootstrapSizes.Large}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
