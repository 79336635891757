import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  CSDManutPage,
  Panel,
  Textbox,
  Autocomplete,
  Switch,
  Textarea,
  RadioButton,
  GridView,
  Button,
} from 'ui/components';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import {
  getFormaPagamento,
  saveFormaPagamento,
  deleteFormaPagamento,
} from 'core/services/FIN/formaPagamento';
import { IFormaPagamento, IFormaPagamentoEmpresa } from 'core/interfaces/FIN';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getContaCorrenteAutoComplete } from 'core/services/FIN/contaCorrente';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { MaskTypes } from 'ui/Helpers/masks';

export default function FormaPagamento({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const [data, setData] = useState<IFormaPagamento | any>({
    vlrJuro: 0,
    vlrMulta: 0,
    vlrTaxaBancaria: 0,
    indiceIGPM: 0,
    jurosMora: 0,
    boletoAceite: null,
    flgGrupo: 'N',
    flgJuroMultaManual: true,
    flgAtivo: true,
  });
  const [dataFormaPagamentoEmpresa, setDataFormaPagamentoEmpresa] = useState<
    IFormaPagamentoEmpresa | any
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const gridViewFormaPagamentoEmpresa = useRef();

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = (): void => {
    setData({
      vlrJuro: 0,
      vlrMulta: 0,
      vlrTaxaBancaria: 0,
      indiceIGPM: 0,
      jurosMora: 0,
      flgGrupo: 'N',
      flgJuroMultaManual: true,
      flgAtivo: true,
    });
    setDataFormaPagamentoEmpresa({});
    if (gridViewFormaPagamentoEmpresa && gridViewFormaPagamentoEmpresa.current)
      gridViewFormaPagamentoEmpresa.current.setDataSource([]);
  };

  const statusGrupo = [
    { text: 'Normal', value: 'N' },
    { text: 'Controle Cheque', value: 'C' },
    { text: 'Gera Boleto', value: 'G' },
    { text: 'Débito em Conta', value: 'D' },
    { text: 'Eletrônico', value: 'E' },
  ];

  const statusJuros = [
    { text: 'Diário', value: 'D' },
    { text: 'Mensal', value: 'M' },
    { text: 'Isento', value: 'I' },
  ];

  const statusTipo = [
    { text: 'Tradicional', value: 'T' },
    { text: 'Escritural', value: 'E' },
  ];

  const statusEmissao = [
    { text: 'Banco', value: 'B' },
    { text: 'Cliente - Complemento', value: 'C' },
    { text: 'Cliente - Pré-Impresso', value: 'I' },
  ];

  const statusDistribuicao = [
    { text: 'Banco', value: 'B' },
    { text: 'Cliente', value: 'C' },
  ];

  const statusProtesto = [
    { text: 'Dias Corridos', value: 'D' },
    { text: 'Úteis', value: 'U' },
    { text: 'Não Protestar', value: 'N' },
  ];

  const statusBoleto = [
    { text: '1-BBrasil', value: '1' },
    { text: '2-Banrisul', value: '2' },
    { text: '3-CEF', value: '3' },
    { text: '4-Sicredi', value: '4' },
    { text: '5-Bradesco', value: '5' },
    { text: '6-Itaú', value: '6' },
    { text: '7-HSBC', value: '7' },
    { text: '8-Santander', value: '8' },
    { text: '9-Sicoob', value: '9' },
    { text: '10-Alios', value: '10' },
    { text: '11-BTG', value: '11' },
  ];

  const aceiteOptions = {
    '1': [
      // BBrasil
      { label: 'Sim', value: 'A' },
      { label: 'Não', value: 'N' },
    ],
    '2': [
      // BBrasil
      { label: 'Sim', value: 'A' },
      { label: 'Não', value: 'N' },
    ],
    '3': [
      // CEF
      { label: 'Sim', value: 'S' },
      { label: 'Não', value: 'N' },
    ],
    '4': [
      // Sicredi
      { label: 'Sim', value: 'S' },
      { label: 'Não', value: 'N' },
    ],
    '5': [
      // Bradesco
      { label: 'Sim', value: 'A' },
      { label: 'Não', value: 'N' },
    ],
    '6': [
      // Itaú
      { label: 'Sim', value: 'A' },
      { label: 'Não', value: 'N' },
    ],
    '7': [
      { label: 'Sim', value: 'S' },
      { label: 'Não', value: 'N' },
    ],
    '8': [
      { label: 'Sim', value: 'S' },
      { label: 'Não', value: 'N' },
    ],
    '9': [
      { label: 'Sim', value: 'S' },
      { label: 'Não', value: 'N' },
    ],
    '10': [
      { label: 'Sim', value: 'A' },
      { label: 'Não', value: 'N' },
    ],
    '11': [
      { label: 'Sim', value: 'S' },
      { label: 'Não', value: 'N' },
    ],
  };

  const boletoCarteiraCobrancaOptions = {
    '1': [
      // BBrasil
      { label: '11 - ', value: '11' },
      { label: '17 - ', value: '17' },
    ],
    '2': [
      // Banrisul
      { label: '06 - O Banco Emite e Envia por Correio', value: '06' },
      { label: '08 - O Cliente Emite e Envia', value: '08' },
    ],
    '3': [
      // CEF
      { label: '109 - Direta Eletrônica sem Emissão - Simples', value: '109' },
    ],
    '4': [
      // Sicredi
      { label: '1 - Sicredi Com Registro', value: '1' },
    ],
    '5': [
      // Bradesco
      { label: '06', value: '06' },
      { label: '09', value: '09' },
    ],
    '6': [
      // Itaú
      { label: '109 - Direta Eletrônica sem Emissão - Simples', value: '109' },
    ],
    '7': [
      // HSBC
      // Nenhuma informação disponível
    ],
    '8': [
      // Santander
      // Nenhuma informação disponível
    ],
    '9': [
      // Sicoob
      { label: '1 - Sicredi Com Registro', value: '1' },
    ],
    '10': [
      // Ailos
      { label: '01', value: '01' },
    ],
    '11': [
      // BTG
      // Nenhuma informação disponível
    ],
  };

  const codMoedaOptions = {
    '1': [
      // BBrasil
      { label: '03 - R$ (Real) ', value: '03' },
    ],
    '2': [
      // Banrisul
      { label: '03 - R$ (Real) ', value: '03' },
    ],
    '3': [],
    '4': [
      // Sicredi
      { label: '9 - R$ (Real)', value: '9' },
    ],
    '5': [
      // Bradesco
    ],
    '6': [
      // Itaú
    ],
    '7': [
      // HSBC
    ],
    '8': [
      // Santander
    ],
    '9': [
      // Sicoob
      { label: '9 - R$ (Real)', value: '9' },
    ],
    '10': [
      // Ailos
      { label: '9 - R$ (Real)', value: '9' },
    ],
    '11': [
      // BTG
    ],
  };

  const variacaoCarteiraOptions = {
    '1': [
      // BBrasil
      { label: 'Não Informado', value: '0' },
      { label: '19 - Carteira', value: '19' },
      { label: '43 - Carteira', value: '43' },
      { label: '27 - Carteira', value: '27' },
    ],
    '2': [
      // Banrisul
    ],
    '3': [],
    '4': [
      // Sicredi
      { label: '1 - Carteira Simples', value: '1' },
    ],
    '5': [
      // Bradesco
    ],
    '6': [
      // Itaú
    ],
    '7': [
      // HSBC
    ],
    '8': [
      // Santander
    ],
    '9': [
      // Sicoob
      { label: '1 - Carteira Simples', value: '1' },
    ],
    '10': [
      // Ailos
    ],
    '11': [
      // BTG
    ],
  };

  const especieTituloOptions = {
    '1': [
      // BBrasil
      { label: 'Não Informado', value: '00' },
      { label: '02 - Duplicata Mercantil', value: '02' },
      { label: '04 - Duplicata de Serviço', value: '04' },
      { label: '06 - Duplicata Rural', value: '06' },
    ],
    '2': [
      // Banrisul
    ],
    '3': [],
    '4': [
      // Sicredi
      { label: 'A - Duplicata Mercantil por Indicação', value: 'A' },
      { label: 'DMI - Duplicata Mercantil por Indicação', value: 'DMI' },
    ],
    '5': [
      // Bradesco
      { label: '12', value: '12' },
    ],
    '6': [
      // Itaú
      { label: 'DS - Duplicata de Prestação de Serviço', value: 'DS' },
    ],
    '7': [
      // HSBC
    ],
    '8': [
      // Santander
    ],
    '9': [
      // Sicoob
      { label: 'A - Duplicata Mercantil por Indicação', value: 'A' },
      { label: 'DMI - Duplicata Mercantil por Indicação', value: 'DMI' },
    ],
    '10': [
      // Ailos
      { label: 'DS - Duplicata de Prestação de Serviço', value: 'DS' },
    ],
    '11': [
      // BTG
    ],
  };

  const boletoInstrucaoCod01Options = {
    '1': [
      // BBrasil
    ],
    '2': [
      // Banrisul
      { label: '18', value: '18' },
    ],
    '3': [],
    '4': [
      // Sicredi
    ],
    '5': [
      // Bradesco
      { label: '00', value: '00' },
      { label: '06', value: '06' },
    ],
    '6': [
      // Itaú
      { label: '05', value: '05' },
    ],
    '7': [
      // HSBC
    ],
    '8': [
      // Santander
    ],
    '9': [
      // Sicoob
    ],
    '10': [
      // Ailos
    ],
    '11': [
      // BTG
    ],
  };

  const boletoInstrucaoCod02Options = {
    '1': [
      // BBrasil
    ],
    '2': [
      // Banrisul
    ],
    '3': [],
    '4': [
      // Sicredi
    ],
    '5': [
      // Bradesco
      { label: '00', value: '00' },
      { label: '05', value: '05' },
    ],
    '6': [
      // Itaú
      { label: '12', value: '12' },
    ],
    '7': [
      // HSBC
    ],
    '8': [
      // Santander
    ],
    '9': [
      // Sicoob
    ],
    '10': [
      // Ailos
    ],
    '11': [
      // BTG
    ],
  };

  const handleBancoChange = (flgBoletoModelo: string) => {
    setData({
      ...data,
      flgBoletoModelo,
      boletoAceite: null,
      boletoCarteiraCobranca: null,
      boletoCodMoeda: null,
      boletoInstrucaoCod01: null,
      boletoInstrucaoCod02: null,
    });
  };

  // Lista de Aceite de um banco especifico

  const onSearchAceiteWrapper = (query: string) => {
    const options = aceiteOptions[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleAceiteSelect = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoAceite: selected?.value || null,
    }));
  };

  // Lista de Carteiras Cobranca de um banco especifico

  const handleCarteiraSelect = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoCarteiraCobranca: selected?.value || null,
    }));
  };

  const onSearchCarteiraWrapper = (query: string) => {
    const options = boletoCarteiraCobrancaOptions[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Lista de Código Moeda de um banco especifico

  const handleCodMoedaSelect = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoCodMoeda: selected?.value || null,
    }));
  };

  const onSearchCodMoedaWrapper = (query: string) => {
    const options = codMoedaOptions[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Lista de Variacao de Carteira de um banco especifico

  const handleVariacaoCarteiraSelect = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoVariacaoCarteira: selected?.value || null,
    }));
  };

  const onSearchVariacaoCarteiraWrapper = (query: string) => {
    const options = variacaoCarteiraOptions[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Lista de Especie Titulo de um banco especifico

  const handleEspecieTituloSelect = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoEspTitulo: selected?.value || null,
    }));
  };

  const onSearchEspecieTituloWrapper = (query: string) => {
    const options = especieTituloOptions[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Lista de Instrucao Codigo 01 de um banco especifico

  const handleInstrucaoCod01Select = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoInstrucaoCod01: selected?.value || null,
    }));
  };

  const onSearchInstrucaoCod01Wrapper = (query: string) => {
    const options = boletoInstrucaoCod01Options[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Lista de Instrucao Codigo 02 de um banco especifico

  const handleInstrucaoCod02Select = (selected) => {
    setData((prev) => ({
      ...prev,
      boletoInstrucaoCod02: selected?.value || null,
    }));
  };

  const onSearchInstrucaoCod02Wrapper = (query: string) => {
    const options = boletoInstrucaoCod02Options[data.flgBoletoModelo] || [];
    return options.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getFormaPagamento(registryKey);
      setData(retorno);

      if (
        gridViewFormaPagamentoEmpresa &&
        gridViewFormaPagamentoEmpresa.current
      )
        gridViewFormaPagamentoEmpresa.current.setDataSource(
          retorno.formaPagamentoEmpresas ?? []
        );
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveFormaPagamento(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setData(status === ResponseStatus.Success ? {} : data);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteFormaPagamento(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchFornecedor = async (e: any) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchEmpresa = async (e: any) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchContaCorrenteRec = async (e: any) => {
    const {
      status,
      message: msg,
      data: contas,
    } = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (msg) onSetMessage(status, msg);
    return contas;
  };

  const onSearchContaCorrentePag = async (e: any) => {
    const {
      status,
      message: msg,
      data: contas,
    } = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (msg) onSetMessage(status, msg);
    return contas;
  };

  const onSearchPlanoContaRec = async (e: any) => {
    const planoDeContaCred = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });
    return planoDeContaCred.planodecontas;
  };

  const onSearchPlanoContaPag = async (e: any) => {
    const planoDeContaDeb = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });
    return planoDeContaDeb.planodecontas;
  };

  const validateItemsGrupoIcmsEstado = (datasource) => {
    if (!datasource.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Empresa.');
      return false;
    }

    if (data.flgTitRec) {
      if (!datasource.nrSeqContaCorrenteRec) {
        onSetMessage(
          ResponseStatus.Error,
          'Campo obrigatório: Conta Corrente de Recebimento quando a forma de pagamento é recebivel.'
        );
        return false;
      }
    }

    if (data.flgTitPag) {
      if (!datasource.nrSeqContaCorrentePag) {
        onSetMessage(
          ResponseStatus.Error,
          'Campo obrigatório: Conta Corrente de Pagamento quando a forma de pagamento é pagavel.'
        );
        return false;
      }
    }

    if (
      data.formaPagamentoEmpresas &&
      data.formaPagamentoEmpresas.length > 0 &&
      data.formaPagamentoEmpresas.some(
        (x) =>
          x.nrSeqContaCorrentePag !== undefined &&
          x.nrSeqContaCorrentePag !== datasource.nrSeqContaCorrentePag &&
          x.status !== StatusGrid.Remover
      )
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possível adicionar mais de uma conta corrente de débito.'
      );
      return false;
    }

    if (
      data.formaPagamentoEmpresas &&
      data.formaPagamentoEmpresas.length > 0 &&
      data.formaPagamentoEmpresas.some(
        (x) =>
          x.nrSeqEmpresa !== undefined &&
          x.nrSeqEmpresa === datasource.nrSeqEmpresa &&
          x.status !== StatusGrid.Remover
      )
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Esta empresa já está vinculada nesta forma de pagamento.'
      );
      return false;
    }

    return true;
  };

  const onAdicionarFormaPagamentoEmpresa = async () => {
    if (validateItemsGrupoIcmsEstado(dataFormaPagamentoEmpresa)) {
      const obj = {
        ...dataFormaPagamentoEmpresa,
        status: dataFormaPagamentoEmpresa.status ?? StatusGrid.Inserir,
      };

      const list = gridViewFormaPagamentoEmpresa.current.getDataSource();
      list.push(obj);

      if (
        gridViewFormaPagamentoEmpresa &&
        gridViewFormaPagamentoEmpresa.current
      ) {
        gridViewFormaPagamentoEmpresa.current.setDataSource(list);
      }

      setData({
        ...data,
        formaPagamentoEmpresas: list,
      });
      setDataFormaPagamentoEmpresa({});
    }
  };

  const onRemoveEmpresa = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewFormaPagamentoEmpresa && gridViewFormaPagamentoEmpresa.current)
      gridViewFormaPagamentoEmpresa.current.setDataSource(datasource);
  };

  const columns = [
    {
      key: 'nrSeqFormaPagamento',
      title: 'nrSeqFormaPagamento',
      visible: false,
    },
    {
      key: 'empresa.noPessoa',
      title: 'Empresa',
    },
    {
      key: 'contaCorrenteRec.noContaCorrente',
      title: 'Conta Corrente Débito',
    },
    {
      key: 'contaCorrentePag.noContaCorrente',
      title: 'Conta Corrente Crédito',
    },
    {
      key: 'planoDeContaPag.cdCaminhoCompleto',
      title: 'Plano Conta Pagamento',
    },
    {
      key: 'planoDeContaRec.cdCaminhoCompleto',
      title: 'Plano Conta Recebimento',
    },
    {
      key: 'flgGeraExtratoRec',
      title: 'Gera Extrato Débito?',
    },
    {
      key: 'flgGeraExtratoPag',
      title: 'Gera Extrato Crédito?',
    },
    {
      key: 'nrSeqPessoaPos',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveEmpresa(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Forma de Pagamento'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqFormaPagamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <Panel>
        <Panel.Header title='Forma Pagamento' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-1 mb-3'>
              <Textbox
                maxLength={1}
                label='Código'
                mask={MaskTypes.Integer}
                readOnly
                text={data.cdFormaPagamento}
                onChangedValue={(cdFormaPagamento: number) =>
                  setData({ ...data, cdFormaPagamento })
                }
              />
            </div>
            <div className='col-4 mb-3'>
              <Textbox
                maxLength={60}
                required
                label='Descrição'
                text={data.noFormaPagamento}
                onChangedValue={(noFormaPagamento: string) =>
                  setData({ ...data, noFormaPagamento })
                }
              />
            </div>
            <div className='col-1'>
              <Switch
                formControl
                checked={data?.flgAtivo}
                label='Ativo'
                onChange={(flgAtivo: boolean) => setData({ ...data, flgAtivo })}
              />
            </div>
            <div className='col-3'>
              <Switch
                formControl
                checked={data?.flgGeraSequenciaRecibo}
                label='Gera Número Sequencial Recibos'
                onChange={(flgGeraSequenciaRecibo: boolean) =>
                  setData({ ...data, flgGeraSequenciaRecibo })
                }
              />
            </div>
            <div className='col-3'>
              <Switch
                formControl
                checked={data?.flgGeraSequenciaReciboPag}
                label='Gera Número Sequencial Para Pagamentos'
                onChange={(flgGeraSequenciaReciboPag: boolean) =>
                  setData({ ...data, flgGeraSequenciaReciboPag })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Switch
                formControl
                checked={data?.flgVinculaFornecedor}
                label='Vincula Fornecedor'
                onChange={(flgVinculaFornecedor: boolean) =>
                  setData({ ...data, flgVinculaFornecedor })
                }
              />
            </div>
            {data?.flgVinculaFornecedor && (
              <div className='col-4 mb-4'>
                <Autocomplete
                  label='Fornecedor'
                  searchDataSource={onSearchFornecedor}
                  selectedItem={data.fornecedor}
                  onSelectItem={(fornecedor: any) =>
                    setData({
                      ...data,
                      fornecedor,
                      nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            )}
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Textarea
                maxLength={450}
                label='Mensagem Integração Bancária'
                text={data.msgBoleto}
                onChangedValue={(msgBoleto: string) =>
                  setData({ ...data, msgBoleto })
                }
              />
            </div>
            <div className='col-6 mb-4'>
              <Textarea
                maxLength={220}
                label='Mensagem em boleto'
                text={data.msgDocumento}
                onChangedValue={(msgDocumento: string) =>
                  setData({ ...data, msgDocumento })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='container-fluid mt-9'>
        <div className='row'>
          <div className='col-6'>
            <Panel>
              <Panel.Header title='Tipo' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgTitRec}
                      label='Recebível'
                      onChange={(flgTitRec: boolean) =>
                        setData({ ...data, flgTitRec })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgTitPag}
                      label='Pagável'
                      onChange={(flgTitPag: boolean) =>
                        setData({ ...data, flgTitPag })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgGeraTitulo}
                      label='Gera Título'
                      onChange={(flgGeraTitulo: boolean) =>
                        setData({ ...data, flgGeraTitulo })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgLiqTitulo}
                      label='Liquidável'
                      onChange={(flgLiqTitulo: boolean) =>
                        setData({ ...data, flgLiqTitulo })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgPDV}
                      label='Exibe PDV'
                      onChange={(flgPDV: boolean) =>
                        setData({ ...data, flgPDV })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgControlaCheque}
                      label='Controla Cheque'
                      onChange={(flgControlaCheque: boolean) =>
                        setData({ ...data, flgControlaCheque })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>

          <div className='col-6'>
            <Panel>
              <Panel.Header title='Grupo' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-8'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Grupo'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusGrupo}
                      selectedButton={'flgGrupo' in data ? data.flgGrupo : null}
                      onChange={(flgGrupo: string) =>
                        setData({ ...data, flgGrupo })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-9'>
        <div className='row'>
          <div className='col-6'>
            <Panel>
              <Panel.Header title='Juros' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Juros'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusJuros}
                      selectedButton={
                        'flgTipoJuro' in data ? data.flgTipoJuro : null
                      }
                      onChange={(flgTipoJuro: string) =>
                        setData({ ...data, flgTipoJuro })
                      }
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={4}
                      label='(%)Juros'
                      mask={MaskTypes.DecimalCustom}
                      text={data.vlrJuro}
                      onChangedValue={(vlrJuro: number) =>
                        setData({ ...data, vlrJuro })
                      }
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={3}
                      label='Carência'
                      mask={MaskTypes.Integer}
                      text={data.carenciaJuros}
                      onChangedValue={(carenciaJuros: number) =>
                        setData({ ...data, carenciaJuros })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgJuroCapitalizado}
                      label='Juros Captalizados'
                      onChange={(flgJuroCapitalizado: boolean) =>
                        setData({ ...data, flgJuroCapitalizado })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>

          <div className='col-6'>
            <Panel>
              <Panel.Header title='Multa' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-3 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={4}
                      label='(%)Multa'
                      mask={MaskTypes.DecimalCustom}
                      text={data.vlrMulta}
                      onChangedValue={(vlrMulta: number) =>
                        setData({ ...data, vlrMulta })
                      }
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={3}
                      label='Carência'
                      mask={MaskTypes.Integer}
                      text={data.carenciaMulta}
                      onChangedValue={(carenciaMulta: number) =>
                        setData({ ...data, carenciaMulta })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgJuroMultaManual}
                      label='Multa e Juros Manuais'
                      onChange={(flgJuroMultaManual: boolean) =>
                        setData({ ...data, flgJuroMultaManual })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgAcrescentaJuro}
                      label='Acrescenta Juros'
                      onChange={(flgAcrescentaJuro: boolean) =>
                        setData({ ...data, flgAcrescentaJuro })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-9'>
        <div className='row'>
          <div className='col-6'>
            <Panel>
              <Panel.Header title='Status' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-5 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={2}
                      label='Float Bancário'
                      mask={MaskTypes.Integer}
                      text={data.floatBancario}
                      onChangedValue={(floatBancario: number) =>
                        setData({ ...data, floatBancario })
                      }
                    />
                  </div>
                  {(data?.flgGrupo === 'G' || data?.flgGrupo === 'D') && (
                    <div className='col-3 mb-3'>
                      {/* @ts-expect-error */}
                      <Textbox
                        maxLength={10}
                        label='Vlr. Taxa Bancária'
                        mask={MaskTypes.DecimalCustom}
                        text={data.vlrTaxaBancaria}
                        onChangedValue={(vlrTaxaBancaria: number) =>
                          setData({ ...data, vlrTaxaBancaria })
                        }
                      />
                    </div>
                  )}
                  <div className='col-4'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgDescTaxaBancaria}
                      label='Desconto Taxa Bancária Receb.'
                      onChange={(flgDescTaxaBancaria: boolean) =>
                        setData({ ...data, flgDescTaxaBancaria })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>

          <div className='col-6'>
            <Panel>
              <Panel.Header title='IGPM' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-3 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={9}
                      label='(%)IGPM Conforme Mês'
                      mask={MaskTypes.DecimalCustom}
                      text={data.indiceIGPM}
                      onChangedValue={(indiceIGPM: number) =>
                        setData({ ...data, indiceIGPM })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={11}
                      label='Mês do IGPM'
                      text={data.mesJuroIGPM}
                      onChangedValue={(mesJuroIGPM: string) =>
                        setData({ ...data, mesJuroIGPM })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={11}
                      label='Ano do IGPM'
                      text={data.anoJuroIGPM}
                      onChangedValue={(anoJuroIGPM: string) =>
                        setData({ ...data, anoJuroIGPM })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={9}
                      label='Juro Mora(%)'
                      mask={MaskTypes.DecimalCustom}
                      text={data.jurosMora}
                      onChangedValue={(jurosMora: number) =>
                        setData({ ...data, jurosMora })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgJuroIGPM}
                      label='Juro IPGM'
                      onChange={(flgJuroIGPM: boolean) =>
                        setData({ ...data, flgJuroIGPM })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
      {(data?.flgGrupo === 'G' ||
        data?.flgGrupo === 'D' ||
        data?.flgGrupo === 'E') && (
        <Panel>
          <Panel.Header
            title='Informações de Envio ao Banco'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3 mb-3'>
                <Textbox
                  maxLength={40}
                  label='N° do Convênio'
                  text={data.boletoNrDocumento}
                  onChangedValue={(boletoNrDocumento: string) =>
                    setData({ ...data, boletoNrDocumento })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                <Textbox
                  maxLength={30}
                  label='Nome Versão Layout Arquivo Remessa'
                  text={data.noVersaoLayout}
                  onChangedValue={(noVersaoLayout: string) =>
                    setData({ ...data, noVersaoLayout })
                  }
                />
              </div>
              {data?.flgGrupo === 'E' && (
                <div className='col-3 mb-3'>
                  <Textbox
                    maxLength={10}
                    label='Densidade do Arquivo'
                    text={data.noDensidadeArquivo}
                    onChangedValue={(noDensidadeArquivo: string) =>
                      setData({ ...data, noDensidadeArquivo })
                    }
                  />
                </div>
              )}
              {data?.flgGrupo === 'E' && (
                <div className='col-3 mb-3'>
                  <Textbox
                    maxLength={10}
                    label='Formato de Envio'
                    text={data.noFormatoEnvio}
                    onChangedValue={(noFormatoEnvio: string) =>
                      setData({ ...data, noFormatoEnvio })
                    }
                  />
                </div>
              )}
              <div className='col-3 mb-3'>
                <Textbox
                  maxLength={2}
                  label='Nr. Max. Caracter CdCliente'
                  mask={MaskTypes.Integer}
                  text={data.nrCaracteresCdCliente}
                  onChangedValue={(nrCaracteresCdCliente: number) =>
                    setData({ ...data, nrCaracteresCdCliente })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
      <Panel>
        <Panel.Header title='Parâmetro Api Pagamento' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mt-1'>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={data?.flgAmbienteProducaoApiBoletoPix}
                label='Usar Ambiente Produção'
                onChange={(flgAmbienteProducaoApiBoletoPix: boolean) =>
                  setData({ ...data, flgAmbienteProducaoApiBoletoPix })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={100}
                label='URL Token'
                text={data.noUrlApiAuthenticate}
                onChangedValue={(noUrlApiAuthenticate: string) =>
                  setData({ ...data, noUrlApiAuthenticate })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={100}
                label='URL Api Boleto Cobrança'
                text={data.noUrlApiBoleto}
                onChangedValue={(noUrlApiBoleto: string) =>
                  setData({ ...data, noUrlApiBoleto })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={100}
                label='URL Api Pix'
                text={data.noUrlApiPix}
                onChangedValue={(noUrlApiPix: string) =>
                  setData({ ...data, noUrlApiPix })
                }
              />
            </div>
          </div>
          <div className='row mt-1'>
            <div className='col-7 mb-3'>
              <Textbox
                maxLength={200}
                label='ApiKey'
                text={data.noApiKey}
                onChangedValue={(noApiKey: string) =>
                  setData({ ...data, noApiKey })
                }
              />
            </div>
          </div>
          <div className='row mt-1'>
            <div className='col-7 mb-3'>
              <Textbox
                maxLength={400}
                label='Cliente Id'
                text={data.noClientId}
                onChangedValue={(noClientId: string) =>
                  setData({ ...data, noClientId })
                }
              />
            </div>
          </div>
          <div className='row mt-1'>
            <div className='col-7 mb-3'>
              <Textbox
                maxLength={400}
                label='Cliente Secret'
                text={data.noClientSecret}
                onChangedValue={(noClientSecret: string) =>
                  setData({ ...data, noClientSecret })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header title='Forma Pagamento Empresa' theme={Theme.Primary} />
        <Panel.Body>
          <Panel>
            <Panel.Header title='Empresa' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-6 mt-3'>
                  <Autocomplete
                    label='Empresa'
                    searchDataSource={onSearchEmpresa}
                    selectedItem={dataFormaPagamentoEmpresa.empresa}
                    onSelectItem={(empresa: any) => {
                      setDataFormaPagamentoEmpresa({
                        ...dataFormaPagamentoEmpresa,
                        empresa,
                        nrSeqEmpresa: empresa.nrSeqEmpresa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
          {data?.flgTitRec && (
            <Panel>
              <Panel.Header title='Recebimento' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Conta Corrente'
                      searchDataSource={onSearchContaCorrenteRec}
                      selectedItem={dataFormaPagamentoEmpresa.contaCorrenteRec}
                      onSelectItem={(contaCorrenteRec: any) => {
                        setDataFormaPagamentoEmpresa({
                          ...dataFormaPagamentoEmpresa,
                          contaCorrenteRec,
                          nrSeqContaCorrenteRec:
                            contaCorrenteRec.nrSeqContaCorrente,
                        });
                      }}
                      dataSourceTextProperty='noContaCorrente'
                    />
                  </div>
                  <div className='col-1'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={dataFormaPagamentoEmpresa?.flgGeraExtratoRec}
                      label='Gera Extrato'
                      onChange={(flgGeraExtratoRec: boolean) =>
                        setDataFormaPagamentoEmpresa({
                          ...dataFormaPagamentoEmpresa,
                          flgGeraExtratoRec,
                        })
                      }
                    />
                  </div>
                  <div className='col-7'>
                    <Autocomplete
                      label='Plano Conta'
                      searchDataSource={onSearchPlanoContaRec}
                      selectedItem={dataFormaPagamentoEmpresa.planoDeContaRec}
                      onSelectItem={(planoDeContaRec: any) => {
                        setDataFormaPagamentoEmpresa({
                          ...dataFormaPagamentoEmpresa,
                          planoDeContaRec,
                          nrSeqPlanoDeContaRec:
                            planoDeContaRec.nrSeqPlanoDeConta,
                        });
                      }}
                      dataSourceTextProperty='cdCaminhoCompleto'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}
          {data?.flgTitPag && (
            <Panel>
              <Panel.Header title='Pagamento' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Conta Corrente'
                      searchDataSource={onSearchContaCorrentePag}
                      selectedItem={dataFormaPagamentoEmpresa.contaCorrentePag}
                      onSelectItem={(contaCorrentePag: any) => {
                        setDataFormaPagamentoEmpresa({
                          ...dataFormaPagamentoEmpresa,
                          contaCorrentePag,
                          nrSeqContaCorrentePag:
                            contaCorrentePag.nrSeqContaCorrente,
                        });
                      }}
                      dataSourceTextProperty='noContaCorrente'
                    />
                  </div>
                  <div className='col-1'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={dataFormaPagamentoEmpresa?.flgGeraExtratoPag}
                      label='Gera Extrato'
                      onChange={(flgGeraExtratoPag: boolean) =>
                        setDataFormaPagamentoEmpresa({
                          ...dataFormaPagamentoEmpresa,
                          flgGeraExtratoPag,
                        })
                      }
                    />
                  </div>
                  <div className='col-7'>
                    <Autocomplete
                      label='Plano Conta'
                      searchDataSource={onSearchPlanoContaPag}
                      selectedItem={dataFormaPagamentoEmpresa.planoDeContaPag}
                      onSelectItem={(planoDeContaPag: any) => {
                        setDataFormaPagamentoEmpresa({
                          ...dataFormaPagamentoEmpresa,
                          planoDeContaPag,
                          nrSeqPlanoDeContaPag:
                            planoDeContaPag.nrSeqPlanoDeConta,
                        });
                      }}
                      dataSourceTextProperty='cdCaminhoCompleto'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}
          <div className='row mt-2'>
            <div className='col-2 mb-3'>
              <Button
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Success}
                template={Button.Templates.Quick}
                onClick={onAdicionarFormaPagamentoEmpresa}
                text='Adicionar'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <GridView
                ref={gridViewFormaPagamentoEmpresa}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
                offlineData
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {data?.flgGrupo === 'G' && (
        <Panel>
          <Panel.Header title='Boleto' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-8'>
                {/* @ts-expect-error */}
                <RadioButton
                  outline
                  label='Juros'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={statusBoleto}
                  selectedButton={
                    'flgBoletoModelo' in data ? data.flgBoletoModelo : null
                  }
                  onChange={handleBancoChange}
                />
              </div>
            </div>
            <Panel>
              <Panel.Header title='Cobrança' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Switch
                      formControl
                      checked={data?.flgBoletoDebitoEmConta}
                      label='Boleto Débito em Conta'
                      onChange={(flgBoletoDebitoEmConta: boolean) =>
                        setData({ ...data, flgBoletoDebitoEmConta })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Switch
                      formControl
                      checked={data?.flgGeraPix}
                      label='Gera Pix'
                      onChange={(flgGeraPix: boolean) =>
                        setData({ ...data, flgGeraPix })
                      }
                    />
                  </div>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Aceite'
                      searchDataSource={onSearchAceiteWrapper}
                      selectedItem={
                        aceiteOptions[data.flgBoletoModelo]?.find(
                          (option) => option.value === data.boletoAceite
                        ) || null
                      }
                      onSelectItem={handleAceiteSelect}
                      dataSourceTextProperty='label'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Carteira de Cobrança'
                      searchDataSource={onSearchCarteiraWrapper}
                      selectedItem={
                        boletoCarteiraCobrancaOptions[
                          data.flgBoletoModelo
                        ]?.find(
                          (option) =>
                            option.value === data.boletoCarteiraCobranca
                        ) || null
                      }
                      onSelectItem={handleCarteiraSelect}
                      dataSourceTextProperty='label'
                    />
                  </div>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Código da Moeda'
                      searchDataSource={onSearchCodMoedaWrapper}
                      selectedItem={
                        codMoedaOptions[data.flgBoletoModelo]?.find(
                          (option) => option.value === data.boletoCodMoeda
                        ) || null
                      }
                      onSelectItem={handleCodMoedaSelect}
                      dataSourceTextProperty='label'
                    />
                  </div>
                  <div className='col-4 mb-3'>
                    {/* @ts-expect-error */}
                    <Textbox
                      maxLength={6}
                      label='Modalidade Pag.'
                      mask={MaskTypes.Integer}
                      text={data.nrModalidadePagamento}
                      onChangedValue={(nrModalidadePagamento: number) =>
                        setData({ ...data, nrModalidadePagamento })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Variação de Carteira'
                      searchDataSource={onSearchVariacaoCarteiraWrapper}
                      selectedItem={
                        variacaoCarteiraOptions[data.flgBoletoModelo]?.find(
                          (option) =>
                            option.value === data.boletoVariacaoCarteira
                        ) || null
                      }
                      onSelectItem={handleVariacaoCarteiraSelect}
                      dataSourceTextProperty='label'
                    />
                  </div>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Espécie de Título'
                      searchDataSource={onSearchEspecieTituloWrapper}
                      selectedItem={
                        especieTituloOptions[data.flgBoletoModelo]?.find(
                          (option) => option.value === data.boletoEspTitulo
                        ) || null
                      }
                      onSelectItem={handleEspecieTituloSelect}
                      dataSourceTextProperty='label'
                    />
                  </div>
                  ;
                </div>
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Header title='Instruções' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Primeiro Código'
                      searchDataSource={onSearchInstrucaoCod01Wrapper}
                      selectedItem={
                        boletoInstrucaoCod01Options[data.flgBoletoModelo]?.find(
                          (option) => option.value === data.boletoInstrucaoCod01
                        ) || null
                      }
                      onSelectItem={handleInstrucaoCod01Select}
                      dataSourceTextProperty='label'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4 mb-4'>
                    <Autocomplete
                      label='Segundo Código'
                      searchDataSource={onSearchInstrucaoCod02Wrapper}
                      selectedItem={
                        boletoInstrucaoCod02Options[data.flgBoletoModelo]?.find(
                          (option) => option.value === data.boletoInstrucaoCod02
                        ) || null
                      }
                      onSelectItem={handleInstrucaoCod02Select}
                      dataSourceTextProperty='label'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Header title='Mensagens' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-7 mb-3'>
                    <Textbox
                      maxLength={60}
                      label='Msg 01'
                      text={data.boletoMsg01}
                      onChangedValue={(boletoMsg01: string) =>
                        setData({ ...data, boletoMsg01 })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-7 mb-3'>
                    <Textbox
                      maxLength={60}
                      label='Msg 02'
                      text={data.boletoMsg02}
                      onChangedValue={(boletoMsg02: string) =>
                        setData({ ...data, boletoMsg02 })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-7 mb-3'>
                    <Textbox
                      maxLength={60}
                      label='Msg 03'
                      text={data.boletoMsg03}
                      onChangedValue={(boletoMsg03: string) =>
                        setData({ ...data, boletoMsg03 })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-7 mb-3'>
                    <Textbox
                      maxLength={60}
                      label='Mora'
                      text={data.boletoMsgMora}
                      onChangedValue={(boletoMsgMora: string) =>
                        setData({ ...data, boletoMsgMora })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Header title='Nosso Número' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-4 mb-3'>
                    <Textbox
                      maxLength={18}
                      label='Atual'
                      mask={MaskTypes.Integer}
                      text={data.nossoNumeroAtual}
                      onChangedValue={(nossoNumeroAtual: number) =>
                        setData({ ...data, nossoNumeroAtual })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={data?.flgBoletoCadastro}
                      label='Cadastro'
                      onChange={(flgBoletoCadastro: boolean) =>
                        setData({ ...data, flgBoletoCadastro })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Tipo'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusTipo}
                      selectedButton={
                        'flgBoletoTipo' in data ? data.flgBoletoTipo : null
                      }
                      onChange={(flgBoletoTipo: string) =>
                        setData({ ...data, flgBoletoTipo })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Emissão'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusEmissao}
                      selectedButton={
                        'flgBoletoEmissao' in data
                          ? data.flgBoletoEmissao
                          : null
                      }
                      onChange={(flgBoletoEmissao: string) =>
                        setData({ ...data, flgBoletoEmissao })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Distribuição'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusDistribuicao}
                      selectedButton={
                        'flgBoletoDistribuicao' in data
                          ? data.flgBoletoDistribuicao
                          : null
                      }
                      onChange={(flgBoletoDistribuicao: string) =>
                        setData({ ...data, flgBoletoDistribuicao })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Protesto'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusProtesto}
                      selectedButton={
                        'flgBoletoProtestoTipo' in data
                          ? data.flgBoletoProtestoTipo
                          : null
                      }
                      onChange={(flgBoletoProtestoTipo: string) =>
                        setData({ ...data, flgBoletoProtestoTipo })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={4}
                      label='Prazo de Protesto'
                      mask={MaskTypes.Integer}
                      text={data.boletoProtestoDias}
                      onChangedValue={(boletoProtestoDias: number) =>
                        setData({ ...data, boletoProtestoDias })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </Panel.Body>
        </Panel>
      )}
    </CSDManutPage>
  );
}
