// import Fornecedor from 'core/models/FIN/fornecedor';
import FormaPagamentoEmpresa from 'core/models/FIN/formaPagamento_Empresa';

export default class FormaPagamento {
  constructor(jsonObject = {}) {
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.noFormaPagamento = jsonObject.noFormaPagamento;
    this.flgGrupo = jsonObject.flgGrupo;
    this.flgControlaCheque = jsonObject.flgControlaCheque;
    this.flgGeraSequenciaRecibo = jsonObject.flgGeraSequenciaRecibo;
    this.flgGeraSequenciaReciboPag = jsonObject.flgGeraSequenciaReciboPag;
    this.flgJuroMultaManual = jsonObject.flgJuroMultaManual;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgVinculaFornecedor = jsonObject.flgVinculaFornecedor;
    this.cdFormaPagamento = jsonObject.cdFormaPagamento;
    this.nossoNumeroInicial = jsonObject.nossoNumeroInicial;
    this.nossoNumeroAtual = jsonObject.nossoNumeroAtual;
    this.nossoNumeroFinal = jsonObject.nossoNumeroFinal;
    this.flgPDV = jsonObject.flgPDV;
    this.flgTipoJuro = jsonObject.flgTipoJuro;
    this.flgJuroCapitalizado = jsonObject.flgJuroCapitalizado;
    this.flgBoletoModelo = jsonObject.flgBoletoModelo;
    this.flgBoletoCadastro = jsonObject.flgBoletoCadastro;
    this.flgBoletoTipo = jsonObject.flgBoletoTipo;
    this.flgBoletoEmissao = jsonObject.flgBoletoEmissao;
    this.flgBoletoDistribuicao = jsonObject.flgBoletoDistribuicao;
    this.flgBoletoProtestoTipo = jsonObject.flgBoletoProtestoTipo;
    this.boletoProtestoDias = jsonObject.boletoProtestoDias;
    this.boletoCarteiraCobranca = jsonObject.boletoCarteiraCobranca;
    this.boletoNrDocumento = jsonObject.boletoNrDocumento;
    this.boletoEspTitulo = jsonObject.boletoEspTitulo;
    this.boletoAceite = jsonObject.boletoAceite;
    this.boletoCodMoeda = jsonObject.boletoCodMoeda;
    this.boletoVariacaoCarteira = jsonObject.boletoVariacaoCarteira;
    this.boletoInstrucaoCod01 = jsonObject.boletoInstrucaoCod01;
    this.boletoInstrucaoCod02 = jsonObject.boletoInstrucaoCod02;
    this.boletoMsg01 = jsonObject.boletoMsg01;
    this.boletoMsg02 = jsonObject.boletoMsg02;
    this.boletoMsg03 = jsonObject.boletoMsg03;
    this.boletoMsgMora = jsonObject.boletoMsgMora;
    this.flgTitRec = jsonObject.flgTitRec;
    this.flgTitPag = jsonObject.flgTitPag;
    this.flgGeraTitulo = jsonObject.flgGeraTitulo;
    this.flgLiqTitulo = jsonObject.flgLiqTitulo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.vlrJuro = jsonObject.vlrJuro;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrDescontoAntecipado = jsonObject.vlrDescontoAntecipado;
    this.noVersaoLayout = jsonObject.noVersaoLayout;
    this.nrCaracteresCdCliente = jsonObject.nrCaracteresCdCliente;
    this.floatBancario = jsonObject.floatBancario;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgVinculaFornecedor = jsonObject.flgVinculaFornecedor;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.flgBoletoDebitoEmConta = jsonObject.flgBoletoDebitoEmConta;
    this.flgJuroMultaManual = jsonObject.flgJuroMultaManual;
    this.densidadeArquivo = jsonObject.densidadeArquivo;
    this.formatoEnvio = jsonObject.formatoEnvio;
    this.noDensidadeArquivo = jsonObject.noDensidadeArquivo;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.carenciaJuros = jsonObject.carenciaJuros;
    this.carenciaMulta = jsonObject.carenciaMulta;
    this.flgJuroIGPM = jsonObject.flgJuroIGPM;
    this.flgAcrescentaJuro = jsonObject.flgAcrescentaJuro;
    this.mesJuroIGPM = jsonObject.mesJuroIGPM;
    this.anoJuroIGPM = jsonObject.anoJuroIGPM;
    this.indiceIGPM = jsonObject.indiceIGPM;
    this.jurosMora = jsonObject.jurosMora;
    this.flgVeiculo = jsonObject.flgVeiculo;
    this.vlrTaxaBancaria = jsonObject.vlrTaxaBancaria;
    this.msgDocumento = jsonObject.msgDocumento;
    this.msgBoleto = jsonObject.msgBoleto;
    this.flgDescTaxaBancaria = jsonObject.flgDescTaxaBancaria;
    this.nrSefaz = jsonObject.nrSefaz;
    this.flgControlaCheque = jsonObject.flgControlaCheque;
    this.flgGeraPix = jsonObject.flgGeraPix;
    this.noUrlApiAuthenticate = jsonObject.noUrlApiAuthenticate;
    this.noUrlApiBoleto = jsonObject.noUrlApiBoleto;
    this.noUrlApiPix = jsonObject.noUrlApiPix;
    this.flgAmbienteProducaoApiBoletoPix =
      jsonObject.flgAmbienteProducaoApiBoletoPix;
    this.noApiKey = jsonObject.noApiKey;
    this.noClientId = jsonObject.noClientId;
    this.noClientSecret = jsonObject.noClientSecret;
    this.noClient = jsonObject.noClient;
    this.nrModalidadePagamento = jsonObject.nrModalidadePagamento;
    this.dtTokenApi = jsonObject.dtTokenApi;
    this.noTokenApi = jsonObject.noTokenApi;
    this.formaPagamentoEmpresas = jsonObject.formaPagamentoEmpresas
      ? jsonObject.formaPagamentoEmpresas.map(
          (item) => new FormaPagamentoEmpresa(item ?? {})
        )
      : [];
    import('core/models/FIN/fornecedor').then(({ default: Fornecedor }) => {
      this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    });
  }

  toJson = () => JSON.stringify(this);
}
