import React, {
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Button,
  Notification,
  FileUpload,
  Loader,
  Autocomplete,
  Panel,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { MimeTypes } from 'ui/Helpers/utils';

import { importCtes, buscaMotoristaEVeiculos } from 'core/services/FRO/viagem';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getEspecieFreteAutoComplete } from 'core/services/FRO/especieFrete';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import { getMotoristaViagemAutoComplete } from 'core/services/FRO/motorista';

import {
  TipoEmbarque,
  Viagem,
  TipoFrete,
  EspecieFrete,
  Veiculo,
  Motorista,
} from 'core/models/FRO';
import { Empresa } from 'core/models/SEG';

interface Props {
  props: any;
  show: boolean;
  viagem: Viagem;
  onClose: () => void;
  carregaViagens: (retorno: [Array<Viagem>, Message]) => void;
}

const ModalImportarCtes = forwardRef(
  (
    { props, show, viagem, onClose, carregaViagens }: Props,
    param: ForwardedRef<any>
  ) => {
    const [loading, setLoading] = useState<Boolean>(false);
    const [message, setMessage] = useState<Message | null>(null);
    const [data, setData] = useState<Viagem>(new Viagem({}));
    const [files, setFiles] = useState<Array<any>>([]);

    useImperativeHandle(param, () => ({
      param: files,
    }));

    const load = async (): Promise<void> => {
      setLoading(true);

      const { empresas } = await getEmpresaDefaultUsuario();

      setFiles([]);

      const newViagem = {
        ...viagem,
      };

      if (empresas.length > 0) {
        const [primeiraEmpresa] = empresas;

        newViagem.unidOrigem = primeiraEmpresa;
      }

      setData(newViagem);

      setLoading(false);
    };

    useEffect(() => {
      if (show) load();
    }, [show, props]);

    const importaCtes = async (): Promise<void> => {
      setLoading(true);

      if (files.length > 0) {
        const { status, message: msg, data: retorno } = await importCtes(
          data,
          files
        );

        if (msg)
          setMessage({
            message: msg,
            theme:
              status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          });

        if (status === ResponseStatus.Success) {
          setData(new Viagem({}));
          setFiles([]);
          setLoading(false);

          carregaViagens(retorno);
        }
      } else {
        setMessage({
          message: 'Não há arquivo selecionado para importação',
          theme: Theme.Danger,
        });
      }

      setLoading(false);
    };

    const onSearchTipoEmbarque = async (e: string) => {
      const {
        status,
        message: msg,
        data: tiposEmbarque,
      } = await getTipoEmbarqueAutoComplete({
        noTipoEmbarque: e,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      return tiposEmbarque;
    };

    const onSearchEspecieFrete = async (e: string) => {
      const {
        status,
        message: msg,
        data: especiesFrete,
      } = await getEspecieFreteAutoComplete({
        noEspecieFrete: e,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      return especiesFrete;
    };

    const onSearchTipoFrete = async (e: string) => {
      const {
        status,
        message: msg,
        tiposFrete,
      } = await getTipoFreteAutoComplete({
        noTipoFrete: e,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      return tiposFrete;
    };

    const onSearchVeiculo = async (e: string) => {
      const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
        placa: e,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      return veiculos;
    };

    const onSend = (): void => {
      onClose();
    };

    const onAutoCompleteEmpresa = async (e: string) => {
      const { status, message: msg, empresas } = await getEmpresaAutoComplete({
        noPessoa: e,
      });

      if (msg)
        setMessage({
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          message: msg,
        });

      return empresas;
    };

    const onSearchMotorista = async (e: string) => {
      const {
        status,
        message: msg,
        motoristas,
      } = await getMotoristaViagemAutoComplete({
        noPessoa: e,
        nrSeqVeiculo: data.veiculoPrincipal?.nrSeqVeiculo,
      });
      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      return motoristas;
    };

    const onSelectedVeiculo = async (
      veiculoPrincipal: Veiculo
    ): Promise<void> => {
      setLoading(true);

      const { status, message: msg, value } = await buscaMotoristaEVeiculos({
        nrSeqVeiculo: veiculoPrincipal.nrSeqVeiculo,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      setData({
        ...data,
        motorista: value?.motorista,
        nrSeqPessoaMot: value?.motorista?.nrSeqPessoaMot,
        veiculoPrincipal,
        nrSeqVeiculoPrinc: veiculoPrincipal.nrSeqVeiculo,
      });

      setLoading(false);
    };

    return (
      // @ts-expect-error
      <Modal
        show={show}
        icon='cloud-upload-alt'
        size={BootstrapSizes.Large}
        title='Importar Ctes'
        onClose={() => onSend()}
      >
        <Loader loading={loading} />
        {message && (
          <div className='m-3'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {/* @ts-expect-error */}
        <Modal.Body>
          <div className='row mb-3'>
            <div className='col-12'>
              <Autocomplete
                label='Unid. Origem'
                searchDataSource={onAutoCompleteEmpresa}
                selectedItem={data?.unidOrigem}
                onSelectItem={(unidOrigem: Empresa) => {
                  setData({
                    ...data,
                    unidOrigem,
                    nrSeqEmpresa: unidOrigem.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Tipo Embarque'
                required
                searchDataSource={onSearchTipoEmbarque}
                selectedItem={data?.coletaEmbarque?.tipoEmbarque}
                onSelectItem={(tipoEmbarque: TipoEmbarque) => {
                  setData({
                    ...data,
                    coletaEmbarque: {
                      ...data?.coletaEmbarque,
                      tipoEmbarque,
                      nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                    },
                  });
                }}
                dataSourceTextProperty='noTipoEmbarque'
              />
            </div>
            <div className='col-6'>
              <Autocomplete
                label='Tipo de Frete'
                required
                searchDataSource={onSearchTipoFrete}
                selectedItem={data?.coletaEmbarque?.tipoFrete}
                onSelectItem={(tipoFrete: TipoFrete) => {
                  setData({
                    ...data,
                    coletaEmbarque: {
                      ...data?.coletaEmbarque,
                      tipoFrete,
                      nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
                    },
                  });
                }}
                dataSourceTextProperty='noTipoFrete'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Espécie Frete'
                required
                searchDataSource={onSearchEspecieFrete}
                selectedItem={data?.coletaEmbarque?.especieFrete}
                onSelectItem={(especieFrete: EspecieFrete) => {
                  setData({
                    ...data,
                    coletaEmbarque: {
                      ...data?.coletaEmbarque,
                      especieFrete,
                      nrSeqEspecieFrete: especieFrete.nrSeqEspecieFrete,
                    },
                  });
                }}
                dataSourceTextProperty='noEspecieFrete'
              />
            </div>
            <div className='col-6'>
              <Autocomplete
                label='Veículo Principal (Caso não informado no XML)'
                required
                searchDataSource={onSearchVeiculo}
                selectedItem={data?.veiculoPrincipal}
                onSelectItem={onSelectedVeiculo}
                dataSourceTextProperty='placa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Motorista'
                searchDataSource={onSearchMotorista}
                selectedItem={data?.motorista}
                onSelectItem={(motorista: Motorista) => {
                  setData({
                    ...data,
                    motorista,
                    nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          {/* @ts-expect-error */}
          <Panel className='mb-3'>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Conhecimentos (XML)'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <FileUpload
                  files={files}
                  onChange={(items: any) => {
                    setFiles(items);
                    setMessage(null);
                  }}
                  allowedMimeTypes={[
                    MimeTypes.Types.XML,
                    MimeTypes.Types.Zip,
                    MimeTypes.Types.PDF,
                  ]}
                  maxFiles={500}
                  dropzone
                />
              </div>
            </Panel.Body>
          </Panel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text='Importar'
            icon='cloud-upload-alt'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            onClick={importaCtes}
          />
          <Button
            text='Sair'
            icon={['fas', 'times']}
            theme={Theme.Danger}
            size={BootstrapSizes.Medium}
            onClick={() => onSend()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ModalImportarCtes;
