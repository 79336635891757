import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Switch,
  Button,
  GridView,
  Autocomplete,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAlmoxarifado,
  saveAlmoxarifado,
  deleteAlmoxarifado,
} from 'core/services/EST/almoxarifado';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';
import AlmoxarifadoEmpresa from 'core/models/EST/almoxarifadoEmpresa';
import AlmoxarifadoUsuario from 'core/models/EST/almoxarifadoUsuario';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

import { StatusGrid } from 'ui/Helpers/enums.ts';

export default function AlmoxarifadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const gridViewEmpresas = useRef();
  const gridViewUsuario = useRef();
  const [data, setData] = useState({});
  const [dataAlmoxarifadoEmpresa, setDataAlmoxarifadoEmpresa] = useState(
    new AlmoxarifadoEmpresa({})
  );
  const [dataAlmoxarifadoUsuario, setDataAlmoxarifadoUsuario] = useState(
    new AlmoxarifadoUsuario({})
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onRemoveEmpresa = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewEmpresas && gridViewEmpresas.current)
      gridViewEmpresas.current.setDataSource(datasource);
  };
  const onAdicionarAlmoxarifadoEmpresa = async () => {
    if (dataAlmoxarifadoEmpresa.nrSeqEmpresa > 0) {
      const obj = {
        ...dataAlmoxarifadoEmpresa,
        status: dataAlmoxarifadoEmpresa.status ?? StatusGrid.Inserir,
      };

      const list = gridViewEmpresas.current.getDataSource();
      list.push(obj);

      if (gridViewEmpresas && gridViewEmpresas.current)
        gridViewEmpresas.current.setDataSource(list);

      setData({
        ...data,
        almoxarifadoEmpresas: list,
      });
      setDataAlmoxarifadoEmpresa({});
    } else if (
      dataAlmoxarifadoEmpresa.nrSeqEmpresa === undefined ||
      dataAlmoxarifadoEmpresa.nrSeqEmpresa === null
    ) {
      onSetMessage(ResponseStatus.Error, 'Informe a Empresa');
    }
  };

  const onRemoveUsuario = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewUsuario && gridViewUsuario.current)
      gridViewUsuario.current.setDataSource(datasource);
  };

  const onAdicionarAlmoxarifadoUsuario = async () => {
    if (dataAlmoxarifadoUsuario.nrSeqUsuario > 0) {
      const obj = {
        ...dataAlmoxarifadoUsuario,
        status: dataAlmoxarifadoUsuario.status ?? StatusGrid.Inserir,
      };

      const list = gridViewUsuario.current.getDataSource();
      list.push(obj);

      if (gridViewUsuario && gridViewUsuario.current)
        gridViewUsuario.current.setDataSource(list);

      setData({
        ...data,
        almoxarifadoUsuarios: list,
      });
      setDataAlmoxarifadoUsuario({});
    } else if (
      dataAlmoxarifadoUsuario.nrSeqUsuario === undefined ||
      dataAlmoxarifadoUsuario.nrSeqUsuario === null
    ) {
      onSetMessage(ResponseStatus.Error, 'Informe o Usuário');
    }
  };

  const columnsEmpresas = [
    {
      key: 'nrSeqEmpresa',
      title: 'Código',
      visible: false,
    },
    { key: 'empresa.noPessoa', title: 'Empresa' },
    {
      key: 'nrSeqEmpresa',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveEmpresa(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsUsuario = [
    {
      key: 'nrSeqUsuario',
      title: 'Código',
      visible: false,
    },
    { key: 'usuario.noPessoa', title: 'Usuário' },
    {
      key: 'nrSeqUsuario',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveUsuario(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onNew = () => {
    setData({});
    setDataAlmoxarifadoEmpresa({});
    setDataAlmoxarifadoUsuario({});
    if (gridViewEmpresas && gridViewEmpresas.current)
      gridViewEmpresas.current.setDataSource([]);

    if (gridViewUsuario && gridViewUsuario.current)
      gridViewUsuario.current.setDataSource([]);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getAlmoxarifado(registryKey);

      setData(retorno);

      if (gridViewEmpresas && gridViewEmpresas.current)
        gridViewEmpresas.current.setDataSource(retorno.almoxarifadoEmpresas);

      if (gridViewUsuario && gridViewUsuario.current)
        gridViewUsuario.current.setDataSource(retorno.almoxarifadoUsuarios);

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveAlmoxarifado(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData(status === ResponseStatus.Success ? {} : data);
      onNew();
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAlmoxarifado(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      onNew();
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Almoxarifado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqAlmoxarifado > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-1'>
          <Textbox
            required
            maxLength={2}
            readOnly
            label='Código'
            text={data.cdAlmoxarifado}
            mask={MaskTypes.Integer}
            onChangedValue={(cdAlmoxarifado) =>
              setData({ ...data, cdAlmoxarifado })
            }
          />
        </div>

        <div className='col-5'>
          <Textbox
            maxLength={40}
            label='Almoxarifado'
            text={data.noAlmoxarifado}
            onChangedValue={(noAlmoxarifado) =>
              setData({ ...data, noAlmoxarifado })
            }
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            label='Estoque Negativo'
            checked={data.flgEstoqueNegativo}
            onChange={(flgEstoqueNegativo) =>
              setData({ ...data, flgEstoqueNegativo })
            }
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            label='Gera Itens Automático'
            checked={data.flgCadAutomatico}
            onChange={(flgCadAutomatico) =>
              setData({ ...data, flgCadAutomatico })
            }
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            label='Pendente Ajuste'
            checked={data.flgPendenteAjuste}
            onChange={(flgPendenteAjuste) =>
              setData({ ...data, flgPendenteAjuste })
            }
          />
        </div>
      </div>

      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Empresas'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-6 mb-3'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={dataAlmoxarifadoEmpresa.empresa}
                  onSelectItem={(empresa) => {
                    setDataAlmoxarifadoEmpresa({
                      ...dataAlmoxarifadoEmpresa,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-auto'>
                <div className='widget-square text-white p-3 br-tl-1 br-bl-1'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onAdicionarAlmoxarifadoEmpresa}
                    text='Adicionar'
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <GridView
                  ref={gridViewEmpresas}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsEmpresas}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Usuários Com Acesso'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div>
                {' '}
                Caso queira que todos usuários com acesso vizualizem este
                almoxarifado, não adicione usuários{' '}
              </div>
              <div className='col-6'>
                <Autocomplete
                  label='Usuário'
                  searchDataSource={onSearchUsuario}
                  selectedItem={dataAlmoxarifadoUsuario.usuario}
                  onSelectItem={(usuario) => {
                    setDataAlmoxarifadoUsuario({
                      ...dataAlmoxarifadoUsuario,
                      usuario,
                      nrSeqUsuario: usuario.nrSeqUsuario,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-auto'>
                <div className='widget-square text-white p-3 br-tl-1 br-bl-1'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onAdicionarAlmoxarifadoUsuario}
                    text='Adicionar'
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <GridView
                  ref={gridViewUsuario}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsUsuario}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
