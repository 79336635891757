/* eslint-disable import/extensions */
import { DBO } from 'core/models/SEG';

import PocOrcamentoTipoDocumento from 'core/models/POC/pocOrcamentoTipoDocumento';

import EnergiaUnidadeConsumidora from '../ENE/energiaUnidadeConsumidora';

export default class PocOrcamentoDocumento extends DBO {
  nrSeqPocOrcamentoDocumento: number;

  nrSeqPocOrcamento: number;

  noImagem: string;

  noImagemBase64: string;

  noTipoArquivo: string;

  noTamanho: string;

  dtCadastro: Date;

  noArquivo: string;

  nrSeqPocOrcamentoTipoDocumento: number;

  noObservacoes: string;

  tipoDocumento: PocOrcamentoTipoDocumento;

  noChaveClickSign: string;

  flgAssinadoClickSign: boolean;

  uc: EnergiaUnidadeConsumidora;

  constructor(jsonObject: PocOrcamentoDocumento) {
    super(jsonObject);
    this.nrSeqPocOrcamentoDocumento = jsonObject.nrSeqPocOrcamentoDocumento;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.noImagem = jsonObject.noImagem;
    this.noImagemBase64 = jsonObject.noImagemBase64;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.dtCadastro = jsonObject.dtCadastro;
    this.noArquivo = jsonObject.noArquivo;
    this.nrSeqPocOrcamentoTipoDocumento =
      jsonObject.nrSeqPocOrcamentoTipoDocumento;
    this.noObservacoes = jsonObject.noObservacoes;

    this.tipoDocumento = new PocOrcamentoTipoDocumento(
      jsonObject.tipoDocumento ?? {}
    );
    this.noChaveClickSign = jsonObject.noChaveClickSign;
    this.flgAssinadoClickSign = jsonObject.flgAssinadoClickSign;
    this.uc = new EnergiaUnidadeConsumidora(jsonObject.uc ?? {});
  }

  toJson = () => JSON.stringify(this);
}
