import React, { useState, useRef, useEffect } from 'react';
import {
  getAparelhoAutoCompleteForDemanda,
  getChipAutoComplete,
  getClienteLinhasAutoCompleteResumido,
} from 'core/services/TEL';
import { GridviewColumns } from 'ui/Helpers/interfaces';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Textarea,
  GridView,
  Button,
  RadioButton,
} from 'ui/components';
import ModalRegistrarChipNovo from './modalRegistrarChipNovo';
import ImportadorDocumentosDemanda from '../ComponentesDemanda/importadorDocumentosDemanda';

export default function ManutencaoDefaultMassivo({
  data,
  setData,
  onSetMessage,
  setDataMassivo,
  dataMassivo,
  onSearchCliente,
  onSelectCliente,
  addFiles,
  documentos,
  setDocumentos,
  setDataListDocRemove,
  setLoading,
}: any) {
  const gridView = useRef<any>();
  const gridViewLinesFromClient = useRef<any>();
  const [showModalCadastroChipNovo, setShowModalCadastroChipNovo] = useState(
    false
  );
  const [linhasInfo, setLinhasInfo] = useState<string>('');
  const [gridHasItem, setGridHasItem] = useState<boolean>(false);
  const [addLinhasMassiva, setAddLinhasMassiva] = useState<string>('V');

  const onSearchChip = async (e: string) => {
    const obj: any = {
      nrPrefixoLinha: e,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
    };

    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const { status, message: msg, chips } = await getChipAutoComplete(obj);

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const onSearchAparelho = async (e: string) => {
    const { aparelhos } = await getAparelhoAutoCompleteForDemanda({
      noAparelho: e,
    });

    return aparelhos;
  };

  const addLinhasMassivo = async () => {
    if (!data.nrSeqPessoaCli) {
      onSetMessage(ResponseStatus.Error, 'Informa o Cliente');

      return;
    }

    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Informa a Empresa');

      return;
    }

    const listaLinhas = linhasInfo
      .replace(/ {2}/g, '\t')
      .replace(/[()\-,. ]/g, '')
      .split(/\r?\n/)
      .map((e) => e.split(/\r?\t| +/))
      .filter((e) => e.length >= 1 && e[0] !== '');

    const linhasValidas = listaLinhas.every((linha) => linha[0].length === 11);

    if (linhasValidas) {
      const promises: Array<Promise<any>> = [];

      listaLinhas.forEach((linha) => {
        promises.push(
          getClienteLinhasAutoCompleteResumido({
            nrPrefixoLinha: linha[0],
            flgStatus: 'N',
            nrSeqPessoaCli: data.nrSeqPessoaCli,
          })
        );
      });

      const linhasNaoEncontradas: Array<string> = [];
      const newDataSourceItems: Array<any> = [];
      const dataSource = gridView?.current?.getDataSource() ?? [];

      const result = await Promise.all(promises);

      result.forEach(async (res, index) => {
        if (res.data.length > 0) {
          const clienteLinha = res.data[0];

          if (
            !dataSource.find(
              (item: any) =>
                item.nrSeqClienteLinha === clienteLinha.nrSeqClienteLinha
            )
          ) {
            const obj: any = {
              nrSeqTipoDemanda: data.nrSeqTipoDemanda,
              nrSeqStatusDemanda: data.nrSeqStatusDemanda,
              dtSolicitacao: data.dtSolicitacao,
              dtCad: data.dtCad,
              flgCobrarEncargoTipoDemanda: data.flgCobrarEncargoTipoDemanda,
              vlrEncargoTipoDemanda: data.vlrEncargoTipoDemanda,
              nrSeqEmpresa: data.nrSeqEmpresa,
              nrSeqPessoaCli: data.nrSeqPessoaCli,
              cliente: data.cliente,
              nrSeqChip: clienteLinha.nrSeqChip,
              chip: clienteLinha.chip,
              nrSeqClienteLinha: clienteLinha.nrSeqClienteLinha,
              nrSeqOperadoraDestino: clienteLinha.chip?.nrSeqOperadora,
              operadoraDestino: clienteLinha.chip?.operadora,
              status: 'Inserir',
            };

            if (data.tipoDemanda?.cdTipoDemanda?.includes('Troca de Chip')) {
              if (listaLinhas[index].length > 1) {
                // eslint-disable-next-line no-unused-vars
                const [_, iccid] = listaLinhas[index];
                obj.nrIccid = iccid;
              } else {
                obj.nrIccid = '';
              }
            } else if (
              data.tipoDemanda?.cdTipoDemanda.includes('Troca de Numero')
            ) {
              if (listaLinhas[index].length > 1) {
                // eslint-disable-next-line no-unused-vars
                const [_, novaLinha] = listaLinhas[index];

                const [chipsEncontrados] = await onSearchChip(novaLinha);

                if (chipsEncontrados.length === 1) {
                  const [chipNovo] = chipsEncontrados;

                  obj.nrSeqChipNovo = chipNovo.nrSeqChip;
                  obj.chipNovo = chipNovo;
                }
              }
            } else if (data.tipoDemanda?.flgTrocaAparelho) {
              if (listaLinhas[index].length > 1) {
                // eslint-disable-next-line no-unused-vars
                const [_, noAparelho] = listaLinhas[index];

                const [aparelhos] = await onSearchAparelho(noAparelho);

                if (aparelhos.length === 1) {
                  const [aparelho] = aparelhos;

                  obj.nrSeqAparelho = aparelho.nrSeqAparelho;
                  obj.aparelho = aparelho;
                }
              }
            }

            newDataSourceItems.push(obj);
          }
        } else {
          linhasNaoEncontradas.push(listaLinhas[index][0]);
        }
      });

      const newDataSource = [...dataSource, ...newDataSourceItems];

      setDataMassivo(newDataSource);

      if (linhasNaoEncontradas.length > 0) {
        const msg = `As seguintes linhas não foram encontradas vinculado ao cliente selecionado ou a uma empresa que voce tenha acesso. Linhas: ${linhasNaoEncontradas.join(
          ', '
        )}`;

        setLinhasInfo(linhasNaoEncontradas.join('\n'));

        onSetMessage(ResponseStatus.Error, msg);
      } else {
        setLinhasInfo('');
      }
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'As linhas devem conter 11 digitos contando o prefixo'
      );
    }
  };

  const updateIccid = async (keyValue: any, value: string, row: number) => {
    const dataSource = gridView.current.getDataSource() ?? [];

    const gridItem = dataSource.find(
      (item: any) => item.nrSeqClienteLinha === keyValue
    );

    if (gridItem.nrIccid !== value) {
      if (value.length !== 20) {
        onSetMessage(ResponseStatus.Error, 'O ICCID deve ter 20 caracteres');
      }

      gridItem.nrIccid = value;

      const dataSourceBuilded = gridView.current.getBuildedDataSource();

      const iccidIndex = dataSourceBuilded[row - 1].findIndex(
        (e: any) => e.key === 'nrIccid'
      );
      dataSourceBuilded[row - 1][iccidIndex].value = value;

      gridView?.current?.setBuildedDataSource(dataSourceBuilded);
    }
  };

  const updateNoObservacao = async (
    keyValue: any,
    value: string,
    row: number
  ) => {
    const dataSource = gridView.current.getDataSource() ?? [];

    const gridItem = dataSource.find(
      (item: any) => item.nrSeqClienteLinha === keyValue
    );

    if (gridItem.noObservacao !== value) {
      gridItem.noObservacao = value;

      const dataSourceBuilded = gridView.current.getBuildedDataSource();

      const index = dataSourceBuilded[row - 1].findIndex(
        (e: any) => e.key === 'noObservacao'
      );

      dataSourceBuilded[row - 1][index].value = value;

      gridView?.current?.setBuildedDataSource(dataSourceBuilded);
    }
  };

  const fNrIccid = (keyValue: any, value: string, row: number) => {
    updateIccid(keyValue, value, row);
  };

  const fNoObservacao = (keyValue: any, value: string, row: number) => {
    updateNoObservacao(keyValue, value, row);
  };

  const onRemoverLinha = (e: any, dataSource: any) => {
    const gridFiltered = dataSource.filter((item: any) => item !== e);

    setDataMassivo(gridFiltered);
  };

  const columnsTrocaChip: Array<GridviewColumns> = [
    { key: 'nrSeqClienteLinha', title: '', visible: false },
    { key: 'chip.nrPrefixoLinha', title: 'Linha', sortable: false },
    {
      key: 'operadoraDestino.noOperadora',
      title: 'Operadora',
      sortable: false,
    },
    {
      key: 'nrIccid',
      title: 'Novo Chip (ICCID)',
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNrIccid,
      sortable: false,
    },
    {
      key: 'noObservacao',
      title: 'Observação',
      sortable: false,
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNoObservacao,
    },
    {
      key: 'nrSeqClienteLinha',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onRemoverLinha(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover Linha',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsDefault: Array<GridviewColumns> = [
    { key: 'nrSeqClienteLinha', title: '', visible: false },
    { key: 'chip.nrPrefixoLinha', title: 'Linha', sortable: false },
    {
      key: 'operadoraDestino.noOperadora',
      title: 'Operadora',
      sortable: false,
    },
    {
      key: 'noObservacao',
      title: 'Observação',
      sortable: false,
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNoObservacao,
    },
    {
      key: 'nrSeqClienteLinha',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onRemoverLinha(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover Linha',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource(dataMassivo ?? []);
    }

    if (dataMassivo.length > 0) {
      setGridHasItem(true);
    } else {
      setGridHasItem(false);
    }
  }, [dataMassivo]);

  const searchClienteLinhas = async () => {
    setLoading(true);

    if (data.nrSeqPessoaCli) {
      const { data: linhas } = await getClienteLinhasAutoCompleteResumido({
        nrSeqPessoaCli: data.nrSeqPessoaCli,
        nrSeqEmpresa: data.nrSeqEmpresa ?? null,
        flgStatus: 'N',
      });

      if (linhas.length > 0) {
        gridViewLinesFromClient?.current?.setDataSource(linhas);

        setData({ ...data, chip: null, nrSeqChip: null });
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Nenhuma linha(chip) encontrada para esse cliente nesta empresa'
        );
      }
    } else {
      onSetMessage(ResponseStatus.Error, 'Informe o Cliente');
    }

    setLoading(false);
  };

  const addMultipleLinesButtons = [
    { text: 'Adicionar Lista de Linhas', value: 'V' },
    { text: 'Selecionar Linhas', value: 'F' },
  ];

  const addLinhasSelecionadas = () => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Informe a Empresa');

      return;
    }
    let selectedLines =
      gridViewLinesFromClient.current.getCheckBoxValuesAt(0) ?? [];

    if (selectedLines.length > 0) {
      const dataSource =
        gridViewLinesFromClient?.current?.getDataSource() ?? [];

      selectedLines = selectedLines.map(
        (column: any) =>
          column.find((prop: any) => prop.key === 'nrSeqClienteLinha').value
      );

      const filteredItemsToAdd = dataSource.filter(
        (item: any) =>
          selectedLines.includes(item.nrSeqClienteLinha) &&
          !dataMassivo.find(
            (addedItem: any) =>
              addedItem.nrSeqClienteLinha === item.nrSeqClienteLinha
          )
      );

      if (selectedLines.length !== filteredItemsToAdd.length) {
        onSetMessage(
          ResponseStatus.Error,
          'Algumas linhas já foram adicionadas'
        );
      }

      const itens = filteredItemsToAdd.map((clienteLinha: any) => {
        const item: any = {};
        item.nrSeqEmpresa = data.nrSeqEmpresa;
        item.dtSolicitacao = data.dtSolicitacao;
        item.nrSeqTipoDemanda = data.nrSeqTipoDemanda;
        item.nrSeqDemandaStatus = data.nrSeqDemandaStatus;
        item.noObservacao = data.noObservacao;
        item.nrSeqPessoaCli = data.nrSeqPessoaCli;
        // @ts-expect-error
        item.status = GridView.EnumStatus.Inserir;
        item.nrSeqClienteLinha = clienteLinha.nrSeqClienteLinha;
        item.nrSeqChip = clienteLinha.nrSeqChip;
        item.chip = clienteLinha.chip;
        item.nrSeqOperadoraDestino =
          clienteLinha.chip?.operadora?.nrSeqOperadora ?? null;
        item.operadoraDestino = clienteLinha.chip?.operadora;

        return item;
      });

      const addedLines = [...dataMassivo, ...itens];

      setDataMassivo(addedLines);

      dataSource.forEach((item: any) => {
        if (selectedLines.includes(item.nrSeqClienteLinha)) {
          // @ts-expect-error
          item.status = GridView.EnumStatus.Remover;
        }
      });

      gridViewLinesFromClient?.current?.setDataSource(dataSource);
    } else {
      onSetMessage(ResponseStatus.Error, 'Nenhuma linha selecionada');
    }
  };

  const columnsLinesFromClient = [
    // @ts-expect-error
    { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
    { key: 'chip.linhaFormatada', title: 'Linha' },
    { key: 'chip.operadora.noOperadora', title: 'Operadora' },
  ];

  return (
    <>
      <div className='row mb-3'>
        <div className='col-auto'>
          {/* @ts-expect-error */}
          <RadioButton
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={addMultipleLinesButtons}
            selectedButton={addLinhasMassiva}
            onChange={(value: string) => {
              setAddLinhasMassiva(value);
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            readOnly={gridHasItem}
            enabled={!gridHasItem}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={data.cliente}
            onSelectItem={(cliente: any) => {
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
              data.cliente = cliente;

              onSelectCliente(cliente);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        {addLinhasMassiva === 'F' && (
          <div className='col-2 d-flex align-items-end'>
            <Button
              outline
              visible={data.flgPermiteAlterar}
              icon='search'
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              style={{ flexGrow: 1 }}
              onClick={searchClienteLinhas}
              text='Buscar Linhas'
            />
          </div>
        )}
      </div>
      {addLinhasMassiva === 'V' ? (
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textarea
              label='Linhas'
              rows={4}
              text={linhasInfo}
              onChangedValue={(linhas: string) => {
                setLinhasInfo(linhas);
              }}
            />
          </div>
          <div className='col-2 d-flex align-items-end'>
            <Button
              outline
              visible={data.flgPermiteAlterar}
              icon='plus'
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              style={{ flexGrow: 1 }}
              onClick={addLinhasMassivo}
              text='Adicionar Linhas'
            />
          </div>
        </div>
      ) : (
        <>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewLinesFromClient}
                // @ts-expect-error
                dataColumns={columnsLinesFromClient}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Button
                outline
                visible={data.flgPermiteAlterar}
                icon='plus'
                size={BootstrapSizes.Medium}
                theme={Theme.Success}
                onClick={addLinhasSelecionadas}
                text='Adicionar Linhas Selecionadas'
              />
            </div>
          </div>
        </>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={
              data.tipoDemanda?.cdTipoDemanda.includes('Troca de Chip')
                ? columnsTrocaChip
                : columnsDefault
            }
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      {addFiles && (
        <ImportadorDocumentosDemanda
          data={data}
          documentos={documentos}
          setDocumentos={setDocumentos}
          setDataListDocRemove={setDataListDocRemove}
          onSetMessage={onSetMessage}
        />
      )}

      <ModalRegistrarChipNovo
        data={data}
        setData={setData}
        show={showModalCadastroChipNovo}
        onClose={() => setShowModalCadastroChipNovo(false)}
      />
    </>
  );
}
