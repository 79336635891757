import AlmoxarifadoEmpresa from 'core/models/EST/almoxarifadoEmpresa';
import AlmoxarifadoUsuario from 'core/models/EST/almoxarifadoUsuario';

export default class Almoxarifado {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdAlmoxarifado = jsonObject.cdAlmoxarifado;
    this.flgEstoqueNegativo = jsonObject.flgEstoqueNegativo;
    this.noAlmoxarifado = jsonObject.noAlmoxarifado;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.flgCadAutomatico = jsonObject.flgCadAutomatico;
    this.flgPendenteAjuste = jsonObject.flgPendenteAjuste;
    this.almoxarifadoEmpresas = jsonObject.almoxarifadoEmpresas
      ? jsonObject.almoxarifadoEmpresas.map(
          (item) => new AlmoxarifadoEmpresa(item ?? {})
        )
      : [];

    this.almoxarifadoUsuarios = jsonObject.almoxarifadoUsuarios
      ? jsonObject.almoxarifadoUsuarios.map(
          (item) => new AlmoxarifadoUsuario(item ?? {})
        )
      : [];

    // #region Propriedades auxiliares
    this.nrSeqAlmoxarifadoNew = jsonObject.nrSeqAlmoxarifadoNew;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
