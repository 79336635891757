import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Steps, ToolbarButtons } from 'ui/components';
import {
  getVeiculo,
  saveVeiculo,
  deleteVeiculo,
  printVeiculoData,
  printVeiculoHistorico,
  printVeiculoTransporteDetalhado,
} from 'core/services/FRO/veiculo';
import { getPneusAtivosByVeiculo } from 'core/services/FRO/pneuVeiculo';
import DetalhesVeiculoStep from './detalhesVeiculoStep';
import MotoristaStep from './motoristaStep';
import TipoCombustivelStep from './tipoCombustivelStep';
import EquipamentoVeiculoStep from './equipamentoVeiculoStep';
import ManutencaoPreventivaStep from './manutencaoPreventivaStep';
import CartaoStep from './cartaoStep';
import DocumentoStep from './documentosStep';
import VinculoVeiculoStep from './vinculoVeiculosStep';
import VinculoCentroDeCustos from './vinculoCentroDeCustos';

export default function VeiculoItem({
  onOpenReport,
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();

  const cadastroCartoes = useRef();
  const cadastroDocumentos = useRef();
  const cadastroEquipamentos = useRef();
  const cadastroManutencoesPreventiva = useRef();
  const cadastroMotoristas = useRef();
  const cadastroTiposCombustivel = useRef();
  const cadastroVeiculosVinculados = useRef();
  const cadastroVeiculo = useRef();
  const [reset, setReset] = useState(true);
  const [data, setData] = useState({
    flgAlienado: false,
    flgProprio: false,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  function getData() {
    return data;
  }

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    setData({
      flgAlienado: false,
      flgProprio: false,
      flgAtivo: true,
    });

    setReset(false);
  };

  const validateData = (obj) => {
    if (
      !obj.transportadora ||
      !obj.placa ||
      !obj.veiculoModelo ||
      !obj.cidade ||
      !obj.veiculoTipo ||
      !obj.tipoCarroceria ||
      (obj.flgAlienado && !obj.noAlienado) ||
      (obj.flgAlienado && !obj.dtFimAlienado)
    ) {
      setMessage({
        message: 'Preencha os campos obrigatórios',
        theme: Theme.Danger,
      });
      return false;
    }

    if (isNaN(data.nrOdometroInicial) || data.nrOdometroInicial === '') {
      data.nrOdometroInicial = 0;
    }

    if (isNaN(data.nrOdometroMaximo) || data.nrOdometroMaximo === '') {
      data.nrOdometroMaximo = 0;
    }

    if (isNaN(data.nrCargaMaxima) || data.nrCargaMaxima === '') {
      data.nrCargaMaxima = null;
    }

    if (isNaN(data.nrCapacidadeDeTanque) || data.nrCapacidadeDeTanque === '') {
      data.nrCapacidadeDeTanque = null;
    }

    if (isNaN(data.vlrCompra) || data.vlrCompra === '') {
      data.vlrCompra = null;
    }

    if (isNaN(data.vlrMercado) || data.vlrMercado === '') {
      data.vlrMercado = null;
    }

    if (isNaN(data.vlrVenda) || data.vlrVenda === '') {
      data.vlrVenda = null;
    }

    if (isNaN(data.anoFabricacao) || data.anoFabricacao === '') {
      data.anoFabricacao = null;
    }

    if (isNaN(data.anoModelo) || data.anoModelo === '') {
      data.anoModelo = null;
    }

    if (
      isNaN(data.vlrMediaMinimaAbastecimento) ||
      data.vlrMediaMinimaAbastecimento === ''
    ) {
      data.vlrMediaMinimaAbastecimento = null;
    }

    return true;
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const veiculo = await getVeiculo(registryKey);

      const pneusAtivos = await getPneusAtivosByVeiculo(veiculo.nrSeqVeiculo);

      setData({
        ...veiculo,
        pais: veiculo.cidade?.estado?.pais,
        nrSeqPais: veiculo.cidade?.estado?.nrSeqPais,
        estado: veiculo.cidade?.estado,
        nrSeqEstado: veiculo.cidade?.nrSeqEstado,
        veiculoMarca: veiculo.veiculoModelo?.veiculoMarca,
        pneusAtivos,
      });

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    if (validateData(data)) {
      const { status, message: msg } = await saveVeiculo(data);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        onNew();
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteVeiculo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printVeiculoData({
      nrSeqVeiculo: data.nrSeqVeiculo,
      flgPrincipal: data.veiculoTipo.flgPrincipal,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const StepDetalhe = () => (
    <div>
      <DetalhesVeiculoStep
        registryKey={registryKey}
        ref={cadastroVeiculo}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepMotorista = () => (
    <div>
      <MotoristaStep
        registryKey={registryKey}
        ref={cadastroMotoristas}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepTipoCombustivel = () => (
    <div>
      <TipoCombustivelStep
        registryKey={registryKey}
        ref={cadastroTiposCombustivel}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepVinculoVeiculos = () => (
    <div>
      <VinculoVeiculoStep
        registryKey={registryKey}
        ref={cadastroVeiculosVinculados}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
        setLoading={setLoading}
      />
    </div>
  );

  const StepEquipamentoVeiculo = () => (
    <div>
      <EquipamentoVeiculoStep
        registryKey={registryKey}
        ref={cadastroEquipamentos}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepManutencaoPreventiva = () => (
    <div>
      <ManutencaoPreventivaStep
        registryKey={registryKey}
        ref={cadastroManutencoesPreventiva}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepCartao = () => (
    <div>
      <CartaoStep
        registryKey={registryKey}
        ref={cadastroCartoes}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepDocumentos = () => (
    <div>
      <DocumentoStep
        registryKey={registryKey}
        ref={cadastroDocumentos}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepVinculaCentroDeCusto = () => (
    <div>
      <VinculoCentroDeCustos
        registryKey={registryKey}
        ref={cadastroDocumentos}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const step1DetalheVeiculo = React.createElement(StepDetalhe);
  const step2Motorista = React.createElement(StepMotorista);
  const step3TipoCombustivel = React.createElement(StepTipoCombustivel);
  const step4VinculoVeiculos = React.createElement(StepVinculoVeiculos);
  const step5EquipamentosVeiculo = React.createElement(StepEquipamentoVeiculo);
  const step6ManutencaoPreventiva = React.createElement(
    StepManutencaoPreventiva
  );
  const step7Cartao = React.createElement(StepCartao);
  const step8Documentos = React.createElement(StepDocumentos);
  const step9VinculaCentroDeCusto = React.createElement(
    StepVinculaCentroDeCusto
  );

  const steps = [
    {
      index: 1,
      title: 'Detalhes',
      visible: true,
      content: () => step1DetalheVeiculo,
    },
    {
      index: 2,
      title: 'Motorista',
      visible: true,
      content: () => step2Motorista,
    },

    {
      index: 3,
      title: 'Tipo Combustível',
      visible: true,
      content: () => step3TipoCombustivel,
    },

    {
      index: 4,
      title: 'Vinculo de Veículos',
      visible: true,
      content: () => step4VinculoVeiculos,
    },
    {
      index: 5,
      title: 'Equipamentos Veículo',
      visible: true,
      content: () => step5EquipamentosVeiculo,
    },
    {
      index: 6,
      title: 'Manutenção Preventiva',
      visible: true,
      content: () => step6ManutencaoPreventiva,
    },
    {
      index: 7,
      title: 'Cartão',
      visible: true,
      content: () => step7Cartao,
    },
    {
      index: 8,
      title: 'Documentos',
      visible: true,
      content: () => step8Documentos,
    },
    {
      index: 9,
      title: 'Vincula Centro De Custos',
      visible: true,
      content: () => step9VinculaCentroDeCusto,
    },
  ];

  const onPrintHistorico = async () => {
    setLoading(true);

    const { value } = await printVeiculoHistorico({
      nrSeqVeiculo: data.nrSeqVeiculo,
      flgPrincipal: data.veiculoTipo.flgPrincipal,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintTransporteDetalhado = async () => {
    setLoading(true);

    const { value } = await printVeiculoTransporteDetalhado({
      nrSeqVeiculo: data.nrSeqVeiculo,
      flgPrincipal: data.veiculoTipo.flgPrincipal,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  useEffect(() => {
    if (!reset) {
      setReset(true);
    }
  }, [reset]);

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Veiculo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqVeiculo > 0 ? onExcluir : null}
      onPrint={Number.isInteger(registryKey) ? onPrint : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {data.nrSeqVeiculo && (
        <ToolbarButtons>
          <ToolbarButtons.Button
            text='Imprimir Historico de Veiculos Vinculados'
            onClick={() => onPrintHistorico()}
          />
          <ToolbarButtons.Button
            text='Imprimir Historico de Transporte'
            onClick={() => onPrintTransporteDetalhado()}
          />
        </ToolbarButtons>
      )}
      {reset && (
        <div>
          <Steps
            propriedade={1}
            steps={steps}
            theme={Theme.Primary}
            align='horizontal'
            registryKey={data.nrSeqVeiculo}
            data={data}
            setData={setData}
            getData={getData}
          />
        </div>
      )}
    </CSDManutPage>
  );
}
