import React, { useEffect, useState, useRef } from 'react';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDBarGraphic,
  FileUpload,
  GridView,
  Panel,
  RadioButton,
  Textarea,
} from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  getChipAutoComplete,
  getClienteLinhasByCliente,
} from 'core/services/TEL';

export default function ManutencaoRetencao({
  data,
  setData,
  onSetMessage,
  addFiles = false,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  setLoading,
  clienteMessage,
  findTransaction,
  onOpenTransaction,
  setDataMassivo,
  dataMassivo,
  onSelectCliente,
  onSelectChip,
}) {
  const gridView = useRef();
  const gridViewLinesFromClient = useRef();
  const gridViewDocumentos = useRef();
  const graficoVozRef = useRef();
  const graficoDadosRef = useRef();
  const graficoSmsRef = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [gridHasItem, setGridHasItem] = useState(false);
  const [showGrafico, setShowGrafico] = useState(false);
  const [graficoData, setGraficoData] = useState({});

  useEffect(() => {
    if ('nrSeqDemanda' in data) {
      if (documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current) {
          gridViewDocumentos.current.setDataSource(data.documentos);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (data.tipoDemanda?.cdTipoDemanda === 'Retencao de Linhas') {
      if (!data.nrSeqDemanda || data.nrSeqDemanda === 0) {
        setData({ ...data, flgCancelarLinha: true });
      }
    }
  }, [data.tipoDemanda]);

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      nrSeqEmpresa: data.nrSeqEmpresa ?? null,
      flgFiltrarEmpresa: true,
    });

    return clientes;
  };

  const onSearchChip = async (e) => {
    if (e?.length < 4) {
      onSetMessage(
        ResponseStatus.Error,
        'Informe ao menos 4 digitos para buscar a linha'
      );

      return [];
    }

    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
      nrSeqEmpresa: data.nrSeqEmpresa ?? null,
    });

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocumentos?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current) {
        gridViewDocumentos.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    }

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const removerLinhaContratoMassivo = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    const dataSourceClienteLinhas =
      gridViewLinesFromClient?.current?.getDataSource() ?? [];

    if (dataSourceClienteLinhas.length > 0) {
      const itemIndex = dataSourceClienteLinhas.findIndex(
        (item) => item.nrSeqClienteLinha === gridItem.nrSeqClienteLinha
      );

      if (itemIndex >= 0) {
        dataSourceClienteLinhas[itemIndex].status = undefined;

        gridViewLinesFromClient?.current?.setDataSource(
          dataSourceClienteLinhas
        );
      }
    }

    if (
      list.filter((el) => el.status !== GridView.EnumStatus.Remover).length ===
      0
    ) {
      setGridHasItem(false);
    }

    setDataMassivo(list);
  };

  const onShowGrafico = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    setGraficoData(gridItem);

    setShowGrafico(true);
  };

  const columns = [
    { key: 'chip.nrPrefixoLinha', title: 'Número' },
    { key: 'plano.noPlano', title: 'Plano' },
    { key: 'chip.operadora.noOperadora', title: 'Operadora' },
    { key: 'tipoChamada.noTipoChamada', title: 'Pacote de Dados' },
    { key: 'centroDeCusto.noCentroDeCusto', title: 'Centro de Custo' },
    {
      key: 'vlrEncargo',
      title: 'Valor Pacote',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noObservacao',
      title: 'Motivo',
    },
    {
      key: 'nrSeqDemanda',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => removerLinhaContratoMassivo(e, dataSource),
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsDocument = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const colunasLinhasDoCliente = [
    { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
    { key: 'chip.linhaFormatada', title: 'Linha' },
    { key: 'chip.operadora.noOperadora', title: 'Operadora' },
    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Centro de Custo',
    },
    { key: 'plano.noPlano', title: 'Plano' },
    { key: 'tipoChamada.noTipoChamada', title: 'Pacote' },
    {
      key: 'vlrEncargo',
      title: 'Valor Pacote',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'consumoVoz',
      title: 'Consumo Voz',
      tooltip: 'Consumo médio de voz dos últimos 3 meses',
    },
    {
      key: 'consumoDados',
      title: 'Consumo Dados',
      tooltip: 'Consumo médio de Dados dos últimos 3 meses',
    },
    {
      key: 'consumoSMS',
      title: 'Consumo SMS',
      tooltip: 'Consumo médio de SMS dos últimos 3 meses',
    },
    {
      key: 'nrSeqClienteLinha',
      title: 'Mostrar Consumo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onShowGrafico(e, datasource),
      theme: Theme.Info,
      icon: 'eye',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Abrir Gráfico',
      tooltipDirection: 'bottom',
    },
  ];

  const searchClienteLinhas = async () => {
    setLoading(true);

    if (!gridHasItem || data.nrSeqChip) {
      if (gridHasItem && data.nrSeqChip) {
        const dataSource = gridView?.current?.getDataSource() ?? [];

        if (dataSource.some((item) => item.nrSeqChip === data.nrSeqChip)) {
          onSetMessage(ResponseStatus.Error, 'Essa linha já foi adicionada');

          setLoading(false);

          return;
        }
      }

      if (data.nrSeqPessoaCli || data.nrSeqChip) {
        const obj = {
          nrSeqPessoaCli: data.nrSeqPessoaCli ?? null,
          nrSeqChip: data.nrSeqChip ?? null,
          showConsumo: true,
        };

        if (data.nrSeqEmpresa) {
          obj.nrSeqEmpresa = data.nrSeqEmpresa;
        }

        const { data: linhas } = await getClienteLinhasByCliente(obj);

        if (linhas.length > 0) {
          gridViewLinesFromClient?.current?.setDataSource(linhas);

          setData({ ...data, chip: null, nrSeqChip: null });
        } else {
          onSetMessage(
            ResponseStatus.Error,
            'Nenhuma linha(chip) encontrada para esse cliente nesta empresa'
          );
        }
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Informe o Cliente ou Número da linha'
        );
      }
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel buscar linhas após ter adicionado linhas para retenção'
      );
    }

    setLoading(false);
  };

  const actionButtons = [
    {
      text: 'Cancelar Linha',
      value: 'S',
    },
    {
      text: 'Migrar p/ PRÉ',
      value: 'N',
    },
  ];

  const addLinhas = async () => {
    setLoading(true);

    let selecteds =
      gridViewLinesFromClient.current.getCheckBoxValuesAt(0) ?? [];

    if (selecteds.length > 0) {
      if (data.noObservacao) {
        const dataSource = gridViewLinesFromClient.current.getDataSource();

        selecteds = selecteds.map(
          (column) =>
            column.find((prop) => prop.key === 'nrSeqClienteLinha').value
        );

        const filteredItens = dataSource.filter((item) =>
          selecteds.includes(item.nrSeqClienteLinha)
        );

        const itens = filteredItens.map((clienteLinha) => {
          const item = { ...clienteLinha };
          item.nrSeqEmpresa = data.nrSeqEmpresa;
          item.dtSolicitacao = data.dtSolicitacao;
          item.nrSeqTipoDemanda = data.nrSeqTipoDemanda;
          item.nrSeqDemandaStatus = data.nrSeqDemandaStatus;
          item.noObservacao = data.noObservacao;
          item.flgCancelarLinha = data.flgCancelarLinha;
          item.status = GridView.EnumStatus.Inserir;

          return item;
        });

        const addedLines = [...dataMassivo, ...itens];

        setDataMassivo(addedLines);

        dataSource.forEach((item) => {
          if (selecteds.includes(item.nrSeqClienteLinha)) {
            item.status = GridView.EnumStatus.Remover;
          }
        });

        gridViewLinesFromClient?.current?.setDataSource(dataSource);

        setData({
          ...data,
          noObservacao: '',
          flgCancelarLinha: 'S',
        });
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Preencha o Motivo da Retenção da linha'
        );
      }
    } else {
      onSetMessage(ResponseStatus.Error, 'Selecione ao menos uma linha');
    }

    setLoading(false);
  };

  useEffect(() => {
    if ('consumosRecente' in graficoData) {
      const labels = graficoData.consumosRecente.map((p) => p.noMes);

      const vozData = graficoData.consumosRecente.map(
        (item) => item.vlrQtdeTotalVoz
      );

      const dadosData = graficoData.consumosRecente.map(
        (item) => item.vlrQtdeTotalDados
      );

      const smsData = graficoData.consumosRecente.map(
        (item) => item.qtdeTotalSms
      );

      graficoVozRef?.current?.setDataSource({
        labels,
        datasets: [
          {
            label: 'Voz',
            data: vozData,
            backgroundColor: 'rgba(220, 226, 35, 0.8)',
          },
        ],
      });

      graficoDadosRef?.current?.setDataSource({
        labels,
        datasets: [
          {
            label: 'Dados',
            data: dadosData,
            backgroundColor: 'rgba(27, 54, 222, 0.8)',
          },
        ],
      });

      graficoSmsRef?.current?.setDataSource({
        labels,
        datasets: [
          {
            label: 'SMS',
            data: smsData,
            backgroundColor: 'rgba(46, 198, 33, 0.8)',
          },
        ],
      });
    }
  }, [graficoData]);

  const optionsBarGraphicDados = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue} GB`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  const optionsBarGraphicVoz = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const seconds = tooltipItem.raw;

            const h = Math.floor(seconds / 3600)
              .toString()
              .padStart(2, '0');
            const m = Math.floor((seconds % 3600) / 60)
              .toString()
              .padStart(2, '0');
            const s = (seconds % 60).toString().padStart(2, '0');

            return `Valor: ${h}:${m}:${s}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  const optionsBarGraphic = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource(dataMassivo);
    }

    if (dataMassivo.length > 0) {
      setGridHasItem(true);
    } else {
      setGridHasItem(false);
    }
  }, [dataMassivo]);

  return (
    <>
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            required
            enabled={!gridHasItem}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) => {
              gridViewLinesFromClient?.current?.setDataSource([]);

              if ('nrSeqPessoaCli' in cliente) {
                data.cliente = cliente;
                data.nrSeqPessoaCli = cliente.nrSeqPessoaCli;
                onSelectCliente(cliente);
              } else {
                setData({
                  ...data,
                  cliente: null,
                  nrSeqPessoaCli: null,
                });
              }
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='N° da Linha'
            searchDataSource={onSearchChip}
            selectedItem={data.chip}
            minLengthTextSearch={5}
            placeholder='Informe ao menos 5 dígitos da linha'
            onSelectItem={(chip) => {
              if ('nrSeqChip' in chip) {
                data.chip = chip;
                data.nrSeqChip = chip.nrSeqChip;

                if (data.nrSeqPessoaCli) {
                  onSelectChip(chip);
                } else {
                  onSelectChip(chip, true);
                }
              } else {
                setData({ ...data, chip: null, nrSeqChip: null });
              }
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col-2 d-flex align-items-end'>
          <Button
            outline
            visible={data.flgPermiteAlterar}
            icon='search'
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            style={{ flexGrow: 1 }}
            onClick={searchClienteLinhas}
            text='Buscar Linhas'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Linhas do Cliente' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewLinesFromClient}
                  dataColumns={colunasLinhasDoCliente}
                  showPagination={false}
                  showSelectSizes={false}
                  canControlVisibility
                />
              </div>
            </div>
            {gridViewLinesFromClient?.current?.getDataSource().length > 0 && (
              <div className='row'>
                <div className='col'>
                  <p>
                    Foram encontrados{' '}
                    {gridViewLinesFromClient?.current?.getDataSource().length}{' '}
                    registros
                  </p>
                </div>
              </div>
            )}
            {showGrafico && (
              <Panel>
                <Panel.Header
                  title={`Consumo dos últimos 3 meses da Linha ${graficoData.chip.linhaFormatada}`}
                  theme={Theme.Primary}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col-4'>
                      <CSDBarGraphic
                        ref={graficoVozRef}
                        options={optionsBarGraphicVoz}
                      />
                    </div>
                    <div className='col-4'>
                      <CSDBarGraphic
                        ref={graficoDadosRef}
                        options={optionsBarGraphicDados}
                      />
                    </div>
                    <div className='col-4'>
                      <CSDBarGraphic
                        ref={graficoSmsRef}
                        options={optionsBarGraphic}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            )}
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Textarea
            label='Motivo'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={4}
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
        <div className='col-3'>
          <div className='row mb-3'>
            <div className='col'>
              <RadioButton
                label='O que deseja fazer com a linha'
                outline
                size={BootstrapSizes.Medium}
                theme={Theme.Primary}
                buttons={actionButtons}
                selectedButton={data.flgCancelarLinha ? 'S' : 'N'}
                onChange={(flgCancelarLinha) => {
                  setData({
                    ...data,
                    flgCancelarLinha: flgCancelarLinha === 'S',
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col d-flex align-items-end'>
              <Button
                outline
                visible={data.flgPermiteAlterar}
                icon='plus'
                style={{ flexGrow: 1 }}
                size={BootstrapSizes.Medium}
                theme={Theme.Success}
                onClick={addLinhas}
                text='Adicionar Linhas'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>

      {addFiles && (
        <Panel>
          <Panel.Header title='Documentos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => {
                    if (files[0].size <= 5000000) {
                      setImportedFile(files);
                    } else {
                      onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                    }
                  }}
                  allowedMimeTypes={[
                    MimeTypes.Types.PDF,
                    MimeTypes.Types.Image,
                    MimeTypes.Types.Word,
                  ]}
                />
              </div>
              <div className='col-3 d-flex justify-content-end'>
                <Button
                  outline
                  text='Adicionar Documento'
                  icon='plus'
                  style={{ flexGrow: 1 }}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={() => onClickAddDocumento()}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewDocumentos}
                  dataColumns={columnsDocument}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
    </>
  );
}
