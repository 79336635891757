import Pessoa from 'core/models/SEG/pessoa';
import Empresa from 'core/models/SEG/empresa';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import Perfil from 'core/models/SEG/perfil';
import PerfilDeAcesso from 'core/models/SEG/perfilDeAcesso';
import UsuarioPerfil from 'core/models/SEG/usuarioPerfil';
import UsuarioTransacaoInicial from 'core/models/SEG/usuario_TransacaoInicial';
import UsuarioCentroDeCusto from 'core/models/FIS/usuario_CentroDeCusto';

export default class Usuario extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.noLogin = jsonObject.noLogin;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgTodoMenu = jsonObject.flgTodoMenu;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqTransacao = jsonObject.nrSeqTransacao;
    this.senha = jsonObject.senha;
    this.nrSeqPessoaUsr = jsonObject.nrSeqPessoaUsr;
    this.flgAuditoria = jsonObject.flgAuditoria;
    this.cdUsuario = jsonObject.cdUsuario;
    this.nrSeqEmpresaDefault = jsonObject.nrSeqEmpresaDefault;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqMobile = jsonObject.nrSeqMobile;
    this.nrTentativasAcesso = jsonObject.nrTentativasAcesso;
    this.flgAutenticacaoAD = jsonObject.flgAutenticacaoAD;
    this.dtCriacao = jsonObject.dtCriacao;
    this.dtUltimaTentativaAcesso = jsonObject.dtUltimaTentativaAcesso;
    this.nrSeqPerfilDeAcesso = jsonObject.nrSeqPerfilDeAcesso;
    this.refreshToken = jsonObject.refreshToken;
    this.noPaginaWeb = jsonObject.noPaginaWeb;
    this.imagemEmpresaDefault = jsonObject.imagemEmpresaDefault;
    this.accessToken = jsonObject.accessToken;
    this.noPessoa = jsonObject.noPessoa;
    this.flgGerarSenha = jsonObject.flgGerarSenha;
    this.perfil = new Perfil(jsonObject.perfil ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.perfilDeAcesso = new PerfilDeAcesso(jsonObject.perfilDeAcesso ?? {});
    this.usuarioPerfils =
      jsonObject.usuarioPerfils?.map((el) => new UsuarioPerfil(el)) ?? [];
    this.usuarioDocumentos =
      jsonObject.usuarioDocumentos?.map((el) => new PessoaDocumento(el)) ?? [];
    this.usuarioCentroDeCustos =
      jsonObject.usuarioCentroDeCustos?.map(
        (el) => new UsuarioCentroDeCusto(el)
      ) ?? [];
    this.usuarioTransacaoIniciais =
      jsonObject.usuarioTransacaoIniciais?.map(
        (el) => new UsuarioTransacaoInicial(el)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
