export default class NfControleMercadoriaHist {
  constructor(jsonObject = {}) {
    this.nrSeqNfControleMercadoriaHist =
      jsonObject.nrSeqNfControleMercadoriaHist;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.nrSeqControleMercadoria = jsonObject.nrSeqControleMercadoria;
    this.nrOrdem = jsonObject.nrOrdem;
    this.noObservacoes = jsonObject.noObservacoes;
    this.vlrFreteAlterado = jsonObject.vlrFreteAlterado;
    this.dtPrevisaoEntrega = jsonObject.dtPrevisaoEntrega;
  }

  toJson = () => JSON.stringify(this);
}
