import qs from 'qs';
import Pagination from 'core/models/pagination';
import Abastecimento from 'core/models/FRO/abastecimento';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/FRO/Abastecimento`;

export const getAbastecimentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Abastecimento(json)) ?? [],
  };
};

export const postGenerateFinancialAbastecimentos = async (abastecimentos) => {
  const response = await api.post(
    `${baseURL}/GenerateFinancial`,
    abastecimentos
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};
export const postGenerateFinancialAbastecimentosIndividual = async (
  abastecimentos
) => {
  const response = await api.post(
    `${baseURL}/GenerateFinancialIndividual`,
    abastecimentos
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const uploadTicketLog = async (data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('extension', MimeTypes.getExtension(data.file.type));
  formData.append('nrSeqFormaPagamento', data.nrSeqFormaPagamento);
  formData.append('nrSeqPessoaPos', data.nrSeqPessoaPos);
  formData.append('dtVencimento', data.dtVencimento);

  const response = await api.post(`${baseURL}/UploadTicketLog`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Abastecimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const postUploadAbastecimentos = async (
  file,
  nrSeqPessoaPos,
  nrSeqFormaPagamento,
  dtVencimento
) => {
  const formData = new FormData();
  formData.append('nrSeqPessoaPos', nrSeqPessoaPos);
  formData.append('nrSeqFormaPagamento', nrSeqFormaPagamento);
  formData.append('dtVencimento', dtVencimento);
  formData.append('file', file);
  // formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/Upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Abastecimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const postUploadAbastecimentosXml = async (
  file,
  nrSeqPessoaPos,
  nrSeqFormaPagamento,
  dtVencimento
) => {
  const formData = new FormData();
  formData.append('nrSeqPessoaPos', nrSeqPessoaPos);
  formData.append('nrSeqFormaPagamento', nrSeqFormaPagamento);
  formData.append('dtVencimento', dtVencimento);
  formData.append('file', file);
  // formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/UploadXml`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.abastecimentoS.map((json) => new Abastecimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const UploadAtualizar = async (data) => {
  const response = await api.post(`${baseURL}/UploadAtualizar`, data);
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value,
    pagination: new Pagination(pagination),
  };
};
export const BuscaEmail = async (data) => {
  const response = await api.post(`${baseURL}/BuscaEmail`, data);
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value,
    pagination: new Pagination(pagination),
  };
};

export const getAbastecimentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Abastecimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculoAbastecimento = async (data) => {
  const response = await api.post(`${baseURL}/getVeiculoAbastecimento`, data);
  return response.data.value ?? {};
};

export const getPreenchePosto = async (data) => {
  const response = await api.post(`${baseURL}/GetPreenchePosto`, data);
  return response.data.value ?? {};
};

export const getAbastecimento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Abastecimento(value) : {};
};

export const SearchAbastecimentoViaChaveWeb = async (site) => {
  const response = await api.get(`${baseURL}/SearchAbastecimentoViaChaveWeb`, {
    params: { site },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getAbastecimentoTipoDespesa = async (id) => {
  const response = await api.get(`${baseURL}/GetAbastecimentoTipoDespesa`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Abastecimento(value) : {};
};

export const postImportAbastecimentos = async (data) => {
  const response = await api.post(`${baseURL}/Import`, data);
  return response.data ?? {};
};

export const saveAbastecimento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
export const SalvarAbastecimentos = async (data) => {
  const response = await api.post(`${baseURL}/SalvarAbastecimentos`, data);
  return response.data ?? {};
};

export const deleteAbastecimento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const GetAbastecimentosAcerto = async (filters) => {
  const response = await api.post(
    `${baseURL}/GetAbastecimentosAcerto`,
    filters
  );
  const { value } = response.data ?? {};
  return value;
};

export const GetAbastecimentoSemAcerto = async (filters) => {
  const response = await api.post(
    `${baseURL}/GetAbastecimentoSemAcerto`,
    filters
  );
  const { value } = response.data ?? {};
  return value;
};

export const getNotaPelaChave = async (filters) => {
  const response = await api.get(`${baseURL}/BuscaNotaPelaChave`, {
    params: filters,
  });

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value,
  };
};

export const RecalcularMedia = async (filters) => {
  const response = await api.post(`${baseURL}/RecalcularMedia`, filters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Abastecimento(json)) ?? [],
  };
};

export const printAbastecimento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelAbastecimento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const AtualizarAbastecimentos = async () => {
  const response = await api.get(`${baseURL}/AtualizarAbastecimentos`);
  if (response.data !== null && response.data !== undefined) {
    return response.data.value.result ?? {};
  }
  return [];
};

export const deleteAllAbastecimento = async (ids, flgConfirmaExclusao) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: {
      ids,
      flgConfirmaExclusao,
    },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getUltimoAbastecimento = async (id) => {
  const response = await api.get(`${baseURL}/GetUltimoAbastecimento`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? new Abastecimento({}),
  };
};
