import qs from 'qs';
import Pagination from 'core/models/pagination';
import Importacao from 'core/models/SEG/importacao';
import api from '../api';

const baseURL = `/SEG/Importacao`;

export const getImportacaoList = async (filters) => {
  const response = await api.get(`/$Importacao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Importacao(json)) ?? [],
  };
};

export const getImportacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Importacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getImportacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Importacao(value) : {};
};

export const saveImportacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  const { status, value, message } = response.data ?? {};
  return {
    status,
    message,
    data: value ? new Importacao(value) : {},
  };
};

export const saveTabela = async (data) => {
  const response = await api.get(`${baseURL}/SaveTable`, {
    params: data,
  });
  const { status, value, message } = response.data ?? {};
  return {
    status,
    message,
    data: value ? new Importacao(value) : {},
  };
};

export const deleteImportacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printImportacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelImportacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllImportacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getImportacaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Importacao(json)) ?? [],
  };
};

export const postUploadImportacao = async (
  nrSeqImportacao,
  flgIgnorarDuplicados,
  file
) => {
  const formData = new FormData();
  formData.append('nrSeqImportacao', nrSeqImportacao);
  formData.append('flgIgnorarDuplicados', flgIgnorarDuplicados);
  formData.append('file', file);
  const response = await api.post(`${baseURL}/Upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};
