import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  BootstrapSizes,
  JustifyContent,
  Theme,
} from 'ui/Helpers/utils';
import SearchCNPJButton from 'ui/components/SearchCNPJButton';
import { Cliente, PessoaInformacaoBancaria } from 'core/models/FIN';
import { Pais, Estado, Cidade, Endereco } from 'core/models/SEG';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDManutPage,
  Textbox,
  Autocomplete,
  RadioButton,
  Switch,
  Capture,
  Panel,
  Button,
  GridView,
  ToolbarButtons,
  DatePicker,
} from 'ui/components';

import {
  saveCliente,
  deleteCliente,
  getParametroClienteEmpresa,
  getClienteCPF,
  getClienteCNPJ,
  getValidateSelectSeller,
  getCliente,
} from 'core/services/FIN/cliente';
import ContatoAdicional from 'core/models/SEG/contatoAdicional';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getCidadeAutoComplete, getCep } from 'core/services/SEG/cidade';
import { getProfissaoAutoComplete } from 'core/services/SEG/profissao';
import { getBancoAutoComplete } from 'core/services/FIN/banco';
import { getEstadoCivilAutoComplete } from 'core/services/SEG/estadocivil';
import { getEnvioPadraoAutoComplete } from 'core/services/TEL/envioPadrao';
import { getPessoaSetorAutoComplete } from 'core/services/SEG/pessoaSetor';
import {
  getEmpresaDefaultUsuario,
  getEmpresasUsuario,
} from 'core/services/SEG/empresa';

const tipoPessoa = [
  {
    text: 'Física',
    value: 'fisica',
  },
  {
    text: 'Jurídica ',
    value: 'juridica',
  },
];

const tipoconta = [
  {
    text: 'Corrente',
    value: 'C',
  },
  {
    text: 'Poupança',
    value: 'P',
  },
];

const sexo = [
  {
    text: 'Masculino',
    value: 'M',
  },
  {
    text: 'Feminino ',
    value: 'F',
  },
];

export default function CadastroRapidoClienteItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();

  const [cliente, setCliente] = useState(new Cliente({ flgAtivo: true }));
  const [pais, setPais] = useState(new Pais());
  const [estado, setEstado] = useState(new Estado());
  const [cidade, setCidade] = useState(new Cidade());
  const [contatoAdicional, setContatoAdicional] = useState(
    new ContatoAdicional({})
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [clienteempresa, setClienteEmpresa] = useState(false);
  const [picture, setPicture] = useState();
  const [formDataCNPJ, setFormDataCNPJ] = useState({});

  const gridViewContatos = useRef();

  let endereco = new Endereco();
  let informacaoBancaria = new PessoaInformacaoBancaria();

  const { id: idSelecao } = PageTypes.Selection;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
      flgAtivo: true,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onValidateSelectSeller = async () => {
    const { value } = await getValidateSelectSeller();

    return value;
  };

  const validReadOnlyDocument = (client) => {
    if (client.nrCPF !== undefined) {
      if (client.nrCPF !== null) {
        if (client.nrCPF.length > 0) {
          client.disableCpfCnpj = true;
          client.disableTipoPessoa = true;
        }
      }
    }

    if (client.nrCNPJ !== undefined) {
      if (client.nrCNPJ !== null) {
        if (client.nrCNPJ.length > 0) {
          client.disableCpfCnpj = true;
          client.disableTipoPessoa = true;
        }
      }
    }
    return client;
  };

  const buscaCamposTransacao = (nomeCampo) => {
    const page = transaction.pages.find((item) => item.id === 'maintenance');

    if (page.settings?.length && page.settings?.length > 0) {
      for (let i = 0; i < page.settings?.length; i += 1) {
        if (page.settings[i]?.noCampo === nomeCampo)
          return page.settings[i]?.noDefaultValue;
      }
    }

    return null;
  };

  const load = useCallback(
    async (id) => {
      setClienteEmpresa(await getParametroClienteEmpresa());

      if (Number.isInteger(id)) {
        if (id > 0) {
          setLoading(true);
          let client = await getCliente(id);
          client.disableCpfCnpj = false;
          client.disableTipoPessoa = false;

          client = validReadOnlyDocument(client);
          setPicture(client.noImagemBase64);
          setCidade(client.endereco?.cidade);
          setEstado(client.endereco?.cidade.estado);
          setPais(client.endereco?.cidade.estado.pais);

          setLoading(false);
          client.flgPermiteAlterarVendedor = !(await onValidateSelectSeller());
          setCliente(client);
          setContatoAdicional({});

          if (gridViewContatos && gridViewContatos.current)
            gridViewContatos.current.setDataSource(
              client.contatosAdicionais ?? []
            );
        }
      } else {
        setCidade(new Cidade());
        setEstado(new Estado());
        setPais(new Pais());
        setContatoAdicional(new ContatoAdicional({}));

        const { empresas: empresadefault } = await getEmpresaDefaultUsuario();

        const vendedorLogado = await onSearchVendedor();

        const flgPermiteAlterarVendedor = !(await onValidateSelectSeller());

        const flgFisica =
          buscaCamposTransacao('flgFisica') === '0' ? false : null;

        if (flgPermiteAlterarVendedor) {
          const client = new Cliente({
            flgPermiteAlterarVendedor,
            flgPermiteAlterar: true,
            flgFisica,
            flgAtivo: true,
          });
          setCliente({
            ...client,
            empresa: empresadefault[0],
            nrSeqEmpresa: empresadefault[0].nrSeqEmpresa,
            flgAtivo: true,
          });
        } else {
          const client = new Cliente(
            new Cliente({
              flgPermiteAlterarVendedor,
              flgPermiteAlterar: true,
              vendedor: vendedorLogado[0],
              flgFisica,
              flgAtivo: true,
            })
          );
          setCliente({
            ...client,
            empresa: empresadefault[0],
            nrSeqEmpresa: empresadefault[0].nrSeqEmpresa,
            flgAtivo: true,
          });
        }
      }

      setMessage(null);
    },
    [registryKey]
  );

  const loadByPerson = useCallback(
    async (client) => {
      setClienteEmpresa(await getParametroClienteEmpresa());

      if (Number.isInteger(client.nrSeqPessoaCli)) {
        if (client.nrSeqPessoaCli > 0) {
          setLoading(true);

          client.disableCpfCnpj = false;
          client.disableTipoPessoa = false;

          client = validReadOnlyDocument(client);
          setPicture(client.noImagemBase64);
          setCidade(client.endereco?.cidade);
          setEstado(client.endereco?.cidade.estado);
          setPais(client.endereco?.cidade.estado.pais);

          setLoading(false);
          client.flgPermiteAlterarVendedor = !(await onValidateSelectSeller());
          setCliente(client);
          setContatoAdicional({});

          if (gridViewContatos && gridViewContatos.current)
            gridViewContatos.current.setDataSource(
              client.contatosAdicionais ?? []
            );
        }
      } else {
        setCidade(new Cidade());
        setEstado(new Estado());
        setPais(new Pais());
        setContatoAdicional(new ContatoAdicional({}));

        const { empresas: empresadefault } = await getEmpresaDefaultUsuario();

        const vendedorLogado = await onSearchVendedor();

        const flgPermiteAlterarVendedor = !(await onValidateSelectSeller());

        const flgFisica =
          buscaCamposTransacao('flgFisica') === '0' ? false : null;

        if (flgPermiteAlterarVendedor) {
          client = new Cliente({
            flgPermiteAlterarVendedor,
            flgPermiteAlterar: true,
            flgFisica,
            flgAtivo: true,
          });
          setCliente({
            ...client,
            empresa: empresadefault[0],
            nrSeqEmpresa: empresadefault[0].nrSeqEmpresa,
            flgAtivo: true,
          });
        } else {
          client = new Cliente(
            new Cliente({
              flgPermiteAlterarVendedor,
              flgPermiteAlterar: true,
              vendedor: vendedorLogado[0],
              flgFisica,
              flgAtivo: true,
            })
          );
          setCliente({
            ...client,
            empresa: empresadefault[0],
            nrSeqEmpresa: empresadefault[0].nrSeqEmpresa,
            flgAtivo: true,
          });
        }
      }

      setMessage(null);
    },
    [registryKey]
  );

  useEffect(() => {
    (async function cli() {
      await load(registryKey);
    })();
  }, [load, registryKey, reload]);

  useEffect(() => {
    if (formDataCNPJ && Object.keys(formDataCNPJ).length > 0) {
      setCidade(formDataCNPJ.endereco.cidade);
      setEstado(formDataCNPJ.endereco.cidade.estado);
      setPais(formDataCNPJ.endereco.cidade.estado.pais);

      const infoCliente = {
        ...formDataCNPJ,
      };

      if (formDataCNPJ.contato) {
        infoCliente.fone = formDataCNPJ.contato.fone;
        infoCliente.celular = formDataCNPJ.contato.celular;
        infoCliente.email = formDataCNPJ.contato.email;
      }

      setCliente({
        ...cliente,
        ...infoCliente,
        disableCpfCnpj: true,
        disableTipoPessoa: true,
      });
    }
  }, [formDataCNPJ]);

  const save = async (limpar) => {
    setLoading(true);
    cliente.endereco.cidade = cidade;
    cliente.endereco.nrSeqCidade = cidade.nrSeqCidade;

    cliente.endereco.cidade.estado = estado;
    cliente.endereco.cidade.nrSeqEstado = estado.nrSeqEstado;

    cliente.endereco.cidade.estado.pais = pais;
    cliente.endereco.cidade.estado.nrSeqPais = pais.nrSeqPais;

    cliente.dtNascimento = null;

    cliente.noImagemBase64 = picture;

    let contatos = [];

    if (gridViewContatos && gridViewContatos.current) {
      contatos = gridViewContatos.current.getDataSource();

      cliente.contatosAdicionais = contatos.map((contato) => {
        contato.nrSeqPessoaSetor = contato.setor?.nrSeqPessoaSetor;

        return contato;
      });
    }

    const { status, message: msg } = await saveCliente(cliente);

    if (limpar) {
      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();

        setCidade(new Cidade());
        setEstado(new Estado());
        setPais(new Estado());
        setCliente(new Cliente({ flgPermiteAlterar: true, flgAtivo: true }));
        setContatoAdicional(new ContatoAdicional({}));
        setPicture(null);

        if (gridViewContatos && gridViewContatos.current)
          gridViewContatos.current.setDataSource([]);
      }

      if (
        msg === 'O CPF escolhido é Inválido.' ||
        msg === 'O CNPJ escolhido é Inválido.'
      ) {
        cliente.disableCpfCnpj = false;
      }
      setCliente(
        status === ResponseStatus.Success
          ? new Cliente({ flgPermiteAlterar: true, flgAtivo: true })
          : cliente
      );
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCliente(registryKey);

      setCliente(status === ResponseStatus.Success ? new Cliente() : cliente);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });
    onSetMessage(status, msg);
    return paises;
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: pais.nrSeqPais ?? new Pais(),
    });
    onSetMessage(status, msg);
    return estados;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: estado.nrSeqEstado ?? new Estado(),
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const onSearchProfissao = async (e) => {
    const { status, message: msg, profissoes } = await getProfissaoAutoComplete(
      {
        noProfissao: e,
      }
    );
    onSetMessage(status, msg);
    return profissoes;
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    onSetMessage(status, msg);
    return bancos;
  };

  const onSearcCliente = async (cpf, cnpj) => {
    if (cpf) {
      const {
        status,
        cliente: clienteSearch,
        message: msg,
      } = await getClienteCPF({
        cpf,
      });

      if (status === ResponseStatus.Error) {
        setCliente({ ...clienteSearch });
      }

      if (clienteSearch.nrSeqPessoaCli !== cliente.nrSeqPessoaCli) {
        loadByPerson(clienteSearch);
      }
      onSetMessage(status, msg);
    } else {
      const {
        status,
        cliente: clienteSearch,
        message: msg,
      } = await getClienteCNPJ({
        cnpj,
      });

      if (status === ResponseStatus.Error) {
        setCliente({ ...clienteSearch });
      }

      if (clienteSearch.nrSeqPessoaCli !== cliente.nrSeqPessoaCli) {
        clienteSearch.id = clienteSearch.nrSeqPessoaCli;
        loadByPerson(clienteSearch);
      }
      onSetMessage(status, msg);
    }
  };

  const onSearchEstadoCivil = async (e) => {
    const {
      status,
      message: msg,
      estadosCivis,
    } = await getEstadoCivilAutoComplete({
      noEstadoCivil: e,
    });
    onSetMessage(status, msg);
    return estadosCivis;
  };

  const onSearchEnvioPadrao = async (e) => {
    const { status, message: msg, envios } = await getEnvioPadraoAutoComplete({
      noEnvioPadrao: e,
    });
    onSetMessage(status, msg);
    return envios;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchCep = async () => {
    setLoading(true);
    if (cliente.endereco.cep && cliente.endereco.cep.length > 4) {
      const cid = await getCep({
        cep: cliente.endereco.cep,
      });

      setCidade(cid.cidade);
      setEstado(cid.cidade.estado);
      setPais(cid.cidade.estado.pais);
      const end = new Endereco({
        cep: cid.cep,
        bairro: cid.bairro,
        noRua: cid.noRua,
      });

      setCliente({ ...cliente, endereco: end });
    }

    setLoading(false);
    return new Endereco();
  };

  const onSearchSetor = async (e) => {
    const { status, message: msg, setores } = await getPessoaSetorAutoComplete({
      noPessoaSetor: e,
    });

    if (msg) onSetMessage(status, msg);

    return setores;
  };

  const onClickRemoveContato = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const contactList = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      contactList.push(gridItem);
    }

    if (gridViewContatos && gridViewContatos.current)
      gridViewContatos.current.setDataSource(contactList);
  };

  const onClickEditContato = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const contactList = datasource.filter((el) => el !== e);

    gridItem.status = GridView.EnumStatus.Alterar;

    setContatoAdicional(gridItem);

    if (gridViewContatos && gridViewContatos.current)
      gridViewContatos.current.setDataSource(contactList);
  };

  const columnsContatos = [
    {
      key: 'nrSeqContatoAdicional',
      title: 'nrSeqContatoAdicional',
      visible: false,
    },
    { key: 'nome', title: 'Nome' },
    { key: 'fone', title: 'Telefone' },
    { key: 'email', title: 'E-mail' },
    { key: 'setor.noPessoaSetor', title: 'Tipo de Contato' },
    { key: 'celular', title: 'Celular', format: GridView.DataTypes.CellPhone },

    { key: 'nrCpf', title: 'CPF' },
    { key: 'nrRg', title: 'RG' },
    {
      key: 'dtNascimento',
      title: 'Data de Nascimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'flgRepresentanteLegal',
      title: 'Representante Legal',
    },
    {
      key: 'nrSeqContatoAdicional',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditContato(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqContatoAdicional',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveContato(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const validaContatoAdicional = () => {
    if (
      !contatoAdicional.flgRepresentanteLegal &&
      !contatoAdicional.nrSeqPessoaSetor
    ) {
      onSetMessage(ResponseStatus.Error, 'Informar o Tipo de Contato.');

      return false;
    }

    if (!contatoAdicional.nome) {
      onSetMessage(ResponseStatus.Error, 'Preencha o Nome do Contato');

      return false;
    }

    if (!contatoAdicional.email && !contatoAdicional.celular) {
      onSetMessage(
        ResponseStatus.Error,
        'Preencha o Email ou Telefone do Contato'
      );

      return false;
    }

    if (contatoAdicional.flgRepresentanteLegal) {
      if (!contatoAdicional.nrCpf) {
        onSetMessage(
          ResponseStatus.Error,
          'Preencha o CPF do Representante Legal'
        );

        return false;
      }

      if (!contatoAdicional.celular && !contatoAdicional.email) {
        onSetMessage(
          ResponseStatus.Error,
          'Preencha o Email ou o Celular do Representante Legal'
        );

        return false;
      }

      if (!contatoAdicional.dtNascimento) {
        onSetMessage(
          ResponseStatus.Error,
          'Preencha a Data de Nascimento do Representante Legal'
        );

        return false;
      }
    }

    return true;
  };

  const onAdicionaContato = () => {
    const dataSource = gridViewContatos.current.getDataSource() ?? [];

    if (validaContatoAdicional()) {
      if (contatoAdicional.status !== GridView.EnumStatus.Alterar) {
        contatoAdicional.status = GridView.EnumStatus.Inserir;
      }

      dataSource.push(contatoAdicional);

      if (gridViewContatos && gridViewContatos.current)
        gridViewContatos.current.setDataSource(dataSource);

      setContatoAdicional(new ContatoAdicional({}));
    }
  };

  return (
    <CSDManutPage
      isActive={isActive}
      title='Cadastro Rápido de Clientes'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      transaction={transaction}
      onNew={() => {
        setCidade(new Cidade());
        setEstado(new Estado());
        setPais(new Pais());
        setCliente(new Cliente({ flgAtivo: true }));
        setPicture(null);
      }}
      onSave={() => cliente.flgPermiteAlterar && save(true)}
      onDelete={
        cliente?.nrSeqPessoaCli > 0 && cliente.flgPermiteAlterar
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Salvar e Manter'
          icon={['far', 'save']}
          onClick={() => cliente.flgPermiteAlterar && save(false)}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='col-3'>
          <Capture picture={picture} onPictureLoad={(e) => setPicture(e)} />
        </div>

        <div className='col-9'>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Código'
                id='txtCodigo'
                text={cliente.cdCliente ?? ' '}
                maxLength={20}
                onChangedValue={(cdCliente) =>
                  setCliente({ ...cliente, cdCliente })
                }
              />
            </div>

            <div className='col'>
              <Textbox
                id='txtNome'
                label='Nome'
                text={cliente.noPessoa}
                maxLength={200}
                onChangedValue={(noPessoa) =>
                  setCliente({ ...cliente, noPessoa })
                }
              />
            </div>
            <div className='col-auto'>
              <RadioButton
                id='clienteTipoPessoa'
                label='Tipo de Pessoa'
                outline
                size={BootstrapSizes.Small}
                disabled={cliente?.disableTipoPessoa}
                theme={Theme.Primary}
                selectedButton={cliente?.flgFisica ? 'fisica' : 'juridica'}
                onChange={(tipo) => {
                  setCliente({
                    ...cliente,
                    flgFisica: tipo === 'fisica',
                  });
                }}
                buttons={tipoPessoa}
              />
            </div>
            <div className='col-1'>
              <Switch
                formControl
                label='Ativo'
                checked={cliente.flgAtivo}
                onChange={(flgAtivo) => setCliente({ ...cliente, flgAtivo })}
              />
            </div>

            <div className='col-2'>
              <Textbox
                label='Data Nascimento'
                text={cliente.dtNascimentoFormat}
                visible={cliente.flgFisica}
                id='txtDtNascimento'
                maxLength={10}
                mask={MaskTypes.Date}
                onChangedValue={(dtNascimentoFormat) =>
                  setCliente({ ...cliente, dtNascimentoFormat })
                }
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='CPF'
                id='txtCpf'
                text={cliente.nrCPF}
                mask={MaskTypes.CPF}
                visible={cliente.flgFisica}
                readOnly={cliente.disableCpfCnpj}
                onChangedValue={(nrCPF) => {
                  if (nrCPF.length === 14) onSearcCliente(nrCPF);
                  setCliente({ ...cliente, nrCPF });
                }}
              />

              <Textbox
                label='CNPJ'
                id='txtCnpj'
                text={cliente.nrCNPJ}
                mask={MaskTypes.CNPJ}
                readOnly={cliente.disableCpfCnpj}
                visible={!cliente.flgFisica}
                onChangedValue={(nrCNPJ) => {
                  if (nrCNPJ.length === 18) onSearcCliente('', nrCNPJ);
                  setCliente({ ...cliente, nrCNPJ });
                }}
              />
            </div>

            {cliente?.nrCNPJ && !cliente?.nrSeqPessoa && (
              <div className='col-2 mt-4'>
                <SearchCNPJButton
                  cnpj={cliente?.nrCNPJ}
                  formData={formDataCNPJ}
                  setFormData={setFormDataCNPJ}
                  onSetMessage={onSetMessage}
                  setLoading={setLoading}
                />
              </div>
            )}

            <div className='col-2'>
              <Textbox
                label='RG'
                id='txtRg'
                text={cliente.nrRG}
                visible={cliente.flgFisica}
                maxLength={15}
                onChangedValue={(nrRG) => setCliente({ ...cliente, nrRG })}
              />

              <Textbox
                label='I.E.'
                id='txtIE'
                text={cliente.iEstadual}
                visible={!cliente.flgFisica}
                maxLength={30}
                onChangedValue={(iEstadual) =>
                  setCliente({ ...cliente, iEstadual })
                }
              />
            </div>
            {!cliente?.flgFisica && (
              <div className='col-2'>
                <Textbox
                  label='Insc. Mun.'
                  id='txtInscricaoMunicipal'
                  text={cliente.ieMunicipal}
                  maxLength={30}
                  onChangedValue={(ieMunicipal) =>
                    setCliente({ ...cliente, ieMunicipal })
                  }
                />
              </div>
            )}

            <div className='col-2'>
              <Textbox
                label='Emissor'
                id='txtEmissor'
                text={cliente.rgOrgaoEmissor}
                maxLength={3}
                onChangedValue={(rgOrgaoEmissor) =>
                  setCliente({ ...cliente, rgOrgaoEmissor })
                }
              />
            </div>

            <div className='col'>
              <RadioButton
                label='Sexo'
                outline
                size={BootstrapSizes.Small}
                visible={cliente.flgFisica}
                theme={Theme.Primary}
                selectedButton={cliente.nrSeqSexo === 1 ? 'M' : 'F'}
                buttons={sexo}
                onChange={(noSexo) => {
                  setCliente({
                    ...cliente,
                    nrSeqSexo: noSexo === 'F' ? 2 : 1,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                id='ddlEstadoCivil'
                label='Estado Civíl'
                searchDataSource={onSearchEstadoCivil}
                visible={cliente.flgFisica}
                selectedItem={cliente.estadoCivil}
                onSelectItem={(estadoCivil) =>
                  setCliente({
                    ...cliente,
                    estadoCivil,
                    nrSeqEstadoCivil: estadoCivil.nrSeqEstadoCivil,
                  })
                }
                dataSourceTextProperty='noEstadoCivil'
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-6'>
              <Textbox
                label='E-mail'
                id='txtEmail'
                text={cliente.email}
                maxLength={250}
                onChangedValue={(email) => setCliente({ ...cliente, email })}
              />
            </div>
            <div className='col-6'>
              <Textbox
                label='E-mail Nfe'
                id='txtEmailNfe'
                text={cliente.emailNfe}
                maxLength={250}
                onChangedValue={(emailNfe) =>
                  setCliente({ ...cliente, emailNfe })
                }
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='Envio Padrão'
                id='txtEnvioPadrao'
                searchDataSource={onSearchEnvioPadrao}
                selectedItem={cliente.envioPadrao}
                onSelectItem={(envioPadrao) =>
                  setCliente({
                    ...cliente,
                    envioPadrao,
                    nrEnvioPadrao: envioPadrao.nrEnvioPadrao,
                  })
                }
                dataSourceTextProperty='noEnvioPadrao'
              />
            </div>

            <div className='col-3'>
              <Textbox
                label='Telefone'
                text={cliente.fone}
                id='txtTelefone'
                maxLength={12}
                mask={MaskTypes.CellPhone}
                onChangedValue={(fone) => setCliente({ ...cliente, fone })}
              />
            </div>

            <div className='col-3'>
              <Textbox
                label='Celular(WhatsApp)'
                id='txtCelularWhastsApp'
                text={cliente.celular}
                mask={MaskTypes.CellPhone}
                onChangedValue={(celular) =>
                  setCliente({ ...cliente, celular })
                }
              />
            </div>

            <div className='col-3'>
              <Switch
                formControl
                label='Celular tem WhatsApp?'
                checked={cliente.possuiWhatsApp}
                onChange={(possuiWhatsApp) => {
                  setCliente({ ...cliente, possuiWhatsApp });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Cep'
                id='txtCep'
                text={cliente.endereco?.cep}
                maxLength={9}
                onChangedValue={(cep) => {
                  endereco = cliente.endereco;
                  setCliente({ ...cliente, endereco: { ...endereco, cep } });
                }}
              />
            </div>
            <div className='col-1 mt-3'>
              <Button
                outline
                theme={Theme.Primary}
                template={Button.Templates.Button}
                icon='search'
                tooltip='Pesquisar o endereço pelo CEP digitado.'
                onClick={onSearchCep}
              />
            </div>
            <div className='col-4'>
              <Textbox
                label='Endereço'
                id='txtEndereco'
                text={cliente.endereco?.noRua}
                maxLength={80}
                onChangedValue={(noRua) => {
                  endereco = cliente.endereco;
                  setCliente({ ...cliente, endereco: { ...endereco, noRua } });
                }}
              />
            </div>

            <div className='col-2'>
              <Textbox
                label='Número'
                id='txtNumero'
                text={cliente.endereco?.numero}
                mask={MaskTypes.Number}
                maxLength={20}
                onChangedValue={(numero) => {
                  endereco = cliente.endereco;
                  setCliente({ ...cliente, endereco: { ...endereco, numero } });
                }}
              />
            </div>

            <div className='col-3'>
              <Textbox
                label='Complemento'
                id='txtComplemento'
                text={cliente.endereco?.complemento}
                maxLength={80}
                onChangedValue={(complemento) => {
                  endereco = cliente.endereco;
                  setCliente({
                    ...cliente,
                    endereco: { ...endereco, complemento },
                  });
                }}
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Bairro'
                id='txtBairro'
                text={cliente.endereco?.bairro}
                maxLength={60}
                onChangedValue={(bairro) => {
                  endereco = cliente.endereco;
                  setCliente({ ...cliente, endereco: { ...endereco, bairro } });
                }}
              />
            </div>

            <div className='col-3'>
              <Autocomplete
                label='País'
                id='txtPais'
                searchDataSource={onSearchPais}
                selectedItem={pais}
                onSelectItem={(obj) => {
                  setPais(obj);
                }}
                dataSourceTextProperty='noPais'
              />
            </div>

            <div className='col-3'>
              <Autocomplete
                label='Estado'
                id='txtEstado'
                searchDataSource={onSearchEstado}
                selectedItem={estado}
                onSelectItem={(obj) => {
                  setEstado(obj);
                }}
                dataSourceTextProperty='noEstado'
              />
            </div>

            <div className='col-3'>
              <Autocomplete
                label='Cidade'
                id='txtCidade'
                searchDataSource={onSearchCidade}
                selectedItem={cidade}
                onSelectItem={(obj) => {
                  setCidade(obj);
                }}
                dataSourceTextProperty='noCidade'
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Carteira / Matrícula'
                id='txtCarteira'
                text={cliente.cdCliente02}
                maxLength={20}
                onChangedValue={(cdCliente02) =>
                  setCliente({ ...cliente, cdCliente02 })
                }
              />
            </div>

            <div className='col-3'>
              <Autocomplete
                label='Profissão'
                searchDataSource={onSearchProfissao}
                selectedItem={cliente.profissao}
                visible={cliente.flgFisica}
                onSelectItem={(profissao) =>
                  setCliente({
                    ...cliente,
                    profissao,
                    nrSeqProfissao: profissao.nrSeqProfissao,
                  })
                }
                dataSourceTextProperty='noProfissao'
              />
            </div>
            <div className='col'>
              {cliente.flgPermiteAlterarVendedor ? (
                <Autocomplete
                  label='Vendedor'
                  searchDataSource={onSearchVendedor}
                  selectedItem={cliente.vendedor}
                  onSelectItem={(vendedor) =>
                    setCliente({
                      ...cliente,
                      vendedor,
                      nrSeqPessoaVen: vendedor.nrSeqPessoa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              ) : (
                <Textbox
                  label='Vendedor '
                  text={cliente.vendedor.noPessoa}
                  readOnly
                />
              )}
            </div>
          </div>
          <div className='row mb-3' />
          {clienteempresa && (
            <div className='col-6'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                visible={clienteempresa}
                selectedItem={cliente.empresa}
                onSelectItem={(empresa) =>
                  setCliente({
                    ...cliente,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
          )}
        </div>
      </div>

      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Contatos Adicionais'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Tipo de Contato'
                  searchDataSource={onSearchSetor}
                  selectedItem={contatoAdicional.setor}
                  onSelectItem={(setor) =>
                    setContatoAdicional({
                      ...contatoAdicional,
                      setor,
                      nrSeqPessoaSetor: setor.nrSeqPessoaSetor,
                    })
                  }
                  dataSourceTextProperty='noPessoaSetor'
                />
              </div>
              {!cliente.flgFisica && (
                <div className='col-2'>
                  <Switch
                    label='Representante Legal'
                    formControl
                    checked={contatoAdicional.flgRepresentanteLegal}
                    tooltip='Informa se o contato é um representante legal do Cliente'
                    onChange={(flgRepresentanteLegal) => {
                      setContatoAdicional({
                        ...contatoAdicional,
                        flgRepresentanteLegal,
                      });
                    }}
                  />
                </div>
              )}
              {contatoAdicional.flgRepresentanteLegal && (
                <>
                  <div className='col-2'>
                    <Textbox
                      label='CPF'
                      text={contatoAdicional.nrCpf}
                      mask={MaskTypes.CPF}
                      onChangedValue={(nrCpf) => {
                        setContatoAdicional({ ...contatoAdicional, nrCpf });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='RG'
                      text={contatoAdicional.nrRg}
                      maxLength={15}
                      onChangedValue={(nrRg) => {
                        setContatoAdicional({ ...contatoAdicional, nrRg });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Celular'
                      mask={MaskTypes.CellPhone}
                      text={contatoAdicional.celular}
                      onChangedValue={(celular) =>
                        setContatoAdicional({
                          ...contatoAdicional,
                          celular,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data de Nascimento'
                      text={contatoAdicional.dtNascimento}
                      mask={MaskTypes.Date}
                      onChange={(dtNascimento) => {
                        setContatoAdicional({
                          ...contatoAdicional,
                          dtNascimento,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              <div className='col-3'>
                <Textbox
                  label='Nome'
                  text={contatoAdicional.nome}
                  maxLength={60}
                  onChangedValue={(nome) =>
                    setContatoAdicional({ ...contatoAdicional, nome })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Telefone'
                  text={contatoAdicional.fone}
                  maxLength={20}
                  onChangedValue={(fone) =>
                    setContatoAdicional({
                      ...contatoAdicional,
                      fone,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  label='E-mail'
                  text={contatoAdicional.email}
                  maxLength={80}
                  onChangedValue={(email) =>
                    setContatoAdicional({ ...contatoAdicional, email })
                  }
                />
              </div>
              <div className='col-2 widget-square p-3'>
                <Button
                  outline
                  className='px-4'
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Quick}
                  onClick={onAdicionaContato}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewContatos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsContatos}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Informações Bancárias'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <RadioButton
                    label='Tipo de Conta'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={cliente.informacaoBancaria?.tpContaCorrente}
                    buttons={tipoconta}
                    onChange={(tpContaCorrente) => {
                      informacaoBancaria = cliente.informacaoBancaria;
                      setCliente({
                        ...cliente,
                        informacaoBancaria: {
                          ...informacaoBancaria,
                          tpContaCorrente,
                        },
                      });
                    }}
                  />
                </div>

                <div className='col-4'>
                  <Autocomplete
                    label='Banco'
                    id='clienteBanco'
                    searchDataSource={onSearchBanco}
                    selectedItem={cliente.informacaoBancaria?.banco}
                    onSelectItem={(banco) => {
                      informacaoBancaria = cliente.informacaoBancaria;
                      setCliente({
                        ...cliente,
                        informacaoBancaria: {
                          ...informacaoBancaria,
                          banco,
                          nrSeqBanco: banco.nrSeqBanco,
                        },
                      });
                    }}
                    dataSourceTextProperty='noBanco'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Agência'
                    text={cliente.informacaoBancaria?.cdAgencia}
                    maxLength={8}
                    onChangedValue={(cdAgencia) => {
                      informacaoBancaria = cliente.informacaoBancaria;
                      setCliente({
                        ...cliente,
                        informacaoBancaria: {
                          ...informacaoBancaria,
                          cdAgencia,
                        },
                      });
                    }}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Conta'
                    text={cliente.informacaoBancaria?.cdContaCorrente}
                    maxLength={20}
                    onChangedValue={(cdContaCorrente) => {
                      informacaoBancaria = cliente.informacaoBancaria;
                      setCliente({
                        ...cliente,
                        informacaoBancaria: {
                          ...informacaoBancaria,
                          cdContaCorrente,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
