import React, { useState } from 'react';
import { BootstrapSizes, MimeTypes } from 'ui/Helpers/utils';
import { Modal, Button, FileUpload } from 'ui/components';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { importarLinhas } from 'core/services/TEL/grupoLinha';

interface IModalImportarLinhas {
  show: boolean;
  onClose: any;
  // eslint-disable-next-line no-undef
  setData: any;
  onSetMessage: (status: number, message: string) => void;
}

export default function ModalImportarLinhas({
  show,
  onClose,
  setData,
  onSetMessage,
}: IModalImportarLinhas) {
  const [importedFiles, setImportedFiles] = useState<Array<any>>([]);

  const onImport = async (): Promise<void> => {
    if (importedFiles.length > 0) {
      const { status, message: msg, value } = await importarLinhas(
        importedFiles[0]
      );

      setImportedFiles([]);
      onSetMessage(status, msg);
      setData({ data: value, show: false });
    }
  };

  return (
    //  @ts-expect-error
    <Modal
      show={show}
      title='Importar Linhas'
      onClose={onClose}
      size={BootstrapSizes.Medium}
      icon={['far', 'comment-alt']}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-3'>
          <div className='col'>
            <p>
              Envie um arquivo <strong>.csv</strong> com as colunas{' '}
              <strong>Linha</strong> e <strong>Observacoes</strong>
            </p>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <FileUpload
              files={importedFiles}
              onChange={(files: Array<any>) => {
                if (files.length > 0) {
                  if (files[0].size <= 5000000) {
                    setImportedFiles(files);
                  } else {
                    onSetMessage(
                      ResponseStatus.Error,
                      'Arquivo não pode ser maior que 5MB'
                    );
                  }
                } else {
                  setImportedFiles(files);
                }
              }}
              allowedMimeTypes={[MimeTypes.Types.Excel]}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Importar Linhas'
          theme={Theme.Success}
          onClick={onImport}
        />
        <Button text='Cancelar' theme={Theme.Danger} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
}
