import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getClientePessoa,
  saveClientePessoa,
  deleteCliente,
  getParametroClienteEmpresa,
} from 'core/services/FIN/cliente';
import { ICliente } from 'core/interfaces/FIN';
// @ts-expect-error
import ClientePanel from './clientePanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';
import ContatoAdicionalPanel from './contatoAdicionalPanel.tsx';
import PessoaReferencias from './pessoaReferenciaPanel.tsx';

interface IClienteState extends ICliente {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Cliente({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);
  const pessoaReferencias = useRef<any>(null);
  const contatosAdicionais = useRef<any>(null);

  const [clienteData, setClienteData] = useState<IClienteState | any>({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);
  const [clienteempresa, setClienteEmpresa] = useState(false);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const clearPessoaReferenciasPanel = () => {
    pessoaReferencias?.current?.clear();
  };

  const fillPessoaReferenciasPanel = () => {
    pessoaReferencias?.current?.fillGrid();
  };

  const clearContatosAdicionaisPanel = () => {
    contatosAdicionais?.current?.clear();
  };

  const fillContatosAdicionaisPanel = () => {
    contatosAdicionais?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();
  const getDataReferenciasGrid = () =>
    pessoaReferencias?.current?.getDataGrid();

  const getDataContatosGrid = () => contatosAdicionais?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();
    clearPessoaReferenciasPanel();
    clearContatosAdicionaisPanel();

    setClienteData({
      flgFisica: true,
      flgAtivo: true,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      clienteDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);
      const retornoCliente: ICliente | any = await getClientePessoa(
        nrSeqPessoa
      );

      if ('nrSeqPessoaCli' in retornoCliente) {
        setClienteData({
          ...retornoCliente,
          pessoa: { nrSeqPessoa: retornoCliente.nrSeqPessoaCli },
        });

        if (
          retornoCliente.clienteDocumentos &&
          retornoCliente.clienteDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();

        if (
          retornoCliente.clienteReferencias &&
          retornoCliente.clienteReferencias.length > 0
        ) {
          fillPessoaReferenciasPanel();
        } else clearPessoaReferenciasPanel();

        if (
          retornoCliente.contatosAdicionais &&
          retornoCliente.contatosAdicionais.length > 0
        ) {
          fillContatosAdicionaisPanel();
        } else clearContatosAdicionaisPanel();
      }
    } else {
      onNew();
    }
    setClienteEmpresa(await getParametroClienteEmpresa());

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    if (!pessoa) {
      setMessage({
        message: 'Necessário preencher as informações de Funcionário.',
        theme: Theme.Danger,
      });
    } else {
      const documentos = getDataDocumentsGrid();
      const referencias = getDataReferenciasGrid();
      const contatos = getDataContatosGrid();

      if (referencias !== undefined && referencias.length > 0) {
        referencias.forEach((referencia) => {
          if (referencia.flgPessoal === 'Não') {
            referencia.flgPessoal = false;
          }
        });
      }

      const { status, message: msg, value } = await saveClientePessoa({
        ...clienteData,
        ...pessoa,
        nrSeqEmpresa: clienteData.nrSeqEmpresa,
        flgFisica: pessoa.flgFisica,
        funcionarioDocumentos: documentos,
        funcionarioReferencias: referencias,
        contatosAdicionais: contatos,
      });

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      const text = value && value.length > 0 ? value.join() : null;

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();

        setWarning({ message: text, theme: Theme.Warning });
        onNew();
      }
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCliente(registryKey);

      setClienteData(status === ResponseStatus.Success ? {} : clienteData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Cliente'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={clienteData?.nrSeqPessoaCli > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={clienteData.pessoa?.nrSeqPessoa}
            data={clienteData}
            setData={setClienteData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Clientes',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <ClientePanel
                      clienteData={clienteData}
                      setClienteData={setClienteData}
                      clienteEmpresa={clienteempresa}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <ContatoAdicionalPanel
                      clienteData={clienteData}
                      setClienteData={setClienteData}
                      onSetMessage={onSetMessage}
                      ref={contatosAdicionais}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <PessoaReferencias
                      clienteData={clienteData}
                      setClienteData={setClienteData}
                      onSetMessage={onSetMessage}
                      ref={pessoaReferencias}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      clienteData={clienteData}
                      setClienteData={setClienteData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
