import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getAlmoxarifadoLocalPagined,
  printAlmoxarifadoLocal,
  deleteAllAlmoxarifadoLocal,
  getAlmoxarifadoLocalAutoComplete,
} from 'core/services/EST/almoxarifadoLocal';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { CSDSelPage, GridView, Autocomplete } from 'ui/components';

const columns = [
  { key: 'nrSeqAlmoxarifadoLocal', type: GridView.ColumnTypes.Checkbox },
  { key: 'noAlmoxarifadoLocal', title: 'Descrição' },
  {
    key: 'noCaminhoPai',
    title: 'Caminho',
    sortKey: 'nrSeqPlanoDeContaPai',
  },
  { key: 'flgAtivo', title: 'Ativo' },
];

export default function AlmoxarifadoLocal({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAlmoxarifadoLocalPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

      const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
        nrSeqUsuario: sessionUser.nrSeqUsuario,
      });

      setFiltros({
        almoxarifado: almoxarifados[0],
        nrSeqAlmoxarifado: almoxarifados[0].nrSeqAlmoxarifado,
      });

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: any) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'AlmoxarifadoLocal/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: any) => {
    const nrSeqAlmoxarifadoLocal = sourceRow.find(
      (e) => e.key === 'nrSeqAlmoxarifadoLocal'
    );
    onOpenMaintenance(nrSeqAlmoxarifadoLocal.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printAlmoxarifadoLocal(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllAlmoxarifadoLocal(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchAlmoxarifado = async (e: any) => {
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
    const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqUsuario: sessionUser.nrSeqUsuario,
    });
    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocal = async (e: any) => {
    const {
      almoxarifados: almoxarifadoLocal,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
      flgAtivo: true,
      flgNotItem: true,
    });
    return almoxarifadoLocal;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Almoxarifado Local'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Almoxarifado'
              searchDataSource={onSearchAlmoxarifado}
              selectedItem={filtros.almoxarifado}
              onSelectItem={(almoxarifado) => {
                setFiltros({
                  ...filtros,
                  almoxarifado,
                  nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                });
              }}
              dataSourceTextProperty='noAlmoxarifado'
            />
          </div>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Local'
              searchDataSource={onSearchAlmoxarifadoLocal}
              selectedItem={filtros.almoxarifadoLocal}
              onSelectItem={(almoxarifadoLocal) => {
                setFiltros({
                  ...filtros,
                  nrSeqAlmoxarifadoLocal:
                    almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                });
              }}
              dataSourceTextProperty='noAlmoxarifadoLocal'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              enableExcelExport
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowDoubleClick={onRowDoubleClick}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
