import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  CSDSelPage,
  GridView,
  Switch,
  DatePicker,
  Autocomplete,
  Textbox,
  ToolbarButtons,
  DropdownMulti,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { getOperadoraAutoComplete } from 'core/services/TEL/operadora';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getGrupoLinhaAutoComplete } from 'core/services/TEL/grupoLinha';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';
import { getChipAutoComplete } from 'core/services/TEL/chip';
import { getTipoDemandaAutoComplete } from 'core/services/HELP/tipoDemanda';

import {
  getDemandaPagined,
  getDemandaPaginedWithTeleCall,
  getDemandasPaginatedWithClickSignDocumentStatusUpdated,
  printDemanda,
} from 'core/services/HELP/demanda';
import { getStatusDemandaAutoComplete } from 'core/services/HELP/statusDemanda';
import { getUsuarioAutoComplete } from 'core/services/SEG';

import ModalProtocolo from './modalProtocolo';
import ModalEnviarEmail from './modalEnviarEmail';
import ModalImportarRelatorioOperadora from './modalImportarRelatorioOperadora';

const columns = [
  { key: 'nrSeqDemanda', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrSeqDemanda', title: 'Contrato' },
  { key: 'noEmpresa', title: 'Empresa', visible: false },
  { key: 'operadoraDestino.noOperadora', title: 'Operadora' },
  { key: 'plano.noPlano', title: 'Plano', visible: false },
  { key: 'noTipoChamada', title: 'Pacote', visible: false },
  {
    key: 'tipoDemanda.noTipoDemanda',
    title: 'Tipo',
  },
  { key: 'noCentroDeCusto', title: 'Centro Custo', visible: false },

  { key: 'chip.linhaFormatada', title: 'Linha' },
  { key: 'noCliente', title: 'Cliente' },
  { key: 'noUsuarioSolicitacao', title: 'Usu. Sol.', visible: false },
  {
    key: 'dtCad',
    title: 'Cadastro',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtEfetivacao',
    title: 'Data Efetivação',
    format: GridView.DataTypes.DateTime,
  },
  {
    key: 'dtPrevistaRenovacao',
    title: 'Renovação',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtPortabilidade',
    title: 'Portabilidade',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtEnvioOperadora',
    title: 'Enviado p/ Operador',
    format: GridView.DataTypes.Date,
    visible: false,
  },
  { key: 'noStatusDemanda', title: 'Status' },
  { key: 'noIteracao', title: 'Iteração' },
  { key: 'noObservacao', title: 'Observação', visible: false },
  { key: 'noStatusPortabilidadeMVNO', title: 'Status MVNO' },
  {
    key: 'dtAgendamento',
    title: 'Agendamento MVNO',
    format: GridView.DataTypes.DateTime,
  },
  {
    key: 'flgPossuiDocumentoAnexo',
    title: 'Possui Documento',
    format: GridView.DataTypes.Boolean,
  },
];

export default function Demanda({
  isActive,
  transaction,
  onOpenPage,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  const gridView = useRef();
  const dropDownTipoDemanda = useRef();
  const dropDownEmpresa = useRef();
  const dropDownOperadora = useRef();
  const dropDownStatusDemanda = useRef();

  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});
  const [selectedDemandas, setSelectedDemandas] = useState([]);
  const [flgPesquisado, setFlgPesquisado] = useState(false);

  const [tipoDemandaSelected, setTipoDemandaSelected] = useState([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);
  const [operadoraSelected, setOperadoraSelected] = useState([]);
  const [statusDemandaSelected, setStatusDemandaSelected] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchDemanda = async (filterPage) => {
    if (!loading) {
      setLoading(true);

      if (filterPage) {
        filters.page = filterPage.page;
        filters.totalByPage = filterPage.totalByPage;
      }

      const {
        data,
        pagination,
        message: msg,
        status,
      } = await getDemandaPagined(filters);
      if (msg) onSetMessage(status, msg);

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setFlgPesquisado(true);

      setLoading(false);
    }
  };

  const searchDemandaWithTeleCall = async (filterPage) => {
    setLoading(true);

    if (filterPage) {
      filters.page = filterPage.page;
      filters.totalByPage = filterPage.totalByPage;
    }
    const obj = { ...filters };

    const {
      data,
      pagination,
      message: msg,
      status,
    } = await getDemandaPaginedWithTeleCall(obj);
    if (msg) onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setFlgPesquisado(true);

    setLoading(false);
  };

  const onOpenMaintenanceNovoContrato = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Demanda/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onOpenMaintenance = async (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Demanda/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onOpenProtocolo = async () => {
    let selecteds = gridView.current.getCheckBoxValuesAt(0);
    let demandas = gridView.current.getDataSource();

    selecteds = selecteds.map(
      (column) => column.find((prop) => prop.key === 'nrSeqDemanda').value
    );

    demandas = demandas.filter((demanda) =>
      selecteds.includes(demanda.nrSeqDemanda)
    );

    setSelectedDemandas(demandas);

    setModalShow({ ...modalShow, protocolo: true });
  };

  const onOpenImportarRelatorio = () => {
    setModalShow({ ...modalShow, importarRelatorio: true });
  };

  const onOpenRegistrarMassivo = async () => {
    const transactionDemanda = findTransaction(TransacationCodes.Demanda);

    transactionDemanda.register = null;
    transactionDemanda.registryKey = null;
    onOpenTransaction(transactionDemanda, true, 'Demanda.aspx');
  };

  const onColumnSort = async (SortBy) => {
    setFilters({ ...filters, SortBy });
    await searchDemanda({ ...filters, SortBy });
  };

  const onPageSizeChange = async (totalByPage) => {
    setFilters({ ...filters, totalByPage });
    await searchDemanda({ ...filters, totalByPage });
  };

  const onPageChange = async (page) => {
    setFilters({ ...filters, page });
    await searchDemanda({ ...filters, page });
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqDemanda = sourceRow.find((e) => e.key === 'nrSeqDemanda');
    onOpenMaintenanceNovoContrato(nrSeqDemanda.value);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });

    onSetMessage(status, msg);

    return chips;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchGrupoLinhas = async (e) => {
    const {
      status,
      message: msg,
      gruposLinhas,
    } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });
    onSetMessage(status, msg);
    return gruposLinhas;
  };

  const onSearchOperadora = async (e) => {
    const { status, message: msg, operadoras } = await getOperadoraAutoComplete(
      {
        noOperadora: e,
      }
    );
    onSetMessage(status, msg);

    return operadoras.filter(
      (operadora) => operadora.noOperadora !== 'Importação Manual'
    );
  };

  const onSearchStatusDemanda = async (e) => {
    const { status, message: msg, data } = await getStatusDemandaAutoComplete({
      noStatusDemanda: e,
    });
    onSetMessage(status, msg);
    return data;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onSearchTipoDemanda = async (e) => {
    const {
      status,
      message: msg,
      tiposDemanda,
    } = await getTipoDemandaAutoComplete({
      noTipoDemanda: e,
    });
    onSetMessage(status, msg);
    return tiposDemanda;
  };

  const onPrint = async () => {
    setLoading(true);

    const { status, message: msg, value } = await printDemanda(filters);

    if (msg) onSetMessage(status, msg);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onOpenEnviarEmail = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const nrSeqDemandasSelecionadas = selecteds.map(
      (row) => row.find((prop) => prop.key === 'nrSeqDemanda').value
    );

    setModalShow({
      ...modalShow,
      enviarEmail: true,
      nrSeqDemandasSelecionadas,
    });
  };

  const onSearchUsuario = async (e) => {
    const {
      status,
      message: msg,
      data: usuarios,
    } = await getUsuarioAutoComplete({
      noPessoa: e,
    });

    onSetMessage(status, msg);

    return usuarios;
  };

  const onUpdateClickSignDocumentStatus = async () => {
    if (!loading) {
      setLoading(true);

      const {
        data,
        pagination,
        message: msg,
        status,
      } = await getDemandasPaginatedWithClickSignDocumentStatusUpdated();

      if (msg) onSetMessage(status, msg);

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setFlgPesquisado(true);

      setLoading(false);
    }
  };

  useEffect(async () => {
    dropDownTipoDemanda.current.loadDataSource(onSearchTipoDemanda);
    dropDownOperadora.current.loadDataSource(onSearchOperadora);
    dropDownEmpresa.current.loadDataSource(onSearchEmpresa);
    dropDownStatusDemanda.current.loadDataSource(onSearchStatusDemanda);
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Demanda'
      loading={loading}
      onSearch={searchDemanda}
      onPrint={() => onPrint()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Enviar E-mail'
          onClick={() => onOpenEnviarEmail()}
          tooltip='Os Emails serão enviados conforme os itens selecionados na lista, o totalizador indica conforme a pesquisa quantos itens ainda estão pendente para envio.'
        />
        <ToolbarButtons.Button
          text='Registrar Protocolo'
          onClick={() => onOpenProtocolo()}
          tooltip='Os protocolos serão registrados conforme os itens selecionados na lista, o totalizador indica conforme a pesquisa quantos itens ainda estão pendente de retorno.v'
        />
        <ToolbarButtons.Button
          text='Gerar Massivo'
          onClick={() => onOpenRegistrarMassivo()}
          tooltip=''
        />
        <ToolbarButtons.Button
          text='Importar Relatório Operadora'
          onClick={() => onOpenImportarRelatorio()}
          tooltip='Enviar o relatório da operadora para buscar e atualizar as demandas'
        />
        <ToolbarButtons.Button
          text='Atualizar Documentos Assinados via ClickSign'
          onClick={() => onUpdateClickSignDocumentStatus()}
          tooltip='Busca as Demandas que possuem documentos ClickSign que tiveram seu status atualizado'
        />
        {(filters.nrSeqStatusDemanda === 9 ||
          filters.nrSeqsStatusDemanda?.includes(9)) &&
          flgPesquisado &&
          (filters.nrSeqOperadora === 20 ||
            filters.nrSeqsOperadora?.includes(20)) && (
            <ToolbarButtons.Button
              text='Verificar Status MVNO'
              onClick={() => searchDemandaWithTeleCall()}
              tooltip=''
            />
          )}
      </ToolbarButtons>

      <div className='row mb-3'>
        <div className='col-3'>
          <DropdownMulti
            // @ts-expect-error
            label='Tipo Demanda'
            ref={dropDownTipoDemanda}
            dataSourcePropertyText='noTipoDemanda'
            dataSourcePropertyValue='nrSeqTipoDemanda'
            selectedItems={tipoDemandaSelected.item ?? []}
            onSelectItem={(item) => {
              setTipoDemandaSelected({ ...tipoDemandaSelected, item });
              setFilters({
                ...filters,
                nrSeqsTipoDemanda: item?.map((x) => x.nrSeqTipoDemanda),
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Prefixo / Número:'
            searchDataSource={onSearchChip}
            selectedItem={filters.chip}
            onSelectItem={(chip) =>
              setFilters({
                ...filters,
                chip,
                nrSeqChip: chip.nrSeqChip,
              })
            }
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col-3'>
          <DropdownMulti
            // @ts-expect-error
            label='Operadora'
            ref={dropDownOperadora}
            dataSourcePropertyText='noOperadora'
            dataSourcePropertyValue='nrSeqOperadora'
            selectedItems={operadoraSelected.item ?? []}
            onSelectItem={(item) => {
              setOperadoraSelected({ ...operadoraSelected, item });
              setFilters({
                ...filters,
                nrSeqsOperadora: item?.map((x) => x.nrSeqOperadora),
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Plano'
            searchDataSource={onSearchPlano}
            selectedItem={filters.plano}
            onSelectItem={(plano) =>
              setFilters({
                ...filters,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={filters.cliente}
            onSelectItem={(cliente) =>
              setFilters({
                ...filters,
                cliente,
                nrSeqPessoa: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <DropdownMulti
            // @ts-expect-error
            label='Empresa'
            ref={dropDownEmpresa}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqEmpresa'
            selectedItems={empresaSelected.item ?? []}
            onSelectItem={(item) => {
              setEmpresaSelected({ ...empresaSelected, item });
              setFilters({
                ...filters,
                nrSeqsEmpresa: item?.map((x) => x.nrSeqEmpresa),
              });
            }}
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            label='Pendentes Envio'
            onChange={(flgPendenteEnvio) =>
              setFilters({
                ...filters,
                flgPendenteEnvio,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Solicitação Inicial'
            text={filters.dtSolicitacaoInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtSolicitacaoInicial) => {
              setFilters({ ...filters, dtSolicitacaoInicial });
            }}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Solicitação Final'
            text={filters.dtSolicitacaoFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtSolicitacaoFinal) => {
              setFilters({ ...filters, dtSolicitacaoFinal });
            }}
          />
        </div>

        <div className='col'>
          <DropdownMulti
            // @ts-expect-error
            label='Status'
            ref={dropDownStatusDemanda}
            dataSourcePropertyText='noStatusDemanda'
            dataSourcePropertyValue='nrSeqStatusDemanda'
            selectedItems={statusDemandaSelected.item ?? []}
            onSelectItem={(item) => {
              setStatusDemandaSelected({ ...statusDemandaSelected, item });
              setFilters({
                ...filters,
                nrSeqsStatusDemanda: item?.map((x) => x.nrSeqStatusDemanda),
              });
            }}
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='Protocolo'
            text={filters.noProtocolo}
            onChangedValue={(noProtocolo) =>
              setFilters({ ...filters, noProtocolo })
            }
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='Código'
            text={filters.noCodigo}
            onChangedValue={(noCodigo) => setFilters({ ...filters, noCodigo })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Renovação Inicial'
            text={filters.dtRenovacaoInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtRenovacaoInicial) => {
              setFilters({ ...filters, dtRenovacaoInicial });
            }}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Renovação Final'
            text={filters.dtRenovacaoFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtRenovacaoFinal) => {
              setFilters({ ...filters, dtRenovacaoFinal });
            }}
          />
        </div>

        <div className='col'>
          <Autocomplete
            label='Grupo Linhas'
            selectedItem={filters.grupoLinha}
            searchDataSource={onSearchGrupoLinhas}
            onSelectItem={(grupoLinha) =>
              setFilters({
                ...filters,
                grupoLinha,
                nrSeqGrupoLinha: grupoLinha.nrSeqGrupoLinha,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
        <div className='col'>
          <Textbox
            label='N° Contrato'
            text={filters.nrSeqDemanda}
            maxLength={8}
            onChangedValue={(nrSeqDemanda) =>
              setFilters({ ...filters, nrSeqDemanda })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Usuario Solicitante'
            searchDataSource={onSearchUsuario}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={filters.usuarioSolicitacao}
            onSelectItem={(usuarioSolicitacao) =>
              setFilters({
                ...filters,
                usuarioSolicitacao,
                nrSeqUsuarioSolicitacao: usuarioSolicitacao.nrSeqUsuario,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>

      <ModalProtocolo
        show={modalShow.protocolo}
        demandas={selectedDemandas}
        onClose={() => {
          setModalShow({ ...modalShow, protocolo: false });
        }}
      />

      <ModalEnviarEmail
        show={modalShow.enviarEmail}
        nrSeqDemandasSelecionadas={modalShow.nrSeqDemandasSelecionadas}
        onClose={() => {
          setModalShow({ ...modalShow, enviarEmail: false });
        }}
      />
      <ModalImportarRelatorioOperadora
        show={modalShow.importarRelatorio}
        onClose={() => {
          setModalShow({ ...modalShow, importarRelatorio: false });
        }}
      />
    </CSDSelPage>
  );
}
