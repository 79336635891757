import React, { useState } from 'react';
import {
  Textbox,
  Panel,
  Autocomplete,
  Button,
  Switch,
  DatePicker,
  RadioButton,
  Textarea,
} from 'ui/components';
import { JustifyContent, BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { MaskTypes } from 'ui/Helpers/masks';
import { IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import InfoPagamentoDemanda from '../ComponentesDemanda/infoPagamentoDemanda';
import ImportadorDocumentosDemanda from '../ComponentesDemanda/importadorDocumentosDemanda';

interface IContratoMessage {
  cycle?: string;
  message?: string;
  color?: string;
  showMessage: boolean;
}

interface IManutencaoDemandaPortabilidade extends IManutencaoDemanda {
  onOpenTransaction: any;
  findTransaction: any;
  contratoMessage: IContratoMessage;
  onGerarContrato: () => void;
  onSolicitaPortabilidade: (params?: any) => Promise<void>;
  onSearchCentroDeCusto: (e: string) => Promise<Array<any>>;
  onSearchOperadora: (e: string) => Promise<Array<any>>;
  onSearchCliente: (e: string) => Promise<Array<any>>;
  onSearchPlano: (e: string) => Promise<Array<any>>;
  onSearchPlanoRateio: (e: string) => Promise<Array<any>>;
  getPlanoRateio: (nrSeq: number) => Promise<any>;
  onSearchPacoteDados: (e: string) => Promise<Array<any>>;
  onSearchChip: (e: string) => Promise<Array<any>>;
  onSearchPlanoModeloAparelho: (e: string) => Promise<Array<any>>;
  onSearchAparelho: (e: string) => Promise<Array<any>>;
  onSearchTituloEspecie: (e: string) => Promise<Array<any>>;
  onSelectCliente: (cliente: any, atualizaData?: boolean) => Promise<any>;
  dataTitular: any;
  setDataTitular: (params: any) => void;
}

export default function ManutencaoPortabilidade({
  data,
  setData,
  onSetMessage,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  infoPagamentoDefault,
  dataChip,
  setDataChip,
  onGerarContrato,
  setLoading,
  getPlanoRateio,
  onSearchCentroDeCusto,
  onSearchChip,
  onSearchCliente,
  onSearchOperadora,
  onSearchPacoteDados,
  onSearchPlano,
  onSearchPlanoRateio,
  onSelectCliente,
  setDataTitular,
  dataTitular,
}: IManutencaoDemandaPortabilidade) {
  const [trocarProvisorio, setTrocarProvisorio] = useState<boolean>(false);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState<boolean>(
    false
  );

  const titularButtons = [
    {
      text: 'Mesmo Titular',
      value: 'S',
    },
    {
      text: 'Portabilidade com Transferência de Titularidade',
      value: 'N',
    },
  ];

  const onTrocarProvisorio = () => {
    if (data.chipNovo)
      setData({
        ...data,
        nrPrefixoProvisorio: data.chipNovo?.linhaFormatada?.substring(1, 3),
      });
    if (trocarProvisorio) setTrocarProvisorio(false);
    else setTrocarProvisorio(true);
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <RadioButton
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={titularButtons}
            selectedButton={dataTitular.flgMesmoTitular}
            onChange={(flgMesmoTitular: string) => {
              setData({
                ...data,
                clienteTitularOld: undefined,
                nrSeqPessoaOld: undefined,
              });
              setDataTitular({ ...dataTitular, flgMesmoTitular });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nr. Linha Portada'
            readOnly={!data.flgPermiteAlterar}
            required
            text={dataChip.nrPrefixoLinha}
            mask={MaskTypes.CellPhone}
            onChangedValue={(nrPrefixoLinha: string) => {
              setDataChip({
                ...dataChip,
                nrPrefixoLinha,
              });
              setData({
                ...data,
                chip: {
                  ...data.chip,
                  nrPrefixoLinha,
                },
              });
            }}
            onBlur={(param1: any, param2: string): void => {
              if (param2 && param2.length < 11) {
                onSetMessage(ResponseStatus.Error, 'Informe o Prefixo.');
              }
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='ICCID'
            readOnly={!data.flgPermiteAlterar}
            text={dataChip.nrIccid}
            maxLength={20}
            onChangedValue={(nrIccid: string) => {
              setDataChip({
                ...dataChip,
                nrIccid,
              });
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nr. Linha Provisória'
            readOnly={
              !data.flgPermiteAlterar && data.flgPermiteAlterarLinhaProvisoria
            }
            mask={MaskTypes.CellPhone}
            text={data.chipNovo?.linhaFormatada}
            onChangedValue={(nrPrefixoLinhaProvisorio: string) => {
              const nrPrefixoLinha = nrPrefixoLinhaProvisorio.replace(
                /\D/g,
                ''
              );
              const nrPrefixoProvisorio = nrPrefixoLinha.substring(0, 2);

              const nrLinhaProvisoria = nrPrefixoLinha.substring(2);

              setData({
                ...data,
                nrPrefixoProvisorio,
                nrLinhaProvisoria,
                chipNovo: { nrPrefixoLinha },
              });
            }}
            onBlur={(_: any, param2: any) => {
              if (param2 && param2.length < 11) {
                onSetMessage(1, 'Informe o Prefixo.');
              }
            }}
          />
        </div>
        {data.nrSeqDemanda && data.flgPermiteAlterarLinhaProvisoria ? (
          <div className='col d-flex align-items-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Default}
              text='Trocar Linha Provisória'
              onClick={onTrocarProvisorio}
            />
          </div>
        ) : (
          <></>
        )}
        <div className='col-2'>
          {/* @ts-expect-error */}
          <DatePicker
            label='Data de Solicitação'
            readOnly={!data.flgPermiteAlterar}
            text={data.dtSolicitacao}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <DatePicker
            label='Data de Envio Operadora'
            readOnly={!data.flgPermiteAlterar}
            text={data.dtEnvioOperadora}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-1'>
          {/* @ts-expect-error */}
          <Textbox
            label='DDD'
            visible={trocarProvisorio}
            readOnly={!data.flgPermiteAlterar}
            text={data.nrPrefixoProvisorio}
            onChangedValue={(nrPrefixoProvisorio: string): void => {
              setData({
                ...data,
                nrPrefixoProvisorio,
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Pesquisar Linha'
            visible={trocarProvisorio}
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchChip}
            selectedItem={data.chipNovo}
            onSelectItem={(chipNovo: any) => {
              setData({
                ...data,
                chipNovo,
                nrLinhaProvisoria: chipNovo.nrLinha,
              });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            visible={trocarProvisorio}
            label='Números Especiais'
            checked={data.chip?.flgNumeroEspecial}
            onChange={(flgNumeroEspecial: boolean) =>
              setDataChip({
                ...dataChip,
                flgNumeroEspecial,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Cliente' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Cliente'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchCliente}
                    minLengthTextSearch={3}
                    placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                    selectedItem={data.cliente}
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.Cliente}
                    nrseqTransactionToOpen='nrSeqPessoaCli'
                    onSelectItem={async (cliente: any): Promise<void> => {
                      data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
                      data.cliente = cliente;

                      onSelectCliente(cliente);
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='RG'
                    text={data.cliente?.nrRG}
                    visible={data.cliente?.flgFisica}
                    maxLength={15}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='CPF'
                    text={data.cliente?.nrDocumento}
                    mask={MaskTypes.CPF}
                    visible={data.cliente?.flgFisica}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='CNPJ'
                    text={data.cliente?.nrDocumento}
                    mask={MaskTypes.CNPJ}
                    visible={!data.cliente?.flgFisica}
                    readOnly
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Endereço'
                    text={data.cliente?.endereco?.noRua}
                    readOnly
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Numero'
                    text={data.cliente?.endereco?.numero}
                    readOnly
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Bairro'
                    text={data.cliente?.endereco?.bairro}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Cidade'
                    text={data.cliente?.endereco?.cidade?.noCidade}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Cep'
                    text={data.cliente?.endereco?.cep}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='UF' readOnly />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='Email' text={data.cliente?.email} readOnly />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Telefone'
                    text={data.cliente?.fone}
                    mask={MaskTypes.CellPhone}
                    readOnly
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      {dataTitular.flgMesmoTitular === 'N' && (
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Titularidade da Linha de Origem'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Titular'
                      enabled={data.flgPermiteAlterar}
                      required
                      minLengthTextSearch={3}
                      searchDataSource={onSearchCliente}
                      selectedItem={data.clienteTitularOld}
                      findTransaction={findTransaction}
                      onOpenTransaction={onOpenTransaction}
                      transactionToOpen={TransacationCodes.Cliente}
                      nrseqTransactionToOpen='nrSeqPessoaCli'
                      onSelectItem={(clienteTitularOld: any) => {
                        setData({
                          ...data,
                          clienteTitularOld,
                          nrSeqPessoaOld: clienteTitularOld.nrSeqPessoaCli,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='RG'
                      text={data.clienteTitularOld?.nrRG}
                      maxLength={15}
                      visible={data.clienteTitularOld?.flgFisica}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='CPF'
                      text={data.clienteTitularOld?.nrDocumento}
                      mask={MaskTypes.CPF}
                      visible={data.clienteTitularOld?.flgFisica}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='CNPJ'
                      text={data.clienteTitularOld?.nrDocumento}
                      mask={MaskTypes.CNPJ}
                      visible={
                        data.clienteTitularOld &&
                        !data.clienteTitularOld?.flgFisica
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Endereço'
                      text={data.clienteTitularOld?.endereco?.noRua}
                      readOnly
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Numero'
                      text={data.clienteTitularOld?.endereco?.numero}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Bairro'
                      text={data.clienteTitularOld?.endereco?.bairro}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Cidade'
                      text={data.clienteTitularOld?.endereco?.cidade?.noCidade}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Cep'
                      text={data.clienteTitularOld?.endereco?.cep}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='UF'
                      text={
                        data.clienteTitularOld?.endereco?.cidade?.estado
                          .cdEstado
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Email'
                      text={data.clienteTitularOld?.email}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Telefone'
                      text={data.clienteTitularOld?.fone}
                      mask={MaskTypes.CellPhone}
                      readOnly
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Operadora Origem'
              enabled={data.flgPermiteAlterar}
              required
              searchDataSource={onSearchOperadora}
              selectedItem={data.operadoraOrigem}
              onSelectItem={(operadoraOrigem: any) =>
                setData({
                  ...data,
                  operadoraOrigem,
                  nrSeqOperadoraOrigem: operadoraOrigem.nrSeqOperadora,
                  plano: undefined,
                  tipoChamada: undefined,
                })
              }
              dataSourceTextProperty='noOperadora'
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Operadora Destino'
              enabled={data.flgPermiteAlterar}
              required
              searchDataSource={onSearchOperadora}
              selectedItem={data.operadoraDestino}
              onSelectItem={(operadoraDestino: any) =>
                setData({
                  ...data,
                  operadoraDestino,
                  nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
                  operadora: operadoraDestino,
                  nrSeqOperadora: operadoraDestino.nrSeqOperadora,
                  plano: undefined,
                  tipoChamada: undefined,
                  vlrPacoteEncargoManual: undefined,
                  planoRateioEmpresa: undefined,
                  nrSeqPlanoRateioEmpresa: undefined,
                })
              }
              dataSourceTextProperty='noOperadora'
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Centro de Custo'
              enabled={data.flgPermiteAlterar}
              searchDataSource={onSearchCentroDeCusto}
              selectedItem={data.centroDeCusto}
              onSelectItem={(centroDeCusto: any) =>
                setData({
                  ...data,
                  centroDeCusto,
                  nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                })
              }
              dataSourceTextProperty='noCentroDeCusto'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Plano'
              enabled={data.flgPermiteAlterar}
              placeholder={
                !data.nrSeqOperadoraDestino
                  ? 'Selecione a Operadora Destino'
                  : ''
              }
              required
              searchDataSource={onSearchPlano}
              selectedItem={data.plano}
              onSelectItem={(plano: any) =>
                setData({
                  ...data,
                  plano,
                  nrSeqPlano: plano.nrSeqPlano,
                  tipoChamada: undefined,
                  vlrPacoteEncargoManual: undefined,
                  planoRateioEmpresa: undefined,
                  nrSeqPlanoRateioEmpresa: undefined,
                })
              }
              dataSourceTextProperty='noPlano'
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Pacote'
              placeholder={
                !data.plano?.nrSeqPlano ||
                !data.centroDeCusto?.nrSeqCentroDeCusto
                  ? 'Selecione Centro de Custo e o Plano'
                  : ''
              }
              required
              searchDataSource={onSearchPacoteDados}
              selectedItem={data.tipoChamada}
              onSelectItem={async (tipoChamada: any): Promise<void> => {
                if ('nrSeqTipoChamada' in tipoChamada) {
                  const planoRateio = await getPlanoRateio(
                    tipoChamada.nrSeqTipoChamada
                  );

                  if (planoRateio) {
                    setHasPlanoRateioDefault(true);

                    setData({
                      ...data,
                      tipoChamada,
                      nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                      vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                      planoRateioEmpresa: planoRateio,
                      nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                    });
                  } else {
                    setHasPlanoRateioDefault(false);

                    setData({
                      ...data,
                      tipoChamada,
                      nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                      nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                      vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                    });
                  }
                } else {
                  setHasPlanoRateioDefault(false);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                  });
                }
              }}
              dataSourceTextProperty='noTipoChamada'
            />
          </div>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='Valor Pacote'
              text={data.vlrPacoteEncargoManual}
              mask={MaskTypes.Decimal}
              readOnly={
                !data.tipoChamada?.flgPermiteEditar && !data.flgPermiteAlterar
              }
              onChangedValue={(vlrPacoteEncargoManual: number) => {
                setData({
                  ...data,
                  vlrPacoteEncargoManual,
                });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4'>
            <Autocomplete
              label='Plano de Rateio Empresa'
              searchDataSource={onSearchPlanoRateio}
              enabled={
                data.flgPermiteAlterar &&
                data.empresa?.nrSeqEmpresa &&
                !hasPlanoRateioDefault
              }
              placeholder={
                !data.empresa?.nrSeqEmpresa ? 'Seleciona a Empresa' : ''
              }
              selectedItem={data.planoRateioEmpresa}
              onSelectItem={(planoRateioEmpresa: any) => {
                setData({
                  ...data,
                  planoRateioEmpresa,
                  nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
                });
              }}
              dataSourceTextProperty='noPlano'
            />
          </div>
          <InfoPagamentoDemanda
            data={data}
            setData={setData}
            onSetMessage={onSetMessage}
            setLoading={setLoading}
            infoPagamentoDefault={infoPagamentoDefault}
          />
        </div>
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textarea
              maxLength={2000}
              readOnly={!data.flgPermiteAlterar}
              label='Observação'
              text={data.noObservacao}
              onChangedValue={(noObservacao: string) => {
                setData({
                  ...data,
                  noObservacao,
                });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div
            className='col'
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Default}
              text='Gerar Contrato'
              onClick={onGerarContrato}
              className='width80'
            />
          </div>
        </div>
        <ImportadorDocumentosDemanda
          data={data}
          documentos={documentos}
          setDocumentos={setDocumentos}
          onSetMessage={onSetMessage}
          setDataListDocRemove={setDataListDocRemove}
        />
      </div>
    </>
  );
}
