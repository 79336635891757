import Chip from '../TEL/chip';
import TipoDemanda from './tipoDemanda';
import TipoChamada from '../TEL/tipoChamada';
import Plano from '../TEL/plano';

import Operadora from '../TEL/operadora';
import Cliente from '../FIN/cliente';
import { FormaPagamento, PessoaInformacaoBancaria } from '../FIN';
import { Vencimento } from '../ASS';
import { Aparelho, CentroDeCusto, ParcelamentoAparelho } from '../TEL';
import { Empresa, Endereco } from '../SEG';
import StatusDemanda from './statusDemanda';
import PlanoModeloAparelho from '../TEL/planoModeloAparelho';

export default class Demanda {
  constructor(jsonObject = {}) {
    this.nrSeqDemanda = jsonObject.nrSeqDemanda;
    this.nrSeqStatusDemanda = jsonObject.nrSeqStatusDemanda;
    this.nrSeqTipoDemanda = jsonObject.nrSeqTipoDemanda;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.dtSolicitacao = jsonObject.dtSolicitacao;
    this.dtEnvioOperadora = jsonObject.dtEnvioOperadora;
    this.dtRetorno = jsonObject.dtRetorno;
    this.noObservacao = jsonObject.noObservacao;
    this.noProtocolo = jsonObject.noProtocolo;
    this.nrSeqUsuarioSolicitacao = jsonObject.nrSeqUsuarioSolicitacao;
    this.nrSeqUsuarioEnvioOperadora = jsonObject.nrSeqUsuarioEnvioOperadora;
    this.nrSeqUsuarioRetorno = jsonObject.nrSeqUsuarioRetorno;
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.nrSeqEncargoManual = jsonObject.nrSeqEncargoManual;
    this.nrSeqChipNovo = jsonObject.nrSeqChipNovo;
    this.nrSeqPacoteSMS = jsonObject.nrSeqPacoteSMS;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.nrSeqTipoChamadaDados = jsonObject.nrSeqTipoChamadaDados;
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.nrSeqParcelamentoAparelho = jsonObject.nrSeqParcelamentoAparelho;
    this.nrSeqClienteLinhaOriginal = jsonObject.nrSeqClienteLinhaOriginal;
    this.nrSeqOperadoraOrigem = jsonObject.nrSeqOperadoraOrigem;
    this.nrSeqOperadoraDestino = jsonObject.nrSeqOperadoraDestino;
    this.nrSeqAparelhoAntigo = jsonObject.nrSeqAparelhoAntigo;
    this.nrProcedimento = jsonObject.nrProcedimento;
    this.nrSeqAssocPlanoConv = jsonObject.nrSeqAssocPlanoConv;
    this.nrSeqPessoaAss = jsonObject.nrSeqPessoaAss;
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqDemandaPrincipal = jsonObject.nrSeqDemandaPrincipal;
    this.dtPrevistaRenovacao = jsonObject.dtPrevistaRenovacao;
    this.vlrPacoteEncargoManual = jsonObject.vlrPacoteEncargoManual;
    this.nrOportunidade = jsonObject.nrOportunidade;

    this.noEmpresa = jsonObject.noEmpresa;
    this.noOperadora = jsonObject.noOperadora;
    this.noStatusDemanda = jsonObject.noStatusDemanda;
    this.noCliente = jsonObject.noCliente;
    this.noTipoChamada = jsonObject.noTipoChamada;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.noUsuarioSolicitacao = jsonObject.noUsuarioSolicitacao;
    this.documento = jsonObject.documento;
    this.chip = new Chip(jsonObject.chip ?? {});
    this.chipNovo = new Chip(jsonObject.chipNovo ?? {});
    this.tipoDemanda = new TipoDemanda(jsonObject.tipoDemanda ?? {});
    this.tipoChamada = new TipoChamada(jsonObject.tipoChamada ?? {});
    this.operadora = new Operadora(jsonObject.operadora ?? {});
    this.operadoraOrigem = new Operadora(jsonObject.operadoraOrigem ?? {});
    this.operadoraDestino = new Operadora(jsonObject.operadoraDestino ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.clienteTitularOld = new Cliente(jsonObject.clienteTitularOld ?? {});
    this.clientePortabilidade = new Cliente(
      jsonObject.clientePortabilidade ?? {}
    );
    this.plano = new Plano(jsonObject.plano ?? {});
    this.planoRateioEmpresa = new Plano(jsonObject.planoRateioEmpresa ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.informacaoBancaria = new PessoaInformacaoBancaria(
      jsonObject.informacaoBancaria ?? {}
    );
    this.vencimento = new Vencimento(jsonObject.vencimento ?? {});
    this.centroDeCusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.pacoteDados = new TipoChamada(jsonObject.pacoteDados ?? {});
    this.dtRenovacao = new Date(this.dtPrevistaRenovacao).toLocaleDateString(
      'pt-BR'
    );
    this.vlrVenda = jsonObject.vlrVenda;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.flgMesmoTitular = jsonObject.flgMesmoTitular;
    this.flgTipoPessoa = jsonObject.flgTipoPessoa;
    this.nrSeqPessoaOld = jsonObject.nrSeqPessoaOld;
    this.dtPortabilidade = jsonObject.dtPortabilidade;
    this.noHoraInicioFim = jsonObject.noHoraInicioFim;
    this.nrSeqHoraInicioFim = jsonObject.nrSeqHoraInicioFim;
    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar;
    this.flgExibeAgendaPortabilidade = jsonObject.flgExibeAgendaPortabilidade;
    this.flgPossuiDocumentoAnexo = jsonObject.flgPossuiDocumentoAnexo;
    this.dtPortabilidadeStr = jsonObject.dtPortabilidadeStr;
    this.dtPrevistaPortabilidade = jsonObject.dtPrevistaPortabilidade;
    this.noIteracao = jsonObject.noIteracao;
    this.status = jsonObject.status;
    this.idsDocRemove = jsonObject.idsDocRemove;
    this.noIteracoes = jsonObject.noIteracoes;
    this.nrSeqVencimento = jsonObject.nrSeqVencimento;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqPlano = jsonObject.nrSeqPlano;
    this.nrSeqPlanoRateioEmpresa = jsonObject.nrSeqPlanoRateioEmpresa;
    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;
    this.nrLinhaProvisoria = jsonObject.nrLinhaProvisoria;
    this.nrPrefixoProvisorio = jsonObject.nrPrefixoProvisorio;
    this.flgPermiteAlterarLinhaProvisoria =
      jsonObject.flgPermiteAlterarLinhaProvisoria;
    this.noStatusPortabilidadeMVNO = jsonObject.noStatusPortabilidadeMVNO;
    this.dtAgendamento = jsonObject.dtAgendamento;
    this.dtCad = jsonObject.dtCad;
    this.demandasFilhas = jsonObject.demandasFilhas ?? [];
    this.statusDemanda = new StatusDemanda(jsonObject.statusDemanda ?? {});
    this.documentos = jsonObject.documentos;
    this.nrSeqStatusDemandaNovo = jsonObject.nrSeqStatusDemandaNovo;
    this.noStatusDemandaNovo = jsonObject.noStatusDemanda;
    this.noCor = jsonObject.noCor;
    this.tituloReceber = jsonObject.tituloReceber;
    this.parcelamentoAparelho = new ParcelamentoAparelho(
      jsonObject.parcelamentoAparelho ?? {}
    );
    this.aparelho = new Aparelho(jsonObject.aparelho ?? {});
    this.planoModeloAparelho = new PlanoModeloAparelho(
      jsonObject.planoModeloAparelho ?? {}
    );
    this.qtdChips = jsonObject.qtdChips;
    this.nrPrefixoChipsSolicitado = jsonObject.nrPrefixoChipsSolicitado;
    this.nrSeqEndereco = jsonObject.nrSeqEndereco;
    this.endereco = new Endereco(jsonObject.endereco ?? {});
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.chipsSolicitados = jsonObject.chipsSolicitados ?? [];
    this.nrSeqChamado = jsonObject.nrSeqChamado;
    this.flgCobrarEncargoTipoDemanda = jsonObject.flgCobrarEncargoTipoDemanda;
    this.nrIccid = jsonObject.nrIccid;
    this.flgObrigaRetencao = jsonObject.flgObrigaRetencao;
    this.vlrEncargoTipoDemanda = jsonObject.vlrEncargoTipoDemanda;
    this.dtEfetivacao = jsonObject.dtEfetivacao;
    this.flgBaseAbrutelecom = jsonObject.flgBaseAbrutelecom;
  }

  toJson = () => JSON.stringify(this);
}
