import React, { useState, useEffect, useRef } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
  formatNumber,
} from 'ui/Helpers/utils';
import {
  postUploadPedagios,
  postImportPedagios,
  postImportPedagiosComFinanceiro,
} from 'core/services/FRO/pedagio';
import {
  Panel,
  Notification,
  FileUpload,
  Loader,
  Button,
  GridView,
  Switch,
  Textbox,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ImportPedagio(props) {
  const gridView = useRef();
  const gridViewNotImport = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [importedPedagios, setImportedPedagios] = useState({});
  const [dataTit, setDataTit] = useState({});
  const [vlrTotal, setVlrTotal] = useState(0);
  const [geraTituloPagar, setGeraTituloPagar] = useState(true);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setImportedFile([]);

    setLoading(false);
  }, []);

  const columnsResumo = [
    { key: 'placa', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'placa', title: 'Placa' },
    { key: 'tag', title: 'Tag' },
    {
      key: 'nrCategoria',
      title: 'Categoria',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'nrPassagens',
      title: 'Nr. Passagens',
      sortable: true,
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'vlrTotalImportadoPlaca',
      title: 'Valor Total',
      format: GridView.DataTypes.Decimal,
    },
  ];

  const columnsPedagiosInconsistentes = [
    { key: 'placa', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'placa', title: 'Placa', colorText: 'noColorInconsistencia' },
    {
      key: 'nrPassagens',
      title: 'Nr. Passagens',
      colorText: 'noColorInconsistencia',
    },
    {
      key: 'tipoUso',
      title: 'Uso',
      sortable: false,
      colorText: 'noColorInconsistencia',
    },

    {
      key: 'vlrTotalImportadoPlaca',
      title: 'Valor Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'noColorInconsistencia',
    },
  ];
  const uploadFile = async () => {
    setLoading(true);

    if (!importedFile)
      setMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const {
        status,
        message: msg,
        pedagios,
        resumoPedagios,
        tituloPagarGerado,
        pedagiosInconsistentes,
      } = await postUploadPedagios(importedFile[0]);

      setVlrTotal(tituloPagarGerado.vlrTitulo);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Warning ? Theme.Warning : Theme.Danger,
      });

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(resumoPedagios);

        if (gridViewNotImport && gridViewNotImport.current)
          gridViewNotImport?.current?.setDataSource(pedagiosInconsistentes);
      }

      if (pedagios && pedagios.length > 0) {
        pedagios.forEach((pedagio) => {
          pedagio.tituloPagar = {};
        });
      }

      setImportedPedagios({
        pedagios,
        tituloPagarGerado,
        resumoPedagios,
        pedagiosInconsistentes,
      });
    }

    setLoading(false);
  };

  const onSend = () => {
    props.onClose();
    setImportedPedagios({});
  };

  const onSave = async () => {
    setLoading(true);

    let data = {};
    if (geraTituloPagar) {
      data = await postImportPedagiosComFinanceiro(
        importedFile[0],
        dataTit.nrTit,
        dataTit.dtVencimento
      );
    } else {
      data = await postImportPedagios(importedFile[0]);
    }

    const { status, message: msg } = data;

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource([]);

      if (gridViewNotImport && gridViewNotImport.current)
        gridViewNotImport.current.setDataSource([]);
      setImportedPedagios({});
    }
    setLoading(false);
  };

  return (
    <Panel>
      <Panel.Header
        title='Importar Pedágios'
        theme={Theme.Primary}
        onClose={() => onSend()}
        icon='cloud-upload-alt'
        size={BootstrapSizes.Large}
      />

      <Panel.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage()}
            />
          </div>
        )}

        <div className='row'>
          <div className='row mb-3'>
            <div className='col  mt-3'>
              <FileUpload
                files={importedFile}
                onChange={(files) => setImportedFile(files)}
                allowedMimeTypes={[MimeTypes.Types.Excel]}
              />
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-2 mt-3'>
            <Button
              size={BootstrapSizes.Small}
              icon='cloud-upload-alt'
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={uploadFile}
              text='Importar'
            />
          </div>

          <div className='col-2 mt-3 '>
            <Button
              size={BootstrapSizes.Small}
              icon={['fas', 'times']}
              theme={Theme.Danger}
              template={Button.Templates.Default}
              onClick={() => onSend()}
              text='Cancelar'
            />
          </div>

          <div className='col-2 '>
            <Switch
              formControl
              checked={geraTituloPagar}
              label='Gera Pagar'
              onChange={(gera) => setGeraTituloPagar(gera)}
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={30}
              label='Nr. Documento'
              text={dataTit.nrTit}
              onChangedValue={(nrTit) => setDataTit({ ...dataTit, nrTit })}
            />
          </div>
          <div className='col mb-3'>
            <DatePicker
              label='Data de Vencimento'
              text={dataTit.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setDataTit({ ...dataTit, dtVencimento })
              }
            />
          </div>
          <div className='col-2 mt-3 '>
            Valor Total: {formatNumber(vlrTotal)}
          </div>
        </div>
      </Panel.Body>
      {importedPedagios.resumoPedagios && (
        <Panel.Footer>
          <div className='row'>
            <div className='col-6 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                icon='check'
                text='Confirmar'
                onClick={() => onSave()}
              />
            </div>
          </div>
        </Panel.Footer>
      )}
      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            title='Resumo de passagens de pedágios para importar'
            size={BootstrapSizes.Large}
            theme={Theme.Light}
          />

          <Panel.Body>
            <div className='row mt-4'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsResumo}
                  offlineData
                  showSelectSizes={false}
                  sumFields
                  enableExcelExport
                  gridSizeList={[5000]}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            title='Lançamentos de pedágio que não serão importados'
            size={BootstrapSizes.Large}
            theme={Theme.Light}
          />

          <Panel.Body>
            <div className='row mt-4'>
              <div className='col mb-3'>
                <GridView
                  ref={gridViewNotImport}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsPedagiosInconsistentes}
                  showSelectSizes={false}
                  sumFields
                  enableExcelExport
                  gridSizeList={[5000]}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </Panel>
  );
}
